import { useEffect, useMemo, useRef, useState } from "react";

import { Link } from "react-router-dom";

import { useSnackbar } from "context/Snackbar/SnackbarContext";

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
  Link as MuiLink,
  TextField,
  Menu,
  MenuItem,
  CircularProgress,
  alpha,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import DownloadIcon from "@mui/icons-material/Download";

import {
  getFirebaseController,
  getDownloadURL,
} from "database/FirebaseController";
import { UpdateChannel, Version } from "database/DataTypes";
import { CHANNEL_MAPPER, getDefaultVersion } from "database/DataDefaultValues";

import DashboardContainer from "components/Dashboard/DashboardContainer";
import ConfirmationModal from "components/BaseModal/ConfirmationModal";
import { BasicModal } from "components/BaseModal/BaseModal";

import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";

import { createMD5 } from "hash-wasm";
import { IHasher } from "hash-wasm/dist/lib/WASMInterface";
import moment from "moment";
import prettyBytes from "pretty-bytes";
import _ from "lodash";

import Dropzone from "react-dropzone";
import { getContrastShade } from "theme/reliveItTheme";

type UploadModalParams = {
  isOpen: boolean;
  channel: UpdateChannel;
};

const getFileHash = async (file) => {
  const chunkSize = 64 * 1024 * 1024;
  const fileReader = new FileReader();
  let hasher: IHasher | null = null;

  const hashChunk = (chunk) => {
    return new Promise((resolve, reject) => {
      fileReader.onload = async (e) => {
        if (e.target?.result && hasher) {
          const view = new Uint8Array(e.target.result as ArrayBufferLike);
          hasher.update(view);

          resolve(true);
        }
      };

      fileReader.readAsArrayBuffer(chunk);
    });
  };

  const hashFile = async (file) => {
    if (hasher) {
      hasher.init();
    } else {
      hasher = await createMD5();
    }

    const chunkNumber = Math.floor(file.size / chunkSize);

    for (let i = 0; i <= chunkNumber; i++) {
      const chunk = file.slice(
        chunkSize * i,
        Math.min(chunkSize * (i + 1), file.size),
      );
      await hashChunk(chunk);
    }

    const hash = hasher.digest();

    return Promise.resolve(hash);
  };

  return await hashFile(file).then((hash) => hash);
};

const UploadModal = ({
  uploadModalParams,
  getVersionList,
  onClose,
}: {
  uploadModalParams: UploadModalParams;
  getVersionList: () => void;
  onClose: () => void;
}) => {
  const [versionNumber, setVersionNumber] = useState("");
  const [isError, setIsError] = useState(false);

  const [fullUpdateFile, setFullUpdateFile] = useState<File | null>(null);
  const [partialUpdateFile, setPartialUpdateFile] = useState<File | null>(null);

  const [clear, setClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const totalFileSizeRef = useRef(0);
  const progressTextRef = useRef<HTMLSpanElement>();

  useEffect(() => {
    setFullUpdateFile(null);
    setPartialUpdateFile(null);
    setIsError(false);
    setIsLoading(false);
    setIsUploading(false);
    setVersionNumber("");

    totalFileSizeRef.current = 0;
  }, [clear]);

  const setProgressText = (progress: number) => {
    if (progressTextRef.current) {
      progressTextRef.current.innerText = Math.round(progress).toString();
    }
  };

  const uploadFile = async (file: Blob, location: string) => {
    return new Promise((resolve, reject) => {
      const uploadTask = getFirebaseController().uploadFileListener(
        file,
        location,
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / totalFileSizeRef.current) * 100;

          setProgressText(progress);
        },
        (err) => {
          reject(err);
        },
        async () => {
          setProgressText(100);

          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

          resolve(downloadUrl);
        },
      );
    });
  };

  const { setSnackbarProps } = useSnackbar();

  const handleUpload = async () => {
    if (!versionNumber || isError) {
      setSnackbarProps({
        open: true,
        content: `Version number is invalid.`,
        severity: "error",
      });

      return;
    }

    if (!fullUpdateFile || !partialUpdateFile) {
      setSnackbarProps({
        open: true,
        content: `Update zip file cannot be empty.`,
        severity: "error",
      });

      return;
    }

    setIsLoading(true);

    const uploadPromises: Promise<any>[] = [];
    const hashPromises: Promise<any>[] = [];
    const fileSizes: number[] = [0, 0];
    const channelName = CHANNEL_MAPPER[uploadModalParams.channel];
    const location = `Versions/${_.capitalize(channelName)}/${versionNumber}`;

    const firebaseController = getFirebaseController();

    const existingVersion =
      await firebaseController.Version.getVersionsByNumber(
        versionNumber,
        uploadModalParams.channel,
      );

    if (existingVersion.length > 0) {
      setSnackbarProps({
        open: true,
        content: `${_.capitalize(
          channelName,
        )} version ${versionNumber} was uploaded.`,
        severity: "error",
      });

      setIsError(true);
      setIsLoading(false);
      setIsUploading(false);

      return;
    }

    setIsUploading(true);

    totalFileSizeRef.current = 0;
    totalFileSizeRef.current += fullUpdateFile.size;
    totalFileSizeRef.current += partialUpdateFile.size;

    fileSizes[0] = fullUpdateFile.size;
    fileSizes[1] = partialUpdateFile.size;

    uploadPromises.push(uploadFile(fullUpdateFile, location));
    uploadPromises.push(uploadFile(partialUpdateFile, location));

    hashPromises.push(getFileHash(fullUpdateFile));
    hashPromises.push(getFileHash(partialUpdateFile));

    Promise.all([...uploadPromises, ...hashPromises]).then(async (data) => {
      const [fullDownloadUrl, partialDownloadUrl, fullMd5, partialMd5] = data;

      const newVersion: Version = {
        ...getDefaultVersion(),
        channel: uploadModalParams.channel,
        epochTime: moment().unix(),
        fullDownloadUrl: fullDownloadUrl || "",
        fullMd5,
        fullSize: fileSizes[0],
        partialDownloadUrl: partialDownloadUrl || "",
        partialMd5,
        partialSize: fileSizes[1],
        storageLocation: location,
        version: versionNumber,
      };

      await firebaseController.Version.addVersion(newVersion);

      onClose();
      setClear(!clear);

      setIsLoading(false);
      setIsUploading(false);
      getVersionList();

      setSnackbarProps({
        open: true,
        content: `${_.capitalize(
          CHANNEL_MAPPER[uploadModalParams.channel],
        )} version ${versionNumber} uploaded successfully!`,
      });
    });
  };

  const ModalHeader = () => {
    return (
      <>
        {isUploading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress size={14} />

            <Typography
              sx={{ ml: 1, fontSize: 20, lineHeight: 1, fontWeight: "bold" }}
            >
              Uploading -{" "}
              <Box component={"span"} ref={progressTextRef}>
                10
              </Box>
              %
            </Typography>
          </Box>
        ) : (
          <Typography sx={{ fontSize: 20, lineHeight: 1, fontWeight: "bold" }}>
            {`New ${CHANNEL_MAPPER[uploadModalParams.channel]} version`}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <BasicModal
        open={uploadModalParams.isOpen}
        disabled={isLoading}
        onClose={onClose}
        onDone={handleUpload}
        onCancel={onClose}
        HeaderComponent={<ModalHeader />}
      >
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: 12, fontWeight: "bold", mb: 0.5 }}>
            Version:
          </Typography>

          <TextField
            disabled={isLoading}
            error={isError}
            value={versionNumber}
            onChange={(e) => {
              const value = e.target.value;

              if (value.match(/^[\d.]+$/) || !value) {
                setVersionNumber(value);

                if (value.match(/^(\d+\.)?(\d+\.)?(\*|\d+)$/)) {
                  setIsError(false);
                } else {
                  setIsError(true);
                }
              }
            }}
            size="small"
            variant="outlined"
            autoFocus
            placeholder="1.0.0"
          />
        </Box>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 12, fontWeight: "bold", mb: 0.5 }}>
                Full update zip:
              </Typography>

              <Dropzone
                accept={{
                  "application/zip": [],
                }}
                maxFiles={1}
                onDrop={(acceptedFiles) => setFullUpdateFile(acceptedFiles[0])}
                disabled={isLoading}
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <Box
                      {...getRootProps()}
                      sx={{
                        border: ({ palette }) =>
                          `1px dashed ${alpha(palette.onSurface.main, 0.3)}`,
                        cursor: isLoading ? "auto" : "pointer",
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        background: ({ palette }) =>
                          isLoading
                            ? alpha(palette.onSurface.main, 0.1)
                            : palette.surface.main,

                        ":hover": {
                          background: ({ palette }) =>
                            alpha(palette.onSurface.main, 0.1),
                          border: ({ palette }) =>
                            `1px dashed ${
                              isLoading
                                ? alpha(palette.onSurface.main, 0.2)
                                : palette.primary.main
                            }`,
                        },
                      }}
                    >
                      <input {...getInputProps()} />
                      {fullUpdateFile ? (
                        <>
                          <FolderZipIcon
                            sx={{
                              fontSize: 14,
                              mr: 0.5,
                            }}
                          />
                          <Typography sx={{ fontSize: 12 }}>
                            {`${fullUpdateFile.name} - ${prettyBytes(
                              fullUpdateFile.size,
                            )}`}
                          </Typography>
                          <IconButton
                            size="small"
                            sx={{ ml: 0.5 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setFullUpdateFile(null);
                            }}
                          >
                            <CloseIcon
                              sx={{
                                fontSize: 16,
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <Typography sx={{ fontSize: 12 }}>
                          Click to select zip file, or drop file here
                        </Typography>
                      )}
                    </Box>
                  );
                }}
              </Dropzone>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: 12, fontWeight: "bold", mb: 0.5 }}>
                Partial update zip:
              </Typography>

              <Dropzone
                accept={{
                  "application/zip": [],
                }}
                maxFiles={1}
                onDrop={(acceptedFiles) =>
                  setPartialUpdateFile(acceptedFiles[0])
                }
                disabled={isLoading}
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <Box
                      {...getRootProps()}
                      sx={{
                        border: ({ palette }) =>
                          `1px dashed ${alpha(palette.onSurface.main, 0.3)}`,
                        cursor: isLoading ? "auto" : "pointer",
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        background: ({ palette }) =>
                          isLoading
                            ? alpha(palette.onSurface.main, 0.1)
                            : palette.surface.main,

                        ":hover": {
                          background: ({ palette }) =>
                            alpha(palette.onSurface.main, 0.1),
                          border: ({ palette }) =>
                            `1px dashed ${
                              isLoading
                                ? alpha(palette.onSurface.main, 0.2)
                                : palette.primary.main
                            }`,
                        },
                      }}
                    >
                      <input {...getInputProps()} />
                      {partialUpdateFile ? (
                        <>
                          <FolderZipIcon
                            sx={{
                              fontSize: 14,
                              mr: 0.5,
                            }}
                          />
                          <Typography sx={{ fontSize: 12 }}>
                            {`${partialUpdateFile.name} - ${prettyBytes(
                              partialUpdateFile.size,
                            )}`}
                          </Typography>
                          <IconButton
                            size="small"
                            sx={{ ml: 0.5 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setPartialUpdateFile(null);
                            }}
                          >
                            <CloseIcon
                              sx={{
                                fontSize: 16,
                              }}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <Typography sx={{ fontSize: 12 }}>
                          Click to select zip file, or drop file here
                        </Typography>
                      )}
                    </Box>
                  );
                }}
              </Dropzone>
            </Box>
          </Grid>
        </Grid>
      </BasicModal>
    </>
  );
};

const VersionIndexWindow = () => {
  const breadcrumbs = useMemo(() => {
    return [];
  }, []);

  const [uploadModalParams, setUploadModalParams] = useState<UploadModalParams>(
    {
      isOpen: false,
      channel: 1,
    },
  );

  const [productionVersionList, setProductionVersionList] = useState<Version[]>(
    [],
  );
  const [betaVersionList, setBetaVersionList] = useState<Version[]>([]);

  const [showChecksums, setShowChecksums] = useState<boolean[]>([false, false]);

  const [versionMenuAnchorEl, setVersionMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [versionMenuId, setVersionMenuId] = useState("");

  const [versionDeleteModalParams, setVersionDeleteModalParams] = useState<{
    isOpen: boolean;
    version: Version | null;
  }>({
    isOpen: false,
    version: null,
  });

  const { setSnackbarProps } = useSnackbar();

  const { trackAction } = useDashboardTracker("versions", true);

  useEffect(() => {
    getVersionList();
  }, []);

  const getVersionList = async () => {
    const firebaseController = getFirebaseController();

    const productionVersions =
      await firebaseController.Version.getVersionsByChannel(0, 5);
    const betaVersions = await firebaseController.Version.getVersionsByChannel(
      1,
      5,
    );

    setProductionVersionList(productionVersions);
    setBetaVersionList(betaVersions);
  };

  const handleDelete = async (version: Version) => {
    trackAction("delete version");

    await getFirebaseController()
      .Version.deleteVersion(version.id, version.storageLocation)
      .then(() => {
        setSnackbarProps({
          open: true,
          content: `${CHANNEL_MAPPER[version.channel]} version ${
            version.version
          } deleted successfully!`,
        });
      });

    getVersionList();
  };

  const handleVersionPromote = async (version: Version) => {
    trackAction("promote version");

    await getFirebaseController()
      .Version.promoteVersion(version.id)
      .then(() => {
        setSnackbarProps({
          open: true,
          content: `${CHANNEL_MAPPER[version.channel]} version ${
            version.version
          } promoted successfully!`,
        });

        getVersionList();
      })
      .catch((err) => {
        setSnackbarProps({
          open: true,
          severity: "error",
          content: err.message.replace("Error: ", ""),
        });
      });
  };

  const getDeleteVersionModalTitleAndMessage = useMemo(() => {
    const title = `Remove ${
      CHANNEL_MAPPER[versionDeleteModalParams.version?.channel as number]
    } version ${versionDeleteModalParams.version?.version}?`;

    let message;

    if (versionDeleteModalParams.version?.channel === 0) {
      message = `Are you sure you want to remove production version ${versionDeleteModalParams.version?.version}? This process cannot be undone.`;
    } else {
      message = `Are you sure you want to remove beta version ${versionDeleteModalParams.version?.version} and its associating build? This process cannot be undone.`;
    }

    return [title, message];
  }, [
    versionDeleteModalParams?.version?.version,
    versionDeleteModalParams.version?.channel,
  ]);

  return (
    <DashboardContainer NavbarProps={{ title: "Version Dashboard" }}>
      <UploadModal
        uploadModalParams={uploadModalParams}
        getVersionList={getVersionList}
        onClose={() => {
          setUploadModalParams({
            isOpen: false,
            channel: 1,
          });
        }}
      />

      <ConfirmationModal
        title={getDeleteVersionModalTitleAndMessage[0]}
        message={getDeleteVersionModalTitleAndMessage[1]}
        open={versionDeleteModalParams.isOpen}
        onDone={() => {
          return handleDelete(versionDeleteModalParams.version as Version);
        }}
        onClose={() => {
          setVersionDeleteModalParams({
            isOpen: false,
            version: null,
          });
        }}
      />

      <Grid container direction={"column"} spacing={4}>
        {[productionVersionList[0], betaVersionList[0]].map(
          (version, channelIndex) => {
            return (
              <Grid item key={channelIndex}>
                <Box>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 14, mb: 0.5 }}
                  >
                    Current Snappy Version ({CHANNEL_MAPPER[channelIndex]}):
                  </Typography>

                  <Card
                    sx={{
                      backgroundColor: ({ palette }) => palette.tertiary.main,
                      color: ({ palette }) => palette.onTertiary.main,
                      width: "100",
                      height: "100%",
                      borderRadius: 1,

                      boxShadow: 1,
                      p: 1,

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: ({ palette }) => palette.surface.main,
                        borderRadius: 1,
                        py: 1,
                        px: 2,
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <StarIcon
                            sx={{
                              color: ({ palette }) => palette.warning.main,
                              backgroundColor: ({ palette }) =>
                                palette.tertiary.main,
                              borderRadius: "100%",
                              p: 0.5,
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: 1,
                            width: "100%",
                          }}
                        >
                          {version ? (
                            <Box>
                              <Typography
                                sx={{ fontSize: 16, fontWeight: "bold" }}
                              >
                                # {version.version}{" "}
                                <span
                                  style={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  {`- (F) ${prettyBytes(
                                    version.fullSize,
                                  )}  (P) ${prettyBytes(version.partialSize)}`}
                                </span>
                              </Typography>

                              <Typography sx={{ fontSize: 12 }}>
                                {moment
                                  .unix(version.epochTime)
                                  .format("DD/MM/YYYY, HH:mm:A")}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  rowGap: 0,
                                  columnGap: 1,
                                  flexWrap: "wrap",
                                }}
                              >
                                <MuiLink
                                  href={version.fullDownloadUrl}
                                  sx={{
                                    ":hover": {
                                      textDecoration: "underline !important",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DownloadIcon
                                      sx={{ fontSize: 12, mr: 0.5 }}
                                    />
                                    Full Update Download
                                  </Typography>
                                </MuiLink>

                                <MuiLink
                                  href={version.partialDownloadUrl}
                                  sx={{
                                    ":hover": {
                                      textDecoration: "underline !important",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DownloadIcon
                                      sx={{ fontSize: 12, mr: 0.5 }}
                                    />
                                    Partial Update Download
                                  </Typography>
                                </MuiLink>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  columnGap: 1,
                                  flexWrap: "wrap",
                                }}
                              >
                                <MuiLink
                                  component="button"
                                  sx={{
                                    color: ({ palette }) =>
                                      palette.secondary.main,
                                    textDecoration: "none !important",
                                    ":hover": {
                                      textDecoration: "underline !important",
                                    },
                                  }}
                                  onClick={() => {
                                    const newShowChecksums = [...showChecksums];
                                    newShowChecksums[channelIndex] =
                                      !showChecksums[channelIndex];

                                    setShowChecksums(newShowChecksums);
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                    }}
                                  >
                                    {showChecksums[channelIndex]
                                      ? "MD5:"
                                      : "Show Checksum"}
                                  </Typography>
                                </MuiLink>

                                {showChecksums[channelIndex] && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      columnGap: 1,
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      (F) {version.fullMd5}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      (P) {version.partialMd5}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>

                              <Typography sx={{ fontSize: 12 }}>
                                Location: {version.storageLocation}
                              </Typography>
                            </Box>
                          ) : (
                            <Box>
                              <Typography sx={{ fontSize: 12 }}>
                                No version
                              </Typography>
                            </Box>
                          )}
                          <Box sx={{ flexBasis: { xs: "100%", sm: "auto" } }}>
                            <Button
                              size="small"
                              variant="contained"
                              disableElevation
                              sx={{
                                color: ({ palette }) =>
                                  `${palette.onPrimary.main} !important`,
                                textTransform: "none",
                                textWrap: "nowrap",
                                fontSize: {
                                  xs: 12,
                                  sm: 13,
                                },
                              }}
                              onClick={() => {
                                setUploadModalParams({
                                  isOpen: true,
                                  channel: channelIndex as UpdateChannel,
                                });
                              }}
                            >
                              Upload new
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            );
          },
        )}

        <Grid item>
          <Grid container spacing={4}>
            {[productionVersionList, betaVersionList].map(
              (versions, channelIndex) => {
                return (
                  <Grid item xs={12} sm={6} key={channelIndex}>
                    <Box>
                      <Typography
                        sx={{ fontWeight: "bold", fontSize: 12, mb: 0.5 }}
                      >
                        Previous versions ({CHANNEL_MAPPER[channelIndex]}):
                      </Typography>

                      <Card
                        sx={{
                          backgroundColor: ({ palette }) =>
                            palette.tertiary.main,
                          width: "100",
                          height: "100%",
                          borderRadius: 1,
                          boxShadow: 1,
                          p: 1,

                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {versions.length > 0 ? (
                          <>
                            {versions.map((version, versionIndex) => {
                              return (
                                <Box
                                  key={version.id}
                                  sx={{
                                    backgroundColor: ({ palette }) =>
                                      palette.surface.main,
                                    borderRadius: 1,
                                    py: 1,
                                    px: 2,
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                    mb: 0.5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box sx={{ display: "flex" }}>
                                      {versionIndex > 0 ? (
                                        <FileUploadIcon
                                          sx={{
                                            color: ({ palette }) =>
                                              palette.onSurface.main,
                                            backgroundColor: ({ palette }) =>
                                              palette.tertiary.main,
                                            borderRadius: "100%",
                                            p: 0.5,
                                          }}
                                        />
                                      ) : (
                                        <StarIcon
                                          sx={{
                                            color: ({ palette }) =>
                                              palette.warning.main,
                                            backgroundColor: ({ palette }) =>
                                              palette.tertiary.main,
                                            borderRadius: "100%",
                                            p: 0.5,
                                          }}
                                        />
                                      )}
                                    </Box>

                                    <Box>
                                      <Typography
                                        sx={{
                                          fontSize: 16,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        # {version.version}{" "}
                                        <span
                                          style={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`- (F) ${prettyBytes(
                                            version.fullSize,
                                          )}  (P) ${prettyBytes(
                                            version.partialSize,
                                          )}`}
                                        </span>
                                      </Typography>

                                      <Typography sx={{ fontSize: 12 }}>
                                        {moment
                                          .unix(version.epochTime)
                                          .format("DD/MM/YYYY, HH:mm:A")}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box>
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        setVersionMenuId(version.id);
                                        setVersionMenuAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      <MoreVertIcon
                                        sx={{
                                          fontSize: {
                                            xs: 20,
                                            md: 24,
                                          },
                                        }}
                                      />
                                    </IconButton>

                                    <Menu
                                      key={version.id}
                                      anchorEl={versionMenuAnchorEl}
                                      open={
                                        !!versionMenuAnchorEl &&
                                        versionMenuId === version.id
                                      }
                                      onClose={() =>
                                        setVersionMenuAnchorEl(null)
                                      }
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      sx={{
                                        ".MuiMenu-paper": {
                                          boxShadow: 1,
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        dense
                                        onClick={() => {
                                          const link =
                                            document.createElement("a");

                                          link.href = version.fullDownloadUrl;
                                          link.click();

                                          setVersionMenuAnchorEl(null);
                                        }}
                                      >
                                        Download Full Update Zip
                                      </MenuItem>

                                      <MenuItem
                                        dense
                                        onClick={() => {
                                          const link =
                                            document.createElement("a");

                                          link.href =
                                            version.partialDownloadUrl;
                                          link.click();

                                          setVersionMenuAnchorEl(null);
                                        }}
                                      >
                                        Download Partial Update Zip
                                      </MenuItem>

                                      {channelIndex === 1 &&
                                        versionIndex === 0 && (
                                          <MenuItem
                                            dense
                                            onClick={() => {
                                              handleVersionPromote(version);

                                              setVersionMenuAnchorEl(null);
                                            }}
                                            sx={{
                                              color: ({ palette }) =>
                                                palette.info.main,
                                            }}
                                          >
                                            Promote to production
                                          </MenuItem>
                                        )}

                                      <MenuItem
                                        dense
                                        onClick={() => {
                                          setVersionDeleteModalParams({
                                            isOpen: true,
                                            version,
                                          });
                                          setVersionMenuAnchorEl(null);
                                        }}
                                        sx={{
                                          color: ({ palette }) =>
                                            palette.error.main,
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </Box>
                                </Box>
                              );
                            })}

                            <Box
                              sx={{
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                component={Link}
                                to={`/versions/list?channel=${channelIndex}`}
                                color="secondary"
                                fullWidth
                                endIcon={<ArrowForwardIcon />}
                                size="small"
                                variant="contained"
                                sx={{
                                  textTransform: "none",
                                  p: 1,
                                  color: ({ palette }) =>
                                    palette.onSecondary.main,
                                }}
                              >
                                View full history
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                backgroundColor: ({ palette }) =>
                                  palette.surface.main,
                                borderRadius: 1,
                                py: 1,
                                px: 2,
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mb: 0.5,
                              }}
                            >
                              <Typography
                                sx={{
                                  display: "block",
                                  fontSize: 12,
                                }}
                              >
                                No version found.
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Card>
                    </Box>
                  </Grid>
                );
              },
            )}
          </Grid>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default VersionIndexWindow;
