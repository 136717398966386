import { memo, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSetAtom } from "jotai";
import { currentDeviceState } from "states/navbar";

import {
  alpha,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  Battery4Bar as Battery4BarIcon,
  BrokenImage as BrokenImageIcon,
  Camera as CameraIcon,
  CloudUpload as CloudUploadIcon,
  ElectricBolt as ElectricBoltIcon,
  Memory as MemoryIcon,
  MyLocation as MyLocationIcon,
  SdStorage as SdStorageIcon,
  SignalCellularAlt as SignalCellularAltIcon,
  SolarPower as SolarPowerIcon,
  Storage as StorageIcon,
  ContentCopy as ContentCopyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Clear as ClearIcon,
  AutoDelete as AutoDeleteIcon,
  Download as DownloadIcon,
  Expand,
  ArrowDropDown,
} from "@mui/icons-material";

import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import Navbar from "components/Layouts/Navbar";
import LineChart, {
  MultiLineTick,
  getTimeAxisDomainAndTick,
  LineChartOptions,
} from "components/Charts/LineChart";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { ToggleTypography } from "components/ToggleTypography/ToggleTypography";
import LDSRingLoader from "components/Loaders/LDSRingLoader";

import {
  AdminEvent,
  Device,
  DeviceEvent,
  DeviceEventType0,
  DeviceEventType1,
  DeviceV2,
  DeviceV2Details,
  DeviceV2Info,
  DeviceV2Logs,
  FirebaseImage,
  IntervalV2,
  UnixEpoch,
} from "database/DataTypes";

import {
  DeviceAction,
  DeviceTrackAction,
  useDeviceTracker,
} from "hooks/eventTracker/useDeviceTracker";
import useTimeZone, {
  MomentWithTimeZone,
  momentWithTimeZoneHOF,
} from "hooks/useTimeZone";

import { getFirebaseController } from "database/FirebaseController";

import _ from "lodash";
import moment, { Moment, MomentInput } from "moment";
import momentTz from "moment-timezone";

import ReactJson from "@microlink/react-json-view";
import { _DeviceController } from "database/_controllers/_DeviceController";
import { getContrastShade } from "theme/reliveItTheme";
import { getDisplayValue } from "utils/display";

export type ControllerLog = {
  title: string;
  content: any;
  src?: string;
  type?: "normal" | "error" | "info";
  contentType: "json" | "text" | "image" | "component";
  download?: any;
};

const TAB_NAME_MAPPER = {
  0: "logs",
  1: "detailed logs",
  2: "power",
  3: "sensor",
  4: "alerts",
  5: "admin logs",
  6: "info",
};

export const megaBytesToGigaBytes = (MB = 0) => {
  const GB = MB / 1000;

  return GB.toFixed(2);
};

function bytesToMegaBytes(bytes = 0) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

export const getPhotoTakenCounts = (
  timeFrom: IntervalV2["timeFrom"],
  timeTo: IntervalV2["timeTo"],
  timeBetweenShots: IntervalV2["timeBetweenShots"],
  timeZone?: string,
): number[] => {
  const momentWithTimeZone = momentWithTimeZoneHOF(timeZone);

  // covert browser time to device time zone
  const currentTime = momentWithTimeZone(moment(), { keepLocalTime: false });

  const startTime = momentWithTimeZone(timeFrom, {
    format: "HH:mm",
  });
  const endTime = momentWithTimeZone(timeTo, {
    format: "HH:mm",
  });

  const totalTimeDiff = endTime.diff(startTime, "seconds");
  const currentTimeElapsed = currentTime.diff(startTime, "seconds");

  const totalShots = Math.floor(totalTimeDiff / timeBetweenShots) + 1;
  const currentShots = currentTime.isBefore(startTime)
    ? 0
    : currentTime.isAfter(endTime)
    ? totalShots
    : Math.floor(currentTimeElapsed / timeBetweenShots) + 1;

  return [currentShots, totalShots];
};

export interface SelectOption<T = string> {
  value: T;
  label: string;
}

export const createSelectOption = <T extends string>(
  value: T,
  label?: string,
): SelectOption<T> => {
  return { value, label: _.startCase(_.toLower(_.toString(label || value))) };
};

const DATE_RANGE_OPTIONS = [
  createSelectOption("Past 12 Hours"),
  createSelectOption("Past 24 Hours"),
  createSelectOption("Past 48 Hours"),
  createSelectOption("Past 3 Days"),
  createSelectOption("Past Week"),
  createSelectOption("Past Month"),
  createSelectOption("Custom"),
];

const DEVICE_EVENTS_BATCH_SIZE = 100;

const TabHeaderMenuBaseProps: TextFieldProps = {
  select: true,
  size: "small",
  InputProps: {
    sx: {
      fontSize: "0.875rem",
      borderRadius: 0,
    },
  },
  inputProps: {
    sx: {
      padding: "2px 8px",
      background: ({ palette }) => `${palette.surface.main} !important`,
      borderRadius: 0,
    },
  },
  sx: {
    background: ({ palette }) => palette.surface.main,
    borderRadius: 0,
    width: {
      xs: "100%",
      sm: 200,
    },
  },
};

const TabHeader = ({
  dateRange,
  dateRangeOption,
  handleDateInputChange,
  handleSearch,
  handleDateRangeOptionChange,
  currentPage,
  totalEventsCount,
  handlePageChanged,
  isLoading,
  name,
  id,
  timeZone,
}: {
  dateRange: UnixEpoch[];
  dateRangeOption: SelectOption["value"];
  handleSearch: (dateRange: UnixEpoch[]) => void;
  handleDateInputChange: (
    date: Moment,
    name: "end" | "start",
    hourly?: boolean,
  ) => void;
  handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
  currentPage?: number;
  totalEventsCount?: number;
  handlePageChanged?: (page: number, tab: string) => void;
  isLoading?: boolean;
  name?: string;
  id?: string;
  timeZone?: string;
}) => {
  return (
    <Box
      id={id}
      sx={{
        p: 1,
        background: ({ palette }) =>
          alpha(getContrastShade(palette.secondary, "light"), 0.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: {
            xs: "100%",
            sm: "auto",
          },
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <TextField
          {...TabHeaderMenuBaseProps}
          disabled={isLoading}
          value={dateRangeOption}
          onChange={(e) => handleDateRangeOptionChange(e.target.value)}
        >
          {DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem
              disableRipple
              dense
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {dateRangeOption === "Custom" && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  disabled={isLoading}
                  maxDate={moment()}
                  value={moment.unix(dateRange[0])}
                  onChange={(date) => {
                    handleDateInputChange(date as Moment, "start", true);
                  }}
                  format="DD/MM/YYYY HH:mm"
                  timezone={timeZone}
                  views={["year", "month", "day", "hours"]}
                  ampm={false}
                  slotProps={{
                    popper: {
                      sx: {
                        ".MuiPaper-root": {
                          ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersMonth-monthButton.Mui-selected, .MuiPickersDay-root.Mui-selected":
                            {
                              color: ({ palette }) => palette.onPrimary.main,
                            },
                        },
                      },
                    },
                    textField: {
                      size: "small",

                      InputProps: {
                        sx: {
                          fontSize: "0.875rem",
                          borderRadius: 0,
                        },
                      },
                      inputProps: {
                        sx: {
                          padding: "2px 8px",
                          background: ({ palette }) =>
                            `${palette.surface.main} !important`,
                          borderRadius: 0,
                        },
                      },
                      sx: {
                        background: ({ palette }) => palette.surface.main,
                        borderRadius: 0,
                        width: {
                          xs: "50%",
                          sm: 200,
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  disabled={isLoading}
                  maxDate={moment()}
                  onChange={(date) => {
                    handleDateInputChange(date as Moment, "end", true);
                  }}
                  value={moment.unix(dateRange[1])}
                  format="DD/MM/YYYY HH:mm"
                  timezone={timeZone}
                  views={["year", "month", "day", "hours"]}
                  ampm={false}
                  slotProps={{
                    popper: {
                      sx: {
                        ".MuiPaper-root": {
                          ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersMonth-monthButton.Mui-selected, .MuiPickersDay-root.Mui-selected":
                            {
                              color: ({ palette }) => palette.onPrimary.main,
                            },
                        },
                      },
                    },

                    textField: {
                      size: "small",

                      InputProps: {
                        sx: {
                          fontSize: "0.875rem",
                          borderRadius: 0,
                        },
                      },
                      inputProps: {
                        sx: {
                          padding: "2px 8px",
                          background: ({ palette }) =>
                            `${palette.surface.main} !important`,
                          borderRadius: 0,
                        },
                      },
                      sx: {
                        background: ({ palette }) => palette.surface.main,
                        borderRadius: 0,
                        width: {
                          xs: "50%",
                          sm: 200,
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>

            <Button
              disabled={isLoading}
              onClick={() => handleSearch(dateRange)}
              disableElevation
              size="small"
              variant="contained"
              sx={{
                height: "max-content",
                fontSize: "0.875rem !important",
                textTransform: "none",
                fontWeight: "normal",
                color: ({ palette }) => `${palette.onPrimary.main} !important`,
                p: 0,
              }}
            >
              Search
            </Button>
          </>
        )}
      </Box>

      {_.isNumber(currentPage) && name && (
        <TablePagination
          size="small"
          rowsPerPageOptions={[]}
          sx={{
            ".MuiTablePagination-toolbar": {
              pl: 1,
            },
            ".MuiTablePagination-displayedRows": {
              fontSize: 12,
              color: ({ palette }) =>
                isLoading
                  ? alpha(palette.secondary.main, 0.7)
                  : alpha(palette.secondary.main, 0.9),
            },
            ".MuiTablePagination-actions": { ml: 0.5 },
            ".MuiIconButton-root": {
              p: 0,
              ml: 0.5,
              svg: {
                fontSize: {
                  xs: 20,
                  sm: 24,
                },
              },
            },
            ".MuiToolbar-root": { minHeight: 0 },
          }}
          backIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading,
                }
              : undefined
          }
          nextIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading,
                }
              : undefined
          }
          component="div"
          count={totalEventsCount!}
          page={currentPage}
          onPageChange={(e, page) => {
            handlePageChanged!(page, name);
          }}
          rowsPerPage={DEVICE_EVENTS_BATCH_SIZE}
        />
      )}
    </Box>
  );
};

const TabBody = ({
  isLoading,
  children,
  minWidth = 600,
  id,
}: {
  isLoading: boolean;
  children: ReactNode;
  minWidth?: any;
  id?: string;
}) => {
  return (
    <Box
      id={id}
      sx={{
        flex: 1,
        position: "relative",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          position: "absolute",

          height: "100%",
          width: "100%",
          display: isLoading ? "flex" : "none",
          background: alpha("#000", 0.05),
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LDSRingLoader />
      </Box>

      <Box
        sx={{
          overflow: "auto",
          height: "100%",
        }}
      >
        <Box
          sx={{
            height: 500,

            minWidth,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const LogsTab = ({
  name,
  deviceEvents,
  handleSearch,
  handleDateInputChange,
  handleDateRangeOptionChange,
  dateRange,
  dateRangeOption,
  isLoading,
  timeZone,
  currentPage,
  totalEventsCount,
  handlePageChanged,
}: {
  name?: string;
  deviceEvents: DeviceEvent[];
  handleSearch: (dateRange: UnixEpoch[]) => void;
  handleDateInputChange: (date: Moment, name: "end" | "start") => void;
  handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
  dateRange: UnixEpoch[];
  dateRangeOption: SelectOption["value"];
  isLoading: boolean;
  timeZone: string;
  currentPage: number;
  totalEventsCount: number;
  handlePageChanged: (page: number, name: string) => void;
}) => {
  const { palette } = useTheme();

  const logTypeColorMapper = {
    0: alpha(palette.primary.main, 1),
    1: alpha(palette.success.main, 1),
    2: alpha(palette.onSurface.main, 1),
    3: alpha(palette.error.main, 1),
    4: alpha(palette.white.main, 1),
    5: alpha(palette.white.main, 1),
  };

  const logsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logsRef.current) {
      logsRef.current.scrollTo({ top: 0 });
    }
  }, [currentPage]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <TabHeader
        id={"logs-tab-header"}
        dateRange={dateRange}
        dateRangeOption={dateRangeOption}
        handleDateInputChange={handleDateInputChange}
        handleSearch={handleSearch}
        handleDateRangeOptionChange={handleDateRangeOptionChange}
        currentPage={currentPage}
        totalEventsCount={totalEventsCount}
        handlePageChanged={handlePageChanged}
        isLoading={isLoading}
        name={name}
      />

      <TabBody
        id="logs-tab-body"
        minWidth={deviceEvents.length > 0 ? 600 : 0}
        isLoading={isLoading}
      >
        <Grid
          ref={logsRef}
          container
          direction={"column"}
          sx={{
            flexWrap: "nowrap",
            height: "100%",
            p: 0,
          }}
        >
          {deviceEvents.length > 0 ? (
            <>
              {deviceEvents.map((data, index) => {
                const additionalInfo = () => {
                  switch (data.eventType) {
                    case 0: {
                      const typedData = data as DeviceEventType0;

                      return (
                        <>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Battery Voltage: {typedData.batteryVoltage}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Charge Current: {typedData.chargeCurrent}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Humidity: {typedData.humidity}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Pressure: {typedData.pressure}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Solar Voltage: {typedData.solarVoltage}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Temperature: {typedData.temperature}
                          </Typography>
                        </>
                      );
                    }

                    case 1: {
                      const typedData = data as DeviceEventType1;

                      return (
                        <>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Image ID: {typedData.imageId}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Image Location: {typedData.imageLoc}
                          </Typography>
                          <Typography sx={{ fontSize: 13 }} display={"block"}>
                            Image Url:{" "}
                            <Link
                              sx={{
                                fontStyle: "underline",
                                color: ({ palette }) => palette.primary.main,
                              }}
                              href={typedData.imageURL}
                            >
                              {typedData.imageURL}
                            </Link>
                          </Typography>
                        </>
                      );
                    }

                    default:
                      break;
                  }
                };

                return (
                  <Grid
                    item
                    sx={{ width: "100%", wordBreak: "break-word" }}
                    key={data._id}
                  >
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        background: ({ palette }) =>
                          alpha(
                            logTypeColorMapper[data.eventType] ||
                              palette.white.main,
                            0.1,
                          ),
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                        display={"block"}
                      >
                        {momentTz
                          .unix(data.eventTime)
                          .tz(timeZone)
                          .format("YYYY-MM-DD HH:mm:ss (Z z)")}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: 13,
                        }}
                        display={"block"}
                      >
                        Type: {data?.eventType}
                      </Typography>
                      <Typography sx={{ fontSize: 13 }} display={"block"}>
                        Details: {data?.eventDetails}
                      </Typography>
                      {additionalInfo()}
                    </Box>

                    <Divider />
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {!isLoading && (
                  <Typography fontSize={12}>No Events.</Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </TabBody>
    </Box>
  );
};

const AdminLogsTab = ({
  name,
  adminEvents,
  handleSearch,
  handleDateInputChange,
  handleDateRangeOptionChange,
  dateRange,
  dateRangeOption,
  isLoading,
  timeZone,
  currentPage,
  totalEventsCount,
  handlePageChanged,
}: {
  name?: string;
  adminEvents: AdminEvent[];
  handleSearch: (dateRange: UnixEpoch[]) => void;
  handleDateInputChange: (date: Moment, name: "end" | "start") => void;
  handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
  dateRange: UnixEpoch[];
  dateRangeOption: SelectOption["value"];
  isLoading: boolean;
  timeZone: string;
  currentPage: number;
  totalEventsCount: number;
  handlePageChanged: (page: number, name: string) => void;
}) => {
  const { palette } = useTheme();

  const logTypeColorMapper = {
    0: alpha(palette.primary.main, 1),
    1: alpha(palette.success.main, 1),
    2: alpha(palette.onSurface.main, 1),
    3: alpha(palette.error.main, 1),
  };

  const logsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logsRef.current) {
      logsRef.current.scrollTo({ top: 0 });
    }
  }, [currentPage]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <TabHeader
        id="admin-logs-tab-header"
        dateRange={dateRange}
        dateRangeOption={dateRangeOption}
        handleDateInputChange={handleDateInputChange}
        handleSearch={handleSearch}
        handleDateRangeOptionChange={handleDateRangeOptionChange}
        currentPage={currentPage}
        totalEventsCount={totalEventsCount}
        handlePageChanged={handlePageChanged}
        isLoading={isLoading}
        name={name}
      />

      <TabBody
        id="admin-logs-tab-body"
        minWidth={adminEvents.length > 0 ? 600 : 0}
        isLoading={isLoading}
      >
        <Grid
          ref={logsRef}
          container
          direction={"column"}
          alignItems={"center"}
          flexWrap={"nowrap"}
          sx={{
            height: "100%",
          }}
        >
          {adminEvents.length > 0 ? (
            <>
              {adminEvents.map((event) => {
                const logType = event.eventTitle.match(/gallery/gi) ? 0 : 1;

                return (
                  <Grid item sx={{ width: "100%" }} key={event.id}>
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        background: ({ palette }) =>
                          alpha(
                            logTypeColorMapper[logType] || palette.white.main,
                            0.1,
                          ),
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                        display={"block"}
                      >
                        {event.eventTitle}
                      </Typography>

                      <Typography sx={{ fontSize: 13 }} display={"block"}>
                        By {`${event.username}`}
                        <span style={{ fontSize: 11 }}>
                          {` (Id: ${event.userId})`}
                        </span>
                      </Typography>

                      <Typography sx={{ fontSize: 12 }} display={"block"}>
                        At{" "}
                        {momentTz
                          .unix(event.eventTime)
                          .format("YYYY-MM-DD, HH:mm:ss")}
                      </Typography>

                      {event.eventDetails && (
                        <Typography
                          sx={{ fontSize: 13, mt: 1 }}
                          display={"block"}
                        >
                          {event.eventDetails}
                        </Typography>
                      )}
                    </Box>

                    <Divider />
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {!isLoading && (
                  <Typography fontSize={12}>No Events.</Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </TabBody>
    </Box>
  );
};

const DeviceLogsListMenu = ({
  value,
  options: deviceLogsList,
  timeZone,
  dateRange,
  dateRangeOption,
  setCurrentLogsIndex,
  handleDateRangeOptionChange,
  handleDateInputChange,
  handleSearch,
  isLoading,
}: {
  value: string | number;
  options: DeviceV2Logs[];
  timeZone: string;
  dateRange: number[];
  dateRangeOption: string;
  setCurrentLogsIndex: (index: number) => void;
  handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
  handleDateInputChange: (date: Moment, name: "end" | "start") => void;
  handleSearch: (dateRange: UnixEpoch[]) => void;
  isLoading: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <TextField
        {...TabHeaderMenuBaseProps}
        select={false}
        value={
          value === 0
            ? "Latest"
            : momentTz(Number(deviceLogsList[value]._id))
                .tz(timeZone)
                .format("YYYY-MM-DD HH:mm:ss (Z z)")
        }
        onClick={(e) => {
          setAnchorEl(isOpen ? null : e.currentTarget);
        }}
        InputProps={{
          ...TabHeaderMenuBaseProps.InputProps,
          readOnly: true,
          sx: {
            ...TabHeaderMenuBaseProps.InputProps?.sx,

            pr: "7px",
          },

          endAdornment: (
            <ArrowDropDown
              sx={{
                transform: `rotate(${isOpen ? "180deg" : "0deg"} )`,
                userSelect: "none",
                cursor: "pointer",
                opacity: 0.5,
              }}
            />
          ),
        }}
        inputProps={{
          ...TabHeaderMenuBaseProps.inputProps,
          sx: {
            ...TabHeaderMenuBaseProps.inputProps?.sx,
            userSelect: "none",
            cursor: "pointer",
          },
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        sx={{
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            p: 1,
            pt: 0,
            display: "flex",
            alignItems: "center",
            width: {
              xs: "100%",
              sm: "auto",
            },
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <TextField
            {...TabHeaderMenuBaseProps}
            fullWidth
            // disabled={isLoading}
            value={dateRangeOption}
            sx={{
              ...TabHeaderMenuBaseProps.sx,
              width: "100%",
            }}
            onChange={(e) => handleDateRangeOptionChange(e.target.value)}
          >
            {DATE_RANGE_OPTIONS.map((option) => (
              <MenuItem
                disableRipple
                dense
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {dateRangeOption === "Custom" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={isLoading}
                    maxDate={moment()}
                    value={moment.unix(dateRange[0])}
                    onChange={(date) =>
                      handleDateInputChange(date as Moment, "start")
                    }
                    timezone={timeZone}
                    views={["year", "month", "day"]}
                    format="DD/MM/YYYY HH:mm"
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPaper-root": {
                            ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersMonth-monthButton.Mui-selected, .MuiPickersDay-root.Mui-selected":
                              {
                                color: ({ palette }) => palette.onPrimary.main,
                              },
                          },
                        },
                      },
                      textField: {
                        size: "small",

                        InputProps: {
                          sx: {
                            fontSize: "0.875rem",
                            borderRadius: 0,
                          },
                        },
                        inputProps: {
                          sx: {
                            padding: "2px 8px",
                            background: ({ palette }) =>
                              `${palette.surface.main} !important`,
                            borderRadius: 0,
                          },
                        },
                        sx: {
                          background: ({ palette }) => palette.surface.main,
                          borderRadius: 0,
                          width: {
                            xs: "50%",
                            sm: 200,
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={isLoading}
                    maxDate={moment()}
                    onChange={(date) =>
                      handleDateInputChange(date as Moment, "end")
                    }
                    value={moment.unix(dateRange[1])}
                    timezone={timeZone}
                    views={["year", "month", "day"]}
                    format="DD/MM/YYYY HH:mm"
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPaper-root": {
                            ".MuiPickersYear-yearButton.Mui-selected, .MuiPickersMonth-monthButton.Mui-selected, .MuiPickersDay-root.Mui-selected":
                              {
                                color: ({ palette }) => palette.onPrimary.main,
                              },
                          },
                        },
                      },

                      textField: {
                        size: "small",

                        InputProps: {
                          sx: {
                            fontSize: "0.875rem",
                            borderRadius: 0,
                          },
                        },
                        inputProps: {
                          sx: {
                            padding: "2px 8px",
                            background: ({ palette }) =>
                              `${palette.surface.main} !important`,
                            borderRadius: 0,
                          },
                        },
                        sx: {
                          background: ({ palette }) => palette.surface.main,
                          borderRadius: 0,
                          width: {
                            xs: "50%",
                            sm: 200,
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>

              <Button
                disabled={isLoading}
                onClick={() => handleSearch(dateRange)}
                disableElevation
                size="small"
                variant="contained"
                sx={{
                  height: "max-content",
                  fontSize: "0.875rem !important",
                  textTransform: "none",
                  fontWeight: "normal",
                  color: ({ palette }) =>
                    `${palette.onPrimary.main} !important`,
                  p: 0,
                }}
              >
                Search
              </Button>
            </>
          )}
        </Box>

        <Divider />

        <Box
          sx={{
            maxHeight: 450,
            overflow: "auto",
          }}
        >
          {deviceLogsList.length > 0 ? (
            [
              deviceLogsList.map((logsItem, logsIndex) => {
                return (
                  <MenuItem
                    selected={logsIndex === value}
                    dense
                    key={logsItem._id}
                    value={logsIndex}
                    onClick={() => {
                      setCurrentLogsIndex(logsIndex);
                      setAnchorEl(null);
                    }}
                  >
                    {logsIndex === 0
                      ? "Latest"
                      : momentTz(Number(logsItem._id))
                          .tz(timeZone)
                          .format("YYYY-MM-DD HH:mm:ss (Z z)")}
                  </MenuItem>
                );
              }),
            ]
          ) : (
            <MenuItem dense value={0}>
              Latest
            </MenuItem>
          )}
        </Box>
      </Menu>
    </>
  );
};

const DetailedLogTab = ({
  deviceLogsList,
  trackAction,
  isV2,
  timeZone,
  currentLogType,
  setCurrentLogType,
  currentLogsIndex,
  setCurrentLogsIndex,
  dateRange,
  dateRangeOption,
  handleDateRangeOptionChange,
  handleDateInputChange,
  handleSearch,
  isLoading,
}: {
  deviceLogsList: DeviceV2Logs[];
  trackAction: DeviceTrackAction;
  isV2: boolean;
  timeZone: string;
  currentLogType: string;
  setCurrentLogType: (type: string) => void;
  currentLogsIndex: number;
  setCurrentLogsIndex: (index: number) => void;
  dateRange: number[];
  dateRangeOption: string;
  handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
  handleDateInputChange: (date: Moment, name: "end" | "start") => void;
  handleSearch: (dateRange: UnixEpoch[]) => void;
  isLoading: boolean;
}) => {
  const currentLogsItem = useMemo(() => {
    return deviceLogsList?.[currentLogsIndex];
  }, [currentLogsIndex, deviceLogsList]);

  const logTypeLabelMapper = useMemo(() => {
    const types = { cameraLogs: "Camera Logs", reporterLogs: "Reporter Logs" };

    if (isV2) {
      return types;
    } else {
      return {
        ...types,
        updaterLogs: "Updater Logs",
      };
    }
  }, [isV2]);

  const logTypeOptions = Object.keys(logTypeLabelMapper).map((key) => {
    return { label: logTypeLabelMapper[key], value: key };
  });

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          p: 1,
          background: ({ palette }) =>
            alpha(getContrastShade(palette.secondary, "light"), 0.5),
          display: "flex",
          alignItems: "start",
          gap: 1,
        }}
      >
        {isV2 && (
          <DeviceLogsListMenu
            value={currentLogsIndex}
            timeZone={timeZone}
            options={deviceLogsList}
            dateRange={dateRange}
            dateRangeOption={dateRangeOption}
            setCurrentLogsIndex={setCurrentLogsIndex}
            handleDateRangeOptionChange={handleDateRangeOptionChange}
            handleDateInputChange={handleDateInputChange}
            handleSearch={handleSearch}
            isLoading={isLoading}
          />
        )}

        <TextField {...TabHeaderMenuBaseProps} value={currentLogType}>
          {logTypeOptions.map((option: any) => (
            <MenuItem
              dense
              key={option.value}
              value={option.value}
              onClick={() => {
                trackAction("filter logs", {
                  log_type: option.label,
                });
                setCurrentLogType(option.value);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <TabBody minWidth={600} isLoading={false}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          flexWrap={"nowrap"}
          sx={{
            height: "100%",
            maxHeight: 500,
            p: 0,
            overflow: "auto",
          }}
        >
          {currentLogsItem ? (
            <Box sx={{ py: 1, px: 2 }} alignSelf={"start"}>
              <Typography fontSize={12} sx={{ whiteSpace: "pre-line" }}>
                {currentLogsItem[currentLogType]}
              </Typography>
            </Box>
          ) : (
            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography fontSize={12}>No Logs.</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </TabBody>
    </Box>
  );
};

const PowersTab = memo(
  ({
    deviceEvents,
    timeZone,
    handleSearch,
    handleDateInputChange,
    handleDateRangeOptionChange,
    dateRange,
    dateRangeOption,
    isLoading,
    chartInterval,
  }: {
    id: any;
    deviceEvents: DeviceEventType0[];
    timeZone: string;
    handleSearch: (dateRange: UnixEpoch[]) => void;
    handleDateInputChange: (date: Moment, name: "end" | "start") => void;
    handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
    dateRange: UnixEpoch[];
    dateRangeOption: SelectOption["value"];
    isLoading: boolean;
    chartInterval: number;
  }) => {
    const { palette } = useTheme();

    const powerData = useMemo(() => {
      return deviceEvents.map((event) => {
        return {
          name: Number(momentTz.unix(event.eventTime).valueOf()),
          Battery: event.batteryVoltage,
          Solar: event.solarVoltage,
        };
      });
    }, [deviceEvents]);

    const chargeCurrentData = useMemo(() => {
      return deviceEvents.map((event) => {
        return {
          name: Number(momentTz.unix(event.eventTime).valueOf()),
          Current: event.chargeCurrent,
        };
      });
    }, [deviceEvents]);

    const powerOptions: LineChartOptions = useMemo(() => {
      const [domain, ticks] = getTimeAxisDomainAndTick(
        powerData[0]?.name,
        powerData[powerData.length - 1]?.name,
        chartInterval,
      );

      return {
        colors: [palette.primary.main, palette.warning.main],
        xLabel: `Date Time (${momentTz().tz(timeZone).format("Z z")})`,
        yLabel: "Power (volt)",
        xLabelDy: 32,
        unit: "V",
        toolTipLabelFormatter: (label) => {
          return momentTz(label)
            .tz(timeZone)
            .format("(ddd) DD/MM/YYYY - HH:mm:ss");
        },
        xProps: {
          type: "number",
          scale: "time",
          domain,
          ticks,
          tick: <MultiLineTick />,
          tickFormatter: (value, index) => {
            return `${momentTz(value)
              .tz(timeZone)
              .format("HH:mm")}/\n(${momentTz(value)
              .tz(timeZone)
              .format("DD/MM")})`;
          },
        },
        yProps: {
          domain: [0, (dataMax) => Math.floor(dataMax + 3)],
        },
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powerData, chartInterval]);

    const chargeCurrentOptions: LineChartOptions = useMemo(() => {
      const [domain, ticks] = getTimeAxisDomainAndTick(
        chargeCurrentData[0]?.name,
        chargeCurrentData[powerData.length - 1]?.name,
        chartInterval,
      );

      return {
        colors: [palette.error.main],
        xLabel: `Date Time (${momentTz().tz(timeZone).format("Z z")})`,
        yLabel: "Current (amp)",
        xLabelDy: 32,
        unit: "A",
        toolTipLabelFormatter: (label) => {
          return momentTz(label)
            .tz(timeZone)
            .format("(ddd) DD/MM/YYYY - HH:mm:ss");
        },
        yProps: {
          domain: [0, (dataMax) => Math.floor(dataMax + 1)],
        },
        xProps: {
          type: "number",
          scale: "time",
          domain,
          ticks,
          tick: <MultiLineTick />,
          tickFormatter: (value, index) => {
            return `${momentTz(value)
              .tz(timeZone)
              .format("HH:mm")}/\n(${momentTz(value)
              .tz(timeZone)
              .format("DD/MM")})`;
          },
        },
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeCurrentData, chartInterval]);

    const dateTitle = useMemo(() => {
      const startDate = moment.unix(dateRange[0]).format("D MMM YYYY");
      const endDate = moment.unix(dateRange[1]).format("D MMM YYYY");

      return startDate === endDate
        ? `on ${startDate}`
        : `from ${startDate} to ${endDate}`;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceEvents]);

    const id = useMemo(() => {
      // this id is used for chart animation id

      return `${
        deviceEvents[0]?.deviceID || deviceEvents[0]?._id
      }_${dateRangeOption}`;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceEvents]);

    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <TabHeader
          id="powers-tab-header"
          dateRange={dateRange}
          dateRangeOption={dateRangeOption}
          handleDateInputChange={handleDateInputChange}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          handleSearch={handleSearch}
          timeZone={timeZone}
        />

        <TabBody id="powers-tab-body" isLoading={isLoading}>
          <Box sx={{ p: 2 }}>
            <Box>
              <LineChart
                id={id}
                data={powerData}
                options={powerOptions}
                title={`Battery And Solar Power Changes ${dateTitle}`}
              />
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box>
              <LineChart
                id={id}
                data={chargeCurrentData}
                options={chargeCurrentOptions}
                title={`Current Charge Changes ${dateTitle}`}
              />
            </Box>
          </Box>
        </TabBody>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

const SensorsTab = ({
  deviceEvents,
  timeZone,
  handleSearch,
  handleDateInputChange,
  handleDateRangeOptionChange,
  dateRange,
  dateRangeOption,
  isLoading,
  chartInterval,
}: {
  deviceEvents: DeviceEventType0[];
  timeZone: string;
  handleSearch: (dateRange: UnixEpoch[]) => void;
  handleDateInputChange: (date: Moment, name: "end" | "start") => void;
  handleDateRangeOptionChange: (option: SelectOption["value"]) => void;
  dateRange: UnixEpoch[];
  dateRangeOption: SelectOption["value"];
  isLoading: boolean;
  chartInterval: number;
}) => {
  const { palette } = useTheme();

  const { temperatureData, humidityData, pressureData } = useMemo(() => {
    const temperatureData: any[] = [];
    const humidityData: any[] = [];
    const pressureData: any[] = [];

    deviceEvents.forEach((event) => {
      temperatureData.push({
        name: Number(momentTz.unix(event.eventTime).valueOf()),
        Temperature: event.temperature,
      });

      humidityData.push({
        name: Number(momentTz.unix(event.eventTime).valueOf()),
        Humidity: event.humidity,
      });

      pressureData.push({
        name: Number(momentTz.unix(event.eventTime).valueOf()),
        Pressure: event.pressure,
      });
    });

    return {
      temperatureData,
      humidityData,
      pressureData,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceEvents]);

  const { temperatureOptions, humidityOptions, pressureOptions } =
    useMemo(() => {
      const [tDomain, tTicks] = getTimeAxisDomainAndTick(
        _.first(temperatureData)?.name,
        _.last(temperatureData)?.name,
        chartInterval,
      );

      const [hDomain, hTicks] = getTimeAxisDomainAndTick(
        _.first(humidityData)?.name,
        _.last(humidityData)?.name,
        chartInterval,
      );

      const [pDomain, pTicks] = getTimeAxisDomainAndTick(
        _.first(pressureData)?.name,
        _.last(pressureData)?.name,
        chartInterval,
      );

      const temperatureOptions: LineChartOptions = {
        colors: [palette.success.main],
        xLabel: `Date Time (${momentTz().tz(timeZone).format("Z z")})`,
        yLabel: "Temperature (°C)",
        xLabelDy: 32,
        unit: "°C",
        toolTipLabelFormatter: (label) => {
          return momentTz(label)
            .tz(timeZone)
            .format("(ddd) DD/MM/YYYY - HH:mm:ss");
        },
        yDomain: [
          (dataMin) => Math.floor(dataMin - 3),
          (dataMax) => Math.floor(dataMax + 3),
        ],
        xProps: {
          type: "number",
          scale: "time",
          domain: tDomain,
          ticks: tTicks,
          tick: <MultiLineTick />,
          tickFormatter: (value, index) => {
            return `${momentTz(value)
              .tz(timeZone)
              .format("HH:mm")}/\n(${momentTz(value)
              .tz(timeZone)
              .format("DD/MM")})`;
          },
        },
      };

      const humidityOptions: LineChartOptions = {
        colors: [palette.primary.main],
        xLabel: `Date Time (${momentTz().tz(timeZone).format("Z z")})`,
        yLabel: "Humidity (%)",
        xLabelDy: 32,
        unit: "%",
        toolTipLabelFormatter: (label) => {
          return momentTz(label)
            .tz(timeZone)
            .format("(ddd) DD/MM/YYYY - HH:mm:ss");
        },
        yDomain: [
          (dataMin) => Math.floor(dataMin - 10),
          (dataMax) => Math.floor(dataMax + 10),
        ],
        xProps: {
          type: "number",
          scale: "time",
          domain: hDomain,
          ticks: hTicks,
          tick: <MultiLineTick />,
          tickFormatter: (value, index) => {
            return `${momentTz(value)
              .tz(timeZone)
              .format("HH:mm")}/\n(${momentTz(value)
              .tz(timeZone)
              .format("DD/MM")})`;
          },
        },
      };

      const pressureOptions: LineChartOptions = {
        colors: [palette.secondary.main],
        xLabel: `Date Time (${momentTz().tz(timeZone).format("Z z")})`,
        yLabel: "Air pressure (mb)",
        xLabelDy: 32,
        unit: "mb",
        toolTipLabelFormatter: (label) => {
          return momentTz(label)
            .tz(timeZone)
            .format("(ddd) DD/MM/YYYY - HH:mm:ss");
        },
        yDomain: [
          (dataMin) => Math.round((dataMin - 50) / 10) * 10,
          (dataMax) => Math.round((dataMax + 50) / 10) * 10,
        ],
        xProps: {
          type: "number",
          scale: "time",
          domain: pDomain,
          ticks: pTicks,
          tick: <MultiLineTick />,
          tickFormatter: (value, index) => {
            return `${momentTz(value)
              .tz(timeZone)
              .format("HH:mm")}/\n(${momentTz(value)
              .tz(timeZone)
              .format("DD/MM")})`;
          },
        },
      };

      return {
        temperatureOptions,
        humidityOptions,
        pressureOptions,
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [temperatureData, humidityData, pressureData, chartInterval]);

  const dateTitle = useMemo(() => {
    const startDate = moment.unix(dateRange[0]).format("D MMM YYYY");
    const endDate = moment.unix(dateRange[1]).format("D MMM YYYY");

    return startDate === endDate
      ? `on ${startDate}`
      : `from ${startDate} to ${endDate}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceEvents]);

  const id = useMemo(() => {
    // this id is used for chart animation id

    return `${
      deviceEvents[0]?.deviceID || deviceEvents[0]?._id
    }_${dateRangeOption}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceEvents]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <TabHeader
        id="sensors-tab-header"
        dateRange={dateRange}
        dateRangeOption={dateRangeOption}
        handleDateInputChange={handleDateInputChange}
        handleDateRangeOptionChange={handleDateRangeOptionChange}
        handleSearch={handleSearch}
      />

      <TabBody id="sensors-tab-body" isLoading={isLoading}>
        <Box sx={{ p: 2 }}>
          <Box>
            <LineChart
              id={id}
              data={temperatureData}
              options={temperatureOptions}
              title={`Temperature Changes ${dateTitle}`}
            />
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box>
            <LineChart
              id={id}
              data={humidityData}
              options={humidityOptions}
              title={`Humidity Changes ${dateTitle}`}
            />
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box>
            <LineChart
              id={id}
              data={pressureData}
              options={pressureOptions}
              title={`Air Pressure Changes ${dateTitle}`}
            />
          </Box>
        </Box>
      </TabBody>
    </Box>
  );
};

const DeviceInfoTab = ({
  deviceInfo,
  isLoading,
}: {
  deviceInfo: DeviceV2Info | null;
  isLoading: boolean;
}) => {
  return (
    <TabBody isLoading={isLoading} minWidth={"max-content"}>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          flexWrap={"nowrap"}
          sx={{
            height: "100%",
            maxHeight: 500,
            p: 0,
            overflow: "auto",
          }}
        >
          {deviceInfo?.extraInfo ? (
            <Box
              sx={{ py: 1, px: 2, fontSize: 12, whiteSpace: "pre-line" }}
              alignSelf={"start"}
              dangerouslySetInnerHTML={{ __html: deviceInfo.extraInfo }}
            />
          ) : (
            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography fontSize={12}>No Info.</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </TabBody>
  );
};

const TabItem = ({ tab, children, currentTab, id = "" }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {tab === currentTab && (
        <Box
          id={id}
          sx={{
            p: 0,
            width: "100%",

            color: ({ palette }) => palette.onSurface.main,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

const getChartInterval = (dateRange: number[]): number => {
  const diff = moment
    .unix(dateRange[1])
    .diff(moment.unix(dateRange[0]), "hour");

  if (diff <= 4) {
    return 0.25;
  } else if (diff <= 12) {
    return 1;
  } else if (diff <= 24) {
    return 2;
  } else if (diff <= 48) {
    return 4;
  } else if (diff <= 72) {
    return 6;
  } else {
    return 12;
  }
};

const TabsSection = ({
  device,
  timeZone,
}: {
  device: DeviceV2;
  timeZone: string;
}) => {
  const [currentTab, setcurrentTab] = useState<number>(0);
  const [deviceEvents, setDeviceEvents] = useState<DeviceEvent[]>([]);
  const [adminEvents, setAdminEvents] = useState<AdminEvent[]>([]);
  const [alertEvents, setAlertEvents] = useState<DeviceEvent[]>([]);
  const [normalEvents, setNormalEvents] = useState<DeviceEvent[]>([]);
  const [displayAlertEvents, setDisplayAlertEvents] = useState<DeviceEvent[]>(
    [],
  );
  const [displayNormalEvents, setDisplayNormalEvents] = useState<DeviceEvent[]>(
    [],
  );
  const [displayAdminEvents, setDisplayAdminEvents] = useState<AdminEvent[]>(
    [],
  );

  const [currentLogPage, setCurrentLogPage] = useState<number>(0);
  const [currentAlertPage, setCurrentAlertPage] = useState<number>(0);
  const [currentAdminLogPage, setCurrentAdminLogPage] = useState<number>(0);

  const [deviceLogsList, setDeviceLogsList] = useState<DeviceV2Logs[]>([]);
  const [deviceInfo, setDeviceInfo] = useState<DeviceV2Info | null>(null);

  const getDefaultDateRange = (timeZone) => {
    return [
      moment().tz(timeZone).subtract(12, "hours").startOf("days").unix(),
      moment().tz(timeZone).endOf("days").unix(),
    ];
  };

  const [dateRange, setDateRange] = useState<UnixEpoch[]>(
    getDefaultDateRange(timeZone),
  );
  const [dateRangeOption, setDateRangeOption] = useState<SelectOption["value"]>(
    DATE_RANGE_OPTIONS[0].value,
  );

  const [adminDateRange, setAdminDateRange] = useState<UnixEpoch[]>(
    getDefaultDateRange(timeZone),
  );
  const [adminDateRangeOption, setAdminDateRangeOption] = useState<
    SelectOption["value"]
  >(DATE_RANGE_OPTIONS[0].value);

  const [logsDateRange, setLogsDateRange] = useState<UnixEpoch[]>(
    getDefaultDateRange(timeZone),
  );
  const [logsDateRangeOption, setLogsDateRangeOption] = useState<
    SelectOption["value"]
  >(DATE_RANGE_OPTIONS[0].value);

  const [currentDeviceLogType, setCurrentDeviceLogType] =
    useState("cameraLogs");

  const [currentDeviceLogsIndex, setCurrentDeviceLogsIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [chartInterval, setChartInterval] = useState(1);

  const filterDeviceEvents = async (
    filterDateRange: UnixEpoch[] = [
      moment().startOf("days").unix(),
      moment().endOf("days").unix(),
    ],
  ) => {
    const useDateRange = filterDateRange || dateRange;

    return await getFirebaseController().Device.getDeviceEvents(
      device.id as number,
      useDateRange[0],
      useDateRange[1],
    );
  };

  const filterDeviceAdminEvents = async (
    filterDateRange: UnixEpoch[] = [
      moment().startOf("days").unix(),
      moment().endOf("days").unix(),
    ],
  ) => {
    const useDateRange = filterDateRange || dateRange;

    return await getFirebaseController().Device.getDeviceAdminEvents(
      device.id as number,
      useDateRange[0],
      useDateRange[1],
    );
  };

  const filterDeviceLogs = async (
    filterDateRange: UnixEpoch[] = [
      moment().startOf("days").unix(),
      moment().endOf("days").unix(),
    ],
  ) => {
    const useDateRange = filterDateRange || dateRange;

    return await getFirebaseController().Device.getDeviceLogsList(
      device.id as number,
      useDateRange[0],
      useDateRange[1],
    );
  };

  const initDeviceEvents = async () => {
    setIsLoading(true);

    return await filterDeviceEvents(dateRange)
      .then((data) => {
        const [normalEvents, alertEvents] = _.partition(
          data,
          (event) => event.eventType !== 3,
        );

        setDeviceEvents(data);
        setAlertEvents(alertEvents);
        setNormalEvents(normalEvents);
        setDisplayNormalEvents(normalEvents.slice(0, DEVICE_EVENTS_BATCH_SIZE));
        setDisplayAlertEvents(alertEvents.slice(0, DEVICE_EVENTS_BATCH_SIZE));

        setCurrentLogPage(0);
        setCurrentAlertPage(0);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const initDeviceAdminEvents = async () => {
    setIsLoading(true);

    return await filterDeviceAdminEvents(adminDateRange)
      .then((data) => {
        setAdminEvents(data);
        setDisplayAdminEvents(data.slice(0, DEVICE_EVENTS_BATCH_SIZE));
        setCurrentAdminLogPage(0);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const initDeviceInfo = async () => {
    setIsLoading(true);

    return await getFirebaseController()
      .Device.getDeviceInfo(device.id)
      .then((data) => {
        if (data) {
          setDeviceInfo(data);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const initDeviceLogs = async () => {
    setIsLoading(true);

    return await filterDeviceLogs([
      moment().subtract(12, "hours").unix(),
      moment().unix(),
    ])
      .then((data) => {
        if (data) {
          setDeviceLogsList(data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handlePageChanged = (newPage, tab: string) => {
    if (tab === "Logs") {
      setCurrentLogPage(newPage);

      setDisplayNormalEvents(
        normalEvents.slice(
          newPage * DEVICE_EVENTS_BATCH_SIZE,
          (newPage + 1) * DEVICE_EVENTS_BATCH_SIZE,
        ),
      );
    } else if (tab === "Alerts") {
      setCurrentAlertPage(newPage);

      setDisplayAlertEvents(
        alertEvents.slice(
          newPage * DEVICE_EVENTS_BATCH_SIZE,
          (newPage + 1) * DEVICE_EVENTS_BATCH_SIZE,
        ),
      );
    } else if (tab === "Admin Logs") {
      setCurrentAdminLogPage(newPage);

      setDisplayAdminEvents(
        adminEvents.slice(
          newPage * DEVICE_EVENTS_BATCH_SIZE,
          (newPage + 1) * DEVICE_EVENTS_BATCH_SIZE,
        ),
      );
    }
  };

  const handleDateInputChange = (
    date: Moment,
    name: "end" | "start",
    hourly: boolean = false,
  ) => {
    let start = dateRange[0];
    let end = dateRange[1];

    const unitOfTime = hourly ? "hours" : "days";

    if (name === "start") {
      start = date.startOf(unitOfTime).unix();

      if (start > end) {
        end = date.endOf(unitOfTime).unix();
      }
    } else {
      end = date.endOf(unitOfTime).unix();

      if (end < start) {
        start = date.startOf(unitOfTime).unix();
      }
    }

    const newDateRange = [start, end];

    if (currentTab === 1) {
      setLogsDateRange(newDateRange);
    } else if (currentTab === 5) {
      setAdminDateRange(newDateRange);
    } else {
      setDateRange(newDateRange);
    }
  };

  const handleDateRangeOptionChange = async (option: SelectOption["value"]) => {
    const currentDate = moment().endOf("days").unix();

    let dateRange = [currentDate, currentDate];

    switch (option) {
      case "Past 12 Hours":
        dateRange = [
          moment.unix(currentDate).subtract(12, "hours").startOf("days").unix(),
          currentDate,
        ];
        break;
      case "Past 24 Hours":
        dateRange = [
          moment.unix(currentDate).subtract(24, "hours").startOf("days").unix(),
          currentDate,
        ];
        break;
      case "Past 48 Hours":
        dateRange = [
          moment.unix(currentDate).subtract(48, "hours").startOf("days").unix(),
          currentDate,
        ];

        break;
      case "Past 3 Days":
        dateRange = [
          moment.unix(currentDate).subtract(3, "days").startOf("days").unix(),
          currentDate,
        ];

        break;
      case "Past Week":
        dateRange = [
          moment.unix(currentDate).subtract(1, "weeks").startOf("days").unix(),
          currentDate,
        ];

        break;

      case "Past Month":
        dateRange = [
          moment.unix(currentDate).subtract(1, "months").startOf("days").unix(),
          currentDate,
        ];

        break;

      case "Custom":
      default:
        currentTab === 1
          ? setLogsDateRangeOption(option)
          : currentTab === 5
          ? setAdminDateRangeOption(option)
          : setDateRangeOption(option);

        return;
    }

    const formatDate = (timeStamp) => {
      return moment.unix(timeStamp).utc().format("MMDDYYYY");
    };

    if (currentTab === 1) {
      // device logs
      setLogsDateRangeOption(option);
      setLogsDateRange(dateRange);
      handleDeviceLogsSearch(dateRange);
    } else if (currentTab === 5) {
      // admin logs
      setAdminDateRangeOption(option);
      setAdminDateRange(dateRange);
      handleAdminEventsSearch(dateRange);

      trackAction("filter admin logs", {
        date_range: `${formatDate(dateRange[0])}-${formatDate(dateRange[1])}`,
        date_range_option: option,
      });
    } else {
      // event logs
      setDateRangeOption(option);
      setDateRange(dateRange);
      handleEventsSearch(dateRange);

      trackAction("filter logs", {
        date_range: `${formatDate(dateRange[0])}-${formatDate(dateRange[1])}`,
        date_range_option: option,
      });
    }
  };

  const handleEventsSearch = async (dateRange: UnixEpoch[]) => {
    setIsLoading(true);

    await filterDeviceEvents(dateRange)
      .then((data) => {
        const [normalEvents, alertEvents] = _.partition(
          data,
          (event) => event.eventType !== 3,
        );

        setDeviceEvents(data);
        setAlertEvents(alertEvents);
        setNormalEvents(normalEvents);
        setDisplayNormalEvents(normalEvents.slice(0, DEVICE_EVENTS_BATCH_SIZE));
        setDisplayAlertEvents(alertEvents.slice(0, DEVICE_EVENTS_BATCH_SIZE));

        setChartInterval(getChartInterval(dateRange));

        setCurrentLogPage(0);
        setCurrentAlertPage(0);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleAdminEventsSearch = async (dateRange: UnixEpoch[]) => {
    setIsLoading(true);

    return await filterDeviceAdminEvents(dateRange)
      .then((data) => {
        setAdminEvents(data);
        setDisplayAdminEvents(data.slice(0, DEVICE_EVENTS_BATCH_SIZE));

        setCurrentAdminLogPage(0);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleDeviceLogsSearch = async (dateRange: UnixEpoch[]) => {
    setIsLoading(true);

    return await filterDeviceLogs(dateRange)
      .then((data) => {
        if (data) {
          setDeviceLogsList(data);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const [normalEventsCount, alertEventsCount] = useMemo(() => {
    return [normalEvents.length, alertEvents.length];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalEvents, alertEvents]);

  const chartEvents: DeviceEventType0[] = useMemo(() => {
    return deviceEvents
      .filter((event) => {
        return event.eventType === 0;
      })
      .reverse() as DeviceEventType0[];
  }, [deviceEvents]);

  useEffect(() => {
    if (currentTab === 1) {
      initDeviceLogs();
    } else if (currentTab === 5) {
      if (adminEvents.length === 0 || adminEvents[0].deviceId !== device.id) {
        initDeviceAdminEvents();
      }
    } else if (currentTab === 6) {
      initDeviceInfo();
    } else {
      if (
        deviceEvents.length === 0 ||
        deviceEvents[0].deviceID !== (device.id as unknown as string)
      ) {
        initDeviceEvents();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, device]);

  const { isTrackerLoaded, trackAction } = useDeviceTracker(device, "status");

  useEffect(() => {
    if (isTrackerLoaded) {
      if (TAB_NAME_MAPPER[currentTab]) {
        trackAction(`${TAB_NAME_MAPPER[currentTab]} tab` as DeviceAction);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, isTrackerLoaded]);

  const isV2 = useMemo(() => {
    return device ? _DeviceController.isV2(device.id) : false;
  }, [device]);

  const params = useParams();

  useEffect(() => {
    setCurrentDeviceLogType("cameraLogs");
    setCurrentDeviceLogsIndex(0);
  }, [params.id]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        value={currentTab}
        onChange={(e, tab) => setcurrentTab(tab)}
        sx={({ palette }) => ({
          borderRadius: 1,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          color: palette.onSecondary.main,

          backgroundColor: ({ palette }) =>
            getContrastShade(palette.secondary, "light"),

          ".MuiTabs-indicator": {
            display: "none",
          },

          ".MuiButtonBase-root": {
            width: {
              xs: "max-content",
              sm: 200,
            },

            fontSize: {
              xs: 12,
              sm: 14,
            },

            color: palette.onSecondary.main,
            fontWeight: "normal",
            opacity: 1,

            borderRight: `1px solid ${alpha(palette.onSecondary.main, 0.4)}`,

            ":hover": {
              backgroundColor: alpha(palette.secondary.main, 0.4),
            },

            ":last-of-type": {
              borderRight: 0,
            },

            "&.Mui-selected": {
              backgroundColor: palette.primary.main,
              color: palette.onPrimary.main,
            },
          },
        })}
        textColor="inherit"
      >
        <Tab label="Logs" />
        <Tab label="Detailed Logs" />
        <Tab label="Power" />
        <Tab label="Sensors" />
        <Tab label="Alerts" />
        <Tab label="Admin Logs" />
        <Tab label="Info" />
      </Tabs>

      <TabItem tab={0} currentTab={currentTab} id="logs-tab">
        <LogsTab
          name="Logs"
          deviceEvents={displayNormalEvents}
          handleSearch={handleEventsSearch}
          handleDateInputChange={handleDateInputChange}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          dateRange={dateRange}
          dateRangeOption={dateRangeOption}
          isLoading={isLoading}
          timeZone={timeZone}
          currentPage={currentLogPage}
          totalEventsCount={normalEventsCount}
          handlePageChanged={handlePageChanged}
        />
      </TabItem>

      <TabItem tab={1} currentTab={currentTab} id="detailed-logs-tab">
        <DetailedLogTab
          deviceLogsList={deviceLogsList}
          trackAction={trackAction}
          isV2={isV2}
          timeZone={timeZone}
          currentLogType={currentDeviceLogType}
          setCurrentLogType={setCurrentDeviceLogType}
          currentLogsIndex={currentDeviceLogsIndex}
          setCurrentLogsIndex={setCurrentDeviceLogsIndex}
          dateRange={logsDateRange}
          dateRangeOption={logsDateRangeOption}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          handleDateInputChange={handleDateInputChange}
          handleSearch={handleDeviceLogsSearch}
          isLoading={isLoading}
        />
      </TabItem>

      <TabItem tab={2} currentTab={currentTab} id="powers-tab">
        <PowersTab
          id={device.id}
          isLoading={isLoading}
          deviceEvents={chartEvents}
          timeZone={timeZone}
          handleSearch={handleEventsSearch}
          handleDateInputChange={handleDateInputChange}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          dateRange={dateRange}
          dateRangeOption={dateRangeOption}
          chartInterval={chartInterval}
        />
      </TabItem>

      <TabItem tab={3} currentTab={currentTab} id="sensors-tab">
        <SensorsTab
          deviceEvents={chartEvents}
          timeZone={timeZone}
          handleSearch={handleEventsSearch}
          handleDateInputChange={handleDateInputChange}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          dateRange={dateRange}
          dateRangeOption={dateRangeOption}
          isLoading={isLoading}
          chartInterval={chartInterval}
        />
      </TabItem>

      <TabItem tab={4} currentTab={currentTab} id="alerts-tab">
        <LogsTab
          name="Alerts"
          deviceEvents={displayAlertEvents}
          handleSearch={handleEventsSearch}
          handleDateInputChange={handleDateInputChange}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          dateRange={dateRange}
          isLoading={isLoading}
          timeZone={timeZone}
          dateRangeOption={dateRangeOption}
          currentPage={currentAlertPage}
          totalEventsCount={alertEventsCount}
          handlePageChanged={handlePageChanged}
        />
      </TabItem>

      <TabItem tab={5} currentTab={currentTab} id="admin-logs-tab">
        <AdminLogsTab
          name="Admin Logs"
          adminEvents={displayAdminEvents}
          handleSearch={handleAdminEventsSearch}
          handleDateInputChange={handleDateInputChange}
          handleDateRangeOptionChange={handleDateRangeOptionChange}
          dateRange={adminDateRange}
          isLoading={isLoading}
          timeZone={timeZone}
          dateRangeOption={adminDateRangeOption}
          currentPage={currentAdminLogPage}
          totalEventsCount={adminEvents.length}
          handlePageChanged={handlePageChanged}
        />
      </TabItem>

      <TabItem tab={6} currentTab={currentTab} id="device-info-tab">
        <DeviceInfoTab deviceInfo={deviceInfo} isLoading={isLoading} />
      </TabItem>
    </Box>
  );
};

const ThumbnailCard = ({
  device,
  momentWithTimeZone,
}: {
  device: DeviceV2;
  momentWithTimeZone: MomentWithTimeZone;
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState<string>("");
  const [lastImage, setLastImage] = useState<FirebaseImage | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (device) {
      setIsLoading(true);
      setIsLoaded(false);

      getDeviceThumbnail(device)
        .then((src) => {
          setThumbnailSrc(src);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [device]);

  const getDeviceThumbnail = async (device: DeviceV2): Promise<string> => {
    const firebaseController = getFirebaseController();

    return await firebaseController.Image.getLastImage(device.id as number)
      .then(async (data) => {
        if (data && data.displayImage) {
          const image = data.displayImage;

          setLastImage(image);

          return await firebaseController.Image.getThumbnail(
            image.storageLocation,
            image.fileName,
          ).then((resultURL) => {
            if (resultURL) {
              return resultURL;
            } else {
              return image.url;
            }
          });
        } else {
          return "";
        }
      })
      .catch((err) => {
        console.error(err);

        return "";
      });
  };

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2,
          backgroundColor: ({ palette }) =>
            !isLoading && !thumbnailSrc
              ? alpha(palette.tertiary.main, 0.6)
              : isLoading || !isLoaded
              ? alpha(palette.tertiary.main, 0.4)
              : "none",
        }}
      >
        {isLoading || (thumbnailSrc && !isLoaded) ? (
          <Loader isShow={isLoading || (thumbnailSrc && !isLoaded)} />
        ) : (
          <>
            {!isLoading && !thumbnailSrc && (
              <BrokenImageIcon fontSize="large" sx={{ color: "#dedbdc" }} />
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box
        onClick={() => navigate(`../${device.id}/image-viewer`)}
        sx={{
          position: "relative",
          cursor: "pointer",
          minHeight:
            thumbnailSrc && isLoaded
              ? 0
              : {
                  xs: 150,
                  sm: 250,
                },
        }}
      >
        <ThumbnailPlaceHolder />

        {thumbnailSrc && (
          <Box
            component={"img"}
            crossOrigin="anonymous"
            src={thumbnailSrc}
            onLoad={() => setIsLoaded(true)}
            sx={{
              display: "block",
              width: "100%",
              objectFit: "contain",
              borderRadius: 2,
            }}
          />
        )}
      </Box>

      <Box sx={{ mt: 0.5, textAlign: "center" }}>
        <ToggleTypography
          sx={{ fontSize: 14 }}
          value={
            lastImage &&
            moment(lastImage.timestamp).format("DD-MM-YYYY HH:mm:ss Z")
          }
        >
          {lastImage &&
            momentWithTimeZone(lastImage.timestamp, {
              keepLocalTime: true,
            }).format("DD-MM-YYYY HH:mm:ss Z")}
        </ToggleTypography>
      </Box>
    </Box>
  );
};

const DeviceControllerLog = ({
  log,
  border = true,
}: {
  log: ControllerLog;
  border?: boolean;
}) => {
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 1000);
    }
  }, [isCopy]);

  return (
    <Box
      sx={{
        borderBottom: border
          ? ({ palette }) => `1px solid ${alpha(palette.onSurface.main, 0.2)}`
          : 0,
        p: 1,
        background: ({ palette }) =>
          log.type === "error"
            ? alpha(palette.error.main, 0.1)
            : log.type === "info"
            ? alpha(palette.primary.main, 0.1)
            : palette.onSurface.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={12} fontWeight={"bold"}>
          {log.title}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          {isCopy && <Typography fontSize={10}>Copied!</Typography>}

          {log.download && (
            <IconButton
              size="small"
              disableTouchRipple
              sx={{ p: 0 }}
              onClick={() => {
                log.download();
              }}
            >
              <DownloadIcon sx={{ fontSize: 14 }} />
            </IconButton>
          )}

          <IconButton
            size="small"
            disableTouchRipple
            sx={{ p: 0 }}
            disabled={log.contentType === "image"}
            onClick={() => {
              if (log.contentType === "image") {
                // navigator.clipboard.writeText(log.src as string);
              } else {
                setIsCopy(true);

                navigator.clipboard.writeText(
                  log.contentType === "json"
                    ? JSON.stringify(log.content)
                    : log.content,
                );
              }
            }}
          >
            {isCopy ? (
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 15,
                }}
              />
            ) : (
              <ContentCopyIcon sx={{ fontSize: 14 }} />
            )}
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ mt: 0.5 }}>
        {log.contentType === "text" && (
          <Typography fontSize={14} whiteSpace={"pre-line"} lineHeight={1}>
            {log.content}
          </Typography>
        )}

        {log.contentType === "component" && log.content}

        {log.contentType === "json" && (
          <Box
            sx={{
              fontSize: 12,

              ".node-ellipsis": {
                fontSize: `12px !important`,
              },

              ".object-size": {
                fontSize: `10px !important`,
              },
            }}
          >
            <ReactJson
              src={log.content}
              enableClipboard={false}
              collapsed={1}
              displayDataTypes={false}
              indentWidth={2}
            />
          </Box>
        )}

        {log.contentType === "image" && (
          <Box>
            <Link href={log.src} target="_blank">
              <Box
                component={"img"}
                crossOrigin="anonymous"
                src={log.src}
                sx={{
                  width: 500,
                }}
              />
            </Link>

            <Box sx={{ mt: 0.5 }}>
              <Typography sx={{ fontSize: 12, display: "inline-block", mr: 1 }}>
                Size: {bytesToMegaBytes(log.content?.size)} MB
              </Typography>
              <Typography sx={{ fontSize: 12, display: "inline-block" }}>
                Type: {log.content?.type}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DeviceControllerModal = ({
  isOpen,
  onClose,
  deviceId,
  deviceDetails,
  trackAction,
}: {
  isOpen: boolean;
  onClose: () => void;
  deviceId: string;
  deviceDetails: DeviceV2Details | null;
  trackAction: DeviceTrackAction;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [workingIp, setWorkingIp] = useState("");
  const [logs, setLogs] = useState<ControllerLog[]>([]);

  const logsRef = useRef<HTMLDivElement>(null);

  const { palette } = useTheme();

  const getWorkingIp = async (useIp?: string) => {
    if (!deviceDetails) {
      return;
    }

    setIsLoading(true);

    let workingIp = "";
    let sslError = false;

    const deviceIPs = [
      ...(useIp ? [useIp] : []),
      "192.168.5.2",
      deviceDetails.vpnIP,
      deviceDetails.ethIP,
      deviceDetails.publicIP,
    ];

    for (const ip of deviceIPs) {
      if (!workingIp && ip !== "Disconnected") {
        await fetch("https://" + ip + ":9000/getConnectivity", {
          mode: "cors",
          signal: AbortSignal.timeout(5000),
        })
          // eslint-disable-next-line no-loop-func
          .then(async (res) => {
            if (res.status === 200) {
              workingIp = ip;
            }
          })
          // eslint-disable-next-line no-loop-func
          .catch((err) => {
            if (!(err instanceof DOMException)) {
              workingIp = ip;
              sslError = true;
            }

            console.error(err);
          });
      }
    }

    if (sslError) {
      handleLog({
        title: "Connection blocked due to SSL error",
        content: (
          <>
            <Typography fontSize={14} whiteSpace={"pre-line"} lineHeight={1.2}>
              The certificate is not trusted because it is self-signed. Open the
              page{" "}
              <Link
                href={`https://${workingIp}:9000/getConnectivity`}
                target="_blank"
                sx={{
                  ":hover": {
                    textDecoration: "underline !important",
                  },
                }}
              >
                {workingIp}
              </Link>{" "}
              to add the certificate in trusted list,{" "}
              <Link
                component="button"
                onClick={async () => {
                  await getWorkingIp(workingIp);
                }}
                sx={{
                  textDecoration: "none !important",
                  ":hover": {
                    textDecoration: "underline !important",
                  },
                }}
              >
                retry the connection
              </Link>{" "}
              when done.
            </Typography>
          </>
        ),
        contentType: "component",
        type: "error",
      });
    } else if (workingIp) {
      setWorkingIp(workingIp);
      handleLog({
        title: "Connected",
        content: `On ${workingIp}`,
        contentType: "text",
        type: "info",
      });
    } else {
      setWorkingIp("");
      handleLog({
        title: "Connection Failed",
        content: `Device not found on your network. Make sure you are on the same network as the device.`,
        contentType: "text",
        type: "error",
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen && !workingIp) {
      getWorkingIp();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetails, isOpen]);

  useEffect(() => {
    if (logsRef.current) {
      logsRef.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [logs]);

  const handleLog = (log: ControllerLog) => {
    setLogs([...logs, log]);
  };

  const fetchControllerApi = async (endpoint: string) => {
    return await fetch(`https://${workingIp}:9000/${endpoint}`);
  };

  const params = useParams();

  const isV2 = _DeviceController.isV2(params.id);

  const buttons = useMemo(() => {
    return [
      {
        label: "Get Info",
        disabled: !workingIp || isLoading,
        onClick: async () => {
          setIsLoading(true);
          trackAction("controller get info");

          await fetchControllerApi("")
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              handleLog({
                title: "Get Info",
                content: data,
                contentType: "json",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Info",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Camera Settings",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        onClick: async () => {
          setIsLoading(true);
          trackAction("controller get camera settings");

          await fetchControllerApi("getCameraSettings")
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              handleLog({
                title: "Get Camera Settings",
                content: data,
                contentType: "json",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Camera Settings",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Next Image Time",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        onClick: async () => {
          setIsLoading(true);
          trackAction("controller get next image time");

          await fetchControllerApi("getNextImageTime")
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              handleLog({
                title: "Get Next Image Time",
                content: data,
                contentType: "json",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Next Image Time",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get File Location",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller get file location");

          await fetchControllerApi("getFileLocation")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Get File Location",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get File Location",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Connectivity",
        disabled: !workingIp || isLoading,
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller get connectivity");

          await fetchControllerApi("getConnectivity")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Get Connectivity",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Connectivity",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Device ID",
        disabled: !workingIp || isLoading,
        onClick: async () => {
          setIsLoading(true);
          trackAction("controller get device id");

          await fetchControllerApi("getDeviceId")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Get Device ID",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Device ID",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Reporter Logs",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        onClick: async () => {
          setIsLoading(true);
          trackAction("controller get reporter logs");

          await fetchControllerApi("getReporterLogs")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              const element = document.createElement("a");
              const file = new Blob([data], { type: "text/plain" });

              element.href = URL.createObjectURL(file);
              element.download = `reporter-logs-${deviceId}-${moment().format(
                "YYYYMMDDhhmmss",
              )}.txt`;

              element.click();

              handleLog({
                title: "Get Reporter Logs",
                content: "success",
                contentType: "text",
                download: () => {
                  element.click();
                },
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Reporter Logs",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Camera Logs",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller get camera logs");

          await fetchControllerApi("getCameraLogs")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              const element = document.createElement("a");
              const file = new Blob([data], { type: "text/plain" });

              element.href = URL.createObjectURL(file);
              element.download = `camera-logs-${deviceId}-${moment().format(
                "YYYYMMDDhhmmss",
              )}.txt`;

              element.click();

              handleLog({
                title: "Get Camera Logs",
                content: "success",
                contentType: "text",
                download: () => {
                  element.click();
                },
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Camera Logs",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Get Updater Logs",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller get updater logs");

          await fetchControllerApi("getUpdaterLogs")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              const element = document.createElement("a");
              const file = new Blob([data], { type: "text/plain" });

              element.href = URL.createObjectURL(file);
              element.download = `updater-logs-${deviceId}-${moment().format(
                "YYYYMMDDhhmmss",
              )}.txt`;

              element.click();

              handleLog({
                title: "Get Updater Logs",
                content: "success",
                contentType: "text",
                download: () => {
                  element.click();
                },
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Get Updater Logs",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Test Image",
        disabled: !workingIp || isLoading,
        onClick: async () => {
          setIsLoading(true);
          trackAction("controller test image");

          await fetchControllerApi("testImage")
            .then((res) => {
              return res.blob();
            })
            .then((data) => {
              const src = URL.createObjectURL(data);

              handleLog({
                title: "Test Image",
                content: data,
                src,
                contentType: "image",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Test Image",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Two Step Focus",
        disabled: !workingIp || isLoading,
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller two step focus");

          await fetchControllerApi("twoStepFocus")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Two Step Focus",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Two Step Focus",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Sleep Device",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        color: palette.warning.main,
        hoverColor: getContrastShade(palette.warning, "dark"),
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller slepp device");

          await fetchControllerApi("sleepDevice")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Sleep Device",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Sleep Device",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Terminate Application",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        color: palette.error.main,
        hoverColor: getContrastShade(palette.error, "dark"),
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller terminate application");

          await fetchControllerApi("terminate")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Terminate Application",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "Terminate Application",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "SetSetupMode",
        disabled: !workingIp || isLoading,
        isHidden: isV2,
        color: palette.error.main,
        hoverColor: getContrastShade(palette.error, "dark"),
        onClick: async () => {
          setIsLoading(true);

          trackAction("controller set setup mode");

          await fetchControllerApi("setSetupMode")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "SetSetupMode",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "SetSetupMode",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Check In",
        disabled: !workingIp || isLoading,
        isHidden: !isV2,
        onClick: async () => {
          setIsLoading(true);

          await fetchControllerApi("checkIn")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Check In",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "checkIn",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
      {
        label: "Exit Setup Mode",
        disabled: !workingIp || isLoading,
        isHidden: !isV2,
        color: palette.error.main,
        hoverColor: getContrastShade(palette.error, "dark"),
        onClick: async () => {
          setIsLoading(true);

          await fetchControllerApi("exitSetup")
            .then((res) => {
              return res.text();
            })
            .then((data) => {
              handleLog({
                title: "Check In",
                content: data,
                contentType: "text",
              });
            })
            .catch((err) => {
              console.error(err);

              handleLog({
                title: "checkIn",
                content: "Action failed! Something went wrong.",
                type: "error",
                contentType: "text",
              });
            });

          setIsLoading(false);
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isV2,
    deviceId,
    isLoading,
    palette.error,
    palette.warning,
    trackAction,
    workingIp,
  ]);

  const smallScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const { breakpoints } = useTheme();

  const controllerHeaderRef = useRef<HTMLDivElement>(null);

  return (
    <Modal open={isOpen} onClose={onClose} keepMounted>
      <Box
        sx={{
          position: "absolute" as "absolute",

          // minWidth: 600,
          maxHeight: "80vh",
          height: "100%",

          ...(smallScreenAndUp
            ? {
                top: "50%",
                left: "50%",
                minHeight: "70vh",
                transform: "translate(-50%, -50%)",
                borderRadius: 1,
              }
            : {
                bottom: "0",
                left: "0",
                minHeight: "80vh",

                borderRadius: 3,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }),

          width: {
            xs: "100vw",
            md: "70vw",
            lg: "60vw",
          },
          bgcolor: "background.paper",
          p: {
            xs: 2,
            sm: 4,
          },
          display: "flex",
        }}
      >
        <Box
          id="controller-container"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-bewteen",
            width: "100%",
            height: "100%",
            gap: 1,
            pointerEvents: isLoading ? "none" : "auto",

            [breakpoints.up("sm")]: {
              flexDirection: "row",
              gap: 1,
            },
          }}
        >
          <Box
            id="controller-buttons-container"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              height: 150,
              width: "100%",
              gap: 0.5,
              pr: 0,
              overflow: "auto",

              [breakpoints.up("sm")]: {
                width: "30%",
                flexDirection: "column",
                flexWrap: "nowrap",
                height: "100%",
                gap: 1,
                pr: 1,
              },
            }}
          >
            {buttons.map((button, buttonIndex) => {
              if (button.isHidden) return null;

              return (
                <Grid item key={buttonIndex}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Button
                      size="small"
                      disabled={button.disabled}
                      onClick={button.onClick}
                      variant="contained"
                      fullWidth
                      sx={({ palette }) => ({
                        textWrap: "nowrap",
                        textTransform: "none",
                        color: `${palette.onPrimary.main} !important`,

                        background: button.color || palette.primary.main,

                        ":hover": {
                          background:
                            button.hoverColor ||
                            getContrastShade(palette.primary, "dark"),
                        },

                        fontSize: {
                          xs: 12,
                          sm: 14,
                        },
                      })}
                    >
                      {button.label}
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Box>

          <Box
            id="controller-content-container"
            sx={{
              position: "relative",
              border: ({ palette }) =>
                `1px solid ${alpha(palette.onSurface.main, 0.7)}`,
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflow: "auto",
              [breakpoints.up("sm")]: {
                width: "70%",
              },
            }}
          >
            <Box
              id="controller-loading-spinner"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                pt: `${controllerHeaderRef.current?.clientHeight}px` || 0,
                display: isLoading ? "flex" : "none",
                background: alpha("#000", 0.1),
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                userSelect: "none",
              }}
            >
              <LDSRingLoader />
            </Box>

            <Box ref={controllerHeaderRef} id="controller-header-container">
              <Box
                sx={{
                  background: ({ palette }) =>
                    getContrastShade(palette.secondary, "light"),
                  borderBottom: ({ palette }) =>
                    `1px solid ${palette.onSecondary}`,
                  px: 1,
                  py: {
                    xs: 0.5,
                    sm: 1,
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: ({ palette }) =>
                    `${palette.onSecondary.main} !important`,
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      sm: 16,
                    },
                  }}
                >
                  Controller Logs
                </Typography>

                <Box>
                  <Tooltip title="Clear logs">
                    <IconButton
                      size="small"
                      onClick={() => setLogs([])}
                      sx={{
                        color: ({ palette }) => palette.onSecondary.main,
                      }}
                    >
                      <AutoDeleteIcon
                        sx={{
                          fontSize: {
                            xs: 14,
                            sm: 18,
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Close">
                    <IconButton
                      size="small"
                      onClick={() => onClose()}
                      sx={{
                        color: ({ palette }) => palette.onSecondary.main,
                      }}
                    >
                      <ClearIcon
                        sx={{
                          fontSize: {
                            xs: 14,
                            sm: 18,
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Box
              id="controller-logs-container"
              sx={{ flex: 1, overflow: "auto" }}
            >
              <Box
                sx={{
                  // position: "relative",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    minHeight: 400,
                  }}
                >
                  <Box ref={logsRef} sx={{ width: "100%", height: "100%" }}>
                    {logs.map((log, logIndex) => {
                      return (
                        <DeviceControllerLog
                          log={log}
                          key={logIndex}
                          border={logIndex !== logs.length - 1}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const DeviceStatusWindow = () => {
  const [device, setDevice] = useState<DeviceV2 | null>(null);
  const [deviceDetails, setDeviceDetails] = useState<DeviceV2Details | null>(
    null,
  );
  const [totalPhotoCount, setTotalPhotoCount] = useState<number>(0);
  const [photoTakenCount, setPhotoTakenCount] = useState<number>(0);
  const [photoUploadedCount, setPhotoUploadedCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isControllerModalOpen, setIsControllerModalOpen] =
    useState<boolean>(false);

  const setCurrentDevice = useSetAtom(currentDeviceState);

  const navigate = useNavigate();

  const params = useParams();

  const { trackAction } = useDeviceTracker(device, "status", true);

  useEffect(() => {
    getDevice();

    return () => {
      setCurrentDevice(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const getDevice = async () => {
    setIsLoading(true);

    const firebaseController = getFirebaseController();

    await firebaseController.Device.getDevice(params.id as string)
      .then(async (device) => {
        if (device) {
          setDevice(device);
          setCurrentDevice(device);

          const deviceDetails =
            await firebaseController.Device.getDeviceDetails(
              device.id as number,
            ).then((details) => {
              if (details) {
                setDeviceDetails(details);
                return details;
              }
            });

          const momentWithTimeZone = momentWithTimeZoneHOF(
            deviceDetails?.timeZone,
          );

          firebaseController.Device.getDeviceEvents(
            device.id as number,
            momentWithTimeZone().startOf("days").unix(),
            momentWithTimeZone().endOf("days").unix(),
            1,
          ).then((events) => {
            setPhotoUploadedCount(events.length);
          });

          firebaseController.Device.getDeviceInterval(device.id as number).then(
            (interval) => {
              if (interval) {
                const [taken, total] = getPhotoTakenCounts(
                  interval.timeFrom,
                  interval.timeTo,
                  interval.timeBetweenShots,
                  deviceDetails?.timeZone,
                );

                setTotalPhotoCount(total);
                setPhotoTakenCount(taken);
              }
            },
          );
        } else {
          throw new Error("Device not found.");
        }
      })
      .catch((err) => {
        navigate("../");
        console.error(err);
      });

    setIsLoading(false);
  };

  const buttons = [
    {
      title: "Device Config",
      onClick: () => {
        navigate("./edit");
        trackAction("settings");
      },
    },
    { title: "Manage Access", onClick: () => {} },
    {
      title: "View Location",
      disabled:
        !deviceDetails?.longitude ||
        !deviceDetails?.latitude ||
        !deviceDetails.timeZone,
      onClick: () => {
        if (deviceDetails) {
          if (deviceDetails.latitude && deviceDetails.latitude) {
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${deviceDetails.latitude},${deviceDetails.longitude}`,
            );
          } else if (deviceDetails.timeZone) {
            const city = deviceDetails.timeZone.split("/")[1];

            window.open(
              `https://www.google.com/maps/search/?api=1&query=${city}`,
            );
          }

          trackAction("view location");
        }
      },
    },
    {
      title: "Controller",
      // disabled: !device?.setupMode,
      onClick: () => {
        setIsControllerModalOpen(!isControllerModalOpen);
        trackAction("controller");
      },
    },
  ];

  const { momentWithTimeZone } = useTimeZone(deviceDetails?.timeZone);

  const details = [
    {
      title: "Last Contact Time",
      value: device?.lastReported
        ? momentWithTimeZone(device?.lastReported).format(
            "DD-MM-YYYY HH:mm:ss Z",
          )
        : "-",
      value2: moment(device?.lastReported).format("DD-MM-YYYY HH:mm:ss Z"),
    },
    {
      title: "Estimated Next Contact",
      value: deviceDetails?.wakeUpTime
        ? momentWithTimeZone(deviceDetails?.wakeUpTime).format(
            "DD-MM-YYYY HH:mm:ss Z",
          )
        : "-",
      value2: moment(deviceDetails?.wakeUpTime).format("DD-MM-YYYY HH:mm:ss Z"),
    },
    {
      title: "Estimated Next Photo",
      value: deviceDetails?.nextPhoto
        ? momentWithTimeZone(deviceDetails?.nextPhoto).format(
            "DD-MM-YYYY HH:mm:ss Z",
          )
        : "-",
      value2: moment(deviceDetails?.nextPhoto).format("DD-MM-YYYY HH:mm:ss Z"),
    },
  ];

  // TODO: refactor this
  const isBatteryWarning =
    !!device && _DeviceController.isBatteryWarning(device.battery);
  const isBatteryError =
    !!device && _DeviceController.isBatteryError(device.battery);

  const isSolarWarning =
    !!device && _DeviceController.isSolarWarning(device.solar);
  const isSolarError = !!device && _DeviceController.isSolarError(device.solar);

  const info = [
    {
      value: `${getDisplayValue<number>(
        device?.battery,
        (v) => v?.toFixed(2),
      )} V`,
      icon: (
        <Badge
          color={isBatteryError ? "error" : "warning"}
          variant="dot"
          invisible={!isBatteryWarning && !isBatteryError}
          sx={{ height: "100%", width: "100%", display: "block" }}
        >
          <Battery4BarIcon />
        </Badge>
      ),
      description: "Device battery power (Volt)",
    },
    {
      value: `${getDisplayValue<number>(
        device?.solar,
        (v) => v?.toFixed(2),
      )} V`,
      icon: (
        <Badge
          color={isSolarError ? "error" : "warning"}
          variant="dot"
          invisible={!isSolarWarning && !isSolarError}
          sx={{ height: "100%", width: "100%", display: "block" }}
        >
          <SolarPowerIcon />{" "}
        </Badge>
      ),
      description: "Device solar power (Volt)",
    },
    {
      value: `${getDisplayValue<number>(
        device?.current,
        (v) => v?.toFixed(2),
      )} A`,
      icon: <ElectricBoltIcon />,
      description: "Device current charge (Amp)",
    },
    {
      value: `${photoTakenCount}/${totalPhotoCount}`,
      icon: <CameraIcon />,
      description: "Estimated photo taken count of the day",
    },
    {
      value: `${photoUploadedCount}/${totalPhotoCount}`,
      icon: <CloudUploadIcon />,
      description: "Estimated photo uploaded count of the day",
    },
    {
      value: getDisplayValue(deviceDetails?.version),
      icon: <MemoryIcon />,
      description: "Device version",
    },
    {
      value: deviceDetails?.timeZone?.split("/")?.pop() || "-",
      icon: <MyLocationIcon />,
      description: "Location city",
    },
    {
      value: `${getDisplayValue(deviceDetails?.signalStrength)}%`,
      icon: <SignalCellularAltIcon />,
      description: "Signal strength",
    },
    {
      value: `${getDisplayValue(deviceDetails?.storageFree, (v) =>
        megaBytesToGigaBytes(v),
      )} GB`,
      icon: <SdStorageIcon />,
      description: "Free space",
    },
    {
      value: `${getDisplayValue(deviceDetails?.storageMax, (v) =>
        megaBytesToGigaBytes(v),
      )} GB`,
      icon: <StorageIcon />,
      description: "Max space",
    },
  ];

  const getFullDeviceName = () => {
    if (device) {
      const name = device.friendlyName || device.deviceId;

      if (device.frontendName) {
        const [clientName, siteName] = (device.frontendName || "").split(" / ");

        const names = [
          clientName,
          siteName,
          device.friendlyName || device.deviceId,
        ]
          .filter((name) => !!name)
          .join(" - ");

        return `Site: ${names}`;
      }

      return `${_DeviceController.isV2(device.id) ? "(V2) " : ""}${name}`;
    } else {
      return "";
    }
  };

  const breadcrumbs = useMemo(() => {
    if (!device) {
      return [];
    }

    return [
      {
        label: "Devices",
        path: "/devices",
      },
      {
        label: device.friendlyName || device.deviceId || "",
        path: `/devices/${device?.id}` || "",
      },
    ];
  }, [device]);

  const smallScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const a = useMemo(() => {
    let tz = deviceDetails?.timeZone as string;

    if (!tz || tz === "null") {
      tz = momentTz.tz.guess() as string;
    }

    return tz;
  }, [deviceDetails]);

  return (
    <Box>
      <Navbar isShowDevice={true} />

      {device && (
        <Box
          sx={({ customConfig, palette }) => ({
            pt: `calc(${customConfig.navbarHeight} + ${customConfig.subNavbarHeight})`,
            color: palette.surface.main,
          })}
        >
          <DeviceControllerModal
            deviceId={device.deviceId}
            deviceDetails={deviceDetails}
            isOpen={isControllerModalOpen}
            onClose={() => setIsControllerModalOpen(false)}
            trackAction={trackAction}
          />

          <Container
            fixed
            maxWidth={"xl"}
            sx={{
              height: "100%",
              p: {
                xs: 2,
                sm: 3,
              },
            }}
          >
            <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ pb: 2 }} />

            <Box
              sx={{
                backgroundColor: ({ palette }) => palette.tertiary.main,
                p: {
                  xs: 1,
                  sm: 2,
                },
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: ({ palette }) =>
                    isLoading
                      ? alpha(palette.surface.main, 0.7)
                      : palette.surface.main,
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={1}
                  sx={{
                    color: ({ palette }) => palette.onSurface.main,
                    p: 2,
                  }}
                >
                  <Grid item xs={12} sm="auto">
                    <Typography
                      sx={{ fontSize: 20, lineHeight: 1, fontWeight: "bold" }}
                    >
                      {getFullDeviceName()}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm="auto">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: 12,
                          sm: 20,
                        },
                        lineHeight: 1,
                        fontWeight: "bold",
                      }}
                    >
                      # {device.deviceId.replace("SNAPPY", "SNAPPY-")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems={"center"}
                  sx={{
                    color: ({ palette }) => palette.onSurface.main,
                    py: 2,
                    px: {
                      xs: 2,
                      sm: 4,
                    },
                  }}
                  spacing={4}
                >
                  <Grid xs={12} md={6} lg={4} item>
                    <Box>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          LATEST IMAGE
                        </Typography>
                      </Box>

                      <ThumbnailCard
                        device={device}
                        momentWithTimeZone={momentWithTimeZone}
                      />
                    </Box>
                  </Grid>

                  <Grid xs={12} md={6} lg={6} item>
                    <Box
                      sx={({ breakpoints }) => ({
                        px: {
                          xl: 10,
                          lg: 4,
                          md: 0,
                        },
                      })}
                    >
                      <Grid
                        container
                        direction={"column"}
                        justifyContent={"space-between"}
                        spacing={{ xs: 4, xl: 8 }}
                      >
                        <Grid item sx={{ width: "100%" }}>
                          <Box sx={{ width: "100%" }}>
                            <Table
                              padding="none"
                              sx={{
                                // maxWidth: "max-content",
                                width: "100%",
                                ".MuiTableCell-root ": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableBody>
                                {details.map((detail, detailIndex) => {
                                  return (
                                    <TableRow
                                      key={detailIndex}
                                      sx={
                                        smallScreenAndUp
                                          ? {}
                                          : {
                                              display: "block",
                                              mb: 2,
                                            }
                                      }
                                    >
                                      <TableCell
                                        sx={({ breakpoints }) => ({
                                          verticalAlign: "top",
                                          fontWeight: "bold",
                                          fontSize: 16,
                                          display: "block",
                                          textAlign: "center",

                                          [breakpoints.up("sm")]: {
                                            display: "table-cell",
                                            textAlign: "left",
                                          },
                                        })}
                                      >
                                        {detail.title}
                                      </TableCell>

                                      <TableCell
                                        sx={({ breakpoints }) => ({
                                          display: "block",
                                          textAlign: "center",

                                          fontSize: 16,
                                          pl: 0,

                                          [breakpoints.up("sm")]: {
                                            pl: 4,
                                            display: "table-cell",
                                            textAlign: "right",
                                          },
                                        })}
                                      >
                                        <ToggleTypography
                                          sx={{ fontSize: 16 }}
                                          value={detail.value2}
                                        >
                                          {detail.value}
                                        </ToggleTypography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Box>
                        </Grid>

                        <Grid item sx={{ width: "100%" }}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: {
                                xs: "repeat(auto-fit, minmax(60px, 1fr))",
                                sm: "repeat(5, minmax(60px, 1fr))",
                                md: "repeat(4, minmax(60px, 1fr))",
                                lg: "repeat(5, minmax(60px, 1fr))",
                                xl: "repeat(5, minmax(60px, 1fr))",
                              },
                              gap: 4,
                            }}
                          >
                            {info.map((info, infoIndex) => {
                              return (
                                <Tooltip
                                  title={info.description}
                                  key={infoIndex}
                                >
                                  <Grid
                                    key={infoIndex}
                                    container
                                    direction={"column"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        svg: {
                                          fontSize: {
                                            xs: 20,
                                            sm: 24,
                                          },
                                        },
                                      }}
                                    >
                                      {info.icon}
                                    </Grid>

                                    <Grid item>
                                      <Typography
                                        sx={{
                                          textAlign: "center",
                                          whiteSpace: "nowrap",
                                          fontSize: {
                                            xs: 12,
                                            sm: 14,
                                          },
                                        }}
                                      >
                                        {info.value}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Tooltip>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid xs={12} md={12} lg={2} item>
                    <Box sx={{ height: "100%" }}>
                      <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        spacing={4}
                      >
                        {buttons.map((button, buttonIndex) => {
                          return (
                            <Grid item key={buttonIndex}>
                              <Button
                                disabled={button.disabled}
                                fullWidth
                                variant={"contained"}
                                onClick={button.onClick}
                                sx={{
                                  textTransform: "none",
                                  color: ({ palette }) =>
                                    palette.onPrimary.main,
                                }}
                              >
                                {button.title}
                              </Button>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  backgroundColor: ({ palette }) => palette.surface.main,
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                {/* TODO: this is a quick fix for time zone bug, only render this component when the device is loaded and timezone is fixed. */}
                {!isLoading && <TabsSection device={device} timeZone={a} />}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default DeviceStatusWindow;
