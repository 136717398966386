import { useState, useEffect } from "react";

import { useAtomValue } from "jotai";
import {
  currentGalleryClientState,
  currentGalleryJobsiteState,
} from "states/imageViewer";

import useEventTracker from "hooks/eventTracker/useEventTracker";

import {
  DeviceV2,
  DropBoxImage,
  FirebaseImage,
  GalleryV2,
  PhotoSentielImage,
} from "database/DataTypes";

import ImageHandlerV2, { Image } from "database/ImageHandlerV2";

export const IMAGE_VIEWER_EVENT_ACTION_ITEM_MAPPER: {
  [key in ImageViewerAction | "download"]?: {
    title: string;
    description?: string | string[];
  };
} = {
  favorite: {
    title: "Favoriting image",
    description: "",
  },
  comments: {
    title: "Comments section",
    description: "",
  },
  tags: {
    title: "Tags toggle",
    description: "",
  },
  "weather info": {
    title: "Weather info",
    description: "",
  },
  "image info": {
    title: "Image info",
    description: "",
  },
  compare: {
    title: "Image compared",
    description: "",
  },
  "all images": {
    title: "All images",
    description: "",
  },
  download: {
    title: "Image Download",
  },
  "download original": {
    title: "Original Image Downloaded",
    description: "",
  },
  "download with timestamp": {
    title: "Download Image with timestamp",
    description: "",
  },
  "timelapse player": {
    title: "Timelapse player",
    description: "",
  },
  settings: {
    title: "Gallery settings",
    description: "",
  },
  "next image": {
    title: "Next images",
  },
  "previous image": {
    title: "Previous images",
  },
  "previous image bottom": {
    title: "Prvious images (toolbar)",
  },
  "next image bottom": {
    title: "Next images (toolbar)",
  },
  "thumbnails slider": {
    title: "Thumbnails list",
  },
  "next day bottom": {
    title: "Next Day",
  },
  "previous day bottom": {
    title: "Previous Day",
  },
  "date picker": {
    title: "Open calander",
  },
  "zoom slider": {
    title: "Zoom image with slider",
  },
  "item toggle": {
    title: "Gallery select dropdown",
  },
  "item search": {
    title: "Gallery Search",
  },
};

export type ImageViewerAction =
  | "favorite"
  | "comments"
  | "tags"
  | "hide"
  | "weather info"
  | "image info"
  | "compare"
  | "all images"
  | "timelapse player"
  | "settings"
  | "download original"
  | "download with timestamp"
  | "download with weather info"
  | "zoom slider"
  | "thumbnails slider"
  | "date picker"
  | "item toggle"
  | "item search"
  | "next image"
  | "next day"
  | "previous image"
  | "previous day"
  | "next image bottom"
  | "next day bottom"
  | "previous image bottom"
  | "previous day bottom"
  | "select all"
  | "filter tags"
  | "filter favorited"
  | "filter commented"
  | "filter current day"
  | "filter past week"
  | "filter past month"
  | "filter custom month"
  | "filter all time favorited"
  | "filter all time tagged"
  | "filter all time commented"
  | "filter all time hidden";

export type TrackImageViewerAction = (actionName: ImageViewerAction) => void;

type ImageViewerTrackInfo = {
  image_viewer_id: string;
  device_id: string;
  device_name: string;
  device_friendly_name: string;
  gallery_id: string;
  gallery_name: string;
  gallery_friendly_name: string;
  job_site_id: string;
  client_id: string;
  page: "main" | "grid" | "weather";
};

const useImageViewerTracker = (
  imageHandler: ImageHandlerV2 | null,
  page: ImageViewerTrackInfo["page"],
  isTrackView?: boolean,
) => {
  const { trackEvent } = useEventTracker();

  const [info, setInfo] = useState<ImageViewerTrackInfo>();
  const [isViewTracked, setIsViewTracked] = useState(false);

  const currentGalleryJobsite = useAtomValue(currentGalleryJobsiteState);
  const currentGalleryClient = useAtomValue(currentGalleryClientState);

  useEffect(() => {
    if (imageHandler) {
      let info = {
        image_viewer_id: imageHandler.currentObject.id as unknown as string,
        device_id: "",
        device_name: "",
        device_friendly_name: "",
        gallery_id: "",
        gallery_name: "",
        gallery_friendly_name: "",
        job_site_id: "",
        client_id: "",
        page,
      };

      const isDevice = imageHandler.isDevice;

      if (isDevice) {
        const device = imageHandler.currentObject as DeviceV2;

        info = {
          ...info,
          device_id: device.id as unknown as string,
          device_name: device.deviceId,
          device_friendly_name: device.friendlyName,
        };

        setInfo(info);
      } else {
        const gallery = imageHandler.currentObject as GalleryV2;

        if (
          currentGalleryJobsite?.id === gallery.jobSite &&
          currentGalleryClient?.sites.includes(gallery.jobSite)
        ) {
          info = {
            ...info,
            gallery_id: gallery.id as unknown as string,
            gallery_name: gallery.galleryName,

            gallery_friendly_name: [
              currentGalleryClient?.name,
              currentGalleryJobsite.name,
              gallery.galleryName,
            ]
              .filter((value) => !!value)
              .join(" - "),
            job_site_id: currentGalleryJobsite.id as unknown as string,
            client_id: currentGalleryClient.id as unknown as string,
          };

          setInfo(info);
        }
      }
    }

    return () => {
      if (imageHandler?.currentObject.id !== info?.image_viewer_id) {
        setIsViewTracked(false);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGalleryClient, currentGalleryJobsite, imageHandler, page]);

  useEffect(() => {
    if (isTrackView && info && !isViewTracked) {
      trackView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const trackAction = (actionName: ImageViewerAction) => {
    if (imageHandler) {
      trackEvent("image_viewer_action", {
        ...info,
        action: actionName,
      });
    }
  };

  const trackImage = (image: Image) => {
    if (image && imageHandler) {
      let imageInfo;

      switch (imageHandler.hostUsed) {
        case "0": {
          const typedImage = image as FirebaseImage;

          imageInfo = {
            image_id: typedImage.id,
            image_name: typedImage.fileName,
            image_host: imageHandler.hostUsed,
          };

          break;
        }
        case "1":
          {
            const typedImage = image as DropBoxImage;

            imageInfo = {
              image_id: typedImage.id,
              image_name: typedImage.name,
              image_host: imageHandler.hostUsed,
            };
          }
          break;

        case "2": {
          const typedImage = image as PhotoSentielImage;

          imageInfo = {
            image_id: typedImage.photo_id,
            image_name: typedImage.photo_id,
            image_host: imageHandler.hostUsed,
          };

          break;
        }

        default:
          break;
      }

      if (imageInfo) {
        trackEvent("image_view", {
          ...info,
          ...imageInfo,
        });
      }
    }
  };

  const trackView = () => {
    if (info) {
      trackEvent("image_viewer_view", {
        ...info,
      });

      setIsViewTracked(true);
    }
  };

  return { trackAction, trackImage };
};

export default useImageViewerTracker;
