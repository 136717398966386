import { RefObject, useEffect, useMemo, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { useAtomValue } from "jotai";
import { searchState } from "states/navbar";
import { useSnackbar } from "context/Snackbar/SnackbarContext";

import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  alpha,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import StarIcon from "@mui/icons-material/Star";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";

import { CHANNEL_MAPPER } from "database/DataDefaultValues";
import { Version } from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import prettyBytes from "pretty-bytes";
import moment from "moment";
import _ from "lodash";

import ConfirmationModal from "components/BaseModal/ConfirmationModal";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import DashboardHeader, {
  SortType,
} from "components/Dashboard/DashboardHeader";
import DashboardContainer from "components/Dashboard/DashboardContainer";

import { Column } from "components/Dashboard/SettingsTable";
import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";
import { getContrastShade } from "theme/reliveItTheme";

const SORT_OPTIONS = ["Version"];

const ListView = ({
  displayVersionList,
  headerRef,
  isLoading,
  currentVersion,
  handleDeleteClick,
  handlePromoteClick,
}: {
  displayVersionList: Version[];
  headerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  currentVersion: Version;
  handleDeleteClick: (version: Version) => void;
  handlePromoteClick: (version: Version) => void;
}) => {
  const columns: Column[] = useMemo(() => {
    return [
      { id: "status", label: "Status", align: "center" },
      { id: "version", label: "Version" },
      { id: "releaseDate", label: "Release Date" },
      { id: "fullSize", label: "Full size" },
      { id: "partialSize", label: "Partial size" },
      { id: "downloads", label: "Downloads" },
      { id: "checksums", label: "MD5 Checksums" },
      { id: "location", label: "Location" },
      { id: "manage", label: "Manage", align: "center" },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!currentVersion) {
      return [];
    }

    return displayVersionList.map((version, versionIndex) => {
      return {
        status: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {currentVersion.version === version.version ? (
              <StarIcon
                sx={{
                  color: ({ palette }) => palette.warning.main,
                  background: ({ palette }) =>
                    alpha(palette.tertiary.main, 0.4),
                  borderRadius: "100%",
                  p: 0.5,
                }}
              />
            ) : (
              <FileUploadIcon
                sx={{
                  color: ({ palette }) => palette.secondary.main,
                  background: ({ palette }) =>
                    alpha(palette.tertiary.main, 0.4),

                  borderRadius: "100%",
                  p: 0.5,
                }}
              />
            )}
          </Box>
        ),
        version: version.version,
        releaseDate: moment
          .unix(version.epochTime)
          .format("DD/MM/YYYY, HH:mm:A"),
        fullSize: prettyBytes(version.fullSize),
        partialSize: prettyBytes(version.partialSize),
        downloads: (
          <Box>
            <MuiLink
              href={version.fullDownloadUrl}
              sx={{
                ":hover": {
                  textDecoration: "underline !important",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DownloadIcon sx={{ fontSize: 12, mr: 0.5 }} />
                Full Update
              </Typography>
            </MuiLink>

            <MuiLink
              href={version.partialDownloadUrl}
              sx={{
                ":hover": {
                  textDecoration: "underline !important",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <DownloadIcon sx={{ fontSize: 12, mr: 0.5 }} />
                Partial Update
              </Typography>
            </MuiLink>
          </Box>
        ),
        checksums: (
          <Box>
            <Typography
              sx={{
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
            >
              (F) {version.fullMd5}
            </Typography>

            <Typography
              sx={{
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                mt: 1,
              }}
            >
              (P) {version.partialMd5}
            </Typography>
          </Box>
        ),
        location: version.storageLocation,
        manage: (
          <Box>
            {currentVersion.channel === 1 && versionIndex === 0 && (
              <IconButton
                size="small"
                sx={{ color: ({ palette }) => palette.primary.main }}
                onClick={() => handlePromoteClick(version)}
              >
                <PublishIcon fontSize="small" />
              </IconButton>
            )}

            <IconButton
              size="small"
              sx={{ color: ({ palette }) => palette.error.main }}
              onClick={() => handleDeleteClick(version)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayVersionList, currentVersion]);

  return (
    <Box>
      <TableContainer
        sx={{
          "@media(min-height: 500px)": {
            maxHeight: ({ customConfig }) =>
              `calc(100vh - ${customConfig.navbarHeight} - ${customConfig.subNavbarHeight} - ${headerRef.current?.clientHeight}px - 72px)`,
          },
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead sx={{ boxShadow: 1 }}>
            <TableRow>
              {columns.map((column) => (
                <Tooltip title={column.description} key={column.id}>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      width: "min-content",
                      textWrap: "nowrap",
                      p: 1.5,
                      backgroundColor: ({ palette }) =>
                        getContrastShade(palette.secondary, "light"),

                      color: ({ palette }) => palette.onSecondary.main,

                      borderBottom: 0,
                      borderRight: ({ palette }) =>
                        `1px solid ${alpha(
                          palette.onSurface.main,
                          0.2,
                        )} !important`,

                      "&:last-child": {
                        borderRight: 0,
                      },
                    }}
                  >
                    {column.label}

                    {column.description && (
                      <Typography
                        variant="caption"
                        sx={{ fontSize: 12, verticalAlign: "super" }}
                      >
                        *
                      </Typography>
                    )}
                  </TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.length > 0 ? (
              <>
                {rows.map((row, rowIndex) => {
                  const version = displayVersionList[rowIndex];

                  return (
                    <TableRow
                      key={`${version.id}_${version.channel}`}
                      hover
                      sx={({ palette }) => ({
                        backgroundColor: palette.surface.main,

                        "&:nth-of-type(even)": {
                          backgroundColor: alpha(
                            getContrastShade(palette.surface, "dark"),
                            0.2,
                          ),
                        },

                        ":hover": {
                          backgroundColor: `${alpha(
                            getContrastShade(palette.surface, "dark"),
                            0.6,
                          )} !important`,
                        },
                      })}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderRight: ({ palette }) =>
                                `1px solid ${alpha(
                                  palette.onSurface.main,
                                  0.2,
                                )} !important`,

                              borderBottom: 0,
                              p: 1.5,

                              "&:last-child": {
                                borderRight: 0,
                              },
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow sx={{ position: "relative" }}>
                <TableCell colSpan={columns.length}>
                  <Box
                    sx={{
                      position: "sticky",
                      top: 0,
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "max-content",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: ({ palette }) =>
                          alpha(palette.onSurface.main, 0.7),
                      }}
                    >
                      {isLoading ? "Loading..." : " No version found."}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const VersionListWindow = () => {
  const [versionList, setVersionList] = useState<Version[]>([]);
  const [displayVersionList, setDisplayVersionList] = useState<Version[]>([]);

  const [currentSortType, setCurrentSortType] = useState<SortType>({
    type: SORT_OPTIONS[0],
    isAsc: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const headerRef = useRef<HTMLDivElement>(null);

  const channelParam = Number(searchParams.get("channel"));

  const [currentChannel, setCurrentChannel] = useState(
    [0, 1].includes(channelParam) ? channelParam : 0,
  );

  const [currentVersion, setCurrentVersion] = useState<Version | null>(null);

  const [versionDeleteModalParams, setVersionDeleteModalParams] = useState<{
    isOpen: boolean;
    version: Version | null;
  }>({
    isOpen: false,
    version: null,
  });

  const { setSnackbarProps } = useSnackbar();

  const { trackAction, isTrackerLoaded } = useDashboardTracker("versions");

  const search = useAtomValue(searchState);

  useEffect(() => {
    if (isTrackerLoaded) {
      trackAction("view list");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackerLoaded]);

  useEffect(() => {
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.onSearch]);

  useEffect(() => {
    getVersionList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVersionList = async (channel = currentChannel) => {
    await getFirebaseController()
      .Version.getVersionsByChannel(channel)
      .then((data) => {
        setCurrentVersion(data[0]);
        setVersionList(data);
        setDisplayVersionList(data);
      });
  };

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: "Versions",
        path: "/Versions",
      },
      {
        label: `List (${CHANNEL_MAPPER[currentChannel]})`,
      },
    ];
  }, [currentChannel]);

  const handleChannelChange = async (channel) => {
    setSearchParams({ channel });
    setCurrentChannel(channel);

    await getVersionList(channel);
  };

  const handleSort = (sortType: string) => {
    const isAsc =
      currentSortType.type === sortType ? !currentSortType.isAsc : false;
    const order = isAsc ? "asc" : "desc";

    setCurrentSortType({
      type: sortType,
      isAsc,
    });

    let newVersionList = _.cloneDeep(displayVersionList);

    switch (sortType) {
      case "Version":
        trackAction("sort version");

        newVersionList = _.orderBy(newVersionList, "epochTime", order);
        break;

      default:
        break;
    }

    setDisplayVersionList(newVersionList);
  };

  const handleSearch = () => {
    let newVersionList = _.cloneDeep(versionList);

    if (search.value) {
      trackAction("search");

      newVersionList = newVersionList.filter((version) => {
        const searchFieldValues = [version.version];

        return searchFieldValues.some((value) => {
          return (
            value &&
            _.toLower(value.toString()).includes(_.toLower(search.value))
          );
        });
      });
    }

    setDisplayVersionList(newVersionList);
  };

  const handleDelete = async (version: Version) => {
    await getFirebaseController()
      .Version.deleteVersion(version.id, version.storageLocation)
      .then(() => {
        setSnackbarProps({
          open: true,
          content: `${CHANNEL_MAPPER[version.channel]} version ${
            version.version
          } deleted successfully!`,
        });
      });

    getVersionList();
  };

  const handleVersionPromote = async (version: Version) => {
    await getFirebaseController()
      .Version.promoteVersion(version.id)
      .then(() => {
        setSnackbarProps({
          open: true,
          content: `${CHANNEL_MAPPER[version.channel]} version ${
            version.version
          } promoted successfully!`,
        });

        getVersionList();
      })
      .catch((err) => {
        setSnackbarProps({
          open: true,
          severity: "error",
          content: err.message,
        });
      });
  };

  const handleDeleteClick = (version: Version) => {
    setVersionDeleteModalParams({
      isOpen: true,
      version,
    });
  };

  const getDeleteVersionModalTitleAndMessage = useMemo(() => {
    const title = `Remove ${
      CHANNEL_MAPPER[versionDeleteModalParams.version?.channel as number]
    } version ${versionDeleteModalParams.version?.version}?`;

    let message;

    if (versionDeleteModalParams.version?.channel === 0) {
      message = `Are you sure you want to remove production version ${versionDeleteModalParams.version?.version}? This process cannot be undone.`;
    } else {
      message = `Are you sure you want to remove beta version ${versionDeleteModalParams.version?.version} and its associating build? This process cannot be undone.`;
    }

    return [title, message];
  }, [
    versionDeleteModalParams?.version?.version,
    versionDeleteModalParams.version?.channel,
  ]);

  return (
    <DashboardContainer NavbarProps={{ title: "Version Dashboard" }}>
      <ConfirmationModal
        title={getDeleteVersionModalTitleAndMessage[0]}
        message={getDeleteVersionModalTitleAndMessage[1]}
        open={versionDeleteModalParams.isOpen}
        onDone={() => {
          return handleDelete(versionDeleteModalParams.version as Version);
        }}
        onClose={() => {
          setVersionDeleteModalParams({
            isOpen: false,
            version: null,
          });
        }}
      />

      <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ pb: 2 }} />

      <DashboardHeader
        sortOptions={SORT_OPTIONS}
        currentSortType={currentSortType}
        handleSort={handleSort}
        buttons={[
          {
            label: "",
            component: (
              <TextField
                select
                size="small"
                value={currentChannel}
                sx={{
                  width: 150,
                  ".MuiSelect-select": {
                    py: 1,
                    minHeight: `0 !important`,
                    lineHeight: 1,
                  },
                  ".MuiInputBase-root": {
                    backgroundColor: ({ palette }) => palette.surface.main,
                    outline: ({ palette }) =>
                      `${palette.onSurface.main} !important`,
                    fontSize: 14,
                    p: 0,

                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: ({ palette }) =>
                        alpha(palette.onSurface.main, 0.2),
                    },

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: ({ palette }) => palette.primary.main,
                    },

                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: ({ palette }) =>
                        alpha(palette.onSurface.main, 0.1),
                    },
                  },
                }}
              >
                {[
                  {
                    value: 0,
                    label: "Production",
                  },
                  {
                    value: 1,
                    label: "Beta",
                  },
                ].map((option) => (
                  <MenuItem
                    onClick={() => handleChannelChange(option.value)}
                    dense
                    key={option.value}
                    value={option.value}
                    sx={{ fontSize: 13 }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ),
          },
        ]}
      />

      <ListView
        displayVersionList={displayVersionList}
        isLoading={false}
        headerRef={headerRef}
        currentVersion={currentVersion!}
        handleDeleteClick={handleDeleteClick}
        handlePromoteClick={handleVersionPromote}
      />
    </DashboardContainer>
  );
};

export default VersionListWindow;
