import { ChangeEvent, useState } from "react";

import { alpha, Box, Container, InputProps, Typography } from "@mui/material";

import SignInWindow from "./SignInWindow";

import {
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import ReliveItLogo from "assets/BEERAssets/ReliveIt/Relive-IT-Logo-White.png";
import { useSnackbar } from "context/Snackbar/SnackbarContext";
import { useAuth } from "context/Auth/AuthContext";
import { getFirebaseController } from "database/FirebaseController";
import { getContrastShade } from "theme/reliveItTheme";

export interface AuthInputValuesType {
  username: string;
  email: string;
  password: string;
  isRemember: boolean;
}

export interface AuthBasePropsType {
  setCurrentPage: (page: number) => void;
  isLoading: boolean;
  isAuthLoading: boolean;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputValues: AuthInputValuesType;
  inputBaseProps: Record<string, any>;
}

const AuthWindow = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<AuthInputValuesType>({
    username: "",
    email: "",
    password: "",
    isRemember: true,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, type, checked } = e.target;

    setInputValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const { setSnackbarProps } = useSnackbar();

  const { isLoading: isAuthLoading } = useAuth();

  const handleSignIn = async () => {
    setIsLoading(true);

    const { username, password, isRemember } = inputValues;

    const firebaseController = getFirebaseController();

    if (username && password) {
      await firebaseController.Auth.setCurrentPersistence(
        isRemember ? browserLocalPersistence : browserSessionPersistence,
      );

      await firebaseController.Auth.signIn(username, password).catch((err) => {
        setSnackbarProps({
          open: true,
          content: "Login failed! Please check your crenentials and try again.",
          severity: "error",
        });

        console.error(err);
      });
    } else {
      setSnackbarProps({
        open: true,
        content: "Please fill in the username and password.",
        severity: "error",
      });
    }

    setIsLoading(false);
  };

  const handleSignUp = async () => {
    setIsLoading(true);

    const { username, email, password } = inputValues;

    if (username && email && password) {
      //
    }

    setIsLoading(false);
  };

  const handleResetPassword = async () => {
    setIsLoading(true);

    const { email } = inputValues;

    if (email) {
      //
    }

    setIsLoading(false);
  };

  const inputBaseProps: InputProps = {
    disabled: isLoading || isAuthLoading,
    fullWidth: true,
    size: "small",
    inputProps: {
      autoCapitalize: "off",
      autoCorrect: "off",
      sx: {
        "&:-webkit-autofill": {
          backgroundColor: ({ palette }) =>
            `${
              isLoading || isAuthLoading
                ? getContrastShade(palette.secondary, "light")
                : palette.secondary.main
            } !important`,
          WebkitBoxShadow: ({ palette }) =>
            `0 0 0 1000px ${
              isLoading || isAuthLoading
                ? getContrastShade(palette.secondary, "light")
                : palette.secondary.main
            } inset`,
          WebkitTextFillColor: ({ palette }) => `${palette.onSecondary.main}`,
        },
      },
    },
    sx: ({ palette }) => ({
      backgroundColor: `${
        isLoading || isAuthLoading
          ? getContrastShade(palette.secondary, "light")
          : palette.secondary.main
      } !important`,

      ".MuiInputBase-root": {
        color: `${palette.onSecondary.main} !important`,

        fieldset: {
          borderColor: `${palette.onSecondary.main} !important`,
        },

        ".MuiInputAdornment-root": {
          color: `${palette.onSecondary.main} !important`,
        },

        "&.Mui-disabled .MuiInputAdornment-root": {
          color: ({ palette }) => palette.secondary.main,
        },
      },

      ".MuiInputBase-root ::placeholder": {
        color: `${palette.onSecondary.main} !important`,
        opacity: "0.8 !important",
      },

      borderRadius: 1,
    }),
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        handleSignIn();
      }
    },
  };

  const baseProps: any = {
    setCurrentPage,
    isLoading,
    isAuthLoading,
    inputValues,
    handleInputChange,
    inputBaseProps,
  };

  return (
    <Container fixed maxWidth={"xl"} sx={{ width: "100vw", height: "100vh" }}>
      <Box
        sx={{
          background: ({ palette }) => palette.secondary.main,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          pb: {
            xs: 12,
            sm: 4,
          },
        }}
      >
        <Box sx={{ width: 350 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            margin="auto"
            sx={{
              width: {
                xs: 150,
                sm: 180,
                md: 220,
              },
            }}
          >
            <img src={ReliveItLogo} alt={"ReliveItLogo"} width="100%" />
          </Box>
          {
            {
              0: <SignInWindow {...baseProps} handleSignIn={handleSignIn} />,
              // 1: <SignUpWindow {...baseProps} handleSignUp={handleSignUp} />,
              // 2: (
              //   <ResetPasswordWindow
              //     {...baseProps}
              //     handleResetPassword={handleResetPassword}
              //   />
              // ),
            }[currentPage]
          }
        </Box>

        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            p: 2,
            display: "flex",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              lineHeight: 1,
              color: ({ palette }) => alpha(palette.onPrimary.main, 0.8),
            }}
          >
            v{process.env.REACT_APP_VERSION || "0"}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AuthWindow;
