import { RefObject, memo, useEffect, useMemo, useRef, useState } from "react";

import {
  alpha,
  Box,
  Button,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ImageHandlerV2, { Image, Thumbnail } from "database/ImageHandlerV2";

import _ from "lodash";
import LDSRingLoader from "./Loaders/LDSRingLoader";
import moment from "moment";

import { useDrag, useGesture, usePinch, useWheel } from "@use-gesture/react";

import ImageWithFallBack from "./ImageWithFallBack/ImageWithFallBack";

type TransformValue = {
  scale: number;
  posX: number;
  posY: number;
  width: number;
  height: number;
};

type OverlayPosition = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

const OVERLAY_BOUND_X = 50;
const OVERLAY_BORDER = 2;
export const MAX_ZOOM = 50;
export const MIN_ZOOM = 1;

const DEFAULT_TRANSFORM_VALUE: TransformValue = {
  scale: 1,
  posX: 0,
  posY: 0,
  width: 0,
  height: 0,
};

const DEFAULT_OVERLAY_POSITION: OverlayPosition = {
  left: 100 + OVERLAY_BOUND_X,
  right: 500,
  top: 0,
  bottom: 500,
};

const DEFAULT_MOBILE_OVERLAY_POSITION: OverlayPosition = {
  left: 0,
  right: 150,
  top: 0,
  bottom: 250,
};

export let currentHighres;

let _unmodifiedZoom: number = 1;
let _transformValue: TransformValue = { ...DEFAULT_TRANSFORM_VALUE };
let _panPreviousMouseLocation: number[] = [0, 0];
let _zoomPreviousMouseLocation: number[] = [0, 0];

let _overlayScaleDirection: number = 0;
let _overlayPosition: OverlayPosition = { ...DEFAULT_OVERLAY_POSITION };

let _prevId: string = "";
let _prevOverlayId: string = "";

let _currentId: string = "";
let _currentOverlayId: string = "";
let _smallScreenAndUp: boolean = true;

const OverlayHandles = memo(
  ({
    isOverlayLoading,
    isMovingOverlay,
    isScalingOverlay,
    isOverlayEnabled,
    isPanning,
    setIsMovingOverlay,
    setIsScalingOverlay,
    overlaySrc,
    overlayRef,
    overlayContainerRef,
    overlayThumbnail,
    getOverlayTransform,
    resizeOverlay,
    moveOverlay,
    zoomImage,
  }: {
    isOverlayLoading: boolean;
    isMovingOverlay: boolean;
    isScalingOverlay: boolean;
    isOverlayEnabled: boolean;
    isPanning: boolean;
    setIsMovingOverlay: (isMovingOverlay: boolean) => void;
    setIsScalingOverlay: (isScalingOverlay: boolean) => void;
    overlaySrc: string;
    overlayRef: RefObject<HTMLImageElement>;
    overlayContainerRef: RefObject<HTMLDivElement>;
    overlayThumbnail: Thumbnail<Image> | undefined;
    getOverlayTransform: () => string;
    resizeOverlay: (event) => void;
    moveOverlay: (event) => void;
    zoomImage: (
      deltaY: number,
      clientX: number,
      clientY: number,
      zoomTo?: number,
    ) => void;
  }) => {
    const bindMoveOverlay = useDrag(
      ({ event, first, last, xy: [x, y], tap, down }) => {
        event.preventDefault();

        if (tap) {
          return;
        }

        if (first && down) {
          setIsMovingOverlay(true);

          _panPreviousMouseLocation = [x, y];

          return;
        } else if (last && !down) {
          setIsMovingOverlay(false);
          return;
        }

        moveOverlay(event);
      },
      {
        preventDefault: true,
        filterTaps: true,
      },
    );

    usePinch(
      ({ event, direction, origin }) => {
        event.preventDefault();

        if (isScalingOverlay) {
          setIsScalingOverlay(false);
        }

        if (isMovingOverlay) {
          setIsMovingOverlay(false);
        }

        zoomImage(0 - direction[0], origin[0], origin[1]);
      },
      {
        target: overlayContainerRef,
        eventOptions: {
          passive: false,
        },
        pointer: {
          touch: true,
        },
      },
    );

    const smallScreenAndUp = useMediaQuery(
      (theme: Theme) => theme.breakpoints.up("sm"),
      { noSsr: true },
    );

    const mediumScreenAndUp = useMediaQuery(
      (theme: Theme) => theme.breakpoints.up("md"),
      { noSsr: true },
    );

    const [isOverlayLoaded, setIsOverlayLoaded] = useState(false);

    useEffect(() => {
      setIsOverlayLoaded(false);
    }, [overlayThumbnail]);

    return (
      <Box
        id={"overlay-image-container"}
        ref={overlayContainerRef}
        sx={{
          position: "absolute",
          overflow: "hidden",
          border: ({ palette }) =>
            `${OVERLAY_BORDER}px solid ${
              isOverlayLoading || !overlaySrc || !isOverlayLoaded
                ? palette.error.main
                : palette.primary.main
            }`,
          width: _overlayPosition.right - _overlayPosition.left,
          height: _overlayPosition.bottom - _overlayPosition.top,
          cursor: isMovingOverlay ? "grabbing" : "grab",
          touchAction: "none",
          visibility: isOverlayEnabled ? "visible" : "hidden",
          ..._overlayPosition,
        }}
        {...bindMoveOverlay()}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            visibility:
              isOverlayEnabled && (isOverlayLoading || !isOverlayLoaded)
                ? "visible"
                : "hidden",
          }}
        >
          <LDSRingLoader color="#ff949c" />
        </Box>

        {overlaySrc && overlayThumbnail?.thumbnail ? (
          <ImageWithFallBack
            id={"overlay-image"}
            alt={"overlay-image"}
            ref={overlayRef}
            crossOrigin="anonymous"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: `calc(100vw - ${smallScreenAndUp ? "16px" : "0px"})`,
              height: `calc(100svh - ${mediumScreenAndUp ? "100px" : "150px"})`,
              objectFit: "contain",
              transform: getOverlayTransform(),
              // backgroundImage: `url(${overlayThumbnail.thumbnail})`,
              // backgroundSize: "contain",
              // backgroundRepeat: "no-repeat",
              // backgroundPosition: "center",
              transition:
                isMovingOverlay || isPanning
                  ? ""
                  : "opacity 0.1s ease, transform 0.1s ease",

              opacity: 0,
            }}
            src={overlaySrc}
            fallbackSrc={overlayThumbnail.thumbnail}
            onLoad={(e) => {
              e.currentTarget.style.backgroundImage = "";

              e.currentTarget.style.opacity = "1";

              setIsOverlayLoaded(true);
            }}
            onError={(e) => {
              setIsOverlayLoaded(true);
            }}
          />
        ) : (
          <>
            {!isOverlayLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: ({ palette }) => palette.secondary.main,
                }}
              >
                <Typography variant="caption">No image found.</Typography>
              </Box>
            )}
          </>
        )}

        {[0, 1, 2, 3, 4, 5, 6, 7].map((direction) => {
          return (
            <HandleButton
              direction={direction}
              key={direction}
              isScalingOverlay={isScalingOverlay}
              setIsMovingOverlay={setIsMovingOverlay}
              setIsScalingOverlay={setIsScalingOverlay}
              resizeOverlay={resizeOverlay}
            />
          );
        })}
      </Box>
    );
  },
);

const HandleButton = memo(
  ({
    direction,
    isScalingOverlay,
    setIsMovingOverlay,
    setIsScalingOverlay,
    resizeOverlay,
  }: {
    direction: number;
    isScalingOverlay: boolean;
    setIsMovingOverlay: (isMovingOverlay: boolean) => void;
    setIsScalingOverlay: (isScalingOverlay: boolean) => void;
    resizeOverlay: (event) => void;
  }) => {
    const positions: any = {};
    const isSelected = isScalingOverlay && direction === _overlayScaleDirection;

    switch (direction) {
      case 0:
        // top left
        positions.top = "0";
        positions.left = "0";

        break;
      case 1:
        // top center
        positions.left = "50%";
        positions.transform = "translateX(-50%)";

        break;
      case 2:
        // top right
        positions.top = "0";
        positions.right = "0";

        break;
      case 3:
        // right center
        positions.top = "50%";
        positions.right = "0%";
        positions.transform = "translateY(-50%)";

        break;
      case 4:
        // bottom right
        positions.bottom = "0";
        positions.right = "0";

        break;
      case 5:
        // bottom center
        positions.bottom = "0";
        positions.left = "50%";
        positions.transform = "translateX(-50%)";

        break;
      case 6:
        // bottom left
        positions.bottom = "0";
        positions.left = "0";

        break;
      case 7:
        //  left center
        positions.top = "50%";
        positions.left = "0";
        positions.transform = "translateY(-50%)";
        break;
    }

    const bindResizeOverlay = useDrag(
      ({ event, first, last, xy: [x, y], tap, down }) => {
        event.preventDefault();

        if (tap) {
          return;
        }

        if (first && down) {
          setIsMovingOverlay(false);
          setIsScalingOverlay(true);

          _panPreviousMouseLocation = [x, y];
          _overlayScaleDirection = direction;

          return;
        } else if (last && !down) {
          setIsScalingOverlay(false);

          return;
        }

        resizeOverlay(event);
      },
      {
        preventDefault: true,
        filterTaps: true,
      },
    );

    return (
      <Button
        variant="outlined"
        sx={{
          padding: 0,
          minWidth: 0,
          position: "absolute",
          width: 12,
          height: 12,
          zIndex: 2,
          borderRadius: 0,
          ...positions,
          touchAction: "none",

          backgroundColor: ({ palette }) => "white !important",
          border: ({ palette }) =>
            isSelected
              ? `1px solid ${palette.warning.main} !important`
              : `1px solid ${alpha("#000", 0.5)} !important`,
        }}
        {...bindResizeOverlay()}
      />
    );
  },
);

const HighResImageV2 = ({
  imageHandler,
  thumbnail,
  isOverlayEnabled,
  overlayThumbnail,
  zoom,
  onZoomEnd,
  onLoaded,
  isOverlayLoading,
  zoomTrigger,
}: {
  imageHandler: ImageHandlerV2;
  thumbnail: Thumbnail<Image> | undefined;
  isOverlayEnabled: boolean;
  overlayThumbnail: Thumbnail<Image> | undefined;
  zoom: number;
  onZoomEnd?: (zoom: number) => void;
  onLoaded?: () => void;
  isOverlayLoading: boolean;
  zoomTrigger: boolean;
}) => {
  const [imageSrc, setImageSrc] = useState<string>("");
  const [overlaySrc, setOverlaySrc] = useState<string>("");

  const [isPanning, setIsPanning] = useState<boolean>(false);
  const [isMovingOverlay, setIsMovingOverlay] = useState<boolean>(false);
  const [isScalingOverlay, setIsScalingOverlay] = useState<boolean>(false);

  const imageContainerRef = useRef<HTMLDivElement>(null);
  const hiddenImageRef = useRef<HTMLImageElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const overlayContainerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (zoom !== _unmodifiedZoom) {
      zoomImageByScale(zoom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomTrigger]);

  useEffect(() => {
    if (thumbnail) {
      const id = imageHandler.getThumbnailImageId(thumbnail).toString();

      if (_prevId !== id) {
        _currentId = id;

        // hacky hot-fix of dropbox image flickering bug :/
        _prevId = id;

        if (thumbnail.thumbnail) {
          setImageSrc(thumbnail.thumbnail);
        }

        getDisplayImageSrc(thumbnail).then((result) => {
          // also a hacky fix to full res flickering bug, to "abort" the setOverlaySrc if the current thumbnail has changed
          if (id === _currentId) {
            setImageSrc(result);
          }
        });
      } else {
        setImageSrc(thumbnail?.fulRes);
      }
    } else {
      setImageSrc("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnail]);

  useEffect(() => {
    if (overlayThumbnail && !isOverlayLoading) {
      const overlayId = imageHandler
        .getThumbnailImageId(overlayThumbnail)
        .toString();

      if (_prevOverlayId !== overlayId) {
        _currentOverlayId = overlayId;

        // hacky hot-fix of dropbox image flickering bug :/
        _prevOverlayId = overlayId;

        if (overlayThumbnail.thumbnail) {
          setOverlaySrc(overlayThumbnail.thumbnail);
        }

        getDisplayImageSrc(overlayThumbnail).then((result) => {
          // also a hacky fix to full res flickering bug, to "abort" the setOverlaySrc if the current thumbnail has changed
          if (overlayId === _currentOverlayId) {
            setOverlaySrc(result);
          }
        });
      } else {
        setOverlaySrc(overlayThumbnail?.fulRes);
      }
    } else {
      setOverlaySrc("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlayThumbnail, isOverlayLoading]);

  const smallScreenAndUp = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("sm"),
    { noSsr: true },
  );

  const mediumScreenAndUp = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("md"),
    { noSsr: true },
  );

  // useEffect(() => {
  //   updateDataScale();

  //   const handleResize = () => {
  //     updateDataScale();
  //     updateOverlay();
  //   };
  //   // not sure about this
  //   window.addEventListener("resize", handleResize);
  // }, []);

  const getDisplayImageSrc = async (
    thumbnail: Thumbnail<Image>,
  ): Promise<string> => {
    return await imageHandler.findFullRes(thumbnail as Thumbnail<Image>);
  };

  const panImage = (clientX, clientY): void => {
    if (isPanning && imageRef.current && hiddenImageRef.current) {
      let newTransformValue = { ..._transformValue };

      newTransformValue.posX += clientX - _panPreviousMouseLocation[0];
      newTransformValue.posY += clientY - _panPreviousMouseLocation[1];

      _panPreviousMouseLocation = [clientX, clientY];

      newTransformValue = confirmImageBounds(newTransformValue);

      hiddenImageRef.current.style.transform = `translate(${newTransformValue.posX}px, ${newTransformValue.posY}px) scale(${newTransformValue.scale})`;

      _transformValue = newTransformValue;

      if (isOverlayEnabled) {
        updateOverlay();
      }
      imageRef.current.style.transform = `translate(${newTransformValue.posX}px, ${newTransformValue.posY}px) scale(${newTransformValue.scale})`;
    }
  };

  const zoomImageByScale = (zoomTo: number) => {
    if (imageContainerRef.current) {
      const containerRect = imageContainerRef.current.getBoundingClientRect();

      const clientX =
        imageContainerRef.current.offsetLeft + containerRect.width / 2;
      const clientY =
        imageContainerRef.current.offsetTop + containerRect.height / 2;

      const deltaY = _unmodifiedZoom - zoomTo;

      zoomImage(deltaY, clientX, clientY, zoomTo);
    }
  };

  /**
   * @param deltaY - The amount of the wheel was scrolled vertically, positive means scrolled down, so zoom out.
   * @param clientX - The horizontal position of the mouse pointer relative to the container.
   * @param clienY -The vertical position of the mouse pointer relative to the container.
   */
  const zoomImage = (
    deltaY: number,
    clientX: number,
    clientY: number,
    zoomTo?: number,
  ): void => {
    if (
      imageContainerRef.current &&
      imageRef.current &&
      hiddenImageRef.current
    ) {
      const containerRect = imageContainerRef.current.getBoundingClientRect();
      const imageRect = hiddenImageRef.current.getBoundingClientRect();

      let newTransformValue = _.cloneDeep(_transformValue);

      const zoom = deltaY > 0 ? -1 : 1;

      _unmodifiedZoom = zoomTo || _unmodifiedZoom + zoom;

      if (_unmodifiedZoom < MIN_ZOOM) {
        _unmodifiedZoom = MIN_ZOOM;
      } else if (_unmodifiedZoom > MAX_ZOOM) {
        _unmodifiedZoom = MAX_ZOOM;
      }

      if (deltaY > 0 && _unmodifiedZoom > MIN_ZOOM) {
        // zoom out from previous location if mouse location changed, to prevent image position shift.

        [clientX, clientY] = _zoomPreviousMouseLocation;
      }

      let scaleDirection = zoom;

      const exponentialScale = exponentialCurve2(_unmodifiedZoom);
      newTransformValue.scale = exponentialScale;

      if (newTransformValue.scale < MIN_ZOOM) {
        newTransformValue.scale = MIN_ZOOM;
        scaleDirection = 0;
      } else if (newTransformValue.scale > MAX_ZOOM) {
        newTransformValue.scale = MAX_ZOOM;
        scaleDirection = 0;
      }

      if (scaleDirection !== 0) {
        const scaleDelta = Math.abs(exponentialScale - _transformValue.scale);

        // position of curser offset in relation to image based on container center
        let position = [
          newTransformValue.posX +
            -1 * (clientX - (containerRect.left + containerRect.right) / 2),
          newTransformValue.posY +
            -1 * (clientY - (containerRect.top + containerRect.bottom) / 2),
        ];

        // gets the new image position based on the new zoom
        position = [
          (scaleDelta * position[0]) /
            (scaleDirection * (imageRect.width / containerRect.width)) +
            newTransformValue.posX,
          (scaleDelta * position[1]) /
            (scaleDirection * (imageRect.height / containerRect.height)) +
            newTransformValue.posY,
        ];

        newTransformValue.posX = position[0];
        newTransformValue.posY = position[1];

        // hiddenImageRef is without transition animation, so we can get the latest getBoundingClientRect info after update.
        hiddenImageRef.current.style.transform = `translate(${newTransformValue.posX}px, ${newTransformValue.posY}px) scale(${newTransformValue.scale})`;

        newTransformValue = confirmImageBounds(newTransformValue);

        hiddenImageRef.current.style.transform = `translate(${newTransformValue.posX}px, ${newTransformValue.posY}px) scale(${newTransformValue.scale})`;

        newTransformValue = updateDataScale(newTransformValue);

        hiddenImageRef.current.style.transform = `translate(${newTransformValue.posX}px, ${newTransformValue.posY}px) scale(${newTransformValue.scale})`;
        imageRef.current.style.transform = `translate(${newTransformValue.posX}px, ${newTransformValue.posY}px) scale(${newTransformValue.scale})`;

        _transformValue = newTransformValue;
        _zoomPreviousMouseLocation = [clientX, clientY];

        updateOverlay();

        if (onZoomEnd) {
          onZoomEnd(_unmodifiedZoom);
        }
      }
    }
  };

  const confirmImageBounds = (
    transformValue: TransformValue,
  ): TransformValue => {
    if (imageContainerRef.current && hiddenImageRef.current) {
      const containerRect = imageContainerRef.current.getBoundingClientRect();
      const imageRect = hiddenImageRef.current.getBoundingClientRect();

      // left bound
      if (transformValue.posX > imageRect.width / 2 - containerRect.width / 2) {
        transformValue.posX = imageRect.width / 2 - containerRect.width / 2;
      } else if (
        // right bound

        transformValue.posX <
        -(imageRect.width / 2) + containerRect.width / 2
      ) {
        transformValue.posX = -(imageRect.width / 2) + containerRect.width / 2;
      }

      // top bound
      if (
        transformValue.posY >
        imageRect.height / 2 - containerRect.height / 2
      ) {
        transformValue.posY = imageRect.height / 2 - containerRect.height / 2;
      } else if (
        // bottom bound

        transformValue.posY <
        -(imageRect.height / 2) + containerRect.height / 2
      ) {
        transformValue.posY =
          -(imageRect.height / 2) + containerRect.height / 2;
      }
    }

    return transformValue;
  };

  const updateDataScale = (newTransformValue?: TransformValue) => {
    const transformValue = newTransformValue || _.cloneDeep(_transformValue);

    if (hiddenImageRef.current) {
      const imageRect = hiddenImageRef.current.getBoundingClientRect();

      transformValue.width = imageRect.width;
      transformValue.height = imageRect.height;
    }

    _transformValue = transformValue;

    return transformValue;
  };

  // calculate only once
  const exponentialCurveValues = useMemo(() => {
    const initialValue = MIN_ZOOM; // Initial value, the lowest scale
    const finalValue = 10; // Final value, the highest scale
    const numSteps = MAX_ZOOM; // Number of steps it takes to the final value from the initial
    const power = 3; // Stepness, the greater number the greater the easing-in effect
    const offSet = 0.01; // For noticeable difference on the first few values when the power is too high

    const results: number[] = [];

    let previousValue = initialValue;

    for (let i = 1; i <= numSteps; i++) {
      const t = i / numSteps;

      let value =
        initialValue + (finalValue - initialValue) * Math.pow(t, power);
      value = Number(value.toFixed(3));

      // offset with previous number
      if (i !== 1 && offSet > 0 && value - previousValue < offSet) {
        value = Number((previousValue + offSet).toFixed(3));
      }

      results.push(value);

      previousValue = value;
    }

    return results;
  }, []);

  const exponentialCurve2 = (zoom: number): number => {
    const results = exponentialCurveValues;

    return results[zoom - 1];
  };

  const exponentialCurve = (zoom: number): number => {
    if (zoom === 1 || zoom === 20) {
      return zoom;
    }

    const a = 1.3;
    const b = 1.7;
    const c = -5.8;
    const d = 2.5;
    const e = 0.4;

    return Math.pow(a, (b * zoom + c) / d) + e;
  };

  const resetZoom = (): void => {
    setIsPanning(false);
    setIsMovingOverlay(false);
    setIsScalingOverlay(false);

    if (imageRef.current && hiddenImageRef.current) {
      _transformValue = { ...DEFAULT_TRANSFORM_VALUE };

      hiddenImageRef.current.style.transform = `translate(${_transformValue.posX}px, ${_transformValue.posY}px) scale(${_transformValue.scale})`;
      imageRef.current.style.transform = `translate(${_transformValue.posX}px, ${_transformValue.posY}px) scale(${_transformValue.scale})`;

      _unmodifiedZoom = 1;

      updateOverlay();

      if (onZoomEnd) {
        onZoomEnd(1);
      }
    }
  };

  const mouseUp = (): void => {
    // if (isPanning) {
    //   setIsPanning(false);
    // }
    // if (isMovingOverlay) {
    //   _isMovingOverlay = false;
    // }
    // if (isScalingOverlay) {
    //   _isScalingOverlay = false;
    // }
  };

  const mouseMove = (e): void => {
    e.preventDefault();

    // if (isPanning) {
    //   panImage(e.clientX, e.clientY);
    // }

    // if (isMovingOverlay) {
    //   moveOverlay(e);
    // }

    // if (isScalingOverlay) {
    //   resizeOverlay(e);
    // }
  };

  const updateOverlay = (): void => {
    if (overlayRef.current && overlayContainerRef.current) {
      overlayRef.current.style.transform = getOverlayTransform();
    }
  };

  const getOverlayTransform = (): string => {
    // const offset = getOverlayOffset();

    return `translate(${
      _transformValue.posX - _overlayPosition.left - OVERLAY_BORDER
    }px, ${
      _transformValue.posY - _overlayPosition.top - OVERLAY_BORDER
    }px) scale(${_transformValue.scale})`;
  };

  // const getOverlayOffset = (): number[] => {
  //   let offset = [0, 0];

  //   if (imageContainerRef.current && hiddenImageRef.current) {
  //     const containerRect = imageContainerRef.current.getBoundingClientRect();
  //     const imageRect = hiddenImageRef.current.getBoundingClientRect();

  //     offset = [
  //       imageRect.x - containerRect.x - _overlayPosition.left - 2,
  //       imageRect.y - containerRect.y - _overlayPosition.top - 2,
  //     ];
  //   }

  //   return offset;
  // };

  const checkOverlayBounds = (
    overlayPosition: OverlayPosition,
    direction: number,
  ) => {
    if (!imageContainerRef.current) {
      return overlayPosition;
    }

    const containerRect = imageContainerRef.current.getBoundingClientRect();

    const boundX = mediumScreenAndUp ? OVERLAY_BOUND_X : 0;

    // check left-right
    if ([0, 6, 7].includes(direction)) {
      if (overlayPosition.left > overlayPosition.right - 100) {
        overlayPosition.left = overlayPosition.right - 100;
      } else if (overlayPosition.left < 0 + boundX) {
        overlayPosition.left = 0 + boundX;
      }
    } else if ([2, 3, 4].includes(direction)) {
      if (overlayPosition.right < overlayPosition.left + 100) {
        overlayPosition.right = overlayPosition.left + 100;
      } else if (overlayPosition.right > containerRect.width - boundX) {
        overlayPosition.right = containerRect.width - boundX;
      }
    }

    // check top-bottom
    if ([0, 1, 2].includes(direction)) {
      if (overlayPosition.top > overlayPosition.bottom - 100) {
        overlayPosition.top = overlayPosition.bottom - 100;
      } else if (overlayPosition.top < 0) {
        overlayPosition.top = 0;
      }
    } else if ([4, 5, 6].includes(direction)) {
      if (overlayPosition.bottom < overlayPosition.top + 100) {
        overlayPosition.bottom = overlayPosition.top + 100;
      } else if (overlayPosition.bottom > containerRect.height) {
        overlayPosition.bottom = containerRect.height;
      }
    }
  };

  const checkOverlayPanBounds = (
    overlayPosition: OverlayPosition,
  ): OverlayPosition => {
    if (!imageContainerRef.current) {
      return overlayPosition;
    }

    const containerRect = imageContainerRef.current.getBoundingClientRect();
    const boundX = mediumScreenAndUp ? OVERLAY_BOUND_X : 0;

    if (overlayPosition.left < 0 + boundX) {
      const offset = 0 - overlayPosition.left + boundX;

      overlayPosition.left += offset;
      overlayPosition.right += offset;
    } else if (overlayPosition.right > containerRect.width - boundX) {
      const offset = containerRect.width - overlayPosition.right - boundX;

      overlayPosition.left += offset;
      overlayPosition.right += offset;
    }

    if (overlayPosition.top < 0) {
      const offset = 0 - overlayPosition.top;

      overlayPosition.top += offset;
      overlayPosition.bottom += offset;
    } else if (overlayPosition.bottom > containerRect.height) {
      const offset = containerRect.height - overlayPosition.bottom;

      overlayPosition.top += offset;
      overlayPosition.bottom += offset;
    }

    _overlayPosition = overlayPosition;

    return overlayPosition;
  };

  const moveOverlay = (e) => {
    if (isMovingOverlay && overlayContainerRef.current && overlayRef.current) {
      e.preventDefault();

      let newOverlayPosition = _.cloneDeep(_overlayPosition);

      const x = e.movementX;
      const y = e.movementY;

      newOverlayPosition.left += x;
      newOverlayPosition.right += x;
      newOverlayPosition.top += y;
      newOverlayPosition.bottom += y;

      _overlayPosition = newOverlayPosition;

      newOverlayPosition = checkOverlayPanBounds(newOverlayPosition);

      overlayContainerRef.current.style.inset = `${_overlayPosition.top}px ${_overlayPosition.right}px ${_overlayPosition.bottom}px ${_overlayPosition.left}px`;

      overlayRef.current.style.transform = getOverlayTransform();
    }
  };

  const resizeOverlay = (e) => {
    if (overlayContainerRef.current && overlayRef.current) {
      e.preventDefault();
      e.stopPropagation();

      const comparisonPosition = { ..._overlayPosition };

      const x = e.movementX;
      const y = e.movementY;

      switch (_overlayScaleDirection) {
        case 0:
          // top left
          comparisonPosition.left += x;
          comparisonPosition.top += y;
          break;
        case 1:
          // top center
          comparisonPosition.top += y;
          break;
        case 2:
          // top right
          comparisonPosition.top += y;
          comparisonPosition.right += x;
          break;
        case 3:
          // right center
          comparisonPosition.right += x;
          break;
        case 4:
          // bottom right
          comparisonPosition.right += x;
          comparisonPosition.bottom += y;
          break;
        case 5:
          // bottom center
          comparisonPosition.bottom += y;
          break;
        case 6:
          // bottom left
          comparisonPosition.bottom += y;
          comparisonPosition.left += x;
          break;
        case 7:
        default:
          // left center
          comparisonPosition.left += x;
          break;
      }

      checkOverlayBounds(comparisonPosition, _overlayScaleDirection);

      _overlayPosition = comparisonPosition;

      overlayContainerRef.current.style.inset = `${_overlayPosition.top}px ${_overlayPosition.right}px ${_overlayPosition.bottom}px ${_overlayPosition.left}px`;

      overlayContainerRef.current.style.width = `${
        _overlayPosition.right - _overlayPosition.left
      }px`;
      overlayContainerRef.current.style.height = `${
        _overlayPosition.bottom - _overlayPosition.top
      }px`;

      updateOverlay();
    }
  };

  useWheel(
    ({ event }) => {
      event.preventDefault();
      event.stopPropagation();

      zoomImage(event.deltaY, event.clientX, event.clientY);
    },
    {
      target: imageContainerRef,
      eventOptions: {
        passive: false,
      },
      pointer: {
        touch: false,
      },
    },
  );

  usePinch(
    (state) => {
      const {
        event,
        offset,
        movement,
        direction,
        distance,
        delta,
        origin,
        da,
      } = state;
      event.preventDefault();
      event.stopPropagation();

      if (direction[0] > 0) {
        // Do something when pinch in
      } else {
        // Do something when pinch out
      }

      if (isScalingOverlay) {
        setIsScalingOverlay(false);
      }

      if (isMovingOverlay) {
        setIsMovingOverlay(false);
      }

      zoomImage(0 - direction[0], origin[0], origin[1]);
    },
    {
      target: imageRef,
      eventOptions: {
        passive: false,
      },
      pointer: {
        touch: true,
      },
    },
  );

  useDrag(
    ({ event, first, last, xy: [x, y], tap, down }) => {
      if (tap) {
        return;
      }

      if (first && down) {
        _panPreviousMouseLocation = [x, y];

        setIsPanning(true);

        return;
      } else if (last && !down) {
        setIsPanning(false);

        return;
      }

      event.stopPropagation();
      // event.preventDefault();

      panImage(x, y);
    },
    {
      target: imageRef,
      filterTaps: true,
      // eventOptions: {
      //   passive: false,
      // },
      // pointer: {
      //   touch: true,
      // },
    },
  );

  const bindGestures = useGesture({
    onDoubleClick: resetZoom,
    onMouseMove: ({ event }) => {
      mouseMove(event);
    },
    onMouseUp: ({ event }) => mouseUp(),
    onMouseLeave: ({ event }) => mouseUp(),
  });

  useEffect(() => {
    if (_smallScreenAndUp === smallScreenAndUp) {
      return;
    }

    if (smallScreenAndUp) {
      _overlayPosition = { ...DEFAULT_OVERLAY_POSITION };
    } else {
      _overlayPosition = { ...DEFAULT_MOBILE_OVERLAY_POSITION };
    }

    if (overlayContainerRef.current) {
      overlayContainerRef.current.style.top = `${_overlayPosition.top}px`;
      overlayContainerRef.current.style.bottom = `${_overlayPosition.bottom}px`;
      overlayContainerRef.current.style.left = `${_overlayPosition.left}px`;
      overlayContainerRef.current.style.right = `${_overlayPosition.right}px`;
      overlayContainerRef.current.style.width = `${
        _overlayPosition.right - _overlayPosition.left
      }px`;
      overlayContainerRef.current.style.height = `${
        _overlayPosition.bottom - _overlayPosition.top
      }px`;
    }

    _smallScreenAndUp = smallScreenAndUp;
  }, [smallScreenAndUp]);

  return (
    <Box
      id={"main-image-container"}
      ref={imageContainerRef}
      sx={{
        margin: "auto",
        position: "relative",
        height: "100%",
        width: "100%",
        userSelect: "none",
        touchAction: "none",
        overflow: "visible",
      }}
      {...bindGestures()}
    >
      <Box
        ref={hiddenImageRef}
        style={{
          position: "absolute",
          transform: `translate(${_transformValue.posX}px, ${_transformValue.posY}px) scale(${_transformValue.scale})`,
          height: "100%",
          width: "100%",
          userSelect: "none",
          visibility: "hidden",
          zIndex: -100,
        }}
      />

      {imageSrc && thumbnail && (
        <ImageWithFallBack
          id={"main-image"}
          ref={imageRef}
          alt="main-image"
          crossOrigin="anonymous"
          src={imageSrc}
          fallbackSrc={thumbnail.thumbnail}
          // sx props not working after build for some reason :(
          style={{
            position: "absolute",
            transform: `translate(${_transformValue.posX}px, ${_transformValue.posY}px) scale(${_transformValue.scale})`,
            backgroundImage: `url(${thumbnail.thumbnail})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "100%",
            width: "100%",
            objectFit: "contain",
            transition:
              isMovingOverlay || isPanning ? "" : "transform 0.1s ease",
            userSelect: "none",
            touchAction: "none",
          }}
          onLoad={(e) => {
            onLoaded && onLoaded();

            e.currentTarget.style.backgroundImage = "";
          }}
          onError={(e) => {
            onLoaded && onLoaded();
          }}
          draggable="false"
        />
      )}

      <OverlayHandles
        isOverlayLoading={isOverlayLoading}
        isMovingOverlay={isMovingOverlay}
        isScalingOverlay={isScalingOverlay}
        isOverlayEnabled={isOverlayEnabled}
        isPanning={isPanning}
        setIsScalingOverlay={setIsScalingOverlay}
        setIsMovingOverlay={setIsMovingOverlay}
        overlaySrc={overlaySrc}
        overlayRef={overlayRef}
        overlayContainerRef={overlayContainerRef}
        overlayThumbnail={overlayThumbnail}
        getOverlayTransform={getOverlayTransform}
        moveOverlay={moveOverlay}
        resizeOverlay={resizeOverlay}
        zoomImage={zoomImage}
      />
    </Box>
  );
};

export default memo(HighResImageV2);
