import { Box, Typography, TypographyProps, useTheme } from "@mui/material";
import IntervalBase from "@/assets/BEERAssets/ReliveIt/IntervalBase.png";
import _ from "lodash";
import { useMemo } from "react";

const IntervalIcon = ({
  amount,
  unit,
  color,
}: {
  amount: number;
  unit: "Mins" | "Secs";
  color?: TypographyProps["color"];
}) => {
  const height = 50;
  const width = 50;

  const theme = useTheme();

  const selectedColor = useMemo(
    () =>
      _.isFunction(color)
        ? color(theme)
        : color || theme.palette.onSurface.main,
    [color, theme],
  );

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box>
        <Typography
          sx={{
            display: "inline",
            fontWeight: "bold",
            fontSize: 16,
            textWrap: "nowrap",
            color: selectedColor,
          }}
        >
          {amount || "-"}
        </Typography>

        {amount && (
          <Typography
            sx={{
              display: "inline",
              fontWeight: "bold",
              fontSize: 12,
              textWrap: "nowrap",
              color: selectedColor,
            }}
          >
            {` ${unit}`}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-35%, -25%)",
          overflow: "hidden",
        }}
      >
        <Box
          component={"img"}
          src={IntervalBase}
          sx={{
            width,
            height,
            objectFit: "contain",
            // The trick here is to move the original icon outside the container and cast the shadow where the icon was
            transform: "translateY(-100px)",
            filter: `drop-shadow(0 100px 0 ${selectedColor})`,
          }}
        />
      </Box>
    </Box>
  );
};

export default IntervalIcon;
