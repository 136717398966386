import { fetchWithRetry } from "utils/fetcher";
import { getFirebaseController } from "./FirebaseController";
import { DropBoxImage, UnixEpoch } from "./DataTypes";
import moment from "moment";
import ImageHandlerV2, { Image } from "./ImageHandlerV2";
import _ from "lodash";
import pLimit from "p-limit";

export const getDropboxController = () => {
  return new DropboxController();
};

type DropboxTokenType = {
  access_token: string;
  expires_in: number;
  token_type: string;
};

type ListFolderResponse = ListFolderData | DropboxApiError;

type GetThumbnailBatchResultData = {
  ".tag": string;
  metadata: DropboxFile;
  thumbnail: string;
};

type GetThumbnailBatchResponse =
  | {
      entries: GetThumbnailBatchResultData[];
    }
  | DropboxApiError;

type ListFolderData = {
  entries: (DropboxFolder | DropboxFile)[];
  cursor: string;
  has_more: boolean;
};

type DropboxApiError = {
  error: any;
  error_summary: string;
};

type DropboxFolder = {
  ".tag": string;
  name: string;
  path_lower: string;
  path_display: string;
  id: string;
};

type DropboxFile = DropBoxImage;

type GetImageHighResData = {
  url: string;
  file?: HTMLElement;
  blob?: Blob;
};

type GetDirectoryContentsOptions = {
  recursive?: boolean;
  retriveAll?: boolean;
  cache?: boolean;
  reload?: boolean;
  limit?: number;
};

type GetFilesOptions = {
  order?: "asc" | "desc";
  hiddenImageIds?: (string | number)[];
} & GetDirectoryContentsOptions;

type GetGalleryImageOptions = {
  isDevice?: boolean;
  objectId?: string | number;
  includeHidden?: boolean;
} & GetFilesOptions;

const limit = pLimit(50);

export class DropboxController {
  private static _instance: DropboxController | null;

  token!: DropboxTokenType | null;
  isGettingToken!: boolean;
  getTokenPromise!: any;
  refreshToken!: string;
  directoryCaches!: { [directory: string]: DropboxFolder[] };
  monthFolderIndexMapper!: {
    [directory: string]: { [folderName: string]: number };
  };

  thumbnailCahces!: { [directory: string]: string };
  imageHighResDataCaches!: { [directory: string]: GetImageHighResData };
  fileCaches!: { [fileId: string]: DropboxFile };

  constructor() {
    if (DropboxController._instance) {
      return DropboxController._instance;
    } else {
      DropboxController._instance = this;
    }

    this.token = null;
    this.isGettingToken = false;
    this.getTokenPromise = null;
    this.directoryCaches = {};
    this.monthFolderIndexMapper = {};
    this.thumbnailCahces = {};
    this.imageHighResDataCaches = {};
    this.fileCaches = {};
  }

  async setupToken() {
    if (!this.token && !this.isGettingToken) {
      return this.generateFromRefreshToken();
    } else {
      return this.getTokenPromise;
    }
  }

  async generateFromRefreshToken() {
    if (this.isGettingToken) {
      return this.getTokenPromise;
    }

    if (!this.refreshToken) {
      this.refreshToken =
        await getFirebaseController().Image.initDropboxRefreshToken();
    }

    this.isGettingToken = true;
    this.getTokenPromise = new Promise((resolve, reject) => {
      // Don't know why, but they wanted it as form data. Tried as json but it didn't work.
      const formData = new FormData();

      formData.append("refresh_token", this.refreshToken);
      formData.append(
        "client_id",
        process.env.REACT_APP_DROPBOXV1KEY as string,
      );
      formData.append(
        "client_secret",
        process.env.REACT_APP_DROPBOXV1SECRET as string,
      );
      formData.append("grant_type", "refresh_token");

      fetch("https://api.dropboxapi.com/oauth2/token", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          this.token = data;
          this.isGettingToken = false;
          this.refreshTimer();

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return this.getTokenPromise;
  }

  async refreshTimer() {
    if (this.token) {
      if (!this.token.expires_in) {
        this.token.expires_in = 600;
      }

      setTimeout(
        () => {
          this.generateFromRefreshToken();
        },
        (this.token.expires_in - 60) * 1000,
      );
    }
  }

  async getDirectoryContents<T = ListFolderData["entries"]>(
    directory: string,
    {
      recursive = false,
      retriveAll = true,
      cache = true,
      reload = false,
      limit = 2000,
    }: GetDirectoryContentsOptions = {},
  ): Promise<T> {
    if (!directory) {
      throw new Error("Invalid directory.");
    }

    if (!reload && this.directoryCaches[directory]) {
      return this.directoryCaches[directory] as T;
    }

    await this.checkToken();

    return await fetchWithRetry(
      `https://api.dropboxapi.com/2/files/list_folder`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token!.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          path: directory,
          recursive: false,
          include_deleted: false,
          include_non_downloadable_files: false,
          limit,
        }),
      },
    )
      .then((res) => res.json())
      .then(async (data: ListFolderResponse) => {
        if ("entries" in data) {
          const totalEntries = data.entries;

          if (retriveAll) {
            let isContinue = data.has_more;
            let currentCursor = data.cursor;

            while (isContinue) {
              const cursorData = await this.getDirectoryContentsWithCursor(
                currentCursor,
              );

              if (cursorData) {
                currentCursor = cursorData.cursor;
                isContinue = cursorData.has_more;

                totalEntries.push(...cursorData.entries);
              } else {
                isContinue = false;
              }
            }
          }

          if (cache) {
            this.directoryCaches[directory] = totalEntries;
          }

          return totalEntries;
        } else {
          return [];
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async getDirectoryContentsWithCursor(
    cursor: ListFolderData["cursor"],
  ): Promise<ListFolderData> {
    await this.checkToken();

    if (!cursor) {
      throw new Error("Invalid cursor.");
    }

    return await fetchWithRetry(
      `https://api.dropboxapi.com/2/files/list_folder/continue`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token!.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cursor }),
      },
    )
      .then((res) => res.json())
      .then((data) => {
        return data;
      });
  }

  async getFolders(directory: string): Promise<DropboxFolder[]> {
    return await this.getDirectoryContents<DropboxFolder[]>(directory).then(
      (folders) => {
        let parsedFolders = folders;

        if (parsedFolders.length > 0) {
          parsedFolders = folders.map((folder, folderIndex) => {
            // fixed all naming, eg: "2023_07_JUL" -> "2023_07_Jul"
            const key = folder.name.replace(/[a-zA-Z]+/g, _.capitalize);

            _.set(this.monthFolderIndexMapper, [directory, key], folderIndex);

            return folder;
          });

          parsedFolders = parsedFolders.sort((a, b) => {
            // sort folder by its name in asc order

            return a.name.localeCompare(b.name);
          });
        }

        return parsedFolders;
      },
    );
  }

  private sortImages(
    images: DropBoxImage[],
    order: GetFilesOptions["order"] = "desc",
  ) {
    const sortedImages = images.sort((a, b) => {
      const timeA = ImageHandlerV2.getImageTime(a, "1");
      const timeB = ImageHandlerV2.getImageTime(b, "1");

      return order === "desc"
        ? timeB.localeCompare(timeA)
        : timeA.localeCompare(timeB);
    });

    return sortedImages;
  }

  async getFiles(
    directory: string,
    options: GetFilesOptions = {},
  ): Promise<DropboxFile[]> {
    const {
      order = "desc",
      cache = true,
      ...getDirectoryContentsOptions
    } = options;

    return await this.getDirectoryContents<DropboxFile[]>(directory, {
      ...getDirectoryContentsOptions,
      cache: false,
    }).then((images) => {
      const sortedImages = this.sortImages(images, order);

      if (cache) {
        this.directoryCaches[directory] = sortedImages;
      }

      return sortedImages;
    });
  }

  async getFileByIds(ids: (number | string)[]): Promise<DropboxFile[]> {
    if (ids.length === 0) {
      return [];
    }

    const files: DropboxFile[] = [];

    const payload: any[] = [];

    ids.forEach((id) => {
      if (this.fileCaches[id]) {
        files.push(this.fileCaches[id]);
      } else {
        payload.push({
          path: id,
        });
      }
    });

    if (payload.length === 0) {
      const sortedImages = this.sortImages(files, "desc");

      return sortedImages;
    }

    // Not sure about this, might change it to use getImageThumbnails
    return await fetchWithRetry(
      `https://api.dropboxapi.com/2/files/get_file_lock_batch`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token!.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entries: payload,
        }),
      },
    )
      .then((res) => res.json())
      .then((data) => {
        if ("entries" in data) {
          data.entries.forEach((entry) => {
            const file = entry.metadata;

            this.fileCaches[file.id] = file;

            files.push(file);
          });

          const sortedImages = this.sortImages(files, "desc");

          return sortedImages;
        } else {
          return [];
        }
      });
  }

  async getImages(
    baseDirectory: string,
    startDate: UnixEpoch,
    endDate: UnixEpoch,
    filterImageIds: (number | string)[] | null,
    hiddenImageIds?: (number | string)[] | null,
  ): Promise<DropBoxImage[]> {
    if (filterImageIds) {
      return await this.getFileByIds(filterImageIds).then((images) => {
        return images.filter((image) => {
          return (
            this.isDateMatched(startDate, endDate, image) &&
            !hiddenImageIds?.includes(image.id)
          );
        });
      });
    }

    const directory = baseDirectory;

    const startFolderName = this.getMonthFolderName(startDate);
    const endFolderName = this.getMonthFolderName(endDate);

    return await this.getImagesByMonth(
      directory,
      startFolderName,
      endFolderName,
    ).then((images) => {
      return images.filter((image) => {
        return (
          this.isDateMatched(startDate, endDate, image) &&
          !hiddenImageIds?.includes(image.id)
        );
      });
    });
  }

  async getImagesByMonth(
    baseDirectory: string,
    startFolderName: string,
    endFolderName: string,
  ): Promise<DropBoxImage[]> {
    const directory = baseDirectory;

    let folderHash = this.monthFolderIndexMapper[directory];

    if (!folderHash) {
      await this.getFolders(directory);

      folderHash = this.monthFolderIndexMapper[directory];
    }

    const folders = Object.keys(folderHash);

    let startIndex = folderHash[startFolderName];
    let endIndex = folderHash[endFolderName];

    if (_.isUndefined(startIndex) && _.isUndefined(endIndex)) {
      console.error("Out of range.");

      return [];
    }

    startIndex = startIndex || 0;
    endIndex = endIndex || folders.length - 1;

    // select all the months folder to fetch, and reverse it so that latest come first
    const selectedFolders = folders.slice(startIndex, endIndex + 1).reverse();

    const promises: Promise<DropBoxImage[]>[] = [];

    selectedFolders.forEach((folder) => {
      promises.push(this.getFiles(`${directory}/${folder}`));
    });

    return await Promise.all(promises)
      .then((images) => {
        const allImages = images.flat();

        return allImages;
      })
      .catch((error) => {
        console.error(error);

        return [];
      });
  }

  async searchFolderRecursive(
    folders: DropboxFolder[],
    index: number,
    addIndex: number,
    options: GetFilesOptions = {},
  ): Promise<DropBoxImage[]> {
    // in case some folder is empty, search next folder.

    const { hiddenImageIds } = options;

    if (!folders[index]) {
      return [];
    }

    return await this.getFiles(folders[index].path_display, options)
      .then(async (images) => {
        let filteredImages = images;

        if (hiddenImageIds) {
          filteredImages = filteredImages.filter((image) => {
            return !hiddenImageIds.includes(image.id);
          });
        }

        if (filteredImages.length > 0) {
          return filteredImages;
        } else {
          // keep searching if this folder has no image
          return await this.searchFolderRecursive(
            folders,
            index + addIndex,
            addIndex,
            options,
          );
        }
      })
      .catch(async () => {
        return await this.searchFolderRecursive(
          folders,
          index + addIndex,
          addIndex,
          options,
        );
      });
  }

  async getFirstAndLastImage(
    directory: string,
    options: GetGalleryImageOptions = {},
  ): Promise<DropBoxImage[]> {
    // TODO: might able to reuse the getLastImage function

    const {
      objectId,
      isDevice = false,
      includeHidden = false,
      ...getFileOptions
    } = options;

    if (!includeHidden && objectId) {
      getFileOptions.hiddenImageIds =
        await getFirebaseController().Image.getHiddenImageIds(
          objectId,
          isDevice,
        );
    }

    return await this.getFolders(directory)
      .then(async (folders) => {
        if (folders.length > 0) {
          const promises: Promise<DropBoxImage[]>[] = [
            this.searchFolderRecursive(folders, 0, 1, {
              ...getFileOptions,
              retriveAll: false,
              cache: false,
              limit: 1,
            }),
          ];

          promises.push(
            this.searchFolderRecursive(
              folders,
              folders.length - 1,
              -1,
              getFileOptions,
            ),
          );

          return await Promise.all(promises).then(
            ([firstFolderImages, lastFolderImages]) => {
              const firstImage =
                firstFolderImages[firstFolderImages.length - 1];
              const lastImage = lastFolderImages[0];

              return [firstImage, lastImage];
            },
          );
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);

        return [];
      });
  }

  async getLastImage(
    directory: string,
    options: GetGalleryImageOptions = {},
  ): Promise<DropBoxImage | void> {
    const { objectId, isDevice = false, ...getFileOptions } = options;

    if (!getFileOptions.hiddenImageIds && objectId) {
      getFileOptions.hiddenImageIds =
        await getFirebaseController().Image.getHiddenImageIds(
          objectId,
          isDevice,
        );
    }

    return await this.getFolders(directory)
      .then(async (folders) => {
        if (folders.length > 0) {
          return await this.searchFolderRecursive(
            folders,
            folders.length - 1,
            -1,
            getFileOptions,
          ).then((images) => {
            return images[0];
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getImageThumbnail(directory: string): Promise<string> {
    if (this.thumbnailCahces[directory]) {
      return this.thumbnailCahces[directory];
    }

    await this.checkToken();

    return await fetchWithRetry(
      `https://content.dropboxapi.com/2/files/get_thumbnail`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token!.access_token}`,
          "Dropbox-API-Arg": `{"path":"${directory}", "size" : "w256h256"}`,
        },
      },
      2,
    )
      .then((resp) => {
        return resp.blob().then((data) => {
          const thumbnail = URL.createObjectURL(data);

          this.thumbnailCahces[directory] = thumbnail;

          return thumbnail;
        });
      })
      .catch((error) => {
        console.error(error);

        return "";
      });
  }

  async getImageThumbnails(
    directories: string[],
    progressCallback?: (progress: number) => void,
  ): Promise<string[]> {
    // Todo: try implement caching

    await this.checkToken();

    // batch fetch, 25 per batch
    const chunk = _.chunk(directories, 25);
    const promises: Promise<string[]>[] = [];

    let progress = 0;
    const total = directories.length;

    if (progressCallback) {
      progressCallback(0);
    }

    chunk.forEach((chuckDirectories) => {
      promises.push(
        limit(() => {
          return fetchWithRetry(
            "https://content.dropboxapi.com/2/files/get_thumbnail_batch",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token!.access_token}`,
              },
              body: JSON.stringify({
                entries: chuckDirectories.map((directory) => {
                  return {
                    path: directory,
                    size: "w1024h768",
                  };
                }),
              }),
            },
          )
            .then((data) => data.json())
            .then((data: GetThumbnailBatchResponse) => {
              if ("entries" in data) {
                const images = data.entries.map((data) => {
                  const byteCharacters = atob(data.thumbnail);

                  const byteNumbers = new Array(byteCharacters.length);

                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }

                  const byteArray = new Uint8Array(byteNumbers);

                  const blob = new Blob([byteArray], { type: "image/jpg" });

                  const blobUrl = URL.createObjectURL(blob);

                  return blobUrl;
                });

                if (progressCallback) {
                  progress += chuckDirectories.length;
                  const currentProgress = (progress / total) * 100;

                  progressCallback(currentProgress);
                }

                return images;
              } else {
                throw new Error(data.error_summary);
              }
            })
            .catch(function (error) {
              console.error(error.error || error);

              return [];
            });
        }),
      );
    });

    return await Promise.all(promises).then((data) => {
      return data.flat();
    });
  }

  async getImageHighRes(
    directory: string,
    fileName: string,
  ): Promise<GetImageHighResData> {
    if (this.imageHighResDataCaches[directory]) {
      return this.imageHighResDataCaches[directory];
    }

    await this.checkToken();

    return await fetchWithRetry(
      `https://content.dropboxapi.com/2/files/download`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.token!.access_token}`,
          "Dropbox-API-Arg": JSON.stringify({
            path: directory,
          }),
        },
      },
      2,
    )
      .then((res) => res.blob())
      .then((data) => {
        data = data.slice(0, data.size, "image/jpeg");

        const imageUrl = URL.createObjectURL(data);
        const link = document.createElement("a");

        link.href = imageUrl;

        const extension = fileName.split(".").pop();
        const name = fileName.replace(`.${extension}`, "");

        link.download = `${name}.jpg`;

        const highResData = {
          url: imageUrl,
          file: link,
          blob: data,
        };

        this.imageHighResDataCaches[directory] = highResData;

        return highResData;
      })
      .catch((error) => {
        console.error(error);

        return {
          url: "",
          file: undefined,
          blob: undefined,
        };
      });
  }

  private async checkToken() {
    await this.setupToken();

    if (!this.token) {
      throw new Error("No token.");
    }
  }

  private isDateMatched(
    startDate: UnixEpoch,
    endDate: UnixEpoch,
    image: Image,
  ): boolean {
    const imageTime = moment(ImageHandlerV2.getImageTime(image, "1")).unix();

    const isWithinTimeRange = imageTime >= startDate && imageTime <= endDate;

    return isWithinTimeRange;
  }

  private getMonthFolderName(date: UnixEpoch): string {
    return moment.unix(date).format("YYYY_MM_MMM");
  }
}
