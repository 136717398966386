import _ from "lodash";

export interface SelectOption<T = string> {
  value: T;
  label: string;
  accessableLevel?: string[];
}

export const createSelectOption = <T = string | boolean>(
  value: T,
  label?: string,
  accessableLevel?: string[],
): SelectOption<T> => {
  return {
    value,
    label: label || _.startCase(_.toLower(_.toString(value))),
    accessableLevel,
  };
};
