import { Fragment, ReactElement, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import {
  List,
  ListItemButtonProps,
  ListItem,
  ListItemButton,
  alpha,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Grid,
  IconButton,
  Divider,
  Typography,
  darken,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LogoutIcon from "@mui/icons-material/Logout";
import FeedbackIcon from "@mui/icons-material/Feedback";
import BarChartIcon from "@mui/icons-material/BarChart";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";

import { useAtom, useAtomValue } from "jotai";
import { currentUserState } from "states/auth";
import { isSidenavShowState, themeSettingsState } from "states/layout";

import { useSnackbar } from "context/Snackbar/SnackbarContext";
import useAccess from "hooks/useAccess";

import ReliveItLogo from "assets/BEERAssets/ReliveIt/Relive-IT-Logo-White.png";
import { getFirebaseController } from "database/FirebaseController";

interface SideNavbarListItem {
  label: string;
  icon: ReactElement;
  isDisabled?: boolean;
  onClick?: () => void;
  route?: string;
  divider?: boolean;
}

const SideNavbarList = ({ listItems }: { listItems: SideNavbarListItem[] }) => {
  return (
    <List>
      {listItems.map((item, itemIndex) => {
        if (item.isDisabled) {
          return null;
        }

        let props: ListItemButtonProps = {};

        if (item.route) {
          props = {
            component: NavLink,
            to: item.route,
          } as ListItemButtonProps;
        } else {
          props = {
            onClick: item.onClick,
          };
        }

        return (
          <Fragment key={item.label}>
            {item.divider && (
              <Divider
                sx={{
                  my: 1,
                  background: ({ palette }) =>
                    alpha(palette.onSecondary.main, 0.1),
                }}
              />
            )}

            <ListItem key={item.label} disablePadding>
              <ListItemButton
                {...props}
                sx={{
                  ":hover": {
                    backgroundColor: ({ palette }) =>
                      alpha(palette.secondary.dark, 0.5),
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                  {item.icon}
                </ListItemIcon>

                <ListItemText
                  primary={item.label}
                  sx={{ m: 0 }}
                  primaryTypographyProps={{ sx: { fontSize: 15 } }}
                />
              </ListItemButton>
            </ListItem>
          </Fragment>
        );
      })}
    </List>
  );
};

const SideNavbar = () => {
  const [isSidenavShow, setIsSidenavShow] = useAtom(isSidenavShowState);

  const navigate = useNavigate();

  const { setSnackbarProps } = useSnackbar();
  const { isAdmin } = useAccess();

  const currentUser = useAtomValue(currentUserState);

  const buttons: SideNavbarListItem[] = useMemo(
    () => [
      {
        label: "Devices",
        icon: <CameraAltIcon fontSize="small" />,
        isDisabled: !isAdmin,
        route: "/devices",
      },
      {
        label: "Galleries",
        icon: <CollectionsIcon fontSize="small" />,
        route: "/galleries",
      },
      {
        label: "Clients",
        icon: <BusinessIcon fontSize="small" />,
        isDisabled: !isAdmin,
        route: "/clients",
      },
      {
        label: "Users",
        icon: <PeopleAltIcon fontSize="small" />,
        isDisabled: !isAdmin,
        route: "/users",
      },
      {
        label: "Job Sites",
        icon: <PlaceIcon fontSize="small" />,
        isDisabled: !isAdmin,
        route: "/job-sites",
      },
      {
        label: "Versions",
        icon: <AccountTreeIcon fontSize="small" />,
        isDisabled: !isAdmin,
        route: "/versions",
      },
      {
        label: "TV Dashboard",
        icon: <ConnectedTvIcon fontSize="small" />,
        route: "/tv-dashboard",
        divider: true,
      },
      {
        label: "Analytics",
        icon: <BarChartIcon fontSize="small" />,
        isDisabled: !isAdmin,
        route: "/analytics",
        divider: true,
      },
      {
        label: "Log out",
        icon: <LogoutIcon fontSize="small" />,
        onClick: async () => {
          await getFirebaseController()
            .Auth.logOut()
            .then(() => {
              setSnackbarProps({
                open: true,
                content: "Logout successfully!",
              });
            });
        },
        divider: true,
      },
      {
        label: "User settings",
        isDisabled: !isAdmin,
        icon: <AccountCircleIcon fontSize="small" />,
        route: currentUser?.id ? `/users/${currentUser.id}` : "#",
      },
      {
        label: "Feedback",
        icon: <FeedbackIcon fontSize="small" />,
        route: "/feedback",
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, isAdmin],
  );

  const themeSettings = useAtomValue(themeSettingsState);

  const logo = useMemo(() => {
    return themeSettings?.logoUrl || ReliveItLogo;
  }, [themeSettings]);

  return (
    <Drawer
      anchor={"left"}
      open={isSidenavShow}
      onClose={() => setIsSidenavShow(false)}
      PaperProps={{
        sx: ({ zIndex, customConfig, palette }) => ({
          height: "100vh",
          width: {
            xs: "80vw",
            sm: "250px",
          },
          background: palette.secondary.main,
          zIndex: zIndex.appBar + 1,
          position: "relative",
          color: ({ palette }) => `${palette.onSecondary.main} !important`,

          ".MuiSvgIcon-root": {
            color: ({ palette }) => `${palette.onSecondary.main} !important`,
          },
        }),
      }}
    >
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            height: ({ customConfig }) => customConfig.navbarHeight,
            color: ({ palette }) => palette.onSecondary.main,
          }}
        >
          <Grid container gap={2} alignItems={"center"} sx={{ height: "100%" }}>
            <Grid item sx={{ ml: 2 }}>
              <IconButton
                color="inherit"
                disableRipple
                sx={{ p: 0 }}
                onClick={() => setIsSidenavShow(!isSidenavShow)}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid item height={"100%"}>
              <Box
                sx={{
                  py: 1,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  crossOrigin="anonymous"
                  component={"img"}
                  src={logo}
                  sx={{
                    width: "auto",
                    height: "100%",
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  onClick={() => navigate(`./`)}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider
            sx={{
              background: ({ palette }) => alpha(palette.onSecondary.main, 0.1),
            }}
          />
        </Box>

        <Box>
          <SideNavbarList listItems={buttons} />
        </Box>

        <Box sx={{ mt: "auto", p: 2, display: "flex" }}>
          <Typography variant="caption" sx={{ lineHeight: 1 }}>
            v{process.env.REACT_APP_VERSION || "0"}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideNavbar;
