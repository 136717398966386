import { useState, useEffect, useMemo, Fragment } from "react";

import { useAtom, useSetAtom } from "jotai";

import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useSnackbar } from "context/Snackbar/SnackbarContext";
import { getAdressAutoComplete, getGeocode } from "hooks/useGeoapify";

import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Chip,
  Divider,
  Switch,
} from "@mui/material";

import { getDirtyFields } from "Windows/Device/DeviceEditWindow";

import Navbar from "components/Layouts/Navbar";
import DashboardEditContainer, {
  baseSettingAutocompleteProps,
  baseSettingSwitchProps,
  baseSettingTextFieldProps,
} from "components/Dashboard/DashboardEditContainer";
import SettingsTable, {
  Column,
  columns,
  SettingConfirmationModal,
  SettingConfirmationModalProps,
  SettingLink,
  SettingRow,
} from "components/Dashboard/SettingsTable";

import DataChips from "components/Dashboard/DataChips";
import Map from "components/Map/Map";

import {
  ROLE_ACCESS_LEVEL_MAPPER,
  getDefaultJobSite,
} from "database/DataDefaultValues";
import { GalleryV2, JobSite, User, UserRole } from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import _ from "lodash";

import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import { SelectOption } from "utils/input";
import { getDifferences } from "utils/form";
import { orderByIgnoreCase } from "utils/display";

import { AddToPhotos } from "@mui/icons-material";

export const jobSiteColumns: Column[] = [
  { id: "name", label: "Name", width: "20%" },
  { id: "email", label: "Email", width: "25%" },
  { id: "physicalAddress", label: "Physical Address", width: "45%" },
  { id: "associatedClients", label: "Associated Clients" },
  { id: "actions", label: "Actions", align: "center" },
];

type JobSiteModalTab = 0 | 1 | 2 | 3 | 4;

type JobSiteModalParams = {
  isOpen: boolean;
  tab: JobSiteModalTab;
  id: JobSite["id"] | null;
};

type InputLegendProps = {
  items: { color: string; label: string; description?: string }[];
};

const InputLegend = ({ items }: InputLegendProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {items.map((item, itemIndex) => {
        return (
          <Fragment key={itemIndex}>
            {itemIndex > 0 && (
              <Divider
                flexItem
                orientation="vertical"
                sx={{ bgcolor: item.color }}
              />
            )}

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  bgcolor: item.color,
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                }}
              />

              <Typography
                variant="caption"
                sx={{
                  fontSize: 12,
                  lineHeight: 0,
                  fontWeight: "bold",
                  ml: 0.5,
                }}
              >
                : {item.label}
              </Typography>

              {item.description && (
                <Typography
                  sx={{
                    fontSize: "12px !important",
                    lineHeight: 1,
                    ml: 0.5,
                  }}
                >
                  ({item.description})
                </Typography>
              )}
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};

const _siteUserRolesCaches: { [key: number]: UserRole[] } = {};
const _siteUserCaches: { [key: number]: User[] } = {};

const JobSiteEditWindow = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [initialJobSite, setInitialJobSite] = useState<JobSite | null>(null);
  const [jobSiteName, setJobSiteName] = useState<string>("");
  const [galleryList, setGalleryList] = useState<GalleryV2[]>([]);

  const [clientListCache, setClientListCache] = useAtom(clientListCacheState);
  const [, setJobSiteListCache] = useAtom(jobSitesListCacheState);

  const isEdit = !!params.id;

  const { setSnackbarProps } = useSnackbar();

  const [mapMarkerPosition, setMapMarkerPosition] = useState<
    [number, number] | null
  >(null);
  const [isAddressSearching, setIsAddressSearching] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  const [userList, setUserList] = useState<User[]>([]);
  const [usersLookup, setUsersLookup] = useState<{ [key: number]: User }>({});

  const [userRoleList, setUserRoleList] = useState<UserRole[]>([]);
  const [userRoleLookup, setUserRoleLookup] = useState<{
    [key: number]: UserRole;
  }>({});

  const [userRoleOptions, setUserRoleOptions] = useState<
    SelectOption<number>[]
  >([]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({});

  const handleClientsChange = (clientIds) => {
    const userRoleOptions: SelectOption<number>[] = [];

    const inheritedUserRoleIds: number[] = [];

    userRoleList.forEach((role) => {
      if (
        role.accessLevel !== "3" &&
        clientIds.includes(role.associatedClient)
      ) {
        if (role.isInheritSites) {
          inheritedUserRoleIds.push(role.id as number);
        }

        userRoleOptions.push({
          label: `${usersLookup[role.associatedUser as number].username} (${
            ROLE_ACCESS_LEVEL_MAPPER[role.accessLevel]
          })`,
          value: role.id as number,
        });
      }
    });

    const usersIds = getValues("userRoles");

    const newUserIds = usersIds.filter((id) => {
      const role = userRoleLookup[id];

      return (
        role.accessLevel !== "3" && clientIds.includes(role.associatedClient)
      );
    });

    setUserRoleOptions(userRoleOptions);
    setValue("userRoles", _.uniq([...inheritedUserRoleIds, ...newUserIds]), {
      shouldDirty: true,
    });

    if (usersIds.length !== newUserIds.length) {
      setValue("isAllUsers", false, { shouldDirty: true });
    }
  };

  const [searchParams] = useSearchParams();

  const setMarkerPositionDebounce = useMemo(
    () =>
      _.debounce(([lat, lon]) => {
        setMapMarkerPosition([Number(lat), Number(lon)]);
      }, 500),
    [],
  );

  const getAdressAutoCompleteDebounce = useMemo(
    () =>
      _.debounce((addressInput: string) => {
        if (!addressInput) {
          setAddressOptions([]);
          return;
        }

        getAdressAutoComplete(addressInput).then((data) => {
          if (data.results) {
            setAddressOptions(
              data.results.map((result) => {
                return {
                  label: result.formatted,
                  value: result.formatted,
                  lat: result.lat,
                  lon: result.lon,
                };
              }),
            );
          }
        });
      }, 400),
    [],
  );

  const init = async () => {
    setIsLoading(true);

    await initUserList().then(async ({ userRoles, usersLookup }) => {
      if (isEdit) {
        await initJobSiteEdit(userRoles, usersLookup);
      } else {
        initJobSiteNew(userRoles, usersLookup);
      }
    });

    setIsLoading(false);
  };

  const initJobSiteEdit = async (
    userRoles: UserRole[] = [],
    usersLookup: { [key: number]: User } = {},
  ) => {
    try {
      const jobSite = await getFirebaseController().JobSite.getJobSite(
        params.id,
      );

      if (jobSite) {
        await getFirebaseController()
          .Gallery.getGalleries({
            jobSiteIds: [jobSite.id],
          })
          .then((gallery) => setGalleryList(gallery));

        setJobSiteName(jobSite.name);

        setInitialJobSite(jobSite);

        const userRoleIds: number[] = [];
        const userRoleOptions: SelectOption<number>[] = [];

        userRoles.forEach((role) => {
          if (role.accessLevel !== "3") {
            if (role.accessableJobSites.includes(jobSite.id)) {
              userRoleIds.push(role.id as number);
            }

            if (jobSite.associatedClients.includes(role.associatedClient)) {
              userRoleOptions.push({
                label: `${
                  usersLookup[role.associatedUser as number].username
                } (${ROLE_ACCESS_LEVEL_MAPPER[role.accessLevel]})`,
                value: role.id as number,
              });
            }
          }
        });

        setUserRoleOptions(userRoleOptions);

        const form = {
          jobSite,
          userRoles: userRoleIds,
        };

        const [lat, lon] = jobSite.gpsLocation.split(",");

        if (lat && lon) {
          const parsedLat = Number(lat);
          const parsedLon = Number(lon);

          form["_temp"] = {
            lat: parsedLat,
            lon: parsedLon,
          };

          setMapMarkerPosition([parsedLat, parsedLon]);
        } else {
          setMapMarkerPosition(null);
        }

        reset(form);
      } else {
        throw new Error("Job site not found.");
      }
    } catch (err) {
      navigate("../");
    }
  };

  const initJobSiteNew = (
    userRoles: UserRole[] = [],
    usersLookup: { [key: number]: User } = {},
  ) => {
    const jobSite = {
      ...getDefaultJobSite(),
    };

    const clientIdParams = searchParams.get("clientId");

    const defaultClientIds: number[] = [];

    const userRoleIds: number[] = [];
    const userRoleOptions: SelectOption<number>[] = [];

    if (clientIdParams) {
      const parsedClientId = Number(clientIdParams);

      const client = clientListCache.find((client) => {
        return client.id === parsedClientId;
      });

      if (client) {
        defaultClientIds.push(client.id as number);

        userRoles.forEach((role) => {
          if (role.accessLevel !== "3") {
            if (client.id === role.associatedClient) {
              if (role.isInheritSites) {
                userRoleIds.push(role.id as number);
              }

              userRoleOptions.push({
                label: `${
                  usersLookup[role.associatedUser as number].username
                } (${ROLE_ACCESS_LEVEL_MAPPER[role.accessLevel]})`,
                value: role.id as number,
              });
            }
          }
        });

        setUserRoleOptions(userRoleOptions);
      }
    }

    setInitialJobSite(jobSite);

    reset({
      jobSite: {
        ...jobSite,
        associatedClients: defaultClientIds,
      },
      userRoles: userRoleIds,
    });
  };

  const initUserList = async () => {
    const firebaseController = getFirebaseController();

    const userRoles = await firebaseController.User.getUserRoles();

    const userPromises: Promise<any>[] = [];

    const usersLookup: { [key: number]: User } = {};
    const userRoleLookup: { [key: number]: UserRole } = {};

    userRoles.forEach((role) => {
      if (role.associatedUser) {
        userRoleLookup[role.id as number] = role;

        userPromises.push(
          firebaseController.User.getUser(role.associatedUser)
            .then((user) => {
              if (user) {
                usersLookup[user.id as number] = user;
              }
            })
            .catch((err) => {
              console.error(err);
            }),
        );
      }
    });

    return await Promise.all(userPromises).then(() => {
      const userList = Object.values(usersLookup);

      setUsersLookup(usersLookup);
      setUserList(userList);
      setUserRoleLookup(userRoleLookup);
      setUserRoleList(userRoles);

      return {
        usersLookup,
        userList,
        userRoleLookup,
        userRoles,
      };
    });
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    if (!jobSiteName) {
      setIsLoading(false);
      return;
    }

    const firebaseController = getFirebaseController();

    let updatedClientIds: number[] = [];

    if (isEdit) {
      const editedFields = getDirtyFields(formState.dirtyFields, getValues());

      if (initialJobSite && !_.isEmpty(editedFields)) {
        const { _temp, jobSite = {}, userRoles } = editedFields;

        const initialRoleIds = userRoleList
          .filter((role) => {
            return role.accessableJobSites.includes(initialJobSite.id);
          })
          .map((role) => role.id);

        const editedUserRoleIds = userRoles
          ? getDifferences(initialRoleIds, userRoles)
          : [];

        const editedClientIds = jobSite.associatedClients
          ? getDifferences(
              initialJobSite.associatedClients,
              jobSite.associatedClients,
            )
          : [];

        if (_temp && _temp.lat && _temp.lon) {
          jobSite.gpsLocation = `${_temp.lat},${_temp.lon}`;
        }

        await firebaseController.JobSite.updateJobSite(
          initialJobSite.id as number,
          jobSite,
          editedUserRoleIds as number[][],
          editedClientIds as number[][],
          { returnDocument: true },
        )
          .then(async (updatedJobSite) => {
            if (editedClientIds.length > 0) {
              updatedClientIds = editedClientIds.flat() as number[];
            }

            if (updatedJobSite) {
              setJobSiteListCache((prevJobSites) => {
                const newJobSites = prevJobSites.map((site) => {
                  if (site.id === updatedJobSite.id) {
                    return updatedJobSite;
                  } else {
                    return site;
                  }
                });

                const sortedJobSites = orderByIgnoreCase(newJobSites, "name");

                return sortedJobSites;
              });
            }

            setSnackbarProps({
              open: true,
              content: `Job site updated successfully!`,
            });

            const clientIdParams = searchParams.get("clientId");

            if (clientIdParams) {
              navigate(`/clients/${clientIdParams}`);
            } else {
              await init();
            }
          })
          .catch((err) => {
            console.error(err);

            setSnackbarProps({
              open: true,
              content: `Job site update failed!`,
              severity: "error",
            });
          });
      }
    } else {
      const { _temp, jobSite, userRoles } = getValues();

      if (jobSite.name) {
        if (_temp && _temp.lat && _temp.lon) {
          jobSite.gpsLocation = `${_temp.lat},${_temp.lon}`;
        }

        if (jobSite.associatedClients) {
          updatedClientIds = jobSite.associatedClients as number[];
        }

        await firebaseController.JobSite.addJobSiteV2(
          { ...jobSite },
          {
            userRoleIds: userRoles,
            returnDocument: true,
          },
        )
          .then(async (createdJobSite) => {
            if (createdJobSite) {
              const clientIdParams = searchParams.get("clientId");

              if (clientIdParams) {
                navigate(`/clients/${clientIdParams}`);
              } else {
                navigate(`../${createdJobSite.id}`);
              }

              setJobSiteListCache((prevJobSites) => {
                const sortedJobSites = orderByIgnoreCase(
                  [...prevJobSites, createdJobSite],
                  "name",
                );

                return sortedJobSites;
              });
            }

            setSnackbarProps({
              open: true,
              content: `Job site created successfully!`,
            });
          })
          .catch((err) => {
            console.error(err);

            setSnackbarProps({
              open: true,
              content: `Job site create failed!`,
              severity: "error",
            });
          });
      }
    }

    if (updatedClientIds.length > 0) {
      await firebaseController.Client.getClients(updatedClientIds).then(
        (clients) => {
          const updatedClientsLookup = _.keyBy(clients, "id");

          setClientListCache((current) => {
            return current.map((client) => {
              const newClient = client;
              const updatedClient =
                updatedClientsLookup[newClient.id as number];

              return updatedClient || newClient;
            });
          });
        },
      );
    }

    setIsLoading(false);
  };

  const handleAddressSearch = async (value?: string) => {
    setIsAddressSearching(true);

    const addressInput = value || getValues("jobSite.physicalAddress");

    await getGeocode(addressInput)
      .then((data) => {
        setIsAddressSearching(false);

        setMapMarkerPosition([data.results[0].lat, data.results[0].lon]);

        setValue("_temp.lat", data.results[0].lat, {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue("_temp.lon", data.results[0].lon, {
          shouldDirty: true,
          shouldValidate: true,
        });
      })
      .catch(() => {
        setIsAddressSearching(false);
      });
  };

  const clientOptions = useMemo(() => {
    return clientListCache.map((client) => {
      return {
        label: client.name,
        value: client.id,
      };
    });
  }, [clientListCache]);

  const jobSiteDetailsRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Name",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("jobSite.name", {
              required: true,
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            error={!!_.get(formState.errors, "jobSite.name")}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Email",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("jobSite.email", {
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Phone",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("jobSite.phone", {
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Archived",
        value: (
          <Controller
            name="jobSite.archived"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => {
                  const isCheck = e.target.checked;

                  if (isEdit && isCheck) {
                    setConfirmationModalParams({
                      isOpen: true,
                      title: "Archive job site?",
                      content:
                        "Note that this will also archive all galleries linked to this job site, and this process cannot be undone once saved. Are you sure you want to proceed?",
                      onDone: () => {
                        props.field.onChange(isCheck);
                      },
                    });
                  } else {
                    props.field.onChange(isCheck);
                  }
                }}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Clients",
        value: (
          <Box>
            <InputLegend
              items={[
                { color: "primary.main", label: "Primary" },
                {
                  color: "secondary.main",
                  label: "Secondary",
                  description: "Click to make client as primary",
                },
              ]}
            />

            <Box sx={{ mt: 1 }}>
              <Controller
                control={control}
                name={"jobSite.associatedClients"}
                defaultValue={[]}
                render={({ field }) => {
                  const fieldIds = field.value;

                  return (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      multiple
                      disabled={isLoading}
                      onChange={(e, data, reason) => {
                        if (isEdit && reason === "clear") {
                          setConfirmationModalParams({
                            isOpen: true,
                            title: `Remove all clients from this job site?`,
                            content:
                              "Note that this will also remove all users under the client from this job site. Are you sure you want to proceed?",
                            onDone: () => {
                              field.onChange(
                                data.map((d) => d.value as number) || [],
                              );
                            },
                          });
                        } else {
                          field.onChange(
                            data.map((d) => d.value as number) || [],
                          );
                        }
                      }}
                      value={fieldIds.map((v) =>
                        clientOptions.find((o) => o.value === v),
                      )}
                      renderTags={(options, getTagProps) => {
                        return options.map((option, index) => {
                          return (
                            <Chip
                              onClick={() => {
                                if (isEdit && fieldIds[0] !== option.value) {
                                  setConfirmationModalParams({
                                    isOpen: true,
                                    title: "Change primary client?",
                                    content: `Are you sure you want to make client "${option.label}" as primary?`,
                                    onDone: () => {
                                      field.onChange(
                                        _.uniq([option.value, ...fieldIds]),
                                      );
                                    },
                                  });
                                }
                              }}
                              color="secondary"
                              size="small"
                              label={option.label}
                              sx={({ palette }) => ({
                                borderRadius: 0.5,

                                color: `${palette.white.main} !important`,

                                backgroundColor:
                                  option.value === fieldIds[0]
                                    ? palette.primary.light
                                    : palette.secondary.light,
                              })}
                              {...getTagProps({ index })}
                              onDelete={(e) => {
                                e.stopPropagation();

                                if (isEdit) {
                                  setConfirmationModalParams({
                                    isOpen: true,
                                    title: `Remove client "${option.label}" from this job site?`,
                                    content:
                                      "Note that this will also remove all users under the client from this job site. Are you sure you want to proceed?",
                                    onDone: () => {
                                      getTagProps({ index }).onDelete(e);
                                    },
                                  });
                                } else {
                                  getTagProps({ index }).onDelete(e);
                                }
                              }}
                            />
                          );
                        });
                      }}
                      options={clientOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  );
                }}
              />
            </Box>
          </Box>
        ),
      },
      {
        settingName: "Accessible Users",
        value: (
          <Box>
            <InputLegend
              items={[{ color: "primary.main", label: "Inherited User" }]}
            />

            <Box sx={{ mt: 1 }}>
              <Controller
                control={control}
                name={"userRoles"}
                defaultValue={[]}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      multiple
                      disabled={isLoading}
                      disableCloseOnSelect
                      onChange={(e, data, reason) => {
                        if (isEdit && reason === "clear") {
                          setConfirmationModalParams({
                            isOpen: true,
                            title: `Remove all users from this job site?`,
                            content:
                              "Are you sure you want to revoke all user access to this job site?",
                            onDone: () => {
                              field.onChange(
                                data.map((d) => d.value as number) || [],
                              );
                            },
                          });
                        } else {
                          field.onChange(
                            data.map((d) => d.value as number) || [],
                          );
                        }
                      }}
                      value={field.value.map((v) =>
                        userRoleOptions.find((o) => o.value === v),
                      )}
                      renderTags={(values, getTagProps) => {
                        return values.map((option, index) => {
                          let isInherited = false;

                          if (option) {
                            const roleId = option.value;
                            const role = userRoleLookup[roleId];

                            isInherited = role?.isInheritSites;
                          }

                          return (
                            <Chip
                              color="secondary"
                              size="small"
                              label={option.label}
                              sx={({ palette }) => ({
                                borderRadius: 0.5,
                                backgroundColor: isInherited
                                  ? palette.primary.light
                                  : palette.secondary.light,
                                color: `${
                                  isInherited
                                    ? palette.white.main
                                    : palette.white.main
                                } !important`,
                              })}
                              {...getTagProps({ index })}
                              onDelete={(e) => {
                                e.stopPropagation();

                                if (isEdit) {
                                  setConfirmationModalParams({
                                    isOpen: true,
                                    title: `Remove user "${option.label}" from this job site?`,
                                    content: `Are you sure you want to revoke user "${option.label}" access to this job site?`,
                                    onDone: () => {
                                      getTagProps({ index }).onDelete(e);
                                    },
                                  });
                                } else {
                                  getTagProps({ index }).onDelete(e);
                                }
                              }}
                            />
                          );
                        });
                      }}
                      options={userRoleOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  );
                }}
              />

              <Box sx={{ mt: 1 }}>
                <FormControlLabel
                  label={`Select all`}
                  sx={{ margin: "0 !important" }}
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: {
                          xs: 12,
                          sm: 14,
                        },
                      },
                    },
                  }}
                  control={
                    <Controller
                      name={"isAllUsers"}
                      control={control}
                      defaultValue={false}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          sx={{ padding: 0, mr: 1 }}
                          disabled={isLoading}
                          size="small"
                          checked={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.checked);

                            if (e.target.checked) {
                              const allIds = userRoleOptions.map(
                                (role) => role.value,
                              );

                              setValue("userRoles", allIds, {
                                shouldDirty: true,
                              });
                            }
                          }}
                        />
                      )}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        ),
      },
      ...(isEdit
        ? [
            {
              settingName: "Galleries",
              value: (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DataChips
                    items={galleryList}
                    labelkey="galleryName"
                    itemLink={(item) => `/galleries/${item.id}`}
                  />

                  {initialJobSite?.id && (
                    <SettingLink
                      to={`/galleries/new?jobSiteId=${initialJobSite.id.toString()}`}
                      icon={
                        <AddToPhotos sx={{ fontSize: "18px !important" }} />
                      }
                      noMargin={galleryList.length === 0}
                    />
                  )}
                </Box>
              ),
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState, isLoading, register, userRoleOptions],
  );

  const jobSiteLocationRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Address",
        value: (
          <Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Controller
                control={control}
                name={"jobSite.physicalAddress"}
                defaultValue={""}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      disabled={isLoading}
                      disableClearable
                      filterOptions={(x) => x}
                      freeSolo
                      onInputChange={(e, newInputValue) => {
                        field.onChange(newInputValue);
                        getAdressAutoCompleteDebounce(newInputValue);
                      }}
                      onChange={(e, data) => {
                        field.onChange(data.value);
                        setMapMarkerPosition([data.lat, data.lon]);

                        setValue("_temp.lat", data.lat, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("_temp.lon", data.lon, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      value={field.value}
                      options={addressOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddressSearch();
                        }
                      }}
                    />
                  );
                }}
              />

              <Button
                size="small"
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: 12,
                  p: 0,
                  color: "white !important",
                }}
                onClick={() => handleAddressSearch()}
                disabled={isLoading || isAddressSearching}
              >
                Search
              </Button>
            </Box>

            <Box
              sx={{
                marginTop: 2,
                height: 300,
              }}
            >
              <Map
                center={[-37.5873527, 145.1229405]}
                markerPosition={mapMarkerPosition}
                mapOnClick={(e, map) => {
                  const { lat, lng } = e.latlng;

                  setMapMarkerPosition([lat, lng]);

                  map.setView([lat, lng], map.getZoom());

                  setValue("_temp.lat", lat.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("_temp.lon", lng.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Box>
          </Box>
        ),
      },
      {
        settingName: "Latitude",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("_temp.lat", {
              min: -90,
              max: 90,
              setValueAs: (value) => {
                return Number(value);
              },
            })}
            error={!!_.get(formState.errors, "_temp.lat")}
            type="number"
            inputProps={{
              ...baseSettingTextFieldProps.inputProps,
              min: -90,
              max: 90,
              step: 0.1,
            }}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Longitude",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("_temp.lon", {
              min: -180,
              max: 180,
              setValueAs: (value) => {
                return Number(value);
              },
            })}
            error={!!_.get(formState.errors, "_temp.lon")}
            type="number"
            inputProps={{
              ...baseSettingTextFieldProps.inputProps,
              min: -180,
              max: 180,
              step: 0.1,
            }}
            disabled={isLoading}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formState,
      isLoading,
      register,
      mapMarkerPosition,
      isAddressSearching,
      addressOptions,
    ],
  );

  const breadcrumbs = useMemo(() => {
    if (!initialJobSite) {
      return [];
    }

    return [
      {
        label: "Job Sites",
        path: "/job-sites",
      },
      isEdit
        ? {
            label: initialJobSite.name || initialJobSite.id?.toString() || "",
          }
        : {
            label: "New",
          },
    ];
  }, [initialJobSite, isEdit]);

  useEffect(() => {
    // use callback to prevent rerender on every field changed

    const subscription = watch((value, props) => {
      if (props.name === "jobSite.name") {
        setJobSiteName(value.jobSite.name);
      } else if (props.name === "jobSite.associatedClients") {
        handleClientsChange(value.jobSite.associatedClients);
      } else if (props.type === "change" && props.name === "userRoles") {
        setValue("isAllUsers", false);
      } else if (
        props.type === "change" &&
        (props.name === "_temp.lon" || props.name === "_temp.lat")
      ) {
        const { lat, lon } = value["_temp"];

        if (lat && lon) {
          setMarkerPositionDebounce([lat, lon]);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, userRoleList]);

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const [confirmationModalParams, setConfirmationModalParams] = useState<
    Omit<SettingConfirmationModalProps, "onClose"> &
      Partial<SettingConfirmationModalProps>
  >({
    isOpen: false,
    title: "",
    content: "",
    onDone: () => {},
  });

  return (
    <Box>
      <Navbar title="Job Site Dashboard" />

      <SettingConfirmationModal
        {...confirmationModalParams}
        onClose={() => {
          setConfirmationModalParams((prev) => {
            return {
              ...prev,
              isOpen: false,
              title: "",
              content: "",
            };
          });
        }}
      />

      <DashboardEditContainer
        breadcrumbs={breadcrumbs}
        title={
          isEdit
            ? initialJobSite?.name || "-"
            : ` New Job Site ${jobSiteName ? `- ${jobSiteName}` : ""}`
        }
        subtitle="Fill in the client's info and press save when done."
        onSave={handleSubmit(handleSave, (errors) => {
          if (_.get(errors, "jobSite.name")) {
            setSnackbarProps({
              open: true,
              content: `Job site name cannot be empty.`,
              severity: "error",
            });
          } else if (_.get(errors, "_temp.lon") || _.get(errors, "_temp.lat")) {
            setSnackbarProps({
              open: true,
              content: `Invalid latitude or longitude.`,
              severity: "error",
            });
          }
        })}
        isLoading={isLoading}
      >
        <Grid container direction="column" wrap="nowrap" gap={4}>
          <Grid item>
            <SettingsTable
              title={"Job Site Details"}
              rows={jobSiteDetailsRow}
              columns={columns}
            />
          </Grid>

          <Grid item>
            <SettingsTable
              title={"Job Site Location"}
              rows={jobSiteLocationRow}
              columns={columns}
            />
          </Grid>
        </Grid>
      </DashboardEditContainer>
    </Box>
  );
};

export default JobSiteEditWindow;
