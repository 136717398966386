import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Autocomplete,
  Button,
  Divider,
  IconButton,
  alpha,
} from "@mui/material";

import {
  PrefixTextField,
  SliderInput,
  getDirtyFields,
  intervalSettingsColumns,
} from "Windows/Device/DeviceEditWindow";
import SettingsTable, {
  columns,
  SettingRow,
  IntervalSettingsRow,
  SettingLink,
  SettingConfirmationModal,
  SettingConfirmationModalProps,
  CopyLinkButton,
} from "components/Dashboard/SettingsTable";
import Navbar from "components/Layouts/Navbar";
import ConfirmationModal from "components/BaseModal/ConfirmationModal";
import DashboardEditContainer, {
  baseSettingAutocompleteProps,
  baseSettingSwitchProps,
  baseSettingTextFieldProps,
} from "components/Dashboard/DashboardEditContainer";

import {
  DAY_NAMES,
  INTERVAL_TIME_OPTIONS,
  INTERVAL_OPTIONS,
  BURST_INTERVAL_OPTIONS,
  getDefaultGallery,
  IMAGE_HOST_MAPPER,
  getDefaultDeviceIntervalSettings,
  ROTATE_VALUE_MAPPER,
  getDefaultGalleryDeviceSettings,
  AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR,
  BRIGHTNESS_RANGE,
  CONTRAST_RANGE,
  SATURATION_RANGE,
  SHARPNESS_RANGE,
  UPDATE_CHANNEL_MAPPER,
  UPDATE_TIME_OPTIONS,
  IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER,
} from "database/DataDefaultValues";
import {
  Client,
  DeviceV2,
  DeviceV2Details,
  DeviceV2Settings,
  GalleryV2,
  User,
  UserRole,
} from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import { useAtomValue } from "jotai";
import { currentClientState, currentJobSitesState } from "states/auth";
import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import { _jobSiteCaches } from "./GalleryGridWindow";

import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "context/Snackbar/SnackbarContext";

import { useGalleryTracker } from "hooks/eventTracker/useGalleryTracker";
import useAccess from "hooks/useAccess";
import useOnScreen from "hooks/useOnScreen";

import _ from "lodash";
import { getAdressAutoComplete, getGeocode } from "hooks/useGeoapify";
import Map from "components/Map/Map";

import DataChips from "components/Dashboard/DataChips";
import { orderByIgnoreCase } from "utils/display";

import { SelectOption } from "utils/input";
import { OpenInNew, Link as LinkIcon } from "@mui/icons-material";

import LinkWrapper from "components/Wrapper/Link";
import { _DeviceController } from "database/_controllers/_DeviceController";
import { getContrastShade } from "theme/reliveItTheme";

const handleEditGalleryAdminEvents = (
  initGallery: GalleryV2,
  editedGallery: Partial<GalleryV2> = {},
  initEditedData: any,
  galleryName: string,
) => {
  const firebaseController = getFirebaseController();

  // TODO: refactor, might move this into gallery controller
  // TODO: better conditional checking

  if (
    (initGallery!.assignedDevice &&
      editedGallery.assignedDevice === undefined) ||
    initGallery!.assignedDevice === editedGallery.assignedDevice
  ) {
    // previosuly assigned device

    firebaseController.Gallery.addUpdateGalleryAdminEvent(
      initGallery.assignedDevice,
      initEditedData,
    );
  } else if (
    initGallery!.assignedDevice &&
    editedGallery.assignedDevice === null
  ) {
    // removing current assigned device

    firebaseController.Gallery.addRemoveDeviceAdminEvent(
      initGallery!.assignedDevice,
      galleryName,
      initGallery.id,
    );
  } else if (!initGallery!.assignedDevice && editedGallery.assignedDevice) {
    // assigning new device

    firebaseController.Gallery.addAssignDeivceAdminEvent(
      editedGallery.assignedDevice,
      galleryName,
      initGallery.id,
    );
  } else if (
    initGallery.assignedDevice &&
    initGallery!.assignedDevice !== editedGallery.assignedDevice
  ) {
    // change assigning device to new device

    firebaseController.Gallery.addRemoveDeviceAdminEvent(
      initGallery!.assignedDevice,
      galleryName,
      initGallery.id,
    );

    firebaseController.Gallery.addAssignDeivceAdminEvent(
      editedGallery.assignedDevice,
      galleryName,
      initGallery.id,
    );
  }
};

const _deviceSettingsCaches: { [key: string]: DeviceV2Settings } = {};
const _deviceDetailsCaches: { [key: string]: DeviceV2Details } = {};
const _deviceCaches: { [key: string]: DeviceV2 } = {};

const GalleryEditWindow = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initGallery, setInitGallery] = useState<GalleryV2 | null>(null);
  const [deviceList, setDeviceList] = useState<DeviceV2[]>([]);
  const [tempAssignedDeviceId, setTempAssignedDeviceId] =
    useState<DeviceV2["id"]>(null);

  const currentUserJobSites = useAtomValue(currentJobSitesState);
  const currentUserClient = useAtomValue(currentClientState);

  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  // const [currentJobSites, setCurrentJobSites] = useState<JobSite[]>([])

  const clientList = useAtomValue(clientListCacheState);
  const jobSiteList = useAtomValue(jobSitesListCacheState);

  const isEdit = !!params.id;

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({});

  const [galleryName, setGalleryName] = useState<string>("");
  const [galleryImageHost, setGalleryImageHost] = useState<string>("0");
  const [intervalSettingsRenderTrigger, setIntervalSettingsRenderTrigger] =
    useState(false);
  const [deviceSettingsRenderTrigger, setDeviceSettingsRenderTrigger] =
    useState(false);
  const [isForcedFocus, setIsForcedFocus] = useState(false);
  const [isShowDeviceSettings, setIsShowDeviceSettings] = useState(false);

  const [mapMarkerPosition, setMapMarkerPosition] = useState<
    [number, number] | null
  >(null);
  const [isAddressSearching, setIsAddressSearching] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  const [confirmationModalParams, setConfirmationModalParams] = useState<
    Omit<SettingConfirmationModalProps, "onClose"> &
      Partial<SettingConfirmationModalProps>
  >({
    isOpen: false,
    title: "",
    content: "",
    onDone: () => {},
  });

  const { setSnackbarProps } = useSnackbar();
  const { isSiteAccessable, isAdmin } = useAccess();

  const cacheManualFocusDistanceRef = useRef(0);

  const handleAssignedDeviceChanged = async (assignedDeviceId: string) => {
    const firebaseController = getFirebaseController();

    const deviceCache = _deviceCaches[assignedDeviceId];
    const deviceSettingsCache = _deviceSettingsCaches[assignedDeviceId];
    const deviceDetailsCache = _deviceDetailsCaches[assignedDeviceId];

    const device =
      deviceCache ||
      (await firebaseController.Device.getDevice(assignedDeviceId));

    const deviceSettings =
      deviceSettingsCache ||
      (await firebaseController.Device.getDeviceSettings(assignedDeviceId));

    const deviceDetails =
      deviceDetailsCache ||
      (await firebaseController.Device.getDeviceDetails(assignedDeviceId));

    if (device && deviceSettings && deviceDetails) {
      _deviceCaches[assignedDeviceId] = device;
      _deviceSettingsCaches[assignedDeviceId] = deviceSettings;
      _deviceDetailsCaches[assignedDeviceId] = deviceDetails;

      Object.keys(device).forEach((key) => {
        setValue(`device.${key}`, device[key], {
          shouldDirty: false,
        });
      });

      Object.keys(deviceSettings).forEach((key) => {
        setValue(`deviceSettings.${key}`, deviceSettings[key], {
          shouldDirty: false,
        });
      });

      Object.keys(deviceDetails).forEach((key) => {
        setValue(`deviceDetails.${key}`, deviceDetails[key], {
          shouldDirty: false,
        });
      });

      setIsForcedFocus(deviceSettings.triggerTwoStep);
      setIsShowDeviceSettings(true);
    } else {
      setIsShowDeviceSettings(false);
    }

    setDeviceSettingsRenderTrigger(!deviceSettingsRenderTrigger);
  };

  const getAdressAutoCompleteDebounce = useMemo(
    () =>
      _.debounce((addressInput: string) => {
        if (!addressInput) {
          setAddressOptions([]);
          return;
        }

        getAdressAutoComplete(addressInput).then((data) => {
          if (data.results) {
            setAddressOptions(
              data.results.map((result) => {
                return {
                  label: result.formatted,
                  value: result.formatted,
                  lat: result.lat,
                  lon: result.lon,
                };
              }),
            );
          }
        });
      }, 400),
    [],
  );

  const handleAddressSearch = async (value?: string) => {
    setIsAddressSearching(true);

    const addressInput = value || getValues("gallery.address");

    await getGeocode(addressInput)
      .then((data) => {
        setIsAddressSearching(false);

        const { lat, lon } = data.results[0] || {};

        if (!_.isUndefined(lat) && !_.isUndefined(lon)) {
          setMapMarkerPosition([lat, lon]);

          setValue("gallery.pinnedLatitude", lat.toString(), {
            shouldDirty: true,
            shouldValidate: true,
          });

          setValue("gallery.pinnedLongitude", lon.toString(), {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      })
      .catch(() => {
        setIsAddressSearching(false);
      });
  };

  useGalleryTracker(initGallery, "settings", true);

  const [jobSiteOptions, setJobSiteOptions] = useState<SelectOption<number>[]>(
    [],
  );

  const initJobSiteOptions = (clientId: number) => {
    const client = clientList.find((c) => c.id === clientId);
    const options: SelectOption<number>[] = [];

    if (client) {
      setCurrentClient(client);

      jobSiteList.forEach((jobSite) => {
        if (jobSite.associatedClients.includes(client.id)) {
          options.push({ label: jobSite.name, value: jobSite.id as number });
        }
      });

      setJobSiteOptions(options);
    } else {
      setJobSiteOptions([]);
    }

    return options;
  };

  const clientListCache = useAtomValue(clientListCacheState);

  const setMarkerPositionDebounce = useMemo(
    () =>
      _.debounce(([lat, lon]) => {
        setMapMarkerPosition([Number(lat), Number(lon)]);
      }, 500),
    [],
  );

  useEffect(() => {
    // use callback to prevent rerender on every field changed

    const subscription = watch((value, props) => {
      if (props.name === "gallery.galleryName") {
        setGalleryName(value.gallery.galleryName);
        handleAutoHostDirectory(value);
      } else if (props.name === "gallery.active" && props.type === "change") {
        setValue("gallery.archived", !value.gallery.active, {
          shouldDirty: true,
        });
      } else if (props.name === "gallery.archived" && props.type === "change") {
        setValue("gallery.active", !value.gallery.archived, {
          shouldDirty: true,
        });
      } else if (props.name === "gallery.galleryImageHost") {
        setGalleryImageHost(value.gallery.galleryImageHost);
        setValue("_temp.host_directory_prefix", "/");
        handleAutoHostDirectory(value);
      } else if (
        props.type === "change" &&
        (props.name === "gallery.pinnedLatitude" ||
          props.name === "gallery.pinnedLongitude")
      ) {
        const { pinnedLatitude, pinnedLongitude } = value.gallery;

        if (pinnedLatitude && pinnedLongitude) {
          setMarkerPositionDebounce([pinnedLatitude, pinnedLongitude]);
        }
      } else if (props.name === "gallery.assignedDevice") {
        const assignedDevice = value.gallery.assignedDevice;

        if (assignedDevice) {
          handleAssignedDeviceChanged(assignedDevice);
        } else {
          setIsShowDeviceSettings(false);
        }
      } else if (
        props.name?.match(/^galleryIntervalSettings.\w+.(active|burst)$/)
      ) {
        setIntervalSettingsRenderTrigger((prev) => !prev);
      } else if (
        props.name?.match("deviceSettings.triggerTwoStep") &&
        props.type === "change"
      ) {
        const triggerTwoStep = value.deviceSettings.triggerTwoStep;

        if (triggerTwoStep) {
          setValue("deviceSettings.manualFocusDistance", -1, {
            shouldDirty: true,
          });

          setValue("_temp.isForcedFocus", false);
        }
      } else if (props.name?.match("deviceSettings.manualFocusDistance")) {
        const currentValue = value.deviceSettings.manualFocusDistance;

        if (currentValue > -1) {
          cacheManualFocusDistanceRef.current =
            value.deviceSettings.manualFocusDistance;
        }
      } else if (props.name?.match("_temp.isForcedFocus")) {
        const isForcedFocus = value._temp.isForcedFocus;

        setIsForcedFocus(isForcedFocus);

        if (isForcedFocus) {
          setValue(
            "deviceSettings.manualFocusDistance",
            cacheManualFocusDistanceRef.current,
            {
              shouldDirty: true,
            },
          );

          setValue("deviceSettings.triggerTwoStep", false, {
            shouldDirty: true,
          });
        } else {
          setValue("deviceSettings.manualFocusDistance", -1, {
            shouldDirty: true,
          });
        }
      } else if (props.name?.match("_temp.client")) {
        const options = initJobSiteOptions(value._temp.client);

        const site = options.find((o) => o.value === value.gallery.jobSite);

        if (!site) {
          setValue("gallery.jobSite", null);
        }

        handleAutoHostDirectory(value);
      } else if (props.name === "gallery.jobSite") {
        getUsersList(value.gallery.jobSite);
        getGalleryList(value.gallery.jobSite);

        handleAutoHostDirectory(value);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, params]);

  useEffect(() => {
    if (galleryImageHost !== "0") {
      const value = getValues("gallery.assignedDevice");
      if (value && value !== tempAssignedDeviceId) {
        setTempAssignedDeviceId(value);
      }

      setValue("gallery.assignedDevice", null);
    } else {
      setValue("gallery.assignedDevice", tempAssignedDeviceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryImageHost]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setIsLoading(true);

    getDeviceList();

    if (isEdit) {
      initGalleryEdit().then(() => setIsLoading(false));
    } else {
      initGalleryNew();

      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getHostDirecotryAndPrefix = (
    galleryImageHost,
    externalHostDirectory,
  ) => {
    const prefixOptions = IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER[galleryImageHost];

    let currentPrefix = "";
    let parsedHostDirectory = externalHostDirectory;

    for (const prefix of prefixOptions) {
      if (externalHostDirectory.startsWith(prefix)) {
        parsedHostDirectory = externalHostDirectory.slice(prefix.length);
        currentPrefix = prefix;

        break;
      }
    }

    return {
      prefix: currentPrefix,
      hostDirectory: parsedHostDirectory,
    };
  };

  const handleAutoHostDirectory = (formValue) => {
    if (formValue.gallery.galleryImageHost !== "0") {
      return;
    }

    const [galleryName, siteId, clientId] = getValues([
      "gallery.galleryName",
      "gallery.jobSite",
      "_temp.client",
    ]);

    const site = jobSitesLookup[siteId];

    const client = clientsLookup[clientId];

    const values = [client?.name, site?.name, galleryName].filter((v) => v);

    setValue("gallery.externalHostDirectory", `${values.join("/")}`, {
      shouldDirty: true,
    });
  };

  const jobSitesLookup = useMemo(() => {
    return _.keyBy(jobSiteList, "id");
  }, [jobSiteList]);

  const clientsLookup = useMemo(() => {
    return _.keyBy(clientList, "id");
  }, [clientList]);

  const [userList, setUserList] = useState<User[]>([]);
  const [galleryList, setGalleryList] = useState<GalleryV2[]>([]);

  const [userToUserRoleMapper, setUserToUserRoleMapper] = useState<{
    [userId: number]: UserRole;
  }>({});

  const getUsersList = async (jobSiteId: number) => {
    const firebaseController = getFirebaseController();

    const userRoles = await firebaseController.User.getUserRoles({
      jobSiteIds: [jobSiteId as number],
      accessLevels: ["0", "1", "2", "4"],
    });

    const usersIds: number[] = [];
    const userToUserRoleMapper = {};

    userRoles.forEach((role) => {
      const userId = role.associatedUser as number;

      usersIds.push(userId);
      userToUserRoleMapper[userId] = role;
    });

    await firebaseController.User.getUsers(usersIds).then((users) => {
      setUserToUserRoleMapper(userToUserRoleMapper);

      setUserList(orderByIgnoreCase(users, "username"));
    });
  };

  const getGalleryList = useCallback(
    async (jobSiteId) => {
      const firebaseController = getFirebaseController();

      const galleries = await firebaseController.Gallery.getGalleries({
        jobSiteIds: [jobSiteId as number],
      });

      const filteredGalleries = galleries.filter((gallery) => {
        if (params.id) {
          return gallery.id !== Number(params.id);
        } else {
          return true;
        }
      });

      setGalleryList(orderByIgnoreCase(filteredGalleries, "galleryName"));
    },
    [params],
  );

  const initGalleryEdit = async () => {
    try {
      const firebaseController = getFirebaseController();

      const gallery = await firebaseController.Gallery.getGallery(params.id);

      if (!gallery || !isSiteAccessable(gallery.jobSite)) {
        throw new Error("Gallery not accessable.");
      }

      // auto change user client based on gallery selected

      const galleryJobSite = jobSitesLookup[gallery.jobSite as number];

      const client = clientListCache.find((client) => {
        return galleryJobSite.associatedClients[0] === client.id;
      });

      if (!client) {
        throw new Error("Client not found.");
      }

      getUsersList(galleryJobSite.id as number);
      getGalleryList(galleryJobSite.id as number);

      initJobSiteOptions(client.id as number);

      const gallerySettings =
        await firebaseController.Gallery.getGallerySettings(gallery.id);

      let deviceSettings;
      let deviceDetails;
      let device;

      if (gallery.assignedDevice && gallery.galleryImageHost !== "2") {
        device = await firebaseController.Device.getDevice(
          gallery.assignedDevice,
        );

        deviceSettings = await firebaseController.Device.getDeviceSettings(
          gallery.assignedDevice,
        );

        deviceDetails = await firebaseController.Device.getDeviceDetails(
          gallery.assignedDevice,
        );

        setIsShowDeviceSettings(true);
      }

      const isForcedFocus = deviceSettings?.manualFocusDistance !== -1;

      setIsForcedFocus(isForcedFocus);

      const galleryIntervalSettings =
        await firebaseController.Gallery.getGalleryIntervals(gallery.id);

      setInitGallery(gallery);
      setGalleryName(gallery.galleryName);
      setGalleryImageHost(gallery.galleryImageHost);

      const { prefix, hostDirectory } = getHostDirecotryAndPrefix(
        gallery.galleryImageHost,
        gallery.externalHostDirectory,
      );

      const formGallery = {
        ...gallery,
        externalHostDirectory: hostDirectory,
        address: gallery.address || "",
      };

      const [lat, lon] = [gallery.pinnedLatitude, gallery.pinnedLongitude];

      if (lat && lon) {
        const parsedLat = Number(lat);
        const parsedLon = Number(lon);

        // @ts-expect-error pinnedLatitude should be string field but we make it number field on input
        formGallery.pinnedLatitude = parsedLat;

        // @ts-expect-error
        formGallery.pinnedLongitude = parsedLon;

        setMapMarkerPosition([parsedLat, parsedLon]);
      } else {
        setMapMarkerPosition(null);
      }

      reset({
        gallery: formGallery,
        gallerySettings,
        galleryIntervalSettings,
        device,
        deviceSettings,
        deviceDetails,
        _temp: {
          client: client.id,
          isForcedFocus,
          host_directory_prefix: prefix,
        },
      });
    } catch (err) {
      console.error(err);

      navigate("../");
    }
  };

  const initGalleryNew = () => {
    const gallery = {
      ...getDefaultGallery(),
    };
    const gallerySettings = getDefaultGalleryDeviceSettings();
    const galleryIntervalSettings = getDefaultDeviceIntervalSettings();

    const formData = {
      gallery,
      gallerySettings,
      galleryIntervalSettings,
      _temp: {
        isForcedFocus: false,
        client: undefined,
        host_directory_prefix: "/",
      },
    };

    const jobSiteIdIdParam = searchParams.get("jobSiteId");

    if (jobSiteIdIdParam) {
      const parsedJobSiteId = Number(jobSiteIdIdParam);

      const jobSite = jobSiteList.find((jobSite) => {
        return jobSite.id === parsedJobSiteId;
      });

      if (jobSite) {
        gallery.jobSite = jobSite.id;
        formData.gallery.jobSite = jobSite.id;

        const clientId = jobSite.associatedClients[0];

        if (clientId) {
          // @ts-expect-error
          formData._temp.client = clientId;
          initJobSiteOptions(clientId);
        }
      } else {
        searchParams.delete("jobSiteId");
        setSearchParams(searchParams);
      }
    }

    setInitGallery(gallery);
    reset(formData);

    setIsLoading(false);
  };

  const getDeviceList = async () => {
    await getFirebaseController()
      .Device.getDevices()
      .then((devices) => {
        setDeviceList(devices.filter((d) => d.id));
      });
  };

  const handleSave = async (rawData) => {
    setIsLoading(true);

    const { _temp: _rawTemp, ...data } = rawData;

    if (
      !initGallery ||
      !galleryName ||
      !currentClient ||
      !data.gallery.jobSite
    ) {
      setSnackbarProps({
        open: true,
        content: `Gallery name, client and job site cannot be empty.`,
        severity: "error",
      });

      setIsLoading(false);
      return;
    }

    const firebaseController = getFirebaseController();

    if (isEdit) {
      const { _temp, ...dirtyFieldsState } = formState.dirtyFields;

      const editedData = getDirtyFields(dirtyFieldsState, data);

      const isHostPrefixChanged = _.has(
        formState.dirtyFields,
        "_temp.host_directory_prefix",
      );

      if (_.isEmpty(editedData) && !isHostPrefixChanged) {
        setIsLoading(false);
        return;
      }

      const {
        gallery,
        gallerySettings,
        galleryIntervalSettings,
        device,
        deviceSettings,
      } = editedData;

      const initEditedData = _.cloneDeep(editedData);

      if (
        gallery &&
        ("galleryName" in gallery ||
          "jobSite" in gallery ||
          "assignedDevice" in gallery)
      ) {
        // we need both data if either of this fields changed, to update device's frontEnd name

        gallery.galleryName = getValues("gallery.galleryName");
        gallery.jobSite = getValues("gallery.jobSite");
      }

      let galleryDataToUpdate = gallery ? { ...gallery } : undefined;

      if (
        isHostPrefixChanged ||
        (gallery && "externalHostDirectory" in gallery)
      ) {
        const hostDirectory = rawData.gallery.externalHostDirectory
          ? _rawTemp.host_directory_prefix +
            rawData.gallery.externalHostDirectory
          : "";

        galleryDataToUpdate ||= {};
        galleryDataToUpdate.externalHostDirectory = hostDirectory;
      }

      await firebaseController.Gallery.updateGallery(
        data.gallery.id,
        currentClient,
        {
          gallery: galleryDataToUpdate,
          gallerySettings,
          galleryIntervalSettings,
          currentAssignedDeviceId: initGallery.assignedDevice,
          device,
          deviceSettings,
        },
      )
        .then(async () => {
          setSnackbarProps({
            open: true,
            content: `Gallery updated successfully!`,
          });

          await initGalleryEdit()
            .then(() => {
              handleEditGalleryAdminEvents(
                initGallery,
                gallery,
                initEditedData,
                galleryName,
              );
            })
            .catch((err) => {
              console.error(err);

              navigate(0);
            });
        })
        .catch((err) => {
          console.error(err);

          setSnackbarProps({
            open: true,
            content: `Gallery update failed!`,
            severity: "error",
          });
        });

      setIsLoading(false);

      delete _jobSiteCaches[data.gallery.id as number];
    } else {
      const { gallery, gallerySettings, galleryIntervalSettings } = data;
      const { _temp, ...dirtyFieldsState } = formState.dirtyFields;

      const editedData = getDirtyFields(dirtyFieldsState, data);

      // these settings is fetch from assigned device, which user might change it when creating gallery
      const { device, deviceSettings } = editedData;

      const hostDirectory = editedData.gallery.externalHostDirectory
        ? _rawTemp.host_directory_prefix +
          editedData.gallery.externalHostDirectory
        : "";

      await firebaseController.Gallery.addGallery(
        currentClient,
        { ...gallery, externalHostDirectory: hostDirectory },
        gallerySettings,
        galleryIntervalSettings,
        { device, deviceSettings },
      )
        .then(() => {
          setIsLoading(false);

          setSnackbarProps({
            open: true,
            content: `Gallery created successfully!`,
          });

          const jobSiteIdParam = searchParams.get("jobSiteId");

          if (jobSiteIdParam) {
            navigate(`/job-sites/${jobSiteIdParam}`);
          } else {
            navigate("../");
          }
        })
        .catch(() => {
          setSnackbarProps({
            open: true,
            content: `Gallery create failed!`,
            severity: "error",
          });
        });
    }
  };

  const handleIntervalSettingsCopy = (day) => {
    const values = getValues(`galleryIntervalSettings.${day}`);

    DAY_NAMES.forEach((day) => {
      setValue(`galleryIntervalSettings.${day}`, values, { shouldDirty: true });
    });

    setIntervalSettingsRenderTrigger((prev) => !prev);
  };

  const deviceOptions = deviceList.map((device) => {
    return { label: device.friendlyName || device.deviceId, value: device.id };
  });

  const clientOptions = useMemo(() => {
    return clientList.map((client) => {
      return { label: client.name, value: client.id };
    });
  }, [clientList]);

  const imageHostOptions = Object.keys(IMAGE_HOST_MAPPER).map((value) => {
    return { value, label: IMAGE_HOST_MAPPER[value] };
  });

  const rotateOptions = useMemo(() => {
    return Object.keys(ROTATE_VALUE_MAPPER).map((value) => {
      return { value: Number(value), label: ROTATE_VALUE_MAPPER[value] };
    });
  }, []);

  const autoWhiteBalanceModeOptions = useMemo(() => {
    return Object.keys(AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR).map((value) => {
      return {
        value: Number(value),
        label: AUTO_WHITE_BALANCE_MODE_NAME_MAPEPR[value],
      };
    });
  }, []);

  const deviceChannelOptions = useMemo(() => {
    return Object.keys(UPDATE_CHANNEL_MAPPER).map((value) => {
      return { value: Number(value), label: UPDATE_CHANNEL_MAPPER[value] };
    });
  }, []);

  const gallerySettingsRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Name",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallery.galleryName", {
              required: true,
              setValueAs: (value) => {
                return _.startCase(value);
              },
            })}
            error={!!_.get(formState.errors, "gallery.galleryName")}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Archived",
        value: (
          <Controller
            name="gallery.archived"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Visible",
        value: (
          <Controller
            name="gallery.visible"
            defaultValue={true}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        settingName: "Image Host",
        value: (
          <Controller
            control={control}
            name={"gallery.galleryImageHost"}
            defaultValue={"0"}
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                select
                disabled={isLoading}
              >
                {imageHostOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        settingName: "External Host Directory",
        value: (
          <Controller
            control={control}
            name="gallery.externalHostDirectory"
            defaultValue={""}
            render={({ field }) => {
              const { ref, ...restField } = field;

              return (
                <Controller
                  control={control}
                  name="_temp.host_directory_prefix"
                  defaultValue={""}
                  render={({ field: hostField }) => {
                    return (
                      <PrefixTextField
                        {...restField}
                        disabled={galleryImageHost === "0" || isLoading}
                        inputRef={ref}
                        prefix={hostField.value}
                        prefixOptions={
                          IMAGE_HOST_DIRECTORY_OPTIONS_MAPPER[galleryImageHost]
                        }
                        onPrefixChange={(prefix) => {
                          hostField.onChange(prefix);
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
        ),
      },
      {
        settingName: "Assigned Device",
        value: (
          <Controller
            control={control}
            name={"gallery.assignedDevice"}
            render={({ field }) => {
              const currentOption =
                deviceOptions.find((o) => o.value === field.value) || null;

              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {galleryImageHost === "2" ? (
                    <TextField
                      {...baseSettingTextFieldProps}
                      {...register("gallery.assignedDevice", {
                        setValueAs: (value) => {
                          return _.startCase(value);
                        },
                      })}
                      disabled={isLoading}
                    />
                  ) : (
                    <>
                      <Autocomplete
                        {...baseSettingAutocompleteProps}
                        disabled={isLoading || galleryImageHost !== "0"}
                        onChange={(e, data) =>
                          field.onChange(data?.value || null)
                        }
                        value={currentOption}
                        options={deviceOptions}
                        renderInput={(params: any) => <TextField {...params} />}
                      />

                      {currentOption && (
                        <SettingLink to={`/devices/${currentOption.value}`} />
                      )}
                    </>
                  )}
                </Box>
              );
            }}
          />
        ),
      },
      {
        settingName: "Client",
        value: (
          <Controller
            control={control}
            name={"_temp.client"}
            rules={{ required: true }}
            render={({ field }) => {
              const currentOption =
                clientOptions.find((o) => o.value === field.value) || null;

              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Autocomplete
                    {...baseSettingAutocompleteProps}
                    disableClearable
                    disabled={isLoading || isEdit}
                    onChange={(e, data) =>
                      field.onChange(data?.value || clientOptions[0].value)
                    }
                    value={currentOption}
                    options={clientOptions}
                    renderInput={(params: any) => (
                      <TextField
                        error={!!_.get(formState.errors, "_temp.client")}
                        {...params}
                      />
                    )}
                  />

                  {currentOption && (
                    <SettingLink to={`/clients/${currentOption.value}`} />
                  )}
                </Box>
              );
            }}
          />
        ),
      },
      {
        settingName: "Job Site",
        value: (
          <Box>
            <Controller
              control={control}
              name={"gallery.jobSite"}
              rules={{ required: true }}
              render={({ field }) => {
                const currentOption =
                  jobSiteOptions.find((o) => o.value === field.value) || null;

                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      disableClearable
                      disabled={isLoading}
                      onChange={(e, data) => {
                        if (isEdit) {
                          setConfirmationModalParams({
                            isOpen: true,
                            title: "Change gallery job site?",
                            content:
                              "Note that this will affect the users who can access this gallery. Are you sure you want to proceed?",
                            onDone: () => {
                              field.onChange(
                                data?.value || jobSiteOptions[0].value,
                              );
                            },
                          });
                        } else {
                          field.onChange(
                            data?.value || jobSiteOptions[0].value,
                          );
                        }
                      }}
                      value={currentOption}
                      options={jobSiteOptions}
                      renderInput={(params: any) => (
                        <TextField
                          error={!!_.get(formState.errors, "gallery.jobSite")}
                          {...params}
                        />
                      )}
                    />
                    {currentOption && (
                      <SettingLink to={`/job-sites/${currentOption.value}`} />
                    )}
                  </Box>
                );
              }}
            />

            {galleryList.length > 0 && (
              <>
                <Divider sx={{ my: 1.5 }} />

                <Box>
                  <Typography
                    sx={{
                      // fontWeight: "bold",
                      fontSize: "12px !important",
                      textDecoration: "underline",
                      lineHeight: 1,
                      mb: 1,
                    }}
                  >
                    Other Galleries:
                  </Typography>

                  <DataChips
                    items={galleryList}
                    labelkey="galleryName"
                    itemLink={(gallery) => `/galleries/${gallery.id}`}
                  />
                </Box>
              </>
            )}
          </Box>
        ),
      },

      {
        settingName: "Accessible Users",
        value: (
          <DataChips
            items={userList}
            labelkey="username"
            itemLink={(user) => `/users/${user.id}`}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      deviceOptions,
      galleryImageHost,
      imageHostOptions,
      isLoading,
      clientOptions,
      jobSiteOptions,
      register,
      setValue,
      currentUserClient,
      isEdit,
      userList,
      galleryList,
    ],
  );

  const locationSettingsRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Address",

        value: (
          <Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Controller
                control={control}
                name={"gallery.address"}
                defaultValue={""}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      disabled={isLoading}
                      disableClearable
                      filterOptions={(x) => x}
                      freeSolo
                      onInputChange={(e, newInputValue) => {
                        field.onChange(newInputValue);
                        getAdressAutoCompleteDebounce(newInputValue);
                      }}
                      onChange={(e, data) => {
                        field.onChange(data.value);

                        setMapMarkerPosition([data.lat, data.lon]);

                        setValue("gallery.pinnedLatitude", data.lat, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                        setValue("gallery.pinnedLongitude", data.lon, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      value={field.value}
                      options={addressOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  );
                }}
              />

              <Button
                size="small"
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: 12,
                  p: 0,
                  color: ({ palette }) =>
                    `${palette.onPrimary.main} !important`,
                }}
                onClick={() => handleAddressSearch()}
                disabled={isLoading || isAddressSearching}
              >
                Search
              </Button>
            </Box>

            <Box
              sx={{
                marginTop: 2,
                height: 300,
              }}
            >
              <Map
                center={[-37.5873527, 145.1229405]}
                markerPosition={mapMarkerPosition}
                mapOnClick={(e, map) => {
                  const { lat, lng } = e.latlng;

                  setMapMarkerPosition([lat, lng]);

                  map.setView([lat, lng], map.getZoom());

                  setValue("gallery.pinnedLatitude", lat.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("gallery.pinnedLongitude", lng.toString(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Box>
          </Box>
        ),
      },
      {
        settingName: "Latitude",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallery.pinnedLatitude", {
              min: -90,
              max: 90,
            })}
            type="number"
            inputProps={{
              ...baseSettingTextFieldProps.inputProps,
              min: -90,
              max: 90,
              step: 0.1,
            }}
            error={!!_.get(formState.errors, "gallery.pinnedLatitude")}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Longitude",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallery.pinnedLongitude", {
              min: -180,
              max: 180,
            })}
            type="number"
            inputProps={{
              ...baseSettingTextFieldProps.inputProps,
              min: -180,
              max: 180,
              step: 0.1,
            }}
            error={!!_.get(formState.errors, "gallery.pinnedLongitude")}
            disabled={isLoading}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      formState,
      isLoading,
      register,
      mapMarkerPosition,
      isAddressSearching,
      addressOptions,
    ],
  );

  const deviceSettingRows: SettingRow[] = useMemo(
    () => [
      {
        id: "gallerySettings.exposureTime",
        settingName: "Exposure Time",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallerySettings.exposureTime", {
              valueAsNumber: true,
            })}
            type="number"
            sx={{ width: 100 }}
            disabled={isLoading}
          />
        ),
      },
      {
        id: "gallerySettings.autoExposure",
        settingName: "Auto Exposure",
        value: (
          <Controller
            name="gallerySettings.autoExposure"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.autoFocus",
        settingName: "Auto Focus",
        value: (
          <Controller
            name="gallerySettings.autoFocus"
            defaultValue={false}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      ...(isShowDeviceSettings
        ? [
            {
              id: "deviceSettings.triggerTwoStep",
              settingName: "Trigger Two Step",
              value: (
                <Controller
                  name="deviceSettings.triggerTwoStep"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
            {
              id: "_temp.isForcedFocus",
              settingName: "Manually Forced Focus",
              value: (
                <Box
                  sx={{
                    display: "flex",
                    // flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Controller
                    name="_temp.isForcedFocus"
                    defaultValue={false}
                    control={control}
                    render={(props) => (
                      <Switch
                        onChange={(e) => props.field.onChange(e.target.checked)}
                        checked={props.field.value}
                        disabled={isLoading}
                        {...baseSettingSwitchProps}
                      />
                    )}
                  />

                  {isForcedFocus && (
                    <TextField
                      {...baseSettingTextFieldProps}
                      {...register("deviceSettings.manualFocusDistance", {
                        valueAsNumber: true,
                      })}
                      type="number"
                      sx={{ width: 100 }}
                      disabled={isLoading}
                    />
                  )}
                </Box>
              ),
            },
            {
              id: "deviceDetails.twoStepFocusValue",
              settingName: "Two Step Focus Value",
              value: (
                <Controller
                  name="deviceDetails.twoStepFocusValue"
                  control={control}
                  render={(props) => (
                    <Typography sx={{ fontSize: 14 }}>
                      {props.field.value}
                    </Typography>
                  )}
                />
              ),
            },
          ]
        : []),

      {
        id: "gallerySettings.autoWhiteBalance",
        settingName: "Auto White Balance",
        value: (
          <Controller
            name="gallerySettings.autoWhiteBalance"
            defaultValue={true}
            control={control}
            render={(props) => (
              <Switch
                onChange={(e) => props.field.onChange(e.target.checked)}
                checked={props.field.value}
                disabled={isLoading}
                {...baseSettingSwitchProps}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.autoWhiteBalanceMode",
        settingName: "Auto White Balance Mode",
        value: (
          <Controller
            control={control}
            name="gallerySettings.autoWhiteBalanceMode"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || rotateOptions[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
                sx={{ width: 100 }}
              >
                {autoWhiteBalanceModeOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      ...(isShowDeviceSettings
        ? [
            {
              id: "deviceSettings.setupMode",
              settingName: "Setup Mode",
              value: (
                <Controller
                  name="deviceSettings.setupMode"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
          ]
        : []),
      {
        id: "gallerySettings.rotate",
        settingName: "Rotate",
        value: (
          <Controller
            control={control}
            name="gallerySettings.rotate"
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value || rotateOptions[0].value}
                select
                disabled={isLoading}
                SelectProps={{
                  MenuProps: {
                    sx: { height: "300px" },
                  },
                }}
                sx={{ width: 100 }}
              >
                {rotateOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        id: "gallerySettings.gain",
        settingName: "Gain",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("gallerySettings.gain", { valueAsNumber: true })}
            type="number"
            sx={{ width: 100 }}
            disabled={isLoading}
          />
        ),
      },
      {
        id: "gallerySettings.brightness",
        settingName: "Brightness",
        value: (
          <Controller
            control={control}
            name="gallerySettings.brightness"
            render={({ field }) => (
              <SliderInput
                min={BRIGHTNESS_RANGE[0]}
                max={BRIGHTNESS_RANGE[1]}
                step={0.01}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.contrast",
        settingName: "Contrast",
        value: (
          <Controller
            control={control}
            name="gallerySettings.contrast"
            render={({ field }) => (
              <SliderInput
                min={CONTRAST_RANGE[0]}
                max={CONTRAST_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.saturation",
        settingName: "Saturation",
        value: (
          <Controller
            control={control}
            name="gallerySettings.saturation"
            render={({ field }) => (
              <SliderInput
                min={SATURATION_RANGE[0]}
                max={SATURATION_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      {
        id: "gallerySettings.sharpness",
        settingName: "Sharpness",
        value: (
          <Controller
            control={control}
            name="gallerySettings.sharpness"
            render={({ field }) => (
              <SliderInput
                min={SHARPNESS_RANGE[0]}
                max={SHARPNESS_RANGE[1]}
                step={0.1}
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                disabled={isLoading}
              />
            )}
          />
        ),
      },
      ...(isShowDeviceSettings
        ? [
            {
              id: "deviceSettings.maxSleepTime",
              settingName: "Max Sleep Time",
              value: (
                <TextField
                  {...baseSettingTextFieldProps}
                  {...register("deviceSettings.maxSleepTime", {
                    valueAsNumber: true,
                  })}
                  disabled={isLoading}
                  type="number"
                  sx={{ width: 100 }}
                />
              ),
            },
            {
              id: "device.deg90Camera",
              settingName: "90 Degree Camera",
              value: (
                <Controller
                  name="device.deg90Camera"
                  defaultValue={true}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.channel",
              settingName: "Update Channel",
              value: (
                <Controller
                  control={control}
                  name={"deviceSettings.channel"}
                  defaultValue={"0"}
                  render={({ field }) => (
                    <TextField
                      {...baseSettingTextFieldProps}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      select
                      disabled={isLoading}
                    >
                      {deviceChannelOptions.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.performUpdate",
              settingName: "Perform Update",
              value: (
                <Controller
                  name="deviceSettings.performUpdate"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <Switch
                      onChange={(e) => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      disabled={isLoading}
                      {...baseSettingSwitchProps}
                    />
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.updateCheckFrom",
              settingName: "Update Check Start Time",
              value: (
                <Controller
                  control={control}
                  name="deviceSettings.updateCheckFrom"
                  render={({ field }) => (
                    <TextField
                      {...baseSettingTextFieldProps}
                      sx={{
                        width: 100,
                      }}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || UPDATE_TIME_OPTIONS[0].value}
                      select
                      disabled={isLoading}
                      SelectProps={{
                        MenuProps: {
                          sx: { height: "300px" },
                        },
                      }}
                    >
                      {UPDATE_TIME_OPTIONS.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ),
            },
            {
              id: "deviceSettings.updateCheckTo",
              settingName: "Update Check Finish Time",
              value: (
                <Controller
                  control={control}
                  name="deviceSettings.updateCheckTo"
                  render={({ field }) => (
                    <TextField
                      {...baseSettingTextFieldProps}
                      sx={{
                        width: 100,
                      }}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || UPDATE_TIME_OPTIONS[0].value}
                      select
                      disabled={isLoading}
                      SelectProps={{
                        MenuProps: {
                          sx: { height: "300px" },
                        },
                      }}
                    >
                      {UPDATE_TIME_OPTIONS.map((option) => (
                        <MenuItem dense key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              ),
            },
          ]
        : []),
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      control,
      isLoading,
      register,
      rotateOptions,
      autoWhiteBalanceModeOptions,
      deviceChannelOptions,
      isForcedFocus,
      isShowDeviceSettings,
      deviceSettingsRenderTrigger,
    ],
  );

  const intervalSettingsRows: IntervalSettingsRow[] = useMemo(() => {
    return DAY_NAMES.flatMap((day) => {
      const isActive = getValues(`galleryIntervalSettings.${day}.active`);
      const isBurst = getValues(`galleryIntervalSettings.${day}.burst`);

      return [
        {
          id: day,
          active: (
            <Controller
              name={`galleryIntervalSettings.${day}.active`}
              defaultValue={false}
              control={control}
              render={({ field }) => (
                <Switch
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                  disabled={isLoading}
                  {...baseSettingSwitchProps}
                />
              )}
            />
          ),
          day: (
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
              sx={{
                color: ({ palette }) =>
                  isActive ? "" : alpha(palette.onSurface.main, 0.4),
                columnGap: 2,
              }}
            >
              <Grid item>{day}</Grid>
              <Grid item xs={12} md="auto">
                <FormControlLabel
                  label="Burst"
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: {
                          xs: 12,
                          sm: 14,
                        },
                      },
                    },
                  }}
                  sx={{
                    margin: 0,
                    mr: 1,
                  }}
                  control={
                    <Controller
                      name={`galleryIntervalSettings.${day}.burst`}
                      control={control}
                      defaultValue={false}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          sx={{
                            padding: 0,
                            mr: {
                              xs: 0.5,
                              sm: 1,
                            },
                          }}
                          disabled={isLoading || !isActive}
                          size="small"
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>
          ),
          startTime: (
            <Controller
              control={control}
              name={`galleryIntervalSettings.${day}.timeFrom`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_TIME_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          finishTime: (
            <Controller
              control={control}
              name={`galleryIntervalSettings.${day}.timeTo`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_TIME_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          interval: (
            <Controller
              control={control}
              name={`galleryIntervalSettings.${day}.timeBetweenShots`}
              render={({ field }) => (
                <TextField
                  {...baseSettingTextFieldProps}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value || INTERVAL_OPTIONS[1].value}
                  select
                  disabled={isLoading || !isActive}
                  SelectProps={{
                    MenuProps: {
                      sx: { height: "300px" },
                    },
                  }}
                >
                  {INTERVAL_OPTIONS.map((option) => (
                    <MenuItem dense key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ),
          action: (
            <Typography
              onClick={() => handleIntervalSettingsCopy(day)}
              sx={{
                width: "max-content",
                fontSize: "12px",
                color: ({ palette }) => palette.primary.main,
                cursor: "pointer",
                ":hover": {
                  color: ({ palette }) =>
                    getContrastShade(palette.primary, "light"),
                },
              }}
            >
              Copy To All
            </Typography>
          ),
        },
        ...(isBurst
          ? [
              {
                id: `${day}_burst`,
                day: (
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      sx={{
                        color: ({ palette }) =>
                          isActive ? "" : alpha(palette.onSurface.main, 0.4),
                      }}
                    >
                      <Typography variant="caption">{`${day}`}</Typography>
                      <Typography
                        variant="caption"
                        fontStyle={"italic"}
                      >{` (Burst Mode)`}</Typography>
                    </Grid>
                  </Grid>
                ),
                startTime: (
                  <Controller
                    control={control}
                    name={`galleryIntervalSettings.${day}.burstTimeFrom`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        onChange={(e) => field.onChange(e.target.value)}
                        defaultValue={getValues(
                          `galleryIntervalSettings.${day}.burstTimeFrom`,
                        )}
                        value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {INTERVAL_TIME_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                finishTime: (
                  <Controller
                    control={control}
                    name={`galleryIntervalSettings.${day}..burstTimeTo`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || INTERVAL_TIME_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {INTERVAL_TIME_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                interval: (
                  <Controller
                    control={control}
                    name={`galleryIntervalSettings.${day}.burstTimeBetweenShots`}
                    render={({ field }) => (
                      <TextField
                        {...baseSettingTextFieldProps}
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || BURST_INTERVAL_OPTIONS[0].value}
                        select
                        disabled={isLoading || !isActive || !isBurst}
                        SelectProps={{
                          MenuProps: {
                            sx: { height: "300px" },
                          },
                        }}
                      >
                        {BURST_INTERVAL_OPTIONS.map((option) => (
                          <MenuItem
                            dense
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                ),
                action: <></>,
              },
            ]
          : []),
      ];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, isLoading, intervalSettingsRenderTrigger]);

  const headerRef = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(headerRef, `-100px`);

  const breadcrumbs = useMemo(() => {
    if (!initGallery) {
      return [];
    }

    return [
      {
        label: "Galleries",
        path: "/galleries",
      },
      isEdit
        ? {
            label: initGallery.galleryName || initGallery.id?.toString() || "",
          }
        : {
            label: "New",
          },
    ];
  }, [initGallery, isEdit]);

  const [galleryRemoveModalOpen, setGalleryRemoveModalParams] = useState<{
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const handleGalleryRemove = async () => {
    setIsLoading(true);

    if (initGallery) {
      await getFirebaseController()
        .Gallery.deleteGallery(initGallery.id)
        .then((status) => {
          if (status) {
            navigate("/galleries");

            setSnackbarProps({
              open: true,
              content: `Gallery deleted successfully!`,
            });
          } else {
            setSnackbarProps({
              open: true,
              content: `Gallery delete failed!`,
              severity: "error",
            });
          }
        });
    }

    setIsLoading(false);
  };

  const isFirebaseGallery = useMemo(() => {
    return galleryImageHost === "0";
  }, [galleryImageHost]);

  return (
    <Box>
      <Navbar title="Gallery Dashboard" />

      {initGallery && (
        <ConfirmationModal
          title={`Remove this gallery?`}
          message={`Are you sure you want to remove gallery ${initGallery.galleryName}? This process cannot be undone.`}
          open={galleryRemoveModalOpen.isOpen}
          onDone={() => handleGalleryRemove()}
          onClose={() =>
            setGalleryRemoveModalParams({
              isOpen: false,
            })
          }
        />
      )}

      <SettingConfirmationModal
        {...confirmationModalParams}
        onClose={() => {
          setConfirmationModalParams((prev) => {
            return {
              ...prev,
              isOpen: false,
              title: "",
              content: "",
            };
          });
        }}
      />

      <DashboardEditContainer
        breadcrumbs={breadcrumbs}
        title={
          isEdit
            ? initGallery?.galleryName || "-"
            : ` New Gallery ${galleryName ? `- ${galleryName}` : ""}`
        }
        subtitle="Fill in the gallery's info and press save when done."
        onSave={handleSubmit(handleSave, (errors) => {
          if (
            _.get(errors, "gallery.galleryName") ||
            _.get(errors, "_temp.client") ||
            _.get(errors, "gallery.jobSites")
          ) {
            setSnackbarProps({
              open: true,
              content: `Gallery name, client and job site cannot be empty.`,
              severity: "error",
            });
          } else if (
            _.get(errors, "gallery.pinnedLatitude") ||
            _.get(errors, "gallery.pinnedLongitude")
          ) {
            setSnackbarProps({
              open: true,
              content: `Invalid latitude or longitude.`,
              severity: "error",
            });
          }
        })}
        onDelete={
          isEdit
            ? () => {
                setGalleryRemoveModalParams({
                  isOpen: true,
                });
              }
            : undefined
        }
        isLoading={isLoading}
      >
        <Grid container direction="column" wrap="nowrap" gap={4}>
          <Grid item>
            <SettingsTable
              title={"Gallery Settings"}
              rows={gallerySettingsRow}
              columns={columns}
              headerActions={
                isEdit && initGallery ? (
                  <>
                    <CopyLinkButton
                      copyValue={getFirebaseController().getFunctionUrl(
                        `latestImage/galleries/${initGallery.id}`,
                      )}
                      disabled={isLoading}
                      color="secondary"
                      endIcon={<LinkIcon />}
                      sx={{
                        color: ({ palette }) => palette.onSecondary.main,
                        border: ({ palette }) =>
                          alpha(palette.onSecondary.main, 0.3),
                        mr: 1,
                      }}
                    >
                      Latest Image
                    </CopyLinkButton>

                    <LinkWrapper to={`image-viewer`}>
                      <Button
                        disabled={isLoading}
                        variant="contained"
                        size="small"
                        disableElevation
                        sx={{
                          fontSize: 12,
                          color: ({ palette }) => palette.onPrimary.main,
                          textTransform: "none",
                          border: ({ palette }) =>
                            alpha(palette.onPrimary.main, 0.3),
                        }}
                        endIcon={
                          <OpenInNew sx={{ fontSize: "14px !important" }} />
                        }
                      >
                        Go To Gallery
                      </Button>
                    </LinkWrapper>
                  </>
                ) : (
                  <></>
                )
              }
            />
          </Grid>

          <Grid item>
            <SettingsTable
              title={"Location"}
              rows={locationSettingsRow}
              columns={columns}
            />
          </Grid>

          {isFirebaseGallery && (
            <>
              <Grid item>
                <SettingsTable
                  title={"Device Settings"}
                  rows={deviceSettingRows}
                  columns={columns}
                />
              </Grid>

              <Grid item>
                <SettingsTable
                  title={"Shooting Times"}
                  rows={intervalSettingsRows}
                  columns={intervalSettingsColumns}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DashboardEditContainer>
    </Box>
  );
};

export default GalleryEditWindow;
