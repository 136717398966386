import { createTheme, PaletteColor, ThemeOptions } from "@mui/material";

import functions from "./functions";

import _ from "lodash";
import createPalette from "@mui/material/styles/createPalette";
import tinycolor from "tinycolor2";

const createCssConst = (obj, parentKey) => {
  let result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey + "-" + _.kebabCase(key);

      if (typeof obj[key] === "object") {
        const nestedObj = createCssConst(obj[key], newKey);
        result = { ...result, ...nestedObj };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};

const createCssVariables = (theme) => ({
  body: {
    ...createCssConst(theme.palette.primary, "--mui-pallette-primary"),
    ...createCssConst(theme.palette.secondary, "--mui-pallette-secondary"),
    ...createCssConst(theme.palette.dark, "--mui-pallette-dark"),
    ...createCssConst(theme.palette.text, "--mui-pallette-text"),
  },
});

const theme = createTheme();

export const getContrastShade = (
  palette: PaletteColor,
  shade?: "light" | "dark",
) => {
  const ratios = {
    light: tinycolor.readability(palette.light, palette.main),
    dark: tinycolor.readability(palette.dark, palette.main),
  };

  if (shade && ratios[shade] > 1.5) {
    return palette[shade];
  }

  if (ratios.light > ratios.dark) {
    return palette.light;
  } else {
    return palette.dark;
  }
};

export const createCustomPaletteColor = (color) => {
  return theme.palette.augmentColor({
    color: {
      main: color,
    },
  });
};

const reliveItTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: "smooth",
        },
        body: {
          // background: "none",
          // backgroundColor:  "#383838",
        },
        "*, *::before, *::after": {
          margin: 0,
          padding: 0,
        },
        "a, a:link, a:visited": {
          textDecoration: "none !important",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down("md")]: {
            maxWidth: "900px !important",
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },

  palette: {
    primary: { main: "#08b0d9" },
    secondary: { main: "#383838" },
    tertiary: createCustomPaletteColor("#e7e7e7"),
    surface: createCustomPaletteColor("#ffffff"),
    background: { default: "#383838", paper: "#ffffff" },

    onPrimary: createCustomPaletteColor("#ffffff"),
    onSecondary: createCustomPaletteColor("#ffffff"),
    onTertiary: createCustomPaletteColor("#383838"),
    onSurface: createCustomPaletteColor("#383838"),
    onBackground: createCustomPaletteColor("#ffffff"),

    light: { main: "#dedbdc" },

    info: { main: "#057b97" },
    success: { main: "#08d99a" },
    warning: { main: "#ffad61" },
    error: { main: "#ff5f5f" },

    white: { main: "#FFFFFF" },

    chart: { main: "#285A64", dark: "#143138" },
  },
  borders: {
    borderRadius: {
      sm: "2px",
      md: "4px",
      lg: "8px",
      pill: "999999px",
      circle: "100%",
    },
  },
  customConfig: {
    imageViewerNavbarHeight: "50px",
    navbarHeight: "50px",
    subNavbarHeight: "45px",
  },
  functions: {
    ...functions,
  },
};

export default reliveItTheme;
