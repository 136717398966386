import { useState, useEffect, useRef, useMemo } from "react";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { currentClientState, currentJobSitesState } from "states/auth";
import { Controller, useForm } from "react-hook-form";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

import { useSnackbar } from "context/Snackbar/SnackbarContext";

import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Autocomplete,
  Chip,
  createTheme,
  Button,
  alpha,
} from "@mui/material";

import { getDirtyFields } from "Windows/Device/DeviceEditWindow";

import Navbar from "components/Layouts/Navbar";
import DashboardEditContainer, {
  baseSettingAutocompleteProps,
  baseSettingTextFieldProps,
} from "components/Dashboard/DashboardEditContainer";
import SettingsTable, {
  Column,
  columns,
  SettingRow,
  JobSiteRows,
  SettingsTableHeaderButton,
  SettingsTableHeaderTextField,
  UserRow,
} from "components/Dashboard/SettingsTable";
import ConfirmationModal from "components/BaseModal/ConfirmationModal";
import BaseModal, {
  ModalActions,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "components/BaseModal/BaseModal";
import DataChips from "components/Dashboard/DataChips";

import { getDefaultClient } from "database/DataDefaultValues";
import { Client, GalleryV2, JobSite, User, UserRole } from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import _ from "lodash";

import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import { orderByIgnoreCase } from "utils/display";
import { SelectOption } from "utils/input";
import { getDifferences } from "utils/form";
import {
  ArchiveRounded,
  Close,
  Delete,
  Edit,
  OpenInNew,
  Refresh,
  Settings,
} from "@mui/icons-material";
import { ColorPickerInput } from "components/ColorPicker/ColorPicker";
import tinycolor from "tinycolor2";
import Dropzone from "react-dropzone";
import prettyBytes from "pretty-bytes";
import reliveItTheme, { getContrastShade } from "theme/reliveItTheme";

export const jobSiteColumns: Column[] = [
  { id: "name", label: "Name", width: "20%" },
  { id: "physicalAddress", label: "Physical Address", width: "40%" },
  { id: "associatedClients", label: "Associated Clients", width: "20%" },
  { id: "actions", label: "Actions", align: "center", width: "10%" },
];

const userColumns: Column[] = [
  { id: "name", label: "Username", width: "40%" },
  { id: "jobSites", label: "Job Sites", align: "center", width: "50%" },
  { id: "actions", label: "Actions", align: "center", width: "10%" },
];

type JobSiteModalTab = 0 | 1 | 2 | 3 | 4;
type userModalTab = 0 | 1 | 2 | 3 | 4;

type JobSiteModalParams = {
  isOpen: boolean;
  tab: JobSiteModalTab;
  id: JobSite["id"] | null;
};

type UserModalParams = {
  isOpen: boolean;
  tab: userModalTab;
  id: User["id"] | null;
};

type ThemeModalParams = {
  isOpen: boolean;
};

const _siteUserRolesCaches: { [key: number]: UserRole[] } = {};
const _siteUserCaches: { [key: number]: User[] } = {};

const UserModal = ({
  userModalParams,
  initUsers,
  onClose,
  client,
  initJobSite,
  usersLookup,
  userToUserRoleLookup,
  jobSitesLookup,
}: {
  userModalParams: UserModalParams;
  initUsers: (clientId: number) => void;
  onClose: () => void;
  client: Client;
  initJobSite: (ids: number[], isUpdateClient?: boolean) => void;
  usersLookup: { [userId: number]: User };
  jobSitesLookup: { [siteId: number]: JobSite };
  userToUserRoleLookup: { [userId: number]: UserRole };
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [tab, setTab] = useState<userModalTab>(0);

  const { setSnackbarProps } = useSnackbar();

  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [editingUserRole, setEditingUserRole] = useState<UserRole | null>(null);

  const [jobSitesListCache] = useAtom(jobSitesListCacheState);

  const [activeOption, archivedOptions] = useMemo(() => {
    const active: SelectOption<number>[] = [];
    const archived: SelectOption<number>[] = [];

    if (client) {
      jobSitesListCache.forEach((site) => {
        if (site.associatedClients.includes(client.id as number)) {
          (site.archived ? archived : active).push({
            label: site.name,
            value: site.id as number,
          });
        }
      });
    }

    return [active, archived];
  }, [jobSitesListCache, client]);

  const { control, getValues, reset, formState } = useForm({});

  useEffect(() => {
    if (userModalParams.isOpen) {
      const userId = userModalParams.id;

      if (userId) {
        const user = usersLookup[userId];
        const userRole = userToUserRoleLookup[userId];

        setEditingUser(user);
        setEditingUserRole(userRole);

        const activeSiteIds: number[] = [];
        const archivedSiteIds: number[] = [];

        userRole.accessableJobSites.forEach((siteId) => {
          const site = jobSitesLookup[siteId as number];

          (site.archived ? archivedSiteIds : activeSiteIds).push(
            siteId as number,
          );
        });

        reset({
          _temp: {
            activeSiteIds,
            archivedSiteIds,
          },
          _initial: {
            activeSiteIds,
            archivedSiteIds,
          },
        });

        setTab(1);
      } else {
        setTab(userModalParams.tab);
      }
    } else {
      setTab(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModalParams]);

  const handleDone = async () => {
    setIsLoading(true);

    const firebaseController = getFirebaseController();

    switch (tab) {
      case 1: {
        // edit tab

        const editedFields = getDirtyFields(formState.dirtyFields, getValues());

        if (editingUserRole && !_.isEmpty(editedFields)) {
          const _initial = getValues()._initial;
          const { _temp } = editedFields;

          const newAccessableJobSites: number[] = [
            ...(_temp.activeSiteIds
              ? _temp.activeSiteIds
              : _initial.activeSiteIds),
            ...(_temp.archivedSiteIds
              ? _temp.archivedSiteIds
              : _initial.archivedSiteIds),
          ];

          await firebaseController.User.updateUserRole(
            editingUserRole.id as number,
            {
              accessableJobSites: newAccessableJobSites,
            },
          )
            .then(async () => {
              await initUsers(client.id as number);

              setSnackbarProps({
                open: true,
                content: `Job site updated successfully!`,
              });

              if (userModalParams.id) {
                onClose();
              }
            })
            .catch((err) => {
              console.error(err);

              setSnackbarProps({
                open: true,
                content: `Job site update failed!`,
                severity: "error",
              });
            });
        }

        break;
      }

      default:
        break;
    }

    setIsLoading(false);
  };

  const content = {
    0: <></>,
    1: (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
        <Box>
          <Typography sx={{ fontSize: 14, mb: 0.5 }}>
            Active Job Sites
          </Typography>

          <Controller
            control={control}
            name={"_temp.activeSiteIds"}
            defaultValue={[]}
            render={({ field }) => {
              return (
                <Autocomplete
                  slotProps={{
                    ...baseSettingAutocompleteProps.slotProps,
                  }}
                  size="small"
                  multiple
                  disabled={isLoading}
                  onChange={(e, data) =>
                    field.onChange(data.map((d) => d.value as number) || [])
                  }
                  value={field.value.map(
                    (v) => activeOption.find((o) => o.value === v) || v,
                  )}
                  renderTags={(values, getTagProps) => {
                    return values.map((value, index) => (
                      <Chip
                        color="secondary"
                        size="small"
                        label={value?.label || value}
                        sx={({ palette }) => ({
                          borderRadius: 0.5,
                          backgroundColor: palette.secondary.light,
                          color: `${palette.white.main} !important`,
                        })}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                  options={activeOption}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              );
            }}
          />
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: 14,
              mb: 0.5,
            }}
          >
            Archived Job Sites
          </Typography>
          <Controller
            control={control}
            name={"_temp.archivedSiteIds"}
            defaultValue={[]}
            render={({ field }) => {
              return (
                <Autocomplete
                  slotProps={{
                    ...baseSettingAutocompleteProps.slotProps,
                  }}
                  size="small"
                  multiple
                  disabled={isLoading}
                  onChange={(e, data) =>
                    field.onChange(data.map((d) => d.value as number) || [])
                  }
                  value={field.value.map(
                    (v) => archivedOptions.find((o) => o.value === v) || v,
                  )}
                  renderTags={(values, getTagProps) => {
                    return values.map((value, index) => (
                      <Chip
                        color="secondary"
                        size="small"
                        label={value?.label || value}
                        sx={({ palette }) => ({
                          borderRadius: 0.5,
                          backgroundColor: palette.secondary.light,
                          color: `${palette.white.main} !important`,
                        })}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                  options={archivedOptions}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              );
            }}
          />
        </Box>
      </Box>
    ),
  }[tab];

  return (
    <BaseModal open={userModalParams.isOpen} onClose={onClose}>
      <ModalHeader
        title={
          {
            1: `Add Job Site to User "${editingUser?.username}"`,
          }[tab]
        }
      />

      <ModalContent>{content}</ModalContent>

      <ModalFooter>
        <ModalActions
          disabled={isLoading}
          onDone={handleDone}
          onCancel={onClose}
        />
      </ModalFooter>
    </BaseModal>
  );
};

const ThemeModal = ({
  themeModalParams,
  onClose,
  client,
  initClientEdit,
}: {
  themeModalParams: ThemeModalParams;
  onClose: () => void;
  client: Client;
  initClientEdit: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [userClient, setUserClient] = useAtom(currentClientState);

  const [logoSrc, setLogoSrc] = useState<string>("");
  const [uploadLogoFile, setUploadLogoFile] = useState<File | null>(null);

  const handleDone = async (data) => {
    setIsLoading(true);

    if (client && userClient) {
      data.settings.logoUrl = logoSrc;

      if (uploadLogoFile) {
        await getFirebaseController()
          .uploadFile(uploadLogoFile, `ClientLogos/${client.id}`)
          .then((url) => {
            data.settings.logoUrl = url;
          });
      }

      const updatedClient = await getFirebaseController().Client.updateClient(
        client.id as number,
        {
          settings: data.settings,
        },
        undefined,
        { returnDocument: true },
      );

      if (updatedClient) {
        initClientEdit();

        if (userClient.id === updatedClient.id) {
          setUserClient(updatedClient);
        }

        onClose();
      }
    }

    setIsLoading(false);
  };

  const { palette: defaultPalette } = createTheme(reliveItTheme);

  const setDefaultTheme = () => {
    setLogoSrc("");

    reset({
      settings: {
        logoUrl: "",

        primaryColor: tinycolor(defaultPalette.primary.main).toHexString(),
        secondaryColor: tinycolor(defaultPalette.secondary.main).toHexString(),
        tertiaryColor: tinycolor(defaultPalette.tertiary.main).toHexString(),

        backgroundColor: tinycolor(
          defaultPalette.background.default,
        ).toHexString(),
        surfaceColor: tinycolor(defaultPalette.surface.main).toHexString(),

        infoColor: tinycolor(defaultPalette.info.main).toHexString(),
        successColor: tinycolor(defaultPalette.success.main).toHexString(),
        warningColor: tinycolor(defaultPalette.warning.main).toHexString(),
        errorColor: tinycolor(defaultPalette.error.main).toHexString(),

        onPrimaryColor: tinycolor(defaultPalette.onPrimary.main).toHexString(),
        onSecondaryColor: tinycolor(
          defaultPalette.onSecondary.main,
        ).toHexString(),
        onTertiaryColor: tinycolor(
          defaultPalette.onTertiary.main,
        ).toHexString(),
        onBackgroundColor: tinycolor(
          defaultPalette.onBackground.main,
        ).toHexString(),
        onSurfaceColor: tinycolor(defaultPalette.onSurface.main).toHexString(),
      },
    });
  };

  useEffect(() => {
    if (themeModalParams.isOpen && client) {
      const { settings } = client;

      if (settings) {
        setLogoSrc(settings.logoUrl);

        reset({
          settings,
        });
      } else {
        setDefaultTheme();
      }
    }

    return () => {
      setUploadLogoFile(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeModalParams, client]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({});

  const colorSettings = [
    [
      {
        title: "Primary Color",
        controllerName: "settings.primaryColor",
        default: defaultPalette.primary.main,
      },
      {
        title: "Secondary Color",
        controllerName: "settings.secondaryColor",
        default: defaultPalette.secondary.main,
      },
      {
        title: "Tertiary Color",
        controllerName: "settings.tertiaryColor",
        default: defaultPalette.tertiary.main,
      },
    ],
    [
      {
        title: "On Primary Color",
        controllerName: "settings.onPrimaryColor",
        default: defaultPalette.onPrimary.main,
      },
      {
        title: "On Secondary Color",
        controllerName: "settings.onSecondaryColor",
        default: defaultPalette.onSecondary.main,
      },
      {
        title: "On Tertiary Color",
        controllerName: "settings.onTertiaryColor",
        default: defaultPalette.onTertiary.main,
      },
    ],
    [
      {
        title: "Background Color",
        controllerName: "settings.backgroundColor",
        default: defaultPalette.background.default,
      },
      {
        title: "Surface Color",
        controllerName: "settings.surfaceColor",
        default: defaultPalette.surface.main,
      },
    ],
    [
      {
        title: "On Background Color",
        controllerName: "settings.onBackgroundColor",
        default: defaultPalette.onBackground.main,
      },
      {
        title: "On Surface Color",
        controllerName: "settings.onSurfaceColor",
        default: defaultPalette.onSurface.main,
      },
    ],
    [
      {
        title: "Info Color",
        controllerName: "settings.infoColor",
        default: defaultPalette.info.main,
      },
      {
        title: "Success Color",
        controllerName: "settings.successColor",
        default: defaultPalette.success.main,
      },
      {
        title: "Warning Color",
        controllerName: "settings.warningColor",
        default: defaultPalette.warning.main,
      },
      {
        title: "Error Color",
        controllerName: "settings.errorColor",
        default: defaultPalette.error.main,
      },
    ],
    // [
    //   { title: "On Info Color", controllerName: "settings.onInfoColor" },
    //   {
    //     title: "On Success Color",
    //     controllerName: "settings.onSuccessColor",
    //   },
    //   {
    //     title: "On Warning Color",
    //     controllerName: "settings.onWarningColor",
    //   },
    //   { title: "On Error Color", controllerName: "settings.onErrorColor" },
    // ],
  ];

  return (
    <BaseModal open={themeModalParams.isOpen} onClose={onClose}>
      <ModalHeader title={"Customs Theme"} />

      <ModalContent>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  lineHeight: 1,
                  mb: 1,
                }}
              >
                Logo:
                {uploadLogoFile && (
                  <Box
                    component={"span"}
                    sx={{
                      fontWeight: "regular",
                      fontSize: 12,
                      lineHeight: 1,
                    }}
                  >
                    {` ${uploadLogoFile.name} - ${prettyBytes(
                      uploadLogoFile.size,
                    )}`}
                  </Box>
                )}
              </Typography>

              <Box>
                <Dropzone
                  accept={{
                    "image/*": [],
                  }}
                  maxFiles={1}
                  onDrop={(acceptedFiles) =>
                    setUploadLogoFile(acceptedFiles[0])
                  }
                  disabled={isLoading}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <Box
                        {...getRootProps()}
                        sx={{
                          border: ({ palette }) =>
                            `1px dashed ${alpha(palette.onSurface.main, 0.3)}`,
                          cursor: isLoading ? "auto" : "pointer",
                          height: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",

                          background: ({ palette }) =>
                            isLoading
                              ? alpha(palette.onSurface.main, 0.1)
                              : alpha(palette.tertiary.main, 0.3),

                          ":hover": {
                            background: ({ palette }) =>
                              alpha(palette.tertiary.main, 0.6),
                            border: ({ palette }) =>
                              `1px dashed ${
                                isLoading
                                  ? alpha(palette.onSurface.main, 0.2)
                                  : palette.primary.main
                              }`,
                          },
                        }}
                      >
                        <input {...getInputProps()} />

                        {uploadLogoFile ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              p: 2,
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Box
                              component={"img"}
                              width={"100%"}
                              height={"100%"}
                              src={URL.createObjectURL(uploadLogoFile)}
                              sx={{
                                cursor: "pointer",
                                objectFit: "contain",
                              }}
                            />

                            <IconButton
                              size="small"
                              sx={{ ml: 0.5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setUploadLogoFile(null);
                              }}
                            >
                              <Close
                                sx={{
                                  fontSize: 16,
                                }}
                              />
                            </IconButton>
                          </Box>
                        ) : logoSrc ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              p: 2,
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <Box
                              component={"img"}
                              width={"100%"}
                              height={"100%"}
                              src={logoSrc}
                              sx={{
                                cursor: "pointer",
                                objectFit: "contain",
                              }}
                            />

                            <IconButton
                              size="small"
                              sx={{ ml: 0.5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setLogoSrc("");
                              }}
                            >
                              <Close
                                sx={{
                                  fontSize: 16,
                                }}
                              />
                            </IconButton>
                          </Box>
                        ) : (
                          <Typography
                            sx={{ fontSize: 12, textAlign: "center" }}
                          >
                            Click to upload image file, or drop file here
                          </Typography>
                        )}
                      </Box>
                    );
                  }}
                </Dropzone>
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: "100%", mt: 2 }}>
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: "bold",
                lineHeight: 1,
                mb: 1,
              }}
            >
              Palette:
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                background: ({ palette }) => alpha(palette.tertiary.main, 0.3),
                border: ({ palette }) => alpha(palette.onSurface.main, 0.1),
                borderRadius: 1,
                py: 2,
                px: 2,
              }}
            >
              {colorSettings.map((row, rowIndex) => {
                return (
                  <Grid key={rowIndex} container wrap="wrap" spacing={2}>
                    {row.map((item, itemIndex) => {
                      return (
                        <Grid
                          key={item.controllerName}
                          item
                          xs={6}
                          sm={3}
                          minWidth={"min-content"}
                          sx={{
                            ":hover": {
                              color: ({ palette }) =>
                                alpha(
                                  getContrastShade(palette.onSurface, "light"),
                                  1,
                                ),

                              ".reset-color-btn": {
                                opacity: 1,
                              },
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                              mb: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                lineHeight: 1,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.title}:
                            </Typography>

                            <IconButton
                              className="reset-color-btn"
                              sx={{ p: 0, opacity: 0 }}
                              size="small"
                              onClick={() => {
                                setValue(
                                  item.controllerName,
                                  tinycolor(item.default).toHexString(),
                                  {
                                    shouldDirty: true,
                                  },
                                );
                              }}
                            >
                              <Refresh sx={{ fontSize: 14 }} />
                            </IconButton>
                          </Box>

                          <Box
                            sx={{
                              ml: 0.5,
                            }}
                          >
                            <Controller
                              control={control}
                              name={item.controllerName}
                              defaultValue={"#000000"}
                              render={({ field }) => {
                                return (
                                  <ColorPickerInput
                                    color={field.value}
                                    onChange={field.onChange}
                                  />
                                );
                              }}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Box>
      </ModalContent>

      <ModalFooter>
        <ModalActions
          disabled={isLoading}
          onDone={handleSubmit(handleDone)}
          onCancel={onClose}
          onDoneLabel="Apply"
          actions={
            <Button
              sx={{ mr: "auto" }}
              size="small"
              color="secondary"
              variant="contained"
              disableElevation
              onClick={setDefaultTheme}
            >
              Reset
            </Button>
          }
        />
      </ModalFooter>
    </BaseModal>
  );
};

const ClientEditWindow = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [initialClient, setInitialClient] = useState<Client | null>(null);
  const [clientName, setClientName] = useState<string>("");

  const [jobSiteList, setJobSiteList] = useState<JobSite[]>([]);
  const [displayJobSiteList, setDisplayJobSiteList] = useState<JobSite[]>([]);

  const [userList, setUserList] = useState<User[]>([]);
  const [displayUserList, setDisplayUserList] = useState<User[]>([]);
  const [userToUserRoleLookup, setUserToUserRoleLookup] = useState<{
    [userId: number]: UserRole;
  }>({});

  const [galleryList, setGalleryList] = useState<GalleryV2[]>([]);

  const setClientListCache = useSetAtom(clientListCacheState);

  const [userModalParams, setUserModalParams] = useState<UserModalParams>({
    isOpen: false,
    tab: 0,
    id: null,
  });

  const [themeModalParams, setThemeModalParams] = useState<ThemeModalParams>({
    isOpen: false,
  });

  const [jobSiteRemoveModalParams, setJobSiteRemoveModalParams] = useState<{
    isOpen: boolean;
    id: JobSite["id"] | null;
    name: JobSite["name"];
  }>({
    isOpen: false,
    id: null,
    name: "",
  });

  const setCurrentJobSitesState = useSetAtom(currentJobSitesState);
  const [currentClient, setCurrentClient] = useAtom(currentClientState);

  const jobSiteSearchRef = useRef<HTMLInputElement>(null);
  const userSearchRef = useRef<HTMLInputElement>(null);

  const isEdit = !!params.id;

  const { setSnackbarProps } = useSnackbar();

  const clientListCache = useAtomValue(clientListCacheState);

  const clientsLookUp: { [clientId: number]: Client } = useMemo(() => {
    return _.keyBy(clientListCache, "id");
  }, [clientListCache]);

  const jobSitesLookup: { [jobSiteId: number]: JobSite } = useMemo(() => {
    return _.keyBy(jobSiteList, "id");
  }, [jobSiteList]);

  const usersLookup: { [userId: number]: User } = useMemo(() => {
    return _.keyBy(userList, "id");
  }, [userList]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({});

  useEffect(() => {
    // use callback to prevent rerender on every field changed

    const subscription = watch((value, props) => {
      if (props.name === "client.name") {
        setClientName(value.client.name);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsLoading(true);

    if (isEdit) {
      initClientEdit().then(() => setIsLoading(false));
    } else {
      const client = {
        ...getDefaultClient(),
      };

      setInitialClient(client);

      reset({
        client,
      });

      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const initClientEdit = async () => {
    try {
      const client = await getFirebaseController().Client.getClient(params.id);

      if (client) {
        const promises: Promise<any>[] = [
          initUsers(client.id as number),

          initJobSite(client.sites),
          getFirebaseController()
            .Gallery.getGalleries({
              jobSiteIds: client.sites,
            })
            .then((gallery) => setGalleryList(gallery)),
        ];

        await Promise.all(promises);

        setInitialClient(client);
        setClientName(client.name);

        reset({
          client,
        });
      } else {
        throw new Error("Client not found.");
      }
    } catch (err) {
      navigate("../");
    }
  };

  const initUsers = async (clientId: number) => {
    try {
      const userRoles =
        await getFirebaseController().User.getUserRolesByClientId(clientId);
      const users = await getFirebaseController().User.getUsers(
        userRoles.map((role) => role.associatedUser as number),
      );

      setUserToUserRoleLookup(_.keyBy(userRoles, "associatedUser"));

      setUserList(users);
      setDisplayUserList(users);

      return [users, userRoles];
    } catch (error) {
      console.error(error);
    }
  };

  const initJobSite = async (siteIds, isUpdateClient = false) => {
    const jobSites: JobSite[] =
      await getFirebaseController().JobSite.getJobSites({ ids: siteIds });

    setJobSiteList(jobSites);
    setCurrentJobSitesState(jobSites);
    setDisplayJobSiteList(jobSites);

    if (isUpdateClient) {
      setCurrentClient({
        ...currentClient,
        sites: siteIds,
      } as Client);

      setInitialClient({
        ...initialClient,
        sites: siteIds,
      } as Client);
    }

    return jobSites;
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    if (!clientName || !currentClient) {
      setIsLoading(false);
      return;
    }

    const firebaseController = getFirebaseController();

    if (isEdit) {
      const editedData = getDirtyFields(formState.dirtyFields, data);

      if (_.isEmpty(editedData)) {
        setIsLoading(false);
        return;
      }

      const { client } = editedData;

      let editedJobSites: [number[], number[]] = [[], []];

      if ("sites" in client) {
        editedJobSites = getDifferences<number>(
          initialClient!.sites as number[],
          client.sites,
        );
      }

      await firebaseController.Client.updateClient(
        data.client.id,
        client,
        editedJobSites,
      )
        .then(async () => {
          await initClientEdit();

          setIsLoading(false);

          setSnackbarProps({
            open: true,
            content: `Client updated successfully!`,
          });
        })
        .catch(() => {
          setSnackbarProps({
            open: true,
            content: `Client update failed!`,
            severity: "error",
          });
        });
    } else {
      const { client } = data;

      const id = firebaseController.getNewDocumentId();

      await firebaseController.Client.addClient(
        { ...client, id },
        { returnDocument: true },
      )
        .then((createdClient) => {
          setIsLoading(false);
          navigate(`../${id}`);

          setSnackbarProps({
            open: true,
            content: `Client created successfully!`,
          });

          if (createdClient) {
            setClientListCache((prevClients) => {
              return orderByIgnoreCase([...prevClients, createdClient], "name");
            });
          }
        })
        .catch(() => {
          setSnackbarProps({
            open: true,
            content: `Client create failed!`,
            severity: "error",
          });
        });
    }
  };

  const displayGalleries = useMemo(() => {
    const mappedGalleries = galleryList.map((gallery) => {
      let _label = gallery.galleryName;

      const jobSite = jobSitesLookup[gallery.jobSite as number];

      if (jobSite) {
        _label = `${jobSite.name} - ${gallery.galleryName}`;
      }

      return {
        _label,
        ...gallery,
      };
    });

    return orderByIgnoreCase(mappedGalleries, "_label");
  }, [galleryList]);

  const clientDetailsRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Name",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("client.name", {
              required: true,
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            error={!!_.get(formState.errors, "client.name")}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Email",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("client.email", {
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Phone",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("client.phone", {
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            disabled={isLoading}
          />
        ),
      },
      {
        settingName: "Address",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("client.address", {
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            disabled={isLoading}
          />
        ),
      },
      ...(isEdit
        ? [
            {
              settingName: "Galleries",
              value: (
                <DataChips<GalleryV2>
                  items={displayGalleries}
                  labelkey="_label"
                  idKey="id"
                  itemLink={(item) => `/galleries/${item.id}/image-viewer`}
                  limit={5}
                />
              ),
            },
            {
              settingName: "Theme",
              value: (
                <IconButton
                  sx={{ p: 0 }}
                  size="small"
                  onClick={() => setThemeModalParams({ isOpen: true })}
                >
                  <Settings fontSize="small" />
                </IconButton>
              ),
            },
          ]
        : []),
    ],
    [formState, isEdit, displayGalleries, isLoading, register],
  );

  const jobSitesRow: JobSiteRows[] = useMemo(
    () =>
      orderByIgnoreCase(displayJobSiteList, "name").map((jobSite) => {
        return {
          name: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.5,
                fontStyle: jobSite.archived ? "italic" : "normal",
                color: ({ palette }) =>
                  jobSite.archived
                    ? palette.secondary.light
                    : palette.secondary.dark,
              }}
            >
              {jobSite.name}

              {jobSite.archived && (
                <Tooltip title="Archived">
                  <ArchiveRounded
                    fontSize="small"
                    sx={{
                      color: ({ palette }) => palette.secondary.light,
                      fontSize: 14,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          ),
          email: <>{jobSite.email}</>,
          physicalAddress: <>{jobSite.physicalAddress}</>,
          associatedClients: (
            <DataChips<number>
              items={jobSite.associatedClients as number[]}
              labelFormat={(id) => {
                return clientsLookUp[id].name;
              }}
              itemLink={(id) => {
                return `/clients/${id}`;
              }}
              isItemActive={(id) => jobSite.associatedClients[0] === id}
            />
          ),
          actions: (
            <>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  if (initialClient) {
                    navigate({
                      pathname: `/job-sites/${jobSite.id}`,
                      search: createSearchParams({
                        clientId: `${initialClient.id}`,
                      }).toString(),
                    });
                  }
                }}
              >
                <OpenInNew
                  fontSize="small"
                  sx={{
                    xs: 14,
                    sm: 20,
                  }}
                />
                {/* <Icon
                  fontSize="small"
                  sx={{
                    fontSize: {
                      xs: 14,
                      sm: 20,
                    },
                  }}
                >
                  edit
                </Icon> */}
              </IconButton>

              <IconButton
                sx={{ ml: 0.5 }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setJobSiteRemoveModalParams({
                    isOpen: true,
                    name: jobSite.name,
                    id: jobSite.id,
                  });
                }}
              >
                <Delete
                  fontSize="small"
                  sx={{
                    xs: 14,
                    sm: 20,
                  }}
                />
              </IconButton>
            </>
          ),
        };
      }),
    [displayJobSiteList, initialClient],
  );

  const usersRow: UserRow[] = useMemo(
    () =>
      orderByIgnoreCase(displayUserList, "username").map((user) => {
        const userRole = userToUserRoleLookup[user.id as number];
        const isAdmin = userRole.accessLevel === "3";

        const sites = userRole.accessableJobSites.map(
          (siteId) => jobSitesLookup[siteId as number],
        );

        return {
          name: <>{user.username}</>,
          jobSites: (
            <>
              {isAdmin ? (
                <Typography sx={{ fontWeight: "bold" }}> All</Typography>
              ) : (
                <DataChips
                  items={sites}
                  labelkey={"name"}
                  itemLink={(site) => `/job-sites/${site.id}`}
                  isItemMuted={(site) => site.archived}
                />
              )}
            </>
          ),
          actions: (
            <>
              <IconButton
                disabled={isAdmin}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setUserModalParams({
                    isOpen: true,
                    tab: 1,
                    id: user.id,
                  });
                }}
              >
                <Edit
                  fontSize="small"
                  sx={{
                    xs: 14,
                    sm: 20,
                  }}
                />
              </IconButton>

              <IconButton
                sx={{ ml: 0.5 }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  if (initialClient) {
                    navigate({
                      pathname: `/users/${user.id}`,
                      search: createSearchParams({
                        clientId: `${initialClient.id}`,
                      }).toString(),
                    });
                  }
                }}
              >
                <OpenInNew
                  fontSize="small"
                  sx={{
                    xs: 14,
                    sm: 20,
                  }}
                />
              </IconButton>
            </>
          ),
        };
      }),
    [displayUserList, initialClient],
  );

  const handleJobSitesSearch = useMemo(
    () => () => {
      const searchValue = jobSiteSearchRef?.current?.value || "";

      if (searchValue) {
        let newJobSiteList = _.cloneDeep(jobSiteList);

        const searchTexts = _.toLower(searchValue).split(" ");

        newJobSiteList = newJobSiteList.filter((site) => {
          const clientNames = site.associatedClients.map(
            (clientId) => clientsLookUp[clientId as number]?.name,
          );

          const fields = [site.name, site.physicalAddress, ...clientNames];

          return searchTexts.every((s) => {
            return fields.some((f) => _.toLower(f).includes(s));
          });
        });

        setDisplayJobSiteList(newJobSiteList);
      } else {
        setDisplayJobSiteList(jobSiteList);
      }
    },
    [jobSiteList],
  );

  const handleUsersSearch = useMemo(
    () => (e) => {
      const searchValue = userSearchRef?.current?.value || "";

      if (searchValue) {
        let newUserList = _.cloneDeep(userList);

        const searchTexts = _.toLower(searchValue).split(" ");

        newUserList = newUserList.filter((user) => {
          const role = userToUserRoleLookup[user.id as number];
          const jobSiteNames = role.accessableJobSites.map((siteId) =>
            _.toLower(jobSitesLookup[siteId as number].name),
          );

          const fields = [_.toLower(user.username), ...jobSiteNames];

          return searchTexts.every((s) => {
            return fields.some((f) => f.includes(s));
          });
        });

        setDisplayUserList(newUserList);
      } else {
        setDisplayUserList(userList);
      }
    },
    [userList],
  );

  const jobSitesTableHeaderActions = useMemo(() => {
    return (
      <>
        <SettingsTableHeaderTextField
          placeholder="Search..."
          onChange={handleJobSitesSearch}
          inputRef={jobSiteSearchRef}
        />

        <SettingsTableHeaderButton
          onClick={() => {
            if (initialClient) {
              navigate({
                pathname: `/job-sites/new`,
                search: createSearchParams({
                  clientId: `${initialClient.id}`,
                }).toString(),
              });
            }
          }}
        >
          New Site
        </SettingsTableHeaderButton>
      </>
    );
  }, [handleJobSitesSearch, initialClient]);

  const usersTableHeaderActions = useMemo(() => {
    return (
      <>
        <SettingsTableHeaderTextField
          placeholder="Search..."
          onChange={handleUsersSearch}
          inputRef={userSearchRef}
        />

        <SettingsTableHeaderButton
          onClick={() => {
            if (initialClient) {
              navigate({
                pathname: `/users/new`,
                search: createSearchParams({
                  clientId: `${initialClient.id}`,
                }).toString(),
              });
            }
          }}
        >
          New User
        </SettingsTableHeaderButton>
      </>
    );
  }, [handleUsersSearch, initialClient]);

  const themeTableHeaderActions = useMemo(() => {
    return (
      <>
        <SettingsTableHeaderButton
          onClick={() => {
            if (initialClient) {
              navigate({
                pathname: `/users/new`,
                search: createSearchParams({
                  clientId: `${initialClient.id}`,
                }).toString(),
              });
            }
          }}
        >
          Reset
        </SettingsTableHeaderButton>
      </>
    );
  }, [handleUsersSearch, initialClient]);

  const handleJobSiteRemove = async (jobSiteId) => {
    if (jobSiteId && initialClient) {
      const sites = _.difference([...initialClient.sites], [jobSiteId]);

      return await getFirebaseController()
        .Client.updateClient(initialClient.id as number, { sites }, [
          [],
          [jobSiteId],
        ])
        .then(async () => {
          await Promise.all([
            initJobSite(sites, true),
            initUsers(initialClient.id as number),
          ]);

          setSnackbarProps({
            open: true,
            content: `Site removed successfully!`,
          });
        })
        .catch((err) => {
          console.error(err);

          setSnackbarProps({
            open: true,
            content: `Site remove failed!`,
          });
        });
    }
  };

  const breadcrumbs = useMemo(() => {
    if (!initialClient) {
      return [];
    }

    return [
      {
        label: "Clients",
        path: "/clients",
      },
      isEdit
        ? {
            label: initialClient.name || initialClient.id?.toString() || "",
          }
        : {
            label: "New",
          },
    ];
  }, [initialClient, isEdit]);

  return (
    <Box>
      <Navbar title="Client Dashboard" />

      {initialClient && isEdit && (
        <>
          <UserModal
            client={initialClient}
            initUsers={initUsers}
            initJobSite={initJobSite}
            userModalParams={userModalParams}
            onClose={() =>
              setUserModalParams({ ...userModalParams, isOpen: false })
            }
            jobSitesLookup={jobSitesLookup}
            userToUserRoleLookup={userToUserRoleLookup}
            usersLookup={usersLookup}
          />

          <ThemeModal
            client={initialClient}
            themeModalParams={themeModalParams}
            onClose={() =>
              setThemeModalParams({ ...themeModalParams, isOpen: false })
            }
            initClientEdit={initClientEdit}
          />

          <ConfirmationModal
            open={jobSiteRemoveModalParams.isOpen}
            title={`Remove site ${jobSiteRemoveModalParams.name}?`}
            message={`Are you sure you want to remove site ${jobSiteRemoveModalParams.name} from 
      ${initialClient.name}? This process cannot be undone.`}
            disabled={isLoading}
            onClose={() =>
              setJobSiteRemoveModalParams({
                isOpen: false,
                id: null,
                name: "",
              })
            }
            onDone={() => {
              handleJobSiteRemove(jobSiteRemoveModalParams.id);
            }}
          />
        </>
      )}

      <DashboardEditContainer
        breadcrumbs={breadcrumbs}
        title={
          isEdit
            ? initialClient?.name || "-"
            : ` New Client ${clientName ? `- ${clientName}` : ""}`
        }
        subtitle="Fill in the client's info and press save when done."
        onSave={handleSubmit(handleSave, (errors) => {
          if (_.get(errors, "client.name")) {
            setSnackbarProps({
              open: true,
              content: `Client name cannot be empty.`,
              severity: "error",
            });
          }
        })}
        isLoading={isLoading}
      >
        <Grid container direction="column" wrap="nowrap" gap={4}>
          <Grid item>
            <SettingsTable
              title={"Client Details"}
              rows={clientDetailsRow}
              columns={columns}
            />
          </Grid>

          {isEdit && (
            <>
              <Grid item>
                <SettingsTable
                  isLoading={isLoading}
                  title={"Associated Users"}
                  rows={usersRow}
                  columns={userColumns}
                  headerActions={usersTableHeaderActions}
                  HeaderActionsProps={{
                    width: { xs: "100%", sm: "auto" },
                  }}
                  noItemLabel="No user found."
                />
              </Grid>

              <Grid item>
                <SettingsTable
                  title={"Job Sites"}
                  rows={jobSitesRow}
                  columns={jobSiteColumns}
                  headerActions={jobSitesTableHeaderActions}
                  HeaderActionsProps={{
                    width: { xs: "100%", sm: "auto" },
                  }}
                  noItemLabel="No job site found."
                />
              </Grid>
            </>
          )}
        </Grid>
      </DashboardEditContainer>
    </Box>
  );
};

export default ClientEditWindow;
