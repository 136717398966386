import { alpha, Box, Chip, Link as MuiLink } from "@mui/material";
import ConditionalWrapper from "components/Wrapper/ConditionalWrapper";
import _ from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getContrastShade } from "theme/reliveItTheme";

const DataChips = <T,>({
  items,
  limit,
  labelkey,
  labelFormat,
  idKey = "id",
  idFormat,
  itemLink,
  itemOnClick,
  isItemActive = () => false,
  isItemMuted = () => false,
  openNewTab = false,
}: {
  items: T[];
  limit?: number;
  labelkey?: string;
  labelFormat?: (item: T) => string;
  idKey?: string;
  idFormat?: (item: T) => string;
  itemLink?: string | ((item: T) => string);
  itemOnClick?: (item: T) => void;
  isItemActive?: (item: T) => boolean;
  isItemMuted?: (item: T) => boolean;
  openNewTab?: boolean;
}) => {
  const [isShowAll, setIsShowAll] = useState(false);

  const displayItems = !isShowAll && limit ? items.slice(0, limit) : items;
  const restItems = !isShowAll && limit ? items.slice(limit - 1, -1) : [];

  return (
    <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
      {displayItems.map((item, itemIndex) => {
        const label = labelFormat
          ? labelFormat(item)
          : labelkey
          ? item[labelkey]
          : undefined;

        if (!label) {
          return null;
        }

        return (
          <ConditionalWrapper
            key={idFormat ? idFormat(item) : item[idKey] || itemIndex}
            isWrap={!!itemLink}
            wrapper={(children) => (
              <MuiLink
                key={item[idKey] || itemIndex}
                component={Link}
                to={_.isFunction(itemLink) ? itemLink(item) : itemLink || ""}
                target={openNewTab ? "_blank" : "_self"}
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </MuiLink>
            )}
          >
            <Chip
              size="small"
              label={label}
              onClick={itemOnClick ? () => itemOnClick(item) : undefined}
              sx={({ palette }) => ({
                cursor: itemOnClick || itemLink ? "pointer" : "auto",
                borderRadius: 0.5,
                backgroundColor: isItemActive(item)
                  ? palette.primary.main
                  : isItemMuted(item)
                  ? alpha(palette.secondary.main, 0.5)
                  : palette.secondary.main,
                color: isItemActive(item)
                  ? palette.onPrimary.main
                  : isItemMuted(item)
                  ? alpha(palette.onSecondary.main, 0.8)
                  : palette.onSecondary.main,

                border: `1px solid ${alpha(palette.onSecondary.main, 0.1)}`,

                ":hover": {
                  backgroundColor: isItemActive(item)
                    ? getContrastShade(palette.primary, "dark")
                    : isItemMuted(item)
                    ? alpha(palette.secondary.main, 0.8)
                    : getContrastShade(palette.secondary, "dark"),
                },
              })}
            />
          </ConditionalWrapper>
        );
      })}

      {restItems.length > 0 && (
        <Chip
          color="secondary"
          size="small"
          label={`+${restItems.length}`}
          sx={({ palette }) => ({
            borderRadius: 0.5,
            backgroundColor: palette.secondary.light,
            color: `${palette.white.main} !important`,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setIsShowAll(true);
          }}
        />
      )}
    </Box>
  );
};

export default DataChips;
