import _, { LoDashStatic } from "lodash";

export const getDisplayValue = <T = string | number | any[]>(
  value: T | undefined,
  formater?: (value: Exclude<T, null | undefined>) => string | number,
) => {
  return _.isUndefined(value) || _.isNull(value) || value === ""
    ? "-"
    : formater
    ? formater(value as Exclude<T, null | undefined>)
    : value;
};

export const getDisplayValueWithUnit = <T = string | number | any[]>(
  value: T | undefined,
  unit?: string,
  space: boolean = true,
) => {
  return getDisplayValue(value, (value) => {
    return unit ? `${value}${space ? " " : ""}${unit}` : `${value}`;
  });
};

export const orderByIgnoreCase = <T>(
  data: T[],
  key: string,
  order: "asc" | "desc" = "asc",
) => {
  return _.orderBy<T>(
    data,
    (item) => {
      return item[key]?.toLocaleLowerCase();
    },
    order,
  );
};
