import {
  where,
  query,
  collection,
  orderBy,
  writeBatch,
  doc,
  Firestore,
  limit,
} from "firebase/firestore";

import { FirebaseController } from "database/FirebaseController";
import {
  getDefaultAdminEvent,
  getDefaultGalleryDeviceSettings,
} from "database/DataDefaultValues";
import {
  DeviceV2,
  DeviceV2Details,
  UnixEpoch,
  AdminEvent,
  DeviceEvent,
  DeviceV2Logs,
  DeviceV2Info,
  IntervalV2,
  DeviceV2IntervalSettings,
  DeviceV2Settings,
  EventType,
  GalleryDeviceV2Settings,
} from "database/DataTypes";

import moment from "moment";
import _ from "lodash";

const WARNING_SOLAR_VOLTAGE_MIN = 14.0;
const WARNING_BATTERY_VOLTAGE_MIN = 14.0;

const ERROR_SOLAR_VOLTAGE_MIN = 13.0;
const ERROR_BATTERY_VOLTAGE_MIN = 14.0;

type UpdateDeviceOptions = {
  device?: Partial<DeviceV2>;
  deviceDetails?: Partial<DeviceV2Details>;
  deviceSettings?: Partial<DeviceV2Settings>;
  deviceIntervalSettings?: Partial<DeviceV2IntervalSettings>;
  currentAssignedGallery?: DeviceV2["assignedGallery"];
};

const DEVICE_V2_ID_PREFIX = "V2_";

export const getDeviceCol = (deviceId) => {
  return _DeviceController.deviceColFromId(deviceId);
};

export class _DeviceController {
  private parent!: FirebaseController;
  private db!: Firestore;

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.db = this.parent.getDb();
  }

  static isV2(deviceId) {
    return deviceId.startsWith(DEVICE_V2_ID_PREFIX);
  }

  static deviceColFromId(deviceId) {
    return this.deviceCol(this.isV2(deviceId));
  }

  static deviceCol(v2 = false) {
    return v2 ? "devicesV2" : "devices";
  }

  static withPrefix(string: string, deviceId: string) {
    return `${this.isV2(deviceId) ? "(V2) " : ""}${string}`;
  }

  static isBatteryWarning(battery: DeviceV2["battery"]) {
    return battery < WARNING_BATTERY_VOLTAGE_MIN;
  }

  static isBatteryError(battery: DeviceV2["battery"]) {
    return battery < ERROR_BATTERY_VOLTAGE_MIN;
  }

  static isSolarWarning(battery: DeviceV2["solar"]) {
    return battery < WARNING_SOLAR_VOLTAGE_MIN;
  }

  static isSolarError(battery: DeviceV2["solar"]) {
    return battery < ERROR_SOLAR_VOLTAGE_MIN;
  }

  static isDeviceWarning(device: DeviceV2) {
    return (
      this.isSolarWarning(device.solar) && this.isBatteryWarning(device.battery)
    );
  }

  static isDeviceError(device: DeviceV2) {
    return (
      (!device.solar && !device.battery) ||
      (this.isSolarError(device.solar) && this.isBatteryError(device.battery))
    );
  }

  isV2(deviceId) {
    return _DeviceController.isV2(deviceId);
  }

  deviceColFromId(deviceId) {
    return _DeviceController.deviceColFromId(deviceId);
  }

  deviceCol(v2 = false) {
    return _DeviceController.deviceCol(v2);
  }

  async getDevice(deviceId) {
    return this.parent.getDocumentWithId<DeviceV2>(
      this.deviceColFromId(deviceId),
      deviceId,
    );
  }

  async getDevices(deviceIds?: string[]) {
    const promises = [
      this.parent.getDocuments<DeviceV2>(this.deviceCol(false), deviceIds),
      this.parent.getDocuments<DeviceV2>(this.deviceCol(true), deviceIds),
    ];

    return await Promise.all(promises).then((data) => data.flat());
  }

  async getDeviceDetails(deviceId: string | number) {
    return this.parent.getDocumentWithId<DeviceV2Details>(
      `${this.deviceCol(this.isV2(deviceId))}/${deviceId}/DeviceDetails`,
      "Details",
    );
  }

  async getDeviceAdminEvents(
    deviceId: string | number,
    timeFrom: UnixEpoch,
    timeTo: UnixEpoch,
  ) {
    const queryFilters = [
      where("deviceId", "==", deviceId.toString()),
      where("eventTime", ">=", timeFrom),
      where("eventTime", "<=", timeTo),
    ];

    return this.parent.getDocumentListWithQuery<AdminEvent>(
      query(
        collection(this.db, "adminEvents"),
        ...queryFilters,
        orderBy("eventTime", "desc"),
      ),
      { includeDocId: true },
    );
  }

  async getDeviceEvents(
    deviceId: string | number,
    timeFrom: UnixEpoch,
    timeTo: UnixEpoch,
    type?: EventType,
  ) {
    const queryFilters = [
      where("deviceID", "==", deviceId.toString()),
      where("eventTime", ">=", timeFrom),
      where("eventTime", "<=", timeTo),

      ...(type !== undefined ? [where("eventType", "==", type)] : []),
    ];

    return this.parent.getDocumentListWithQuery<DeviceEvent>(
      query(
        this.parent.getColRef("deviceEvents"),
        ...queryFilters,
        orderBy("eventTime", "desc"),
      ),
      { includeDocId: true },
    );
  }

  async getDeviceLogs(deviceId) {
    const isV2 = this.isV2("V2_37f78c06cfac2bd59cd1642a9d4a685e");

    if (isV2) {
      return this.parent
        .getDocumentListWithQuery<DeviceV2Logs>(
          query(
            this.parent.getColRef(`devicesV2/${deviceId}/DeviceLogs`),
            orderBy("epochTime", "desc"),
            limit(1),
          ),
        )
        .then((data) => {
          if (data) {
            return data[0];
          }
        });
    } else {
      return this.parent.getDocumentWithId<DeviceV2Logs>(
        `devices/${deviceId}/DeviceLogs`,
        "Logs",
      );
    }
  }

  async getDeviceLogsList(deviceId, timeFrom?: UnixEpoch, timeTo?: UnixEpoch) {
    const queries: any[] = [];

    if (this.isV2(deviceId)) {
      if (timeFrom && timeTo) {
        queries.push(
          where("epochTime", ">=", timeFrom * 1000),
          where("epochTime", "<=", timeTo * 1000),
        );
      }

      queries.push(orderBy("epochTime", "desc"));
    }

    return this.parent
      .getDocumentListWithQuery<DeviceV2Logs>(
        query(
          this.parent.getColRef(
            `${this.deviceColFromId(deviceId)}/${deviceId}/DeviceLogs`,
          ),
          ...queries,
        ),
        { includeDocId: true },
      )
      .then((data) => {
        if (data) {
          return data;
        }
      });
  }

  async getDeviceInfo(deviceId) {
    return await this.parent.getDocumentWithValue<DeviceV2Info>(
      "deviceInfos",
      "deviceId",
      deviceId,
    );
  }

  async getDeviceInterval(
    deviceId: string | number,
    day = moment().format("dddd"),
  ) {
    return this.parent.getDocumentWithId<IntervalV2>(
      `${this.deviceColFromId(deviceId)}/${deviceId}/DeviceIntervalSettings`,
      day,
    );
  }

  async getDeviceIntervals(id) {
    const intervals = (await this.parent.getDocumentHash<IntervalV2>(
      `${this.deviceColFromId(id)}/${id}/DeviceIntervalSettings`,
    )) as DeviceV2IntervalSettings;

    return intervals;
  }

  async getDeviceSettings(id) {
    return this.parent.getDocumentWithId<DeviceV2Settings>(
      `${this.deviceColFromId(id)}/${id}/DeviceSettings`,
      "Settings",
    );
  }

  async addDeviceAdminEvents(adminEvent: Partial<AdminEvent>) {
    const id =
      adminEvent.id || this.parent.getNewDocumentIdString("adminEvents");

    if (this.parent.currentUser) {
      const newAdminEvent = {
        ...getDefaultAdminEvent(),
        ...adminEvent,
        id,
        userId: adminEvent.userId || this.parent.currentUser.id,
        username: adminEvent.username || this.parent.currentUser.username,
        eventTime: adminEvent.eventTime || moment().unix(),
      };

      return await this.parent.createOrUpdateDocument(
        newAdminEvent,
        "adminEvents",
        id,
      );
    } else {
      throw new Error("Update failed.");
    }
  }

  async addDeviceInfo(deviceInfo: Exclude<DeviceV2Info, "id">) {
    const id =
      deviceInfo.id || this.parent.getNewDocumentIdString("deviceInfos");

    const newDeviceInfo = {
      ...deviceInfo,
      id,
    };

    return await this.parent.createOrUpdateDocument(
      newDeviceInfo,
      "deviceInfos",
      id,
    );
  }

  async updateDevice(
    deviceId: string,
    {
      device,
      deviceDetails,
      deviceSettings,
      deviceIntervalSettings,
      currentAssignedGallery,
    }: UpdateDeviceOptions,
  ) {
    const batch = writeBatch(this.db);

    const devicesRef = doc(
      this.parent.getColRef(this.deviceColFromId(deviceId)),
      deviceId.toString(),
    );

    if (device) {
      batch.update(devicesRef, device);
    }

    if (deviceDetails) {
      const deviceDetailsRef = doc(
        collection(devicesRef, "DeviceDetails"),
        "Details",
      );

      batch.update(deviceDetailsRef, deviceDetails);
    }

    if (deviceSettings) {
      const deviceSettingsRef = this.parent.getDocRef(
        `${this.deviceColFromId(deviceId)}/${devicesRef.id}/DeviceSettings`,
        "Settings",
      );

      batch.update(deviceSettingsRef, deviceSettings);

      if (currentAssignedGallery) {
        const gallerySettingsRef =
          this.parent.getDocRef<GalleryDeviceV2Settings>(
            `galleries/${currentAssignedGallery.id}/GallerySettings`,
            "Settings",
          );

        batch.update(
          gallerySettingsRef,
          this._pickGallerySettingsFromDeviceSettings(deviceSettings),
        );
      }
    }

    if (deviceIntervalSettings) {
      for (const day in deviceIntervalSettings) {
        const deviceIntervalSettingsRef = doc(
          collection(devicesRef, "DeviceIntervalSettings"),
          day,
        );

        batch.update(deviceIntervalSettingsRef, deviceIntervalSettings[day]);

        if (currentAssignedGallery) {
          const galleryIntervalSettingsRef = this.parent.getDocRef(
            `galleries/${currentAssignedGallery.id}/GalleryIntervalSettings`,
            day,
          );

          batch.update(galleryIntervalSettingsRef, deviceIntervalSettings[day]);
        }
      }
    }

    return await batch.commit();
  }

  async updateDeviceInfo(
    deviceInfoId: string,
    deviceInfo: Partial<DeviceV2Info>,
  ) {
    return await this.parent.createOrUpdateDocument(
      deviceInfo,
      "deviceInfos",
      deviceInfoId,
    );
  }

  private _pickGallerySettingsFromDeviceSettings(
    deviceSettings: Partial<DeviceV2Settings>,
  ) {
    // pick only the field that existed in gallery settings from device settings

    const allowedSettings = _.pick(
      deviceSettings,
      ...Object.keys(getDefaultGalleryDeviceSettings()),
    );

    return allowedSettings as Partial<GalleryDeviceV2Settings>;
  }
}
