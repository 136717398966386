import {
  where,
  query,
  limit,
  orderBy,
  startAfter,
  startAt,
  Query,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { ref as refStore } from "firebase/storage";

import {
  FirebaseController,
  getDownloadURL,
} from "database/FirebaseController";

import {
  getDefaultImageExtras,
  getDefaultPhotoSentinelImage,
  getDefaultComment,
} from "database/DataDefaultValues";
import {
  Comment,
  UnixEpoch,
  FirebaseImage,
  PhotoSentielImage,
  ImageExtras,
  WebAppSetup,
} from "database/DataTypes";

import _ from "lodash";
import moment from "moment";
import { Image } from "database/ImageHandlerV2";

type CreateOrUpdateImageExtrasOptions = {
  isInitDefault?: boolean;
  isReturnUpdated?: boolean;
};
export class _ImageController {
  private parent!: FirebaseController;
  hiddenImageIds!: { [objectId: string | number]: (string | number)[] };
  hiddenImageExtras!: { [objectId: string | number]: ImageExtras[] };
  hiddenImagesUpdatedAt!: { [objectId: string | number]: UnixEpoch };

  constructor(parent: FirebaseController) {
    this.parent = parent;
    this.hiddenImageIds = {};
    this.hiddenImageExtras = {};
    this.hiddenImagesUpdatedAt = {};
  }

  async getThumbnail(location: string, fileName: string) {
    const thumbnailName = fileName.replace(".jpg", "_925x695.jpg");
    const thumbnailRef = refStore(
      this.parent.getStorageRef(),
      location + "thumbnails/" + thumbnailName,
    );

    return getDownloadURL(thumbnailRef);
  }

  async getImages(
    objectId: string | number,
    {
      isDevice,
      startDate,
      endDate,
      imageIds,
      hiddenImageIds,
    }: {
      isDevice?: boolean;
      startDate?: UnixEpoch;
      endDate?: UnixEpoch;
      imageIds?: (string | number)[];
      hiddenImageIds?: (string | number)[];
    },
  ) {
    let images: FirebaseImage[] = [];

    const field = isDevice ? "deviceId" : "galleryId";

    const queries: any[] = [where(field, "==", objectId)];

    if (!_.isUndefined(startDate)) {
      queries.push(where("epochTime", ">=", startDate));
    }

    if (!_.isUndefined(endDate)) {
      queries.push(where("epochTime", "<=", endDate));
    }

    if (imageIds) {
      if (imageIds.length > 0) {
        const promises: Promise<FirebaseImage[]>[] = [];

        // Firestore "in" operator only supports up to 30 entries, so multiple fetch is needed
        const idsChuck = _.chunk(imageIds, 30);

        idsChuck.forEach((ids) => {
          promises.push(
            this.parent.getDocumentListWithQuery<FirebaseImage>(
              query(
                this.parent.getColRef("images"),
                ...queries,
                where("id", "in", ids),
              ),
            ),
          );
        });

        // have sort on client side as we doing batch fetching
        await Promise.all(promises).then((data) => {
          images = data.flat();

          images = _.orderBy(images, "epochTime", "desc");
        });
      } else {
        return [];
      }
    } else {
      queries.push(orderBy("epochTime", "desc"));

      images = await this.parent.getDocumentListWithQuery<FirebaseImage>(
        query(this.parent.getColRef("images"), ...queries),
      );
    }

    // Have to do it on client side due to FirebaseError: Invalid query. All where filters with an inequality (<, <=, !=, not-in, >, or >=) must be on the same field.
    const visibleImages = hiddenImageIds
      ? images.filter((image) => {
          return !hiddenImageIds.includes(image.id);
        })
      : images;

    return visibleImages;
  }

  private async _findImageRecursively(
    objectId: string | number,
    isDevice: boolean = true,
    order: "asc" | "desc",
  ) {
    const key = isDevice ? "deviceId" : "galleryId";

    const hiddenImageIds = await this.getHiddenImageIds(objectId, isDevice);
    const hiddenImageIdSet = new Set(hiddenImageIds);

    let displayImage: FirebaseImage | undefined;
    let image: FirebaseImage | undefined;
    let lastSnap: QueryDocumentSnapshot<unknown> | undefined;

    while (!displayImage) {
      const queries = [
        where(key, "==", objectId),
        orderBy("epochTime", order),
        limit(5),
        ...(lastSnap ? [startAfter(lastSnap)] : []),
      ];

      const q = query(
        this.parent.getColRef<FirebaseImage>("images"),
        ...queries,
      );

      const querySnapshot = await this.parent.getQuerySnapshot(q);

      if (querySnapshot && !querySnapshot.empty) {
        image = querySnapshot.docs[0].data() as FirebaseImage;

        for (const doc of querySnapshot.docs) {
          lastSnap = doc;

          if (!hiddenImageIdSet.has(doc.id)) {
            displayImage = doc.data() as FirebaseImage;

            break;
          }
        }
      } else {
        break;
      }
    }

    return {
      displayImage,
      image,
    };
  }

  async getFirstImage(objectId: string | number, isDevice: boolean = true) {
    return await this._findImageRecursively(objectId, isDevice, "asc");
  }

  async getLastImage(objectId: string | number, isDevice: boolean = true) {
    return await this._findImageRecursively(objectId, isDevice, "desc");
  }

  async getPhotoSentinelImages(imageIds: number[]) {
    const promises: Promise<PhotoSentielImage[]>[] = [];

    const idsChuck = _.chunk(imageIds, 30);

    idsChuck.forEach((ids) => {
      promises.push(
        this.parent.getDocumentListWithQuery<PhotoSentielImage>(
          query(
            this.parent.getColRef("photoSentinelImages"),
            where("photo_id", "in", ids),
          ),
        ),
      );
    });

    return Promise.all(promises).then((data) => {
      return _.orderBy(data.flat(), "datetime_taken_local", "desc");
    });
  }

  async getImageExtras(
    imageId: number | string,
    {
      objectId,
      isDevice,
    }: {
      objectId?: string | number;
      isDevice?: boolean;
    } = {},
  ) {
    const queries: any[] = [where("imageApplied", "==", imageId)];

    if (objectId) {
      if (isDevice) {
        queries.push(where("deviceId", "==", objectId));
      } else {
        queries.push(where("galleryId", "==", objectId));
      }
    }

    return this.parent.getDocumentListWithQuery<ImageExtras>(
      query(this.parent.getColRef("imageExtras"), ...queries),
    );
  }

  async getAllImageExtras({
    objectId,
    isDevice = false,
    userId,
    isCommented,
    isFavorited,
    isTagged,
    isHidden,
  }: {
    objectId: string | number;
    isDevice: boolean;
    userId?: number;
    isCommented?: boolean;
    isFavorited?: boolean;
    isTagged?: boolean;
    isHidden?: boolean;
  }) {
    const queries: any[] = [];

    if (isDevice) {
      queries.push(where("deviceId", "==", objectId));
    } else {
      queries.push(where("galleryId", "==", objectId));
    }

    if (userId) {
      queries.push(where("associatedUser", "==", userId));
    }

    if (!_.isUndefined(isCommented)) {
      queries.push(where("comments", isCommented ? "!=" : "==", []));
    }

    if (!_.isUndefined(isFavorited)) {
      queries.push(where("favorited", "==", isFavorited));
    }

    if (!_.isUndefined(isTagged)) {
      queries.push(where("tags", isTagged ? "!=" : "==", []));
    }

    if (!_.isUndefined(isHidden)) {
      queries.push(where("hidden", "==", isHidden));
    }

    return this.parent.getDocumentListWithQuery<ImageExtras>(
      query(this.parent.getColRef("imageExtras"), ...queries),
    );
  }

  async getHiddenImageExtras(
    objectId: number | string,
    isDevice: boolean,
    {
      reload = false,
      resultCallback,
    }: {
      reload?: boolean;
      resultCallback?: (
        hiddenImagesExtras: ImageExtras[],
        updated: boolean,
      ) => void;
    } = {},
  ) {
    if (!reload && this.hiddenImageExtras[objectId]) {
      // implement expired time so that when image is hidden, changes will reflected on another user next time it calling this function
      const expired =
        !this.hiddenImagesUpdatedAt[objectId] ||
        moment().unix() - this.hiddenImagesUpdatedAt[objectId] > 60 * 15;

      if (!expired) {
        if (resultCallback) {
          resultCallback(this.hiddenImageExtras[objectId], false);
        }

        return this.hiddenImageExtras[objectId];
      }
    }

    const imageExtras = await this.getAllImageExtras({
      objectId,
      isDevice,
      isHidden: true,
    });

    this.hiddenImageExtras[objectId] = imageExtras;
    this.hiddenImagesUpdatedAt[objectId] = moment().unix();

    if (resultCallback) {
      resultCallback(imageExtras, true);
    }

    return imageExtras;
  }

  async getHiddenImageIds(
    objectId: number | string,
    isDevice: boolean,
    {
      reload = false,
    }: {
      reload?: boolean;
    } = {},
  ) {
    let ids: (number | string)[] = [];

    await this.getHiddenImageExtras(objectId, isDevice, {
      reload,
      resultCallback: (hiddenImageExtras, updated) => {
        if (updated) {
          hiddenImageExtras.forEach((imageExtra) => {
            ids.push(imageExtra.imageApplied);
          });

          this.hiddenImageIds[objectId] = ids;
        } else {
          ids = this.hiddenImageIds[objectId];
        }
      },
    });

    return ids;
  }

  async initDropboxRefreshToken(): Promise<string> {
    return await this.parent
      .getDocumentWithId<WebAppSetup>("webAppSetup", "Main")
      .then((data) => {
        if (data) {
          this.parent.dropboxRefreshToken = data.refreshToken;

          return data.refreshToken;
        } else {
          throw new Error("WebAppSetup not found.");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async createOrUpdateImageExtras(
    objectId: string | number,
    isDevice: boolean = false,
    imageExtras: Partial<ImageExtras>,
    {
      isInitDefault = true,
      isReturnUpdated = true,
    }: CreateOrUpdateImageExtrasOptions = {},
  ) {
    const id = imageExtras.id || this.parent.getNewDocumentId();

    const newImageExtras = {
      ...(isInitDefault ? getDefaultImageExtras() : {}),
      ...imageExtras,
      ...(isDevice ? { deviceId: objectId } : { galleryId: objectId }),
      associatedUser: this.parent.currentUser!.id || null,
      id,
    };

    return await this.parent
      .createOrUpdateDocument(newImageExtras, "imageExtras", id)
      .then(async () => {
        return await this.parent.getDocumentWithId<ImageExtras>(
          "imageExtras",
          id,
        );
      });
  }

  async updateImageExtras(
    imageExtrasId: number,
    imageExtras: Partial<ImageExtras>,
  ) {
    return await this.parent
      .updateDocument(imageExtras, "imageExtras", imageExtrasId)
      .then(async () => {
        return await this.parent.getDocumentWithId<ImageExtras>(
          "imageExtras",
          imageExtrasId,
        );
      });
  }

  async addPhotoSentinelImage(photoData: PhotoSentielImage) {
    const newPhotoData = {
      ...getDefaultPhotoSentinelImage(),
      ...photoData,
    };

    return await this.parent.createOrUpdateDocument(
      newPhotoData,
      "photoSentinelImages",
      photoData.photo_id as number,
    );
  }

  async addComment(comment: Partial<Comment>) {
    const id = comment.id || this.parent.getNewDocumentId();

    const newComment = {
      ...getDefaultComment(),
      ...comment,
      id,
    };

    return await this.parent.createOrUpdateDocument(newComment, "comments", id);
  }

  async getComment(commentId) {
    return this.parent.getDocumentWithId<Comment>("comments", commentId);
  }

  async getComments(commentIds) {
    return this.parent.getDocumentListWithValue<Comment>(
      "comments",
      "id",
      commentIds,
    );
  }
}
