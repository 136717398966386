import { Box, useTheme, Typography, alpha } from "@mui/material";
import _ from "lodash";
import { memo, useCallback } from "react";
import {
  LineChart as ReLineChart,
  Line,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  XAxisProps,
  YAxisProps,
} from "recharts";
import { LineDot } from "recharts/types/cartesian/Line";
import {
  AxisDomain,
  AxisInterval,
  BaseAxisProps,
} from "recharts/types/util/types";

import * as d3 from "d3";
import { TimeInterval } from "d3";
import moment from "moment";

// TODO: just make 2 props object for x and y instead of declaring all. eg: xProps: {}, YProps: {}
export type LineChartOptions = {
  colors?: string[];
  xLabel?: string;
  yLabel?: string;
  xKey?: string;
  yKey?: string;
  xDomain?: AxisDomain;
  yDomain?: AxisDomain;
  xInterval?: AxisInterval;
  yInterval?: AxisInterval;
  xTicks?: XAxisProps["ticks"];
  yTicks?: YAxisProps["ticks"];
  xTickFormatter?: BaseAxisProps["tickFormatter"];
  yTickFormatter?: BaseAxisProps["tickFormatter"];
  yDot?: LineDot;
  unit?: string;
  showLegend?: boolean;
  minHeight?: number;
  toolTipValueFormatter?: (props: any) => string;
  toolTipLabelFormatter?: (props: any) => string;
  xProps?: XAxisProps;
  yProps?: YAxisProps;
  xLabelDy?: number;
  yLabelDy?: number;
  isAnimationActive?: boolean;
};

export const MultiLineTick = (props) => {
  const { x, y, payload } = props;

  const textProps = _.pick(props, [
    "className",
    "fill",
    "fontSize",
    "textAnchor",
    "verticalAnchor",
  ]);

  const values: string[] = props.tickFormatter
    ? props.tickFormatter(payload.value).split("/\n")
    : [payload.value];

  return (
    <g transform={`translate(${x},${y})`}>
      <text {...textProps} x={0} y={0} dy={8}>
        {values.map((value, index) => {
          return (
            <tspan
              key={index}
              textAnchor="middle"
              x="0"
              dy={8 + index * 0.75 * 8}
            >
              {value}
            </tspan>
          );
        })}
      </text>
    </g>
  );
};

export const getTimeAxisDomainAndTick = (
  min,
  max,
  tickInterval = 1,
): [number[], number[]] => {
  const scale = d3
    .scaleTime()
    .domain([
      Number(moment(min).valueOf()),
      Number(moment(max).add(1, "hour").startOf("hour").valueOf()),
    ]);

  const domain = scale.domain().map((date) => date.getTime());

  const ticks = scale
    .ticks(d3.timeHour.every(tickInterval) as TimeInterval)
    .map((a) => a.getTime());

  return [domain, ticks];
};

const getTimeAxisTick = () => {};

const CustomTooltip = (props) => {
  const { active, payload, label, options = {} } = props;

  if (active) {
    return (
      <Box
        sx={{
          background: ({ palette }) => palette.surface.main,
          color: ({ palette }) => palette.onSurface.main,
          p: 1,
          border: ({ palette }) =>
            `1px solid ${alpha(palette.onSurface.main, 0.4)}`,
        }}
      >
        {options.content ? (
          <>{options.content(payload)}</>
        ) : (
          <>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              {options.toolTipLabelFormatter
                ? options.toolTipLabelFormatter(label, payload)
                : label}
            </Typography>

            {payload?.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                >
                  <Box
                    sx={{
                      fontSize: 14,
                      background: item.color,
                      borderRadius: "100%",
                      height: 10,
                      width: 10,
                    }}
                  />

                  <Typography sx={{ fontSize: 14 }}>
                    {`${
                      options.toolTipValueFormatter
                        ? options.toolTipValueFormatter(payload, index)
                        : options.unit
                        ? `${item.value} ${options.unit}`
                        : item.value
                    }`}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    );
  }

  return null;
};

const LineChart = memo(
  ({
    id,
    title,
    data,
    options,
    onItemClick,
  }: {
    id?: any;
    data: any[];
    title?: string;
    options?: LineChartOptions;
    onItemClick?: (item, itemIndex) => void;
  }) => {
    const { palette } = useTheme();

    const { name, ...keys } = data[0] || {};

    const {
      yKey,
      xKey,
      yDot,
      showLegend = true,
      minHeight = 300,
      isAnimationActive = true,
    } = options || {};

    const dataKeys = Object.keys(keys);

    const colors = options?.colors || [
      palette.primary.main,
      palette.warning.main,
      "black",
    ];

    const CustomLabel = ({
      x,
      y,
      stroke,
      value,
      width,
    }: {
      x?: any;
      y?: any;
      stroke?: any;
      value?: any;
      width?: any;
    }) => {
      if (value) {
        // No label if there is a value. Let the cell handle it.
        return null;
      }

      return (
        <text
          x={x}
          y={y}
          // Move slightly above axis
          dy={-10}
          // Center text
          dx={width / 2}
          fill={stroke}
          fontSize={15}
          textAnchor="middle"
        >
          N/A
        </text>
      );
    };

    const handleItemClick = (itemIndex: number) => {
      if (onItemClick) {
        onItemClick(data[itemIndex], itemIndex);
      }
    };

    const bindClick = useCallback(() => {
      if (onItemClick) {
        return {
          onClick: (payload) => {
            if (!_.isUndefined(payload.activeTooltipIndex)) {
              handleItemClick(payload.activeTooltipIndex);
            }
          },
          onMouseMove: (payload, event) => {
            if (!_.isUndefined(payload.activeTooltipIndex)) {
              event.target.style.cursor = "pointer";
            } else {
              event.target.style.cursor = "auto";
            }
          },
          onMouseLeave: (payload, event) => {
            event.target.style.cursor = "auto";
          },
        };
      } else {
        return {};
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onItemClick]);

    return (
      <Box>
        {title && (
          <Typography
            sx={{
              mb: 2,
              fontSize: 16,
              fontWeight: "bold",
              textDecoration: "underline",
              color: ({ palette }) => palette.grey[700],
            }}
          >
            {title}
          </Typography>
        )}

        {data.length > 0 ? (
          <ResponsiveContainer
            width="100%"
            height="100%"
            minHeight={minHeight}
            debounce={300}
          >
            <ReLineChart
              key={id}
              data={data}
              margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 10,
              }}
              {...bindClick()}
            >
              <CartesianGrid strokeDasharray="1 1" stroke={palette.grey[300]} />

              {showLegend && (
                <Legend
                  align="right"
                  fontSize={8}
                  iconType="circle"
                  iconSize={12}
                  wrapperStyle={{
                    width: "max-content",
                    border: `1px solid ${palette.grey[400]}`,
                    padding: "0 20px",
                    fontSize: 14,
                    bottom: 0,
                    position: "absolute",
                  }}
                />
              )}

              <XAxis
                style={{
                  marginBottom: "200px",
                }}
                dataKey={xKey || "name"}
                fontSize={12}
                stroke={palette.grey[700]}
                domain={options?.xDomain || [0, "auto"]}
                interval={options?.xInterval}
                tickFormatter={options?.xTickFormatter}
                ticks={options?.xTicks}
                {...options?.xProps}
              >
                {options?.xLabel && (
                  <Label
                    position="center"
                    dy={options?.xLabelDy || 20}
                    fontSize={14}
                    fontWeight={"bold"}
                    value={options.xLabel}
                  />
                )}
              </XAxis>

              <YAxis
                allowDataOverflow={true}
                fontSize={12}
                stroke={palette.grey[700]}
                domain={options?.yDomain || [0, "auto"]}
                interval={options?.yInterval}
                {...options?.yProps}
              >
                {options?.yLabel && (
                  <Label
                    position="center"
                    dx={-20}
                    angle={-90}
                    fontSize={14}
                    fontWeight={"bold"}
                    value={options.yLabel}
                  />
                )}
              </YAxis>

              <Tooltip content={<CustomTooltip options={options} />} />

              {(yKey ? [yKey] : dataKeys).map((key, index) => {
                return (
                  <Line
                    key={key}
                    animationDuration={1000}
                    type="linear"
                    dataKey={key}
                    stroke={colors[index]}
                    strokeWidth={3}
                    dot={yDot}
                    isAnimationActive={isAnimationActive}
                  />
                );
              })}
            </ReLineChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              minHeight,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 12,
                color: ({ palette }) => palette.grey[600],
              }}
            >
              No data.
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

export default LineChart;
