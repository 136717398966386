import {
  Box,
  Divider,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
} from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";

import {
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";

type MultiLevelMenuItemType = {
  name: string;
  description: string;
  icon: ReactNode | ReactNode[];
  isDisabled?: boolean;
  isBlocked?: boolean;
  isActive?: boolean;
  divider?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item: MultiLevelMenuItemType,
    itemIndex: number,
  ) => void;
  items?: MultiLevelMenuItemType[];
};

const MultiLevelMenu = ({
  anchorEl,
  open,
  onClose,
  items,
  MenuItemProps,
}: {
  anchorEl: MenuProps["anchorEl"];
  open: MenuProps["open"];
  onClose: MenuProps["onClose"];
  items: MultiLevelMenuItemType[];
  MenuItemProps?: MenuItemProps;
}) => {
  const [indices, setIndices] = useState<number[]>([]);

  const displayItems = useMemo(() => {
    let currentDisplayItems = items;

    for (const index of indices) {
      currentDisplayItems =
        currentDisplayItems[index]?.items || currentDisplayItems;
    }

    return currentDisplayItems;
  }, [indices, items]);

  const handleBack = () => {
    setIndices((indices) => {
      return indices.splice(-1);
    });
  };

  useEffect(() => {
    if (open) {
      setIndices([]);
    }
  }, [open]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {indices.length > 0 && [
        <MenuItem
          key={"back"}
          dense
          onClick={handleBack}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            py: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",

              ".MuiSvgIcon-root": {
                fontSize: "16px !important",
              },
            }}
          >
            <ArrowBackIcon />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>Back</Box>
        </MenuItem>,
        <Divider key={"divider"} />,
      ]}

      {displayItems.map((item, itemIndex) => {
        return [
          <MenuItem
            key={itemIndex}
            dense
            onClick={(e) => {
              if (!item.isDisabled) {
                if (item.items && item.items.length > 0) {
                  setIndices([...indices, itemIndex]);
                } else if (item.onClick) {
                  item.onClick(e, item, itemIndex);

                  onClose!(e, "backdropClick");
                }
              }
            }}
            sx={{
              color: ({ palette }) =>
                item.isActive ? palette.primary.main : "inherit",
              display: "flex",
              alignItems: "center",
              gap: 2,
              py: 0,
            }}
          >
            {item.icon && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  ".MuiSvgIcon-root": {
                    fontSize: "16px !important",
                  },
                }}
              >
                {item.icon}
              </Box>
            )}

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {item.description}
            </Box>

            {item.items && item.items.length > 0 && (
              <Box
                sx={{
                  //   ml: "auto",
                  display: "flex",
                  alignItems: "center",

                  ".MuiSvgIcon-root": {
                    fontSize: "12px !important",
                  },
                }}
              >
                <ArrowForwardIcon />
              </Box>
            )}
          </MenuItem>,
          item.divider && <Divider />,
        ];
      })}
    </Menu>
  );
};

export default MultiLevelMenu;
