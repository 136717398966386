import _ from "lodash";
import moment from "moment";
import momentTz, { Moment, MomentInput } from "moment-timezone";
import { useCallback } from "react";

// inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean

type MomentWithTimeZoneHOFOptions = {
  keepLocalTime?: boolean;
  format?: moment.MomentFormatSpecification;
};

export type MomentWithTimeZone = (
  date?: MomentInput,
  options?: MomentWithTimeZoneHOFOptions,
) => Moment;

/*
when keepLocalTime set to true, it parse the date to the time zone without offsetting the time:
- 2024-10-09T08:51:52 -> Australia/Melbourne = 2024-10-09T08:51:52+11:00

when keepLocalTime set to false it will parse the input date as in browser timezone, in this case +8, then offset it to the specified time zone
- 2024-10-09T08:51:52 -> 2024-10-09T08:51:52+08:00 -> Australia/Melbourne = 2024-10-09T11:51:52+11:00

parseZone is needed when converting iso string with time offset, it parse the input date but keeping the input date offset
- "2024-10-09T08:51:52+09:00" -> "2024-10-09T08:51:52+09:00"

without parseZone it convert the input date to browser timezone offset, in this case +8, notice the ouput hour and offset has changed
- "2024-10-09T08:51:52+09:00" -> "2024-10-09T07:51:52+08:00"
*/
export const momentWithTimeZoneHOF =
  (timeZone: string = "") =>
  (
    date?: MomentInput,
    { keepLocalTime = true, format }: MomentWithTimeZoneHOFOptions = {},
  ): Moment => {
    let currentTimeZone = timeZone;

    const isValid = momentTz.tz.zone(timeZone);

    if (!isValid) {
      currentTimeZone = momentTz.tz.guess() as string;
    }

    let fn: typeof moment = moment;

    if (_.isString(date) && date.includes("+")) {
      fn = moment.parseZone as typeof moment;
    }

    return fn(date, format).tz(currentTimeZone, keepLocalTime);
  };

const useTimeZone = (timeZone: string = "") => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const momentWithTimeZone = useCallback(
    momentWithTimeZoneHOF(timeZone),

    [timeZone],
  );

  return { momentWithTimeZone };
};

export default useTimeZone;
