import { alpha, Box, Popover, TextField, Typography } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import tinycolor from "tinycolor2";

export const ColorPickerInput = ({
  color,
  onChange,
}: {
  color: string;
  onChange: (newColor: string) => void;
}) => {
  //   const [color, setColor] = useState("#ffffff");

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1, width: 400 }}>
          <ColorPicker
            color={color}
            onChange={(newColor) => onChange(newColor)}
          />
        </Box>
      </Popover>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            width: 24,
            height: 24,
            background: color,
            border: ({ palette }) =>
              `1px solid ${alpha(palette.secondary.main, 0.1)}`,
          }}
        />

        <Typography sx={{ fontSize: 12 }}>{color}</Typography>
      </Box>
    </Box>
  );
};

const ColorPicker = ({
  color,
  onChange,
}: {
  color: string;
  onChange: (newColor: string) => void;
}) => {
  //   const [color, setColor] = useState("");
  const [value, setValue] = useState(color);

  const handleInputChanged = (e) => {
    if (e.target.value.replace("#", "").length <= 6) {
      const a = tinycolor(e.target.value);

      onChange(a.toString("hex"));

      setValue(e.target.value);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        ".react-colorful": {
          width: "100%",
          borderRadius: 1,
        },

        ".react-colorful__saturation": {
          borderRadius: 1,
          borderBottom: 0,
          overflow: "hidden",
        },
        ".react-colorful__hue": {
          mt: 1,
          height: 12,

          borderRadius: 1,
          overflow: "hidden",
        },

        ".react-colorful__saturation-pointer": {
          width: 16,
          height: 16,
          cursor: "move",
        },
        ".react-colorful__hue-pointer": {
          width: 16,
          height: 16,
          cursor: "ew-resize",
        },
      }}
    >
      <HexColorPicker
        color={color}
        onChange={(newColor) => {
          onChange(newColor);
          setValue(newColor);
        }}
      />

      <Box sx={{ mt: 2 }}>
        <TextField
          label="hex"
          size="small"
          fullWidth
          value={value}
          onBlur={() => setValue(color)}
          onChange={handleInputChanged}
        />
      </Box>
    </Box>
  );
};

export default ColorPicker;
