import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  Button,
  Container,
  TextField,
  TextFieldProps,
  alpha,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useAtom, useAtomValue } from "jotai";
import { currentUserState } from "states/auth";
import { isSubmittedState } from "states/feedback";

import { getFirebaseController } from "database/FirebaseController";

import { useSnackbar } from "context/Snackbar/SnackbarContext";

import DashboardContainer from "components/Dashboard/DashboardContainer";

import { FieldError, UseFormReturn, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import zod from "zod";

import _ from "lodash";

const feedbackFormSchema = zod.object({
  firstName: zod.string().trim().min(1),
  lastName: zod.string().trim().min(1),
  email: zod.string().trim().email(),
  message: zod.string().trim().min(1),
});

const FormInput = ({
  label,
  error,
  TextFieldProps,
}: {
  label: string;
  error?: FieldError;
  TextFieldProps?: TextFieldProps;
}) => {
  return (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Typography sx={{ fontSize: 12, fontWeight: "bold", lineHeight: 1 }}>
          {label}{" "}
          <Box
            component={"span"}
            sx={{ color: ({ palette }) => palette.error.main }}
          >
            *
          </Box>
        </Typography>
      </Grid>

      <Grid item>
        <TextField
          size="small"
          fullWidth
          {...TextFieldProps}
          error={!!error}
          sx={{
            transition: ({ transitions }) => transitions.create("background"),
            ".MuiInputBase-root.Mui-disabled": {
              background: ({ palette }) => alpha(palette.onSurface.main, 0.1),
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

const FeedbackForm = ({
  onSubmit,
  register,
  errors,
  isLoading,
}: {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  register: UseFormReturn["register"];
  errors: UseFormReturn["formState"]["errors"];
  isLoading: boolean;
}) => {
  return (
    <Box component={"form"} onSubmit={onSubmit}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item sx={{ mb: 2 }}>
          <Box>
            <Typography sx={{ fontSize: 24 }}>
              Send us your feedback!
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: 14 }}>
              Your opinion is important to us.
            </Typography>
          </Box>
        </Grid>

        <Grid item container spacing={2} wrap="nowrap">
          <Grid item xs={6}>
            <FormInput
              label="First Name"
              error={errors["firstName"] as FieldError}
              TextFieldProps={{
                ...register("firstName"),
                error: !!errors["firstName"],
                disabled: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Last Name"
              error={errors["lastName"] as FieldError}
              TextFieldProps={{
                ...register("lastName"),
                error: !!errors["lastName"],
                disabled: isLoading,
              }}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <FormInput
              label="Email"
              error={errors["email"] as FieldError}
              TextFieldProps={{
                ...register("email"),
                error: !!errors["email"],
                disabled: isLoading,
              }}
            />
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" wrap="nowrap" gap={0.5}>
            <FormInput
              label="Message"
              error={errors["message"] as FieldError}
              TextFieldProps={{
                multiline: true,
                rows: 10,
                ...register("message"),
                error: !!errors["message"],
                disabled: isLoading,
              }}
            />
          </Grid>
        </Grid>

        <Grid item sx={{ width: "100%" }}>
          <Button
            type="submit"
            fullWidth
            size="small"
            variant="contained"
            sx={{
              textTransform: "none",
              color: ({ palette }) => `${palette.onPrimary.main} !important`,
              mt: 2,
              display: "block",
            }}
            disabled={isLoading}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const ThankYouPage = () => {
  return (
    <Box
      sx={{
        height: 400,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <CheckCircleIcon color="success" sx={{ fontSize: 70 }} />
        </Box>

        <Box>
          <Typography sx={{ fontSize: 22, textAlign: "center" }}>
            Thank you for your feedback!
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center" }}>
            Your form has been subbmited successfully.
          </Typography>
        </Box>

        <Box>
          <Button
            variant="outlined"
            component={Link}
            to={"/galleries"}
            startIcon={<ArrowBackIcon />}
            size="small"
            sx={{
              mt: 4,
              textTransform: "none",
            }}
          >
            Back to Galleries
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const FeedbackIndexWindow = () => {
  const [isSubmitted, setIsSubmitted] = useAtom(isSubmittedState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(feedbackFormSchema),
  });

  const currentUser = useAtomValue(currentUserState);

  const { setSnackbarProps } = useSnackbar();

  const handleFormSubmit = async (formData) => {
    if (currentUser) {
      setIsLoading(true);

      await getFirebaseController()
        .Feedback.addFeedBack(formData)
        .then(() => {
          setIsSubmitted(true);

          setIsLoading(false);

          setSnackbarProps({
            open: true,
            content: `Feedback submitted successfully!`,
          });
        })
        .catch(() => {
          setIsLoading(false);

          setSnackbarProps({
            open: true,
            content: `Feedback submit failed!`,
            severity: "error",
          });
        });
    }
  };

  return (
    <DashboardContainer NavbarProps={{ title: "Feedback" }}>
      <Container
        fixed
        maxWidth={"md"}
        sx={{
          height: "100%",
          px: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box
          sx={{
            // backgroundColor: "#dedbdc",
            p: {
              xs: 1,
              sm: 2,
            },
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: ({ palette }) => palette.surface.main,
              borderRadius: 1,
              boxShadow: 1,
              px: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              py: 4,
              position: "relative",
              color: ({ palette }) => palette.onSurface.main,
            }}
          >
            {isSubmitted ? (
              <ThankYouPage />
            ) : (
              <FeedbackForm
                isLoading={isLoading}
                onSubmit={handleSubmit(handleFormSubmit)}
                register={register}
                errors={errors}
              />
            )}
          </Box>
        </Box>
      </Container>
    </DashboardContainer>
  );
};

export default FeedbackIndexWindow;
