import { alpha, Theme, useTheme } from "@mui/material";
import {
  LocalizationProvider,
  DateCalendar,
  DateCalendarProps,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";

import { Moment } from "moment";
import { CSSProperties, useEffect, useMemo, useState } from "react";

const CustomDay = (
  props: PickersDayProps<Moment> & { currentDate?: Moment },
) => {
  const { currentDate, day, ...other } = props;

  const selected =
    currentDate?.format("YYYY-DD-MM") === day.format("YYYY-DD-MM");

  return (
    <PickersDay
      {...other}
      selected={selected}
      day={day}
      sx={{
        ":focus:not(:hover):not(.Mui-selected)": {
          backgroundColor: undefined,
        },
      }}
    />
  );
};

const CustomCalendar = (
  props: DateCalendarProps<Moment> & {
    onAccept: (date: Moment) => void;
    selectedBackgroundColor?:
      | CSSProperties["backgroundColor"]
      | ((theme: Theme) => CSSProperties["backgroundColor"]);
    selectedColor?:
      | CSSProperties["backgroundColor"]
      | ((theme: Theme) => CSSProperties["backgroundColor"]);
  },
) => {
  const { onAccept, selectedBackgroundColor, selectedColor } = props;

  // a hacky workaround for DateCalendar so that it won't close when just selecting year or month.
  const [localValue, setLocalValue] = useState<Moment | null>(null);

  const theme = useTheme();

  useEffect(() => {
    if (props.value) {
      setLocalValue(props.value);
    }
  }, [props.value]);

  const [backgroundColor, color] = useMemo(() => {
    const a = selectedBackgroundColor
      ? _.isFunction(selectedBackgroundColor)
        ? selectedBackgroundColor(theme)
        : selectedBackgroundColor
      : theme.palette.primary.main;

    const b = selectedColor
      ? _.isFunction(selectedColor)
        ? selectedColor(theme)
        : selectedColor
      : theme.palette.onPrimary.main;

    return [a, b];
  }, [selectedBackgroundColor, selectedColor, theme]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        views={["year", "month", "day"]}
        {...props}
        value={localValue}
        onChange={(date, state) => {
          if (date) {
            if (state === "finish") {
              onAccept(date as Moment);
            }

            setLocalValue(date);
          }
        }}
        reduceAnimations={true}
        slots={{
          day: CustomDay,
        }}
        slotProps={{
          day: {
            currentDate: props.value,
          } as any,
        }}
        autoFocus={false}
        sx={{
          maxHeight: 336,
          height: 336,

          ".MuiPickersMonth-monthButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected, .MuiPickersDay-root.Mui-selected":
            {
              backgroundColor: `${backgroundColor} !important`,
              color: `${color} !important`,

              ":hover": {
                opacity: 0.9,
              },
            },

          ".MuiPickersMonth-monthButton.Mui-disabled, .MuiPickersYear-yearButton.Mui-disabled, .MuiPickersDay-root.Mui-disabled":
            {
              color: ({ palette }) => alpha(palette.secondary.main, 0.4),
            },

          ".MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewButton":
            {
              color: ({ palette }) => palette.secondary.main,

              "&.Mui-disabled": {
                color: ({ palette }) => alpha(palette.secondary.main, 0.4),
              },
            },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomCalendar;
