import { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Circle, Path, Svg, SVGProps } from "@react-pdf/renderer";

interface FaPdfIconProps {
  faIcon?: IconDefinition;
  style?: SVGProps["style"] & { color?: string };
}

interface MuiPdfIconProps {
  icon: React.ElementType;
  size?: number;
  color?: string;
  className?: string;
}

export const FaPdfIcon = ({ faIcon, style = {} }: FaPdfIconProps) => {
  if (!faIcon) {
    return null;
  }

  const { icon } = faIcon;

  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];
  const color = style.color || "black";

  return (
    <Svg
      viewBox={`0 0 ${icon[0]} ${icon[1]}`}
      style={{ ...style, aspectRatio: 640 / 512 }}
    >
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
          />
        ))}
    </Svg>
  );
};

export const MuiPdfIcon = ({
  icon: Icon,
  size = 24,
  color = "black",
  className = "",
}: MuiPdfIconProps) => {
  const [paths, setPaths] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    // Render the icon to a string
    const iconString = ReactDOMServer.renderToString(<Icon />);

    // Create a temporary DOM element to parse the string
    const parser = new DOMParser();
    const doc = parser.parseFromString(iconString, "image/svg+xml");
    const svgElement = doc.querySelector("svg");

    if (svgElement) {
      const svgPaths = Array.from(svgElement.querySelectorAll("path")).map(
        (path, index) => (
          <Path key={index} d={path.getAttribute("d") || ""} fill={color} />
        ),
      );
      const svgCircles = Array.from(svgElement.querySelectorAll("circle")).map(
        (circle, index) => (
          <Circle
            key={index}
            cx={circle.getAttribute("cx") || ""}
            cy={circle.getAttribute("cy") || ""}
            r={circle.getAttribute("r") || ""}
            fill={color}
          />
        ),
      );
      setPaths([...svgPaths, ...svgCircles]);
    }
  }, [Icon, color]);

  return (
    <Svg viewBox="0 0 24 24" style={{ width: size, height: size }}>
      {paths}
    </Svg>
  );
};
