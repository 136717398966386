import { useMemo, useState } from "react";

import {
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Theme,
  alpha,
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";

import useLocalStorage from "hooks/useLocalStorage";

import GalleryGridWindow from "Windows/Gallery/GalleryGridWindow";

import _ from "lodash";

const COLUMN_OPTIONS = ["Auto", 3, 4, 5, 6];

const TVGalleryGridWindow = () => {
  const [columnMenuAnchorEl, setColumnMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [selectedColumnOption, setSelectedColumnOption] = useLocalStorage<
    string | number
  >("tv-gallery-column", "Auto");

  const handleColumnsChanged = (option) => {
    setSelectedColumnOption(option);
    setColumnMenuAnchorEl(null);
  };

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg"),
  );

  const gridColumns = useMemo(() => {
    if (isLargeScreen && _.isNumber(selectedColumnOption)) {
      return {
        xs: 12 / 1,
        sm: 12 / 2,
        md: 12 / selectedColumnOption,
      };
    } else {
      return {
        xs: 12 / 1,
        sm: 12 / 2,
        md: 12 / 3,
        lg: 12 / 4,
      };
    }
  }, [selectedColumnOption, isLargeScreen]);

  return (
    <Box sx={{ p: 1 }}>
      {isLargeScreen && (
        <Box sx={{ mb: 1 }}>
          <Box
            onClick={(e) => setColumnMenuAnchorEl(e.currentTarget)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              width: "max-content",
            }}
          >
            <ViewColumnIcon
              sx={{
                color: ({ palette }) => alpha(palette.onSecondary.main, 0.8),
                cursor: "pointer",
                ":hover": {
                  color: ({ palette }) => alpha(palette.onSecondary.main, 1),
                },
                transition: ({ transitions }) => transitions.create("color"),
              }}
            />
          </Box>

          <Menu
            anchorEl={columnMenuAnchorEl}
            open={Boolean(columnMenuAnchorEl)}
            onClose={() => setColumnMenuAnchorEl(null)}
            sx={{
              minWidth: 200,

              ".MuiList-root": {
                p: 0,
              },
            }}
          >
            <MenuItem
              dense
              sx={{
                background: ({ palette }) =>
                  `${palette.secondary.main} !important`,
                color: ({ palette }) => palette.onSecondary.main,
              }}
            >
              Columns
            </MenuItem>

            {COLUMN_OPTIONS.map((option) => {
              const isSelected = option === selectedColumnOption;

              return (
                <MenuItem
                  selected={isSelected}
                  dense
                  onClick={() => handleColumnsChanged(option)}
                  key={option}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      )}

      <GalleryGridWindow type={1} gridColumns={gridColumns} />
    </Box>
  );
};

export default TVGalleryGridWindow;
