import {
  useState,
  useEffect,
  useRef,
  useMemo,
  ReactElement,
  forwardRef,
  Ref,
  Dispatch,
  SetStateAction,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { currentClientState, currentUserState } from "states/auth";
import { useAtom, useAtomValue } from "jotai";

import {
  Box,
  Grid,
  Typography,
  TextField,
  Icon,
  IconButton,
  Chip,
  InputAdornment,
  TextFieldProps,
  MenuItem,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

import ConfirmationModal from "components/BaseModal/ConfirmationModal";
import { BasicModal } from "components/BaseModal/BaseModal";
import SettingsTable, {
  Column,
  columns,
  SettingConfirmationModal,
  SettingConfirmationModalParams,
  SettingConfirmationModalProps,
  SettingRow,
  SettingsTableHeaderButton,
  UserRoleRow,
} from "components/Dashboard/SettingsTable";
import DashboardEditContainer, {
  baseSettingAutocompleteProps,
  baseSettingTextFieldProps,
} from "components/Dashboard/DashboardEditContainer";
import Navbar from "components/Layouts/Navbar";

import { useSnackbar } from "context/Snackbar/SnackbarContext";
import { getDirtyFields } from "Windows/Device/DeviceEditWindow";

import {
  ROLE_ACCESS_LEVEL_DISPLAY_ORDER,
  ROLE_ACCESS_LEVEL_MAPPER,
  getDefaultUser,
  getDefaultUserRole,
} from "database/DataDefaultValues";
import {
  Client,
  JobSite,
  RoleAccessLevel,
  User,
  UserRole,
} from "database/DataTypes";

import { getFirebaseController } from "database/FirebaseController";

import _ from "lodash";

import DataChips from "components/Dashboard/DataChips";
import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import { SelectOption } from "utils/input";

export const PasswordTextField = forwardRef(
  (props: TextFieldProps, ref: Ref<HTMLDivElement>): ReactElement => {
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

    return (
      <TextField
        {...props}
        ref={ref}
        type={isShowPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                size="small"
                onClick={() => setIsShowPassword(!isShowPassword)}
                onMouseDown={(e) => e.preventDefault()}
                color="inherit"
              >
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          ...props.InputProps,
        }}
      />
    );
  },
);

const userRoleColumns: Column[] = [
  { id: "client", label: "Client", width: "20%" },
  { id: "accessLevel", label: "Access Level", width: "25%" },
  { id: "accessableJobSites", label: "Accessible Sites", width: "45%" },
  { id: "actions", label: "Actions", align: "center" },
];

type UserRolesModalTab = 0 | 1 | 2 | 3;

interface UserRolesModalParams {
  isOpen: boolean;
  tab: UserRolesModalTab;
  userRole: UserRole | null;
}

const UserEditWindow = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [initialUser, setInitialUser] = useState<User | null>(null);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  const [userJobSitesLookup, setUserJobSitesLookup] = useState<{
    [key: number]: JobSite;
  }>({});
  const [userClientLookup, setUserClientLookup] = useState<{
    [key: number]: Client;
  }>({});

  const [userName, setUserName] = useState<string>("");

  const [isEditPassword, setIsEditPassword] = useState(false);

  const [userRemoveModalParams, setUserRemoveModalParams] = useState<{
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const currentClient = useAtomValue(currentClientState);

  const isEdit = !!params.id;

  const { setSnackbarProps } = useSnackbar();

  const [clientListCache] = useAtom(clientListCacheState);
  const [jobsiteListCache] = useAtom(jobSitesListCacheState);

  const [jobSiteOptions, setJobSiteOptions] = useState<SelectOption<number>[]>(
    [],
  );

  const clientOptions = useMemo(() => {
    return clientListCache.map((client) => {
      return {
        label: client.name,
        value: client.id,
      };
    });
  }, [clientListCache]);

  const clientsLookup = useMemo(() => {
    return _.keyBy(clientListCache, "id");
  }, [clientListCache]);

  const accessLevelOptions = useMemo(() => {
    const options = Object.keys(ROLE_ACCESS_LEVEL_MAPPER).map((value) => {
      return {
        value,
        label: ROLE_ACCESS_LEVEL_MAPPER[value],
        order: ROLE_ACCESS_LEVEL_DISPLAY_ORDER[value],
      };
    });

    return _.orderBy(options, "order");
  }, []);

  const [isInheritSites, setIsInheritSites] = useState(false);

  const [formAccessLevel, setFormAccessLevel] = useState<RoleAccessLevel>("0");

  const [userState] = useAtom(currentUserState);

  const [confirmationModalParams, setConfirmationModalParams] =
    useState<SettingConfirmationModalParams>({
      isOpen: false,
      title: "",
      content: "",
      onDone: () => {},
    });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState,
  } = useForm({});

  const handleClientChange = (clientId) => {
    const displayJobSiteOptions: SelectOption<number>[] = [];

    jobsiteListCache.forEach((jobSite) => {
      if (jobSite.associatedClients.includes(clientId)) {
        displayJobSiteOptions.push({
          label: jobSite.name,
          value: jobSite.id as number,
        });
      }
    });

    setJobSiteOptions(displayJobSiteOptions);

    const jobSiteIds = getValues("userRole.accessableJobSites");

    const newSiteIds = jobSiteIds.filter((id) => {
      const client = clientsLookup[clientId];

      return client.sites.includes(id);
    });

    setValue("userRole.accessableJobSites", newSiteIds, { shouldDirty: true });
  };

  const initUserEdit = async () => {
    try {
      const firebaseController = getFirebaseController();

      const user = await firebaseController.User.getUser(params.id);

      if (!user) {
        throw new Error("User not found.");
      }

      const userRoles: UserRole[] =
        await firebaseController.User.getUserRolesByUserId(user.id as number);

      const promises: Promise<any>[] = [];

      const jobSitesLookup: { [key: number]: JobSite } = {};

      // only first role will be using now
      const role = userRoles[0];

      promises.push(
        firebaseController.JobSite.getJobSites({
          ids: role.accessableJobSites as number[],
        }).then((jobsites) => {
          jobsites.forEach((site) => {
            jobSitesLookup[site.id as number] = site;
          });
        }),
      );

      Promise.all(promises).then(() => {
        setUserJobSitesLookup(jobSitesLookup);
        // setUserClientLookup(clientsLookup);
      });

      setUserRoles(userRoles);
      setInitialUser(user);
      setUserName(user.username);

      const userRole = userRoles[0];

      if (userRole) {
        handleClientChange(userRole.associatedClient);
        setIsInheritSites(userRole.isInheritSites);
        setFormAccessLevel(userRole.accessLevel);
      }

      reset({
        user,
        userRole: userRoles[0],
      });
    } catch (err) {
      navigate("../");
    }
  };

  const initUserNew = async (clientId?: number) => {
    const client = clientId
      ? clientListCache.find((client) => {
          return clientId === client.id;
        })
      : currentClient;

    const user: User = getDefaultUser();
    const userRole: UserRole = {
      ...getDefaultUserRole(),
      associatedClient: client?.id || currentClient!.id,
    };

    setInitialUser(user);
    setUserRoles([userRole]);

    if (client) {
      setUserClientLookup({ [client.id as number]: client });
      handleClientChange(client.id as number);
    }

    reset({
      user: {
        ...user,
        username: "",
      },
      userRole,
    });
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    if (!userName || !currentClient) {
      setIsLoading(false);
      return;
    }

    const firebaseController = getFirebaseController();

    if (isEdit) {
      const editedData = getDirtyFields(formState.dirtyFields, data);

      if (_.isEmpty(editedData)) {
        setIsLoading(false);
        return;
      }

      const { user, userRole } = editedData;

      await firebaseController.User.updateUser(
        data.user.id,
        user,
        data.userRole.id,
        userRole,
      )
        .then(async () => {
          const clientIdParams = searchParams.get("clientId");

          if (clientIdParams) {
            navigate(`/clients/${clientIdParams}`);
          } else {
            await initUserEdit().then(() => {
              setSnackbarProps({
                open: true,
                content: `User updated successfully!`,
              });
            });
          }
        })
        .catch((err) => {
          console.error(err);

          setSnackbarProps({
            open: true,
            content: `User update failed!`,
            severity: "error",
          });
        });

      setIsLoading(false);
    } else {
      const { user, password, userRole } = data;

      if (!password && user.username) {
        setIsLoading(false);
        return;
      }

      await firebaseController.User.createUser(user, userRole, password)
        .then((result) => {
          setSnackbarProps({
            open: true,
            content: `User created successfully!`,
          });

          const clientIdParams = searchParams.get("clientId");

          if (clientIdParams) {
            navigate(`/clients/${clientIdParams}`);
          } else {
            navigate(`/clients/${userRole.associatedClient}`);
          }
        })
        .catch((err) => {
          setSnackbarProps({
            open: true,
            content: `User create failed!`,
            severity: "error",
          });

          console.error(err);
        });

      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (isEdit && initialUser) {
      setIsLoading(true);

      await getFirebaseController()
        .User.deleteUser(initialUser.id)
        .then((result) => {
          setSnackbarProps({
            open: true,
            content: `User deleted successfully!`,
          });

          navigate(`../`);
        })
        .catch((err) => {
          setSnackbarProps({
            open: true,
            content: `User delete failed!`,
            severity: "error",
          });

          console.error(err);
        });

      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    setIsLoading(true);

    const password = getValues("password");

    if (!password || !initialUser) {
      setIsLoading(false);

      setSnackbarProps({
        open: true,
        content: `Please fill in the new password!`,
        severity: "error",
      });

      return;
    }

    await getFirebaseController()
      .Auth.resetPassword(initialUser.userId, password)
      .then((results) => {
        if (results.status) {
          setIsEditPassword(false);
          setValue("password", "");
          setIsLoading(false);

          setSnackbarProps({
            open: true,
            content: `User's password reset successfully!`,
          });
        } else {
          throw new Error("");
        }
      })
      .catch((err) => {
        console.error(err);

        setSnackbarProps({
          open: true,
          content: `User's password reset failed!`,
          severity: "error",
        });

        setIsLoading(false);
      });
  };

  const handleUserRoleRemove = async (userRoleId) => {
    if (userRoleId && userRoles.length > 1) {
      return await getFirebaseController()
        .User.deleteUserRole(userRoleId)
        .then(() => {
          setUserRoles((prev) => prev.filter((role) => role.id !== userRoleId));

          setSnackbarProps({
            open: true,
            content: `User's role removed successfully!`,
          });
        })
        .catch((err) => {
          setSnackbarProps({
            open: true,
            content: `User's role remove failed!`,
          });

          console.error(err);
        });
    }
  };

  useEffect(() => {
    // use callback to prevent rerender on every field changed

    const subscription = watch((value, props) => {
      if (props.name === "user.username") {
        setUserName(value.user.username);
      } else if (props.name === "userRole.associatedClient") {
        handleClientChange(value.userRole.associatedClient);
      } else if (props.name === "userRole.accessLevel") {
        setFormAccessLevel(value.userRole.accessLevel);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsLoading(true);

    if (currentClient) {
      if (isEdit) {
        initUserEdit().then(() => setIsLoading(false));
      } else {
        const clientIdParams = searchParams.get("clientId");

        initUserNew(clientIdParams ? Number(clientIdParams) : undefined).then(
          () => setIsLoading(false),
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, currentClient]);

  const isAdminRole = formAccessLevel === "3";

  const userDetailsRow: SettingRow[] = useMemo(
    () => [
      {
        settingName: "Name",
        value: (
          <TextField
            {...baseSettingTextFieldProps}
            {...register("user.username", {
              required: true,
              setValueAs: (value) => {
                return value.trim();
              },
            })}
            disabled={isLoading}
            error={!!_.get(formState.errors, "user.username")}
          />
        ),
      },
      {
        settingName: "Password",
        value: (
          <>
            {isEdit ? (
              <>
                {isEditPassword ? (
                  <>
                    <PasswordTextField
                      {...baseSettingTextFieldProps}
                      {...register("password", { required: true })}
                      error={!!_.get(formState.errors, "password")}
                      placeholder="New password"
                      disabled={isLoading}
                    />

                    <Box sx={{ mt: 1 }}>
                      <Typography
                        onClick={() => setIsEditPassword(false)}
                        sx={{
                          display: "inline",
                          textDecoration: "underline",
                          fontSize: "14px",
                          cursor: "pointer",
                          color: ({ palette }) => palette.secondary.light,

                          ":hover": {
                            color: ({ palette }) => palette.secondary.main,
                          },
                        }}
                      >
                        Cancel
                      </Typography>

                      <Typography
                        onClick={handleResetPassword}
                        sx={{
                          ml: 1,
                          display: "inline",
                          textDecoration: "underline",
                          fontSize: "14px",
                          cursor: "pointer",
                          color: ({ palette }) => palette.error.dark,

                          ":hover": {
                            color: ({ palette }) => palette.error.main,
                          },
                        }}
                      >
                        Save
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Typography
                    onClick={() => setIsEditPassword(true)}
                    sx={{
                      textDecoration: "underline",
                      width: "max-content",
                      fontSize: "14px",
                      cursor: "pointer",
                      color: ({ palette }) => palette.primary.main,

                      ":hover": {
                        color: ({ palette }) => palette.primary.light,
                      },
                    }}
                  >
                    Reset Password
                  </Typography>
                )}
              </>
            ) : (
              <PasswordTextField
                {...baseSettingTextFieldProps}
                {...register("password", { required: true })}
                error={!!_.get(formState.errors, "password")}
                disabled={isLoading}
              />
            )}
          </>
        ),
      },
      {
        settingName: "Client",
        value: (
          <Box>
            <Controller
              control={control}
              name={"userRole.associatedClient"}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => {
                const client =
                  clientOptions.find((o) => o.value === field.value) || null;

                return (
                  <>
                    {isEdit ? (
                      <>
                        {client && (
                          <DataChips
                            items={[client]}
                            labelkey="label"
                            itemLink={(item) => {
                              return `/clients/${item?.value}`;
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <Autocomplete
                        {...baseSettingAutocompleteProps}
                        disabled={isLoading || isEdit}
                        disableClearable
                        onChange={(e, data) => field.onChange(data.value)}
                        value={
                          clientOptions.find((o) => o.value === field.value) ||
                          null
                        }
                        options={clientOptions}
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    )}
                  </>
                );
              }}
            />
          </Box>
        ),
      },
      {
        settingName: "Access Level",
        value: (
          <Controller
            control={control}
            name={"userRole.accessLevel"}
            defaultValue={"2"}
            render={({ field }) => (
              <TextField
                {...baseSettingTextFieldProps}
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                select
                disabled={isLoading}
              >
                {accessLevelOptions.map((option) => (
                  <MenuItem dense key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        ),
      },
      {
        settingName: "Inherit Job Sites",
        value: (
          <Controller
            control={control}
            name={"userRole.isInheritSites"}
            defaultValue={isInheritSites}
            render={({ field }) => (
              <Checkbox
                disableRipple
                disabled={isAdminRole}
                size="small"
                sx={{
                  p: 0,
                }}
                checked={isAdminRole || field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  setIsInheritSites(e.target.checked);

                  if (e.target.checked) {
                    setValue(
                      "userRole.accessableJobSites",
                      jobSiteOptions.map((option) => option.value),
                      { shouldDirty: true },
                    );
                  }
                }}
              />
            )}
          />
        ),
      },
      {
        settingName: "Accessible Sites",
        value: (
          <Controller
            control={control}
            name={"userRole.accessableJobSites"}
            defaultValue={[]}
            render={({ field }) => {
              return (
                <>
                  {isAdminRole ? (
                    <Typography sx={{ fontSize: 12 }}>All</Typography>
                  ) : isInheritSites ? (
                    <DataChips<SelectOption>
                      items={field.value.map(
                        (v) => jobSiteOptions.find((o) => o.value === v) || v,
                      )}
                      labelkey="label"
                      itemLink={(item) => `/job-sites/${item.value}`}
                    />
                  ) : (
                    <Autocomplete
                      {...baseSettingAutocompleteProps}
                      multiple
                      disabled={isLoading || isInheritSites}
                      disableCloseOnSelect
                      onChange={(e, data, reason) => {
                        if (isEdit && reason === "clear") {
                          setConfirmationModalParams({
                            isOpen: true,
                            title: `Remove all accessible job sites?`,
                            content:
                              "Are you sure you want to remove all accessible job site?",
                            onDone: () => {
                              field.onChange(
                                data.map((d) => d.value as number) || [],
                              );
                            },
                          });
                        } else {
                          field.onChange(
                            data.map((d) => d.value as number) || [],
                          );
                        }
                      }}
                      value={field.value.map(
                        (v) => jobSiteOptions.find((o) => o.value === v) || v,
                      )}
                      renderTags={(options, getTagProps) => {
                        return options.map((option, optionIndex) => (
                          <Chip
                            color="secondary"
                            size="small"
                            label={option?.label || option}
                            sx={({ palette }) => ({
                              borderRadius: 0.5,
                              backgroundColor: palette.secondary.light,
                              color: `${palette.white.main} !important`,
                            })}
                            {...getTagProps({ index: optionIndex })}
                            onDelete={(e) => {
                              e.stopPropagation();

                              if (isEdit) {
                                setConfirmationModalParams({
                                  isOpen: true,
                                  title: `Remove access to site "${option.label}"?`,
                                  content: `Are you sure you want to revoke user access to job site "${option.label}"?`,
                                  onDone: () => {
                                    getTagProps({
                                      index: optionIndex,
                                    }).onDelete(e);
                                  },
                                });
                              } else {
                                getTagProps({ index: optionIndex }).onDelete(e);
                              }
                            }}
                          />
                        ));
                      }}
                      options={jobSiteOptions}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  )}
                </>
              );
            }}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formState,
      control,
      isEdit,
      isLoading,
      register,
      clientOptions,
      accessLevelOptions,
      isEditPassword,
      jobSiteOptions,
      isInheritSites,
      isAdminRole,
    ],
  );

  const breadcrumbs = useMemo(() => {
    if (!initialUser) {
      return [];
    }

    return [
      {
        label: "Users",
        path: "/users",
      },
      isEdit
        ? {
            label: initialUser.username || initialUser.id?.toString() || "",
          }
        : {
            label: "New",
          },
    ];
  }, [initialUser, isEdit]);

  return (
    <Box>
      <Navbar isShowClient={!isEdit} title="User Dashboard" />

      {initialUser && (
        <>
          <ConfirmationModal
            title={`Remove this user?`}
            message={`Are you sure you want to remove ${initialUser.username}? This process cannot be undone.`}
            open={userRemoveModalParams.isOpen}
            onDone={handleDeleteUser}
            onClose={() =>
              setUserRemoveModalParams({
                isOpen: false,
              })
            }
          />
        </>
      )}

      <SettingConfirmationModal
        {...confirmationModalParams}
        onClose={() => {
          setConfirmationModalParams((prev) => {
            return {
              ...prev,
              isOpen: false,
              title: "",
              content: "",
            };
          });
        }}
      />

      <DashboardEditContainer
        breadcrumbs={breadcrumbs}
        title={
          isEdit
            ? initialUser?.username || "-"
            : ` New User ${userName ? `- ${userName}` : ""}`
        }
        subtitle="Fill in the user's info and press save when done."
        onSave={handleSubmit(handleSave, (errors) => {
          if (_.get(errors, "user.username") || _.get(errors, "password")) {
            setSnackbarProps({
              open: true,
              content: `Username and password cannot be empty.`,
              severity: "error",
            });
          }
        })}
        onDelete={
          // cannot delete own user
          userState && userState.id?.toString() !== params.id && isEdit
            ? () => {
                setUserRemoveModalParams({
                  isOpen: true,
                });
              }
            : undefined
        }
        isLoading={isLoading}
      >
        <Grid container direction="column" wrap="nowrap" gap={4}>
          <Grid item>
            <SettingsTable
              title={"User Details"}
              rows={userDetailsRow}
              columns={columns}
            />
          </Grid>
        </Grid>
      </DashboardEditContainer>
    </Box>
  );
};

export default UserEditWindow;
