import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  TabsProps,
  TextField,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TopToolBar, TopToolBarButton } from "./ImageViewerWindow";
import {
  DeviceV2,
  GalleryV2,
  UnixEpoch,
  VisualCrossingWeather,
  VisualCrossingWeatherConditions,
} from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";
import ImageHandlerV2, {
  Image,
  Thumbnail,
  WeatherLocation,
} from "database/ImageHandlerV2";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useRef, useEffect, useState, useMemo, Fragment } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  currentGalleryClientState,
  currentGalleryJobsiteState,
  currentWeatherImageState,
  gridComponentCahcesState,
  imageViewerComponentCahcesState,
  selectedImageState,
  weatherReportComponentCahcesState,
} from "states/imageViewer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useAccess from "hooks/useAccess";
import {
  ArrowBack,
  BrokenImage,
  Download,
  ExpandMore,
} from "@mui/icons-material";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useImageViewerTracker from "hooks/eventTracker/useImageViewerTracker";
import FontAwesomeSvgIcon from "components/Icons/FontAwesomeSvgIcon";
import useWeather, {
  getFaWeatherIcon,
  FaWeatherIconName,
  getFaWeatherColor,
  WeatherStation,
  AggregatedWeatherHourData,
  AggregatedWeatherDayData,
  testHourData,
  testWeatherData,
} from "hooks/useWeather";
import LineChart, { LineChartOptions } from "components/Charts/LineChart";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";
import LDSRingLoader from "components/Loaders/LDSRingLoader";
import { momentWithTimeZoneHOF } from "hooks/useTimeZone";
import useLocalStorage from "hooks/useLocalStorage";
import { getDisplayValueWithUnit, getDisplayValue } from "utils/display";
import { createSelectOption } from "utils/input";
import { getContrastShade } from "theme/reliveItTheme";
import { toPng } from "html-to-image";
import { WeatherPdf } from "./WeatherPdf";
import { pdf } from "@react-pdf/renderer";

const DATE_RANGE_OPTIONS = [
  createSelectOption("Past Week"),
  createSelectOption("Past 15 Days"),
];

const CustomizedDot = (props: any) => {
  const { payload, dotDataKey, color } = props;

  const windDirection = (_.get(payload, dotDataKey) + 180) % 360;

  const size = 20;
  const mid = size / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={props.cx - mid}
      y={props.cy - mid}
      height={`${size}px`}
      width={`${size}px`}
      viewBox="0 0 24 24"
      fill={color || "#08b0d9"}
    >
      <path
        d="M0 0h24v24H0z"
        fill="none"
        transform={`rotate(${windDirection} 12 12)`}
      />
      <path
        d="M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z"
        transform={`rotate(${windDirection} 12 12)`}
      />
    </svg>
  );
};

const ThumbnailCard = ({
  thumbnail,
  imageHandler,
  onClick,
  thumbnailSrc,
  setThumbnailSrc,
}: {
  thumbnail: Thumbnail<Image> | undefined;
  imageHandler: ImageHandlerV2 | null;
  onClick?: () => {};
  thumbnailSrc: string;
  setThumbnailSrc: (src: string) => void;
}) => {
  // const [thumbnailSrc, setThumbnailSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const setSelectedImage = useSetAtom(selectedImageState);

  const navigate = useNavigate();
  const params = useParams();

  const handleImageClick = () => {
    if (thumbnail) {
      setSelectedImage(thumbnail.image);

      navigate(`../${params.id}/image-viewer`);
    }
  };

  const getThumbnail = async () => {
    if (imageHandler) {
      if (thumbnail) {
        setIsLoading(true);

        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setThumbnailSrc(thumbnail.thumbnail);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (imageHandler && thumbnail) {
      getThumbnail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setThumbnailSrc("");
      setIsLoaded(false);
    };
  }, [imageHandler, thumbnail]);

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          backgroundColor: ({ palette }) =>
            !isLoading && !thumbnailSrc
              ? alpha(palette.tertiary.main, 0.6)
              : isLoading || !isLoaded
              ? alpha(palette.tertiary.main, 0.4)
              : "none",
        }}
      >
        {isLoading || (thumbnailSrc && !isLoaded) ? (
          <Loader isShow={isLoading || (thumbnailSrc && !isLoaded)} />
        ) : (
          <>
            {!isLoading && !thumbnailSrc && (
              <BrokenImage fontSize="large" sx={{ color: "#dedbdc" }} />
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          // maxWidth: 400,
          display: "flex",
          ml: "auto",

          borderRadius: 2,
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          minHeight: thumbnailSrc
            ? 0
            : {
                xs: 150,
                sm: 250,
              },
        }}
        onClick={handleImageClick}
      >
        <ThumbnailPlaceHolder />

        {thumbnailSrc && (
          <Box
            component={"img"}
            crossOrigin="anonymous"
            src={thumbnailSrc}
            onLoad={() => setIsLoaded(true)}
            sx={{
              display: "block",
              width: "100%",
              objectFit: "contain",
              borderRadius: 2,
              userSelect: "none",
            }}
          />
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          mt: 1,
          display: "block",
          lineHeight: 1,
          textAlign: "center",

          color: ({ palette }) => palette.secondary.main,
        }}
      >
        {imageHandler && thumbnail
          ? `${moment(imageHandler.getImageTime(thumbnail.image)).format(
              "YYYY-MM-DD HH:mm:ss A",
            )}`
          : "-"}
      </Typography>
    </Box>
  );
};

const HourThumbnailCard = ({
  thumbnail,
  imageHandler,
  onClick,
}: {
  thumbnail: Thumbnail<Image> | undefined;
  imageHandler: ImageHandlerV2 | null;
  onClick: (thumbnail: Thumbnail<Image>) => void;
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const getThumbnail = async () => {
    if (imageHandler) {
      if (thumbnail) {
        setIsLoading(true);

        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setThumbnailSrc(thumbnail.thumbnail);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (imageHandler && thumbnail) {
      getThumbnail();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setThumbnailSrc("");
      setIsLoaded(false);
    };
  }, [imageHandler, thumbnail]);

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          backgroundColor: ({ palette }) =>
            !isLoading && !thumbnailSrc
              ? alpha(palette.tertiary.main, 0.6)
              : isLoading || !isLoaded
              ? alpha(palette.tertiary.main, 0.4)
              : "none",
        }}
      >
        {isLoading || (thumbnailSrc && !isLoaded) ? (
          <Loader isShow={isLoading || (thumbnailSrc && !isLoaded)} />
        ) : (
          <>
            {!isLoading && !thumbnailSrc && (
              <BrokenImage fontSize="large" sx={{ color: "#dedbdc" }} />
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: 150,
          display: "flex",
          ml: "auto",

          borderRadius: 2,
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          minHeight: thumbnailSrc
            ? 0
            : {
                xs: 100,
              },

          transition: ({ transitions }) =>
            transitions.create("transform", { duration: 100 }),
          ":hover": {
            transform: "scale(1.02)",

            "+ span": {
              color: ({ palette }) => palette.onSecondary.main,
              fontWeight: "bold",
            },
          },
        }}
        onClick={() => {
          if (thumbnail) {
            onClick(thumbnail);
          }
        }}
      >
        <ThumbnailPlaceHolder />

        {thumbnailSrc && (
          <Box
            component={"img"}
            crossOrigin="anonymous"
            src={thumbnailSrc}
            onLoad={() => setIsLoaded(true)}
            sx={{
              display: "block",
              width: "100%",
              objectFit: "contain",
              borderRadius: 2,
              userSelect: "none",
            }}
          />
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          mt: 1,
          display: "block",
          lineHeight: 1,
          textAlign: "center",
          color: ({ palette }) => palette.onSecondary.main,
          fontSize: 10,
        }}
      >
        {imageHandler && thumbnail
          ? moment(imageHandler.getImageTime(thumbnail.image)).format("HH:mm A")
          : "-"}
      </Typography>
    </Box>
  );
};

const HourDataAccordion = ({
  data,
  dataIndex,
  currentWeatherHourItemIndex,
  setCurrentWeatherHourItemIndex,
  imageHandler,
  setSelectedHourThumbnail,
}: {
  data: AggregatedWeatherHourData;
  dataIndex: number;
  currentWeatherHourItemIndex: number;
  setCurrentWeatherHourItemIndex: (index: number) => void;
  imageHandler: ImageHandlerV2 | null;
  setSelectedHourThumbnail: (thumbnail: Thumbnail<Image>) => void;
}) => {
  const isActive = currentWeatherHourItemIndex === dataIndex;

  const [thumbnails, setThumbnails] = useState<Thumbnail<Image>[]>([]);

  const setSelectedImage = useSetAtom(selectedImageState);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (imageHandler) {
      const thumbnails = imageHandler.thumbnails.filter((thumbnail) => {
        const imageHour = moment(
          imageHandler.getImageTime(thumbnail.image),
        ).format("HH");

        const dateHour = moment(data.vcData.datetime, "HH").format("HH");

        return imageHour === dateHour;
      });

      setThumbnails(thumbnails);
    }
  }, [imageHandler]);

  const isExpandable = thumbnails.length > 0;

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  return (
    <Accordion
      id={`hour_data_${dataIndex}_${currentWeatherHourItemIndex}`}
      disabled={!isExpandable}
      elevation={0}
      data-disabled={!isExpandable}
      expanded={isActive}
      onChange={() => {
        if (isActive) {
          setCurrentWeatherHourItemIndex(-1);
        } else {
          setCurrentWeatherHourItemIndex(dataIndex);
        }
      }}
      sx={{
        borderRadius: 1,
        scrollMargin: 120,
        scrollBehavior: "smooth",

        "&::before": {
          display: "none",
        },

        outline: ({ palette }) =>
          isActive
            ? `1px solid ${palette.secondary.main}`
            : `1px solid ${alpha(palette.secondary.main, 0.1)}`,
        "&.Mui-expanded": {
          my: 1,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMore
            sx={{ visibility: isExpandable ? "visible" : "hidden" }}
          />
        }
        sx={{
          p: 1,
          gap: 1,
          m: 0,
          minHeight: 0,
          ".MuiAccordionSummary-content": {
            m: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 1,
            overflow: "auto",
            flexWrap: mediumScreenAndUp ? "nowrap" : "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,

              width: {
                sm: "50%",
                md: "35%",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {moment(data.vcData.datetime, "HH:mm").format("HH:mm")}
            </Typography>

            <FontAwesomeSvgIcon
              icon={getFaWeatherIcon(data.vcData.icon as FaWeatherIconName)}
              sx={{
                fontSize: 22,
                color: getFaWeatherColor(data.vcData.icon as FaWeatherIconName),
              }}
            />

            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {getDisplayValueWithUnit(data.wzData.temperature, "°", false)}
            </Typography>
          </Box>

          {(mediumScreenAndUp || isActive) && (
            <Box
              sx={({ breakpoints }) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.5,
                width: "80%",
                // width: {
                //   xs: "100%",
                //   md: "50%",
                //   lg: "40%",
                // },
                p: 0.5,
                background: ({ palette }) => alpha(palette.secondary.main, 0.1),
                borderRadius: 1,

                [breakpoints.down("sm")]: {
                  p: 1,
                },
              })}
            >
              {[
                {
                  value: `${getDisplayValue(
                    data.wzData.wind_direction_compass,
                  )}`,
                  icon: getFaWeatherIcon("wind"),
                  title: "Wind Direction",
                },
                {
                  value: `${getDisplayValue(data.wzData.wind_speed, (value) => {
                    return `${value} Km/h (${(value / 3.6).toFixed(1)} m/s)`;
                  })}`,
                  icon: faDroplet,
                  title: "Wind Speed",
                },
                {
                  value: `${getDisplayValue(data.wzData.wind_gust, (value) => {
                    return `${value} Km/h (${(value / 3.6).toFixed(1)} m/s)`;
                  })}`,
                  icon: faDroplet,
                  title: "Wind Gust",
                },
                {
                  value: getDisplayValueWithUnit(
                    data.wzData.rainfall_since_9am,
                    "mm",
                  ),
                  icon: faDroplet,
                  title: "Rain To 9am",
                },
              ].map((data, dataIndex) => {
                return (
                  <Fragment key={dataIndex}>
                    {dataIndex > 0 && (
                      <Divider flexItem orientation="vertical" />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // gap: 1,
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {data.title}
                      </Typography>

                      {/* <FontAwesomeSvgIcon
                        icon={data.icon}
                        sx={{
                          fontSize: 14,
                        }}
                      /> */}

                      <Typography
                        sx={{
                          fontSize: 12,
                          textAlign: "center",
                        }}
                      >
                        {data.value}
                      </Typography>
                    </Box>
                  </Fragment>
                );
              })}
            </Box>
          )}
        </Box>
      </AccordionSummary>

      {isExpandable && (
        <AccordionDetails sx={{ p: 1, paddingTop: 0 }}>
          <Box
            sx={{
              p: 1,
              borderRadius: 1,

              background: ({ palette }) =>
                getContrastShade(palette.secondary, "light"),
            }}
          >
            <Box
              sx={{
                display: "flex",
                overflow: "auto",

                gap: 1,
                // minHeight: 100,
                // height: 100,
              }}
            >
              {thumbnails.map((thumbnail, thumbnailIndex) => {
                return (
                  <Box key={thumbnailIndex}>
                    <HourThumbnailCard
                      thumbnail={thumbnail}
                      imageHandler={imageHandler}
                      onClick={(thumbnail) => {
                        // navigate(`../${params.id}/image-viewer`);
                        // setSelectedImage(thumbnail.image);

                        const element = document.getElementById(
                          `day_summary_container`,
                        );

                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }

                        setSelectedHourThumbnail(thumbnail);
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const ChartTabs = ({
  chartsData,
  TabsProps,
  isLoading,
  onItemClick,
}: {
  chartsData: any;
  TabsProps?: TabsProps;
  isLoading?: boolean;
  onItemClick?: (data, dataIndex) => void;
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const currentChartData = useMemo(() => {
    return chartsData[currentTab];
  }, [currentTab, chartsData]);

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={(e, newTab) => setCurrentTab(newTab)}
        sx={{
          background: ({ palette }) => alpha(palette.primary.main, 1),

          ".MuiTab-root": {
            color: ({ palette }) => palette.secondary.main,

            "&.Mui-selected": {
              background: ({ palette }) =>
                alpha(getContrastShade(palette.primary, "dark"), 1),
              color: ({ palette }) => palette.onPrimary.main,
            },
          },

          ".MuiTabs-indicator": {
            background: ({ palette }) => palette.onPrimary.main,
          },
        }}
        {...TabsProps}
      >
        {chartsData.map((item, itemIndex) => {
          return (
            <Tab key={itemIndex} value={itemIndex} label={item.tabLabel} />
          );
        })}
      </Tabs>

      <Box
        sx={{
          flex: 1,
          overflow: "auto",

          background: ({ palette }) =>
            currentChartData.data.length > 0
              ? palette.surface.main
              : alpha(palette.onSurface.main, 0.1),

          p: 2,
        }}
      >
        <Box sx={{ minWidth: 500 }}>
          <LineChart
            id={currentChartData.title}
            data={currentChartData.data}
            options={currentChartData.options}
            title={currentChartData.title}
            onItemClick={onItemClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ImageViewerWeatherWindow = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingHoursData, setIsLoadingHoursData] = useState<boolean>(true);
  const [currentObject, setCurrentObject] = useState<DeviceV2 | GalleryV2>();
  const [imageHandler, setImageHandler] = useState<ImageHandlerV2 | null>(null);

  const [galleryLocation, setGalleryLocation] = useState<
    WeatherLocation | undefined
  >(undefined);
  const [dateRange, setDateRange] = useState<UnixEpoch[]>([]);

  const [weatherDaysData2, setWeatherDaysData2] = useState<
    AggregatedWeatherDayData[]
  >([]);

  const [weatherHoursData2, setWeatherHoursData2] = useState<
    AggregatedWeatherHourData[]
  >([]);

  const [componentCahces, setComponentCahces] = useAtom(
    weatherReportComponentCahcesState,
  );

  const [imageViewerComponentCahces] = useAtom(imageViewerComponentCahcesState);

  const [customDateRange, setCustomDateRange] = useState<UnixEpoch[]>([]);

  const [dateRangeOption, setDateRangeOption] = useState(
    DATE_RANGE_OPTIONS[0].value,
  );

  const [selectedStation, setSelectedStation] = useState<string>();

  const [stationList, setStationList] = useState<WeatherStation[]>([]);
  const [usedStations, setUsedStations] = useState<WeatherStation[]>([]);

  const [currentWeatherItemIndex, setCurrentWeatherItemIndex] = useState(-1);
  const [currentWeatherHourItemIndex, setCurrentWeatherHourItemIndex] =
    useState(-1);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { palette } = useTheme();

  const { trackAction, trackImage } = useImageViewerTracker(
    imageHandler,
    "weather",
    true,
  );

  const { getWeather } = useWeather();

  const { isBlocked, isAccessable, isSiteAccessable, isAdmin } = useAccess();

  const [weatherStationCaches, setWeatherStationCaches] = useLocalStorage(
    "weather-staions",
    {},
  );

  const compCachesRef = useRef<any>(null);
  const firstRender = useRef(true);
  const skipEffects = useRef({
    imageHandler: false,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const isDevice = !!location.pathname.match("/devices/");

  const [thumbnailSrc, setThumbnailSrc] = useState("");

  const momentTz = useMemo(() => {
    return momentWithTimeZoneHOF(galleryLocation?.timeZone);
  }, [galleryLocation]);

  useEffect(() => {
    compCachesRef.current = {
      galleryLocation,
      dateRange,
      customDateRange,
      weatherDaysData: weatherDaysData2,
      weatherHoursData: weatherHoursData2,
      currentWeatherItemIndex,
      currentWeatherHourItemIndex,
      selectedStation,
      stationList,
      usedStations,
      selectedHourThumbnail,
      imageHandler,
    };
  });

  useEffect(() => {
    setIsLoading(true);

    const viewerCaches = imageViewerComponentCahces[params.id as string];
    const caches = componentCahces[params.id as string];

    if (caches) {
      const imageHandler = caches.imageHandler as ImageHandlerV2;

      setCurrentObject(imageHandler.currentObject);
      setImageHandler(imageHandler);
      setDateRange(caches.dateRange);
      setCustomDateRange(caches.customDateRange);
      setGalleryLocation(caches.galleryLocation);
      setWeatherDaysData2(caches.weatherDaysData);
      setWeatherHoursData2(caches.weatherHoursData);
      setCurrentWeatherItemIndex(caches.currentWeatherItemIndex);
      setCurrentWeatherHourItemIndex(caches.currentWeatherHourItemIndex);
      setSelectedStation(caches.selectedStation);
      setStationList(caches.stationList);
      setUsedStations(caches.usedStations);
      setSelectedHourThumbnail(caches.selectedHourThumbnail);

      setIsLoading(false);
      setIsLoadingHoursData(false);

      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = caches.scrollTop || 0;
        }
      }, 100);

      skipEffects.current.imageHandler = true;
    } else if (viewerCaches) {
      const imageHandler = viewerCaches.imageHandler as ImageHandlerV2;

      setCurrentObject(imageHandler.currentObject);
      setImageHandler(imageHandler);

      initWeather(imageHandler, [
        momentTz(
          imageHandler.getImageTime(
            imageHandler.thumbnails[viewerCaches.currentThumbnailIndex].image,
          ),
          { keepLocalTime: true },
        )
          .startOf("day")
          .unix(),
      ]);

      setSelectedHourThumbnail(
        imageHandler.thumbnails[viewerCaches.currentThumbnailIndex],
      );

      setIsLoading(false);

      skipEffects.current.imageHandler = true;
    } else {
      setImageHandler(null);
      setDateRangeOption(DATE_RANGE_OPTIONS[0].value);
      setSelectedStation("");
      setStationList([]);
      setUsedStations([]);
      initImageHandler();
    }

    return () => {
      // TODO: try implement component cache, so that when user navigate back it doens't nid to be re-init again

      setComponentCahces((caches) => {
        return {
          ...caches,
          [params.id as string]: _.cloneDeep(compCachesRef.current),
        };
      });

      setSelectedHourThumbnail(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (!firstRender.current && imageHandler) {
      if (skipEffects.current.imageHandler) {
        skipEffects.current.imageHandler = false;

        return;
      }

      setIsLoading(true);

      imageHandler
        .getLatestImages()
        .then(async () => {
          const latestImage = imageHandler.images[0];

          // setWeatherHoursData2(testHourData as AggregatedWeatherHourData[]);
          // setWeatherDaysData2(testWeatherData as AggregatedWeatherDayData[]);
          // setIsLoading(false);
          // setCurrentWeatherItemIndex(0);
          // setIsLoadingHoursData(false);

          setSelectedHourThumbnail(imageHandler.thumbnails[0]);

          // start of day so that we can cache the data by date, we don't care about exact time anyway
          initWeather(imageHandler, [
            momentTz(imageHandler.getImageTime(latestImage), {
              keepLocalTime: true,
            })
              .startOf("day")
              .unix(),
          ]);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageHandler]);

  // this must be place on last
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const initWeatherData = async (
    location: WeatherLocation,
    dateRange: number[],
    station?: WeatherStation,
    dayIndex?: number,
  ) => {
    setIsLoading(true);

    setGalleryLocation(location);
    setDateRange(dateRange);

    setSelectedDate(dateRange[1] || dateRange[0]);

    await getWeather(location.param, dateRange, {
      include: ["days"],
      timeZone: location.timeZone,
    }).then(async (data) => {
      const weatherData = data?.days;

      if (weatherData) {
        const currentIndex = dayIndex || weatherData.length - 1;

        setWeatherDaysData2(weatherData);

        getHoursData(
          weatherData[currentIndex],
          currentIndex,
          location,
          station,
        );

        if (data.wzData.related_location?.name) {
          setUsedStations([data.wzData.related_location.name]);
        }
      }

      setIsLoading(false);
    });
  };

  const handleDateRangeInputChange = async (e) => {
    setIsLoading(true);

    if (!imageHandler || dateRangeOption === e.target.value) {
      return;
    }

    try {
      const newDateRange = dateRange;

      switch (e.target.value) {
        case "Past Week":
          // trackAction("filter past week");

          newDateRange[0] = moment
            .unix(newDateRange[1])
            .subtract(1, "weeks")
            .unix();

          break;
        case "Past 15 Days":
          // trackAction("filter 15 days");

          newDateRange[0] = moment
            .unix(newDateRange[1])
            .subtract(15, "days")
            .unix();

          break;

        default:
          return;
      }

      setDateRangeOption(e.target.value);

      await initWeather(
        imageHandler,
        newDateRange,
        selectedStation,
        currentWeatherItemIndex,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const initImageHandler = async () => {
    setIsLoading(true);

    const getData = async () =>
      isDevice
        ? await getFirebaseController().Device.getDevice(params.id)
        : await getFirebaseController().Gallery.getGallery(params.id);

    await getData()
      .then(async (currentDevice) => {
        if (!currentDevice) {
          throw new Error(`${isDevice ? "Divice" : "Gallery"} not found.`);
        }

        if (
          !isDevice &&
          !isAdmin &&
          !isSiteAccessable((currentDevice as GalleryV2).jobSite)
        ) {
          navigate("/");
          return;
        }

        const imageHandler = new ImageHandlerV2(currentDevice, isDevice);

        setCurrentObject(currentDevice);
        setImageHandler(imageHandler);
      })
      .catch((err) => {
        console.error(err);
        navigate("../");
      });
  };

  const initWeather = async (
    imageHandler: ImageHandlerV2,
    dateRange: UnixEpoch[],
    station?: WeatherStation,
    dayIndex?: number,
  ) => {
    if (imageHandler) {
      setIsLoading(true);

      const parsedDateRange = dateRange;

      if (dateRange.length === 1) {
        // default to 1 weeks if only 1 date in range

        parsedDateRange.unshift(
          moment.unix(dateRange[0]).subtract(1, "weeks").unix(),
        );
      }

      await imageHandler.getWeatherLocation().then(async (location) => {
        if (location) {
          return await initWeatherData(
            location,
            parsedDateRange,
            station,
            dayIndex,
          );
        }
      });

      setIsLoading(false);
    }
  };

  const getHoursData = async (
    dayData: AggregatedWeatherDayData,
    dayDataIndex: number,
    location: WeatherLocation,
    station?: WeatherStation,
  ) => {
    setCurrentWeatherItemIndex(dayDataIndex);

    setIsLoadingHoursData(true);

    await getWeather(location.param, [dayData.vcData.datetimeEpoch], {
      include: ["hours"],
      timeZone: location.timeZone,
    }).then((data) => {
      const hoursData = data?.hours;

      if (hoursData) {
        setWeatherHoursData2(hoursData);
      }

      setIsLoadingHoursData(false);
    });
  };

  const [selectedDate, setSelectedDate] = useState<number>(moment().unix());

  const handleDayClick = async (
    data: AggregatedWeatherDayData,
    dataIndex: number,
  ) => {
    const element = document.getElementById(`day_summary_container`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }

    setIsLoading(true);

    if (imageHandler && galleryLocation) {
      const startDate = moment(data.vcData.datetime).startOf("days").unix();
      const endDate = moment(data.vcData.datetime).endOf("days").unix();

      setSelectedDate(startDate);

      await imageHandler.filter(startDate, endDate).then(async () => {
        setIsLoading(false);

        setSelectedHourThumbnail(imageHandler.thumbnails[0]);

        await getHoursData(data, dataIndex, galleryLocation, selectedStation);
      });
    }
  };

  const handleHourClick = async (
    data: VisualCrossingWeatherConditions,
    dataIndex: number,
  ) => {
    if (data) {
      const element = document.getElementById(`hour_data_${dataIndex}`);

      if (element && element.getAttribute("data-disabled") === "false") {
        setCurrentWeatherHourItemIndex(dataIndex);

        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 250);
      }
    }
  };

  const breadcrumbs = useMemo(() => {
    if (!currentObject || !isAdmin) {
      return [];
    }

    if (isDevice) {
      const device = currentObject as DeviceV2;
      return [
        {
          label: "Devices",
          path: "/devices",
        },
        {
          label: device.friendlyName || device.deviceId || "",
          path: `/devices/${device.id}`,
        },
        {
          label: "Image Viewer",
          path: `/devices/${device.id}/image-viewer`,
        },
        {
          label: "Weather Report",
        },
      ];
    } else {
      const gallery = currentObject as GalleryV2;

      return [
        {
          label: "Galleries",
          path: "/galleries",
        },
        {
          label: gallery.galleryName || "",
          path: `/galleries/${gallery.id}`,
        },
        {
          label: "Image Viewer",
          path: `/galleries/${gallery.id}/image-viewer`,
        },
        {
          label: "Weather Report",
        },
      ];
    }
  }, [isDevice, isAdmin, currentObject]);

  const [isDownloading, setIsDownloading] = useState(false);

  const buttons: TopToolBarButton[] = [
    {
      name: "download",
      description: "Download PDF",
      icon: <Download />,
      isDisabled: isLoading || isLoadingHoursData || isDownloading,
      onClick: () => {
        handleDownloadPdf();
      },
    },
    {
      name: "back",
      description: "Back to Image Viewer",
      icon: <ArrowBack />,
      onClick: () => {
        const toPath = location.pathname.split("/").slice(0, -1).join("/");

        navigate(toPath);
      },
    },
  ];

  const currentWeatherItem: AggregatedWeatherDayData = useMemo(() => {
    return weatherDaysData2[currentWeatherItemIndex];
  }, [currentWeatherItemIndex, weatherDaysData2]);

  const {
    hoursTemperatureOptions,
    hoursWindSpeedOptions,
    hoursPrecipOptions,
  }: { [key: string]: LineChartOptions } = useMemo(() => {
    const baseOptions: Partial<LineChartOptions> = {
      xKey: "vcData.datetime",
      xLabel: `Time`,
      xInterval: 0,
      yInterval: 0,
      yDomain: [
        (dataMin) => Math.floor(dataMin - 3),
        (dataMax) => Math.floor(dataMax + 3),
      ],
      xTickFormatter: (value, i) => {
        return moment(value, "HH").format("HH");
      },
      showLegend: false,
      minHeight: 200,
    };

    return {
      hoursTemperatureOptions: {
        ...baseOptions,
        colors: [palette.error.main],
        yLabel: "Temperature (°C)",
        yKey: "wzData.temperature",
        unit: "°C",
      },
      hoursWindSpeedOptions: {
        ...baseOptions,
        colors: [palette.primary.main],
        yKey: "wzData.wind_speed",
        yLabel: "Wind Speed (Km/h)",
        yDot: (
          <CustomizedDot
            color={getContrastShade(palette.primary, "dark")}
            dotDataKey={"wzData.wind_direction"}
          />
        ),
        unit: "Km/h",
        yDomain: [0, (dataMax) => Math.floor(dataMax + 3)],
        toolTipValueFormatter: (payload) => {
          const { payload: data, value } = payload[0];

          if (data) {
            return `${data.wzData.wind_direction_compass} ${value} Km/h`;
          } else {
            return `${value} Km/h`;
          }
        },
      },
      hoursPrecipOptions: {
        ...baseOptions,
        colors: [palette.warning.main],
        yKey: "wzData.rainfall_since_9am",
        yLabel: "Rain To 9am (mm)",
        unit: "mm",
        yDomain: [0, (dataMax) => Math.floor(dataMax + 3)],
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeatherItem]);

  const [selectedHourThumbnail, setSelectedHourThumbnail] =
    useState<Thumbnail<Image>>();

  const {
    temperatureOptions,
    windSpeedOptions,
    precipOptions,
  }: { [key: string]: LineChartOptions } = useMemo(() => {
    const baseOptions: Partial<LineChartOptions> = {
      xKey: "vcData.datetime",
      xLabel: `Date`,
      xInterval: "preserveEnd",
      yInterval: 0,
      yDomain: [
        (dataMin) => Math.floor(dataMin - 3),
        (dataMax) => Math.floor(dataMax + 3),
      ],
      //   xDomain: ["auto", "auto"],
      xTickFormatter: (value, i) => {
        return moment(value).format("MMM DD");
      },
      showLegend: false,
      minHeight: 200,
    };

    return {
      temperatureOptions: {
        ...baseOptions,
        colors: [palette.error.main],
        yLabel: "Temperature (°C)",
        yKey: "wzData.max",
        unit: "°C",
      },
      windSpeedOptions: {
        ...baseOptions,
        colors: [palette.primary.main],
        yLabel: "Wind Speed (Km/h)",
        yKey: "wzData._max_wind_speed",
        yDot: (
          <CustomizedDot
            color={getContrastShade(palette.primary, "dark")}
            dotDataKey={"wzData._max_wind_direction"}
          />
        ),
        unit: "Km/h",
        yDomain: [0, (dataMax) => Math.floor(dataMax + 3)],
        toolTipValueFormatter: (payload) => {
          const { payload: data, value } = payload[0];

          if (data) {
            return `${data.wzData._max_wind_direction_compass} ${value} Km/h`;
          } else {
            return `${value} Km/h`;
          }
        },
      },
      precipOptions: {
        ...baseOptions,
        colors: [palette.warning.main],
        yLabel: "Rain To 9am (mm)",
        yKey: "wzData.rainfall",
        unit: "mm",
        yDomain: [0, (dataMax) => Math.floor(dataMax + 3)],
      },
    };
  }, [currentWeatherItem]);

  // const [maxWindSpeed, maxWindGust] = useMemo(() => {
  //   let maxWindSpeed;
  //   let maxWindGust;

  //   weatherHoursData2.forEach((data) => {
  //     if (data.wzData.wind_speed > (maxWindSpeed || 0)) {
  //       maxWindSpeed = data.wzData.wind_speed;
  //     }

  //     if (data.wzData.wind_gust > (maxWindGust || 0)) {
  //       maxWindGust = data.wzData.wind_gust;
  //     }
  //   });

  //   return [maxWindSpeed, maxWindGust];
  // }, [weatherHoursData2]);

  const handleDateInputChange = async (newDate: Moment) => {
    if (imageHandler) {
      const timeStamp = newDate.unix();

      if (timeStamp >= dateRange[0] && timeStamp <= dateRange[1]) {
        const index = weatherDaysData2.findIndex((data) => {
          return data.vcData.datetime === newDate.format("YYYY-MM-DD");
        });

        await handleDayClick(weatherDaysData2[index], index);
      } else {
        const newDateRange = [timeStamp, timeStamp];

        switch (dateRangeOption) {
          case "Past Week":
            newDateRange[0] = moment
              .unix(newDateRange[1])
              .subtract(1, "weeks")
              .unix();

            break;
          case "Past 15 Days":
            newDateRange[0] = moment
              .unix(newDateRange[1])
              .subtract(15, "days")
              .unix();

            break;
        }

        await initWeather(imageHandler, newDateRange).then(() => {});
      }
    }
  };

  const hourChartsData = [
    {
      title: "Temperature",
      options: hoursTemperatureOptions,
      data: weatherHoursData2,
      tabLabel: "Temperature",
    },
    {
      title: "Wind Speed",
      options: hoursWindSpeedOptions,
      data: weatherHoursData2,
      tabLabel: "Wind",
    },
    {
      title: "Rain To 9am",
      options: hoursPrecipOptions,
      data: weatherHoursData2,
      tabLabel: "Rain To 9am",
    },
  ];
  const ref = useRef();

  // const [chartImage, setChartImage] = useState("");

  // useEffect(() => {
  //   const generateImage = async () => {
  //     const node = ref.current;

  //     if (node) {
  //       const dataUrl = await toPng(node);

  //       setChartImage(dataUrl);
  //     }
  //   };

  //   if (!isLoadingHoursData) {
  //     generateImage();
  //   }
  // }, [ref, isLoadingHoursData]);

  const client = useAtomValue(currentGalleryClientState);
  const site = useAtomValue(currentGalleryJobsiteState);

  const handleDownloadPdf = async () => {
    setIsDownloading(true);

    const node = ref.current;

    try {
      if (currentObject && node && imageHandler && thumbnailSrc) {
        const chartImage = await toPng(node);

        console.log();

        const blob = await pdf(
          <WeatherPdf
            chartImage={chartImage}
            dayData={weatherDaysData2[currentWeatherItemIndex]}
            hoursData={weatherHoursData2}
            title={
              isDevice
                ? (currentObject as DeviceV2).frontendName
                : [
                    client?.name,
                    site?.name,
                    (currentObject as GalleryV2).galleryName,
                  ]
                    .filter((v) => v)
                    .join(" - ")
            }
            thumbnailSrc={thumbnailSrc}
            thumbnailDateTime={
              selectedHourThumbnail
                ? `${moment(
                    imageHandler.getImageTime(selectedHourThumbnail.image),
                  ).format("YYYY-MM-DD HH:mm:ss A")}`
                : "-"
            }
            timeZone={galleryLocation?.timeZone}
            stations={usedStations}
            hourlyDataThumbnails={imageHandler.getHourlyThumnails()}
          />,
        ).toBlob();

        const pdfURL = URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.download = `weather_report_${moment()
          .tz(galleryLocation?.timeZone || "UTC")
          .format("YYYY_MM_DD_HHmmss")}.pdf`;
        a.href = pdfURL;

        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);

      setIsDownloading(false);
    }

    setIsDownloading(false);
  };

  return (
    <Box
      onScroll={(e) => {
        compCachesRef.current.scrollTop = e.currentTarget.scrollTop;
      }}
      ref={containerRef}
      sx={({ palette }) => ({
        width: "100vw",
        height: "100vh",
        backgroundColor: palette.secondary.main,
        color: palette.onSecondary.main,
        position: "fixed",
        overflow: "auto",
        ".MuiIconButton-root": {
          color: alpha(palette.onSecondary.main, 0.6),
          transition: ({ transitions }) => transitions.create("all"),

          ":hover": {
            color: palette.onSecondary.main,
          },

          "&.Mui-disabled": {
            color: alpha(palette.onSecondary.main, 0.4),
          },
        },
      })}
    >
      <TopToolBar buttons={buttons} imageHandler={imageHandler} />

      <Box
        sx={({ palette, customConfig }) => ({
          py: customConfig.imageViewerNavbarHeight,
          backgroundColor: `${palette.secondary.main} !important`,
          height: "100%",
          width: "100%",
        })}
      >
        <Container
          fixed
          maxWidth={"lg"}
          sx={{
            p: {
              xs: 2,
              sm: 3,
            },
            pt: 0,
          }}
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} sx={{ pb: 2 }} />

          <Box pb={2}>
            <Grid
              container
              alignItems={"stretch"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Grid item display={"flex"} alignItems={"center"}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month", "day"]}
                    minDate={moment.unix(imageHandler?.dateRange[0] || 0)}
                    maxDate={moment.unix(imageHandler?.dateRange[1] || 0)}
                    disabled={!imageHandler || isLoading}
                    value={selectedDate ? moment.unix(selectedDate) : undefined}
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPickersMonth-monthButton.Mui-disabled, .MuiPickersYear-yearButton.Mui-disabled":
                            {
                              color: ({ palette }) =>
                                alpha(palette.secondary.main, 0.4),
                            },
                        },
                      },
                      textField: {
                        size: "small",
                        color: "onBackground",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              alpha(getContrastShade(palette.secondary), 0.2),
                            color: ({ palette }) => palette.onBackground.main,
                            outline: ({ palette }) =>
                              `${palette.onSecondary.main} !important`,

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    onAccept={(date) => {
                      handleDateInputChange(date as Moment);
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item display={"flex"} alignItems={"center"}>
                <TextField
                  size="small"
                  select
                  variant={"outlined"}
                  onChange={handleDateRangeInputChange}
                  value={dateRangeOption}
                  disabled={!imageHandler || isLoading}
                  sx={{
                    width: 200,
                    ".MuiInputBase-root": {
                      backgroundColor: ({ palette }) =>
                        getContrastShade(palette.secondary, "light"),
                      color: ({ palette }) => palette.onSecondary.main,
                      outline: ({ palette }) =>
                        `${palette.onSecondary.main} !important`,
                      fontSize: {
                        xs: 14,
                        md: 16,
                      },

                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) =>
                          alpha(palette.onSecondary.main, 1),
                      },

                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) => palette.primary.main,
                      },

                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: ({ palette }) =>
                          alpha(palette.onSecondary.main, 0.5),
                      },
                    },
                  }}
                >
                  {DATE_RANGE_OPTIONS.map((option, optionIndex) => {
                    return (
                      <MenuItem
                        dense
                        key={optionIndex}
                        value={option.value as string}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>

            {dateRangeOption === "Custom Month" && imageHandler && (
              <Grid item display={"flex"} alignItems={"center"} gap={1} mt={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["year", "month"]}
                    minDate={moment
                      .unix(imageHandler!.dateRange[0])
                      .startOf("month")}
                    maxDate={moment
                      .unix(imageHandler!.dateRange[1])
                      .endOf("month")}
                    disabled={isLoading}
                    value={moment.unix(customDateRange[0])}
                    // slots={{
                    //   day: CustomPickersDay,
                    // }}
                    slotProps={{
                      textField: {
                        size: "small",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              getContrastShade(palette.secondary, "light"),
                            color: ({ palette }) => palette.onSecondary.main,
                            outline: ({ palette }) =>
                              `${palette.onSecondary.main} !important`,

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    // onChange={(date) =>
                    //   handleDateInputChange(date as Moment, "month")
                    // }
                  />
                </LocalizationProvider>
              </Grid>
            )}

            {dateRangeOption === "Custom Range" && imageHandler && (
              <Grid item display={"flex"} alignItems={"center"} gap={1} mt={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    minDate={moment.unix(imageHandler!.dateRange[0])}
                    maxDate={moment.unix(imageHandler!.dateRange[1])}
                    disabled={isLoading}
                    value={moment.unix(customDateRange[0])}
                    // slots={{
                    //   day: CustomPickersDay,
                    // }}
                    slotProps={{
                      textField: {
                        size: "small",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              getContrastShade(palette.secondary, "light"),
                            color: ({ palette }) => palette.onSecondary.main,
                            outline: ({ palette }) =>
                              `${palette.onSecondary.main} !important`,

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    // onChange={(date) =>
                    //   handleDateInputChange(date as Moment, "start")
                    // }
                  />
                </LocalizationProvider>

                <Typography>To</Typography>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    minDate={moment.unix(imageHandler!.dateRange[0])}
                    maxDate={moment.unix(imageHandler!.dateRange[1])}
                    disabled={isLoading}
                    value={moment.unix(customDateRange[1])}
                    // slots={{
                    //   day: CustomPickersDay,
                    // }}
                    slotProps={{
                      textField: {
                        size: "small",

                        sx: {
                          width: 200,
                          ".MuiInputBase-root": {
                            backgroundColor: ({ palette }) =>
                              getContrastShade(palette.secondary, "light"),

                            color: ({ palette }) => palette.onSecondary.main,
                            outline: ({ palette }) =>
                              `${palette.onSecondary.main} !important`,

                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 1),
                            },

                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                palette.primary.main,
                            },

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 0.5),
                            },
                          },
                        },
                      },
                    }}
                    // onChange={(date) =>
                    //   handleDateInputChange(date as Moment, "end")
                    // }
                  />
                </LocalizationProvider>
              </Grid>
            )}

            <Box>
              <Box
                sx={{
                  mt: 2,
                  background: ({ palette }) => palette.surface.main,
                  borderRadius: 1,
                  boxShadow: 1,
                  color: ({ palette }) => palette.onSurface.main,
                  overflow: "hidden",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{
                      height: 320,
                      width: "100%",
                      background: ({ palette }) =>
                        alpha(palette.onSurface.main, 0.1),
                    }}
                  />
                ) : (
                  <ChartTabs
                    chartsData={[
                      {
                        title: "Temperature",
                        options: temperatureOptions,
                        data: weatherDaysData2,
                        tabLabel: "Temperature",
                      },
                      {
                        title: "Wind Speed",
                        options: windSpeedOptions,
                        data: weatherDaysData2,
                        tabLabel: "Wind",
                      },
                      {
                        title: "Rain To 9am",
                        options: precipOptions,
                        data: weatherDaysData2,
                        tabLabel: "Rain To 9am",
                      },
                    ]}
                    onItemClick={handleDayClick}
                  />
                )}
              </Box>

              <Box
                sx={{
                  borderRadius: 1,

                  color: ({ palette }) => palette.onSurface.main,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    py: 2,
                  }}
                >
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        height: 200,
                        width: "100%",
                        background: ({ palette }) =>
                          alpha(palette.onSurface.main, 0.1),
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "stretch",
                        overflow: "scroll",
                        gap: 1,
                      }}
                    >
                      {weatherDaysData2.map((data, dataIndex) => {
                        const isActive = currentWeatherItemIndex === dataIndex;

                        const day = moment(data.vcData.datetime).format("ddd");
                        const isWeekend = day === "Sun" || day === "Sat";

                        return (
                          <Box
                            key={data.vcData.datetime}
                            sx={{
                              background: ({ palette }) => palette.surface.main,
                              borderRadius: 1,
                            }}
                          >
                            <Box
                              key={data.vcData.datetime}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",

                                height: 210,
                                cursor: "pointer",
                                minWidth: 65,
                                width: 65,

                                transition: ({ transitions }) =>
                                  transitions.create("all"),

                                borderRadius: 1,
                                p: 1,
                                background: ({ palette }) =>
                                  isActive
                                    ? alpha(palette.warning.main, 0.5)
                                    : palette.surface.main,

                                ":hover": {
                                  background: ({ palette }) =>
                                    isActive
                                      ? alpha(palette.warning.main, 0.7)
                                      : alpha(palette.onSurface.main, 0.1),
                                },
                              }}
                              onClick={() => handleDayClick(data, dataIndex)}
                            >
                              <FontAwesomeSvgIcon
                                icon={getFaWeatherIcon(
                                  data.vcData.icon as FaWeatherIconName,
                                )}
                                sx={{
                                  fontSize: 20,
                                  color: getFaWeatherColor(
                                    data.vcData.icon as FaWeatherIconName,
                                  ),
                                }}
                              />
                              <Box
                                sx={{
                                  mt: 1,
                                  textAlign: "center",
                                  color: ({ palette }) =>
                                    isWeekend
                                      ? palette.secondary.dark
                                      : getFaWeatherColor(
                                          data.vcData.icon as FaWeatherIconName,
                                        ),
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {day}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  {moment(data.vcData.datetime).format("DD")}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 8, fontWeight: "bold" }}
                                >
                                  {moment(data.vcData.datetime).format(
                                    "MMM, YY",
                                  )}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  textAlign: "center",
                                  mt: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 14, fontWeight: "bold" }}
                                >
                                  {getDisplayValueWithUnit(
                                    data.wzData.max,
                                    "°",
                                  )}
                                </Typography>
                              </Box>

                              <Box sx={{ mt: "auto", textAlign: "center" }}>
                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                    mb: 1,
                                  }}
                                >
                                  {getDisplayValueWithUnit(
                                    data.wzData._max_wind_speed,
                                    " Km/h",
                                  )}
                                </Typography>

                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                    mb: 1,
                                  }}
                                >
                                  {getDisplayValueWithUnit(
                                    data.wzData._max_wind_gust,
                                    "Km/h",
                                  )}
                                </Typography>

                                <FontAwesomeSvgIcon
                                  icon={faDroplet}
                                  sx={{
                                    fontSize: 12,
                                    color: ({ palette }) =>
                                      alpha(palette.info.light, 0.5),
                                  }}
                                />

                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                  }}
                                >
                                  {getDisplayValue(
                                    data.wzData.rainfall,
                                    (precip) => {
                                      return `${(precip as number).toFixed(
                                        1,
                                      )} mm`;
                                    },
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                id="day_summary_container"
                sx={{
                  background: ({ palette }) => palette.surface.main,

                  scrollMargin: ({ customConfig }) =>
                    customConfig.imageViewerNavbarHeight,

                  borderRadius: 1,
                  boxShadow: 1,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    background: ({ palette }) =>
                      alpha(getContrastShade(palette.primary, "light"), 1),
                    color: ({ palette }) => palette.onPrimary.main,
                    boxShadow: 1,
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    position: "sticky",
                    zIndex: 10,
                    top: 50,
                  }}
                >
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "bold", lineHeight: 1 }}
                  >
                    {`Summary - ${moment(
                      currentWeatherItem?.wzData?.date,
                    ).format("ddd, MMM DD, YYYY")}`}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    p: 2,
                    color: ({ palette }) => palette.onSurface.main,
                    gap: 4,
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}
                      >
                        <FontAwesomeSvgIcon
                          icon={getFaWeatherIcon(
                            currentWeatherItem?.vcData
                              ?.icon as FaWeatherIconName,
                          )}
                          sx={{
                            fontSize: 80,
                            pb: 0.5,
                            color: getFaWeatherColor(
                              currentWeatherItem?.vcData
                                ?.icon as FaWeatherIconName,
                            ),
                          }}
                        />

                        <Box>
                          <Typography
                            sx={{
                              fontSize: 24,
                              fontWeight: "bold",
                              lineHeight: 1,
                            }}
                          >
                            {getDisplayValueWithUnit(
                              currentWeatherItem?.wzData?.max,
                              "°C",
                            )}
                          </Typography>

                          <Typography
                            sx={{
                              mt: 1.5,
                              fontSize: 14,
                              fontWeight: "bold",
                              lineHeight: 1,
                            }}
                          >
                            {getDisplayValue(
                              currentWeatherItem?.vcData?.conditions,
                            )}
                          </Typography>

                          <Typography sx={{ fontSize: 12 }}>
                            {getDisplayValue(
                              currentWeatherItem?.vcData?.description,
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      {/* <Box sx={{ mt: 2 }}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            lineHeight: 1,
                          }}
                        >
                          {getDisplayValue(
                            currentWeatherItem?.vcData?.conditions,
                          )}
                        </Typography>

                        <Typography sx={{ fontSize: 12 }}>
                          {getDisplayValue(
                            currentWeatherItem?.vcData?.description,
                          )}
                        </Typography>
                      </Box> */}
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      {[
                        [
                          {
                            label: "Wind Speed",
                            value: `${getDisplayValue(
                              currentWeatherItem?.wzData._max_wind_speed,
                              (value) =>
                                `${value} Km/h (${(value / 3.6).toFixed(
                                  1,
                                )} m/s)`,
                            )}`,
                          },
                          {
                            label: "Wind Gust",
                            value: `${getDisplayValue(
                              currentWeatherItem?.wzData._max_wind_gust,
                              (value) =>
                                `${value} Km/h (${(value / 3.6).toFixed(
                                  1,
                                )} m/s)`,
                            )}`,
                          },
                        ],

                        [
                          {
                            label: "Rain To 9am",
                            value: getDisplayValueWithUnit(
                              currentWeatherItem?.wzData?.rainfall,
                              "mm",
                            ),
                          },
                          {
                            label: "Solar Energy",
                            value: getDisplayValue(
                              currentWeatherItem?.wzData?.insolation,
                              (solarenergy) => {
                                return `${(
                                  Number(solarenergy) * 277.78
                                ).toFixed(2)} Wh/m²`;
                              },
                            ),
                          },
                        ],
                        [
                          {
                            label: "Sun Rise",
                            value: getDisplayValue(
                              currentWeatherItem?.vcData?.sunrise,
                              (value) =>
                                moment(value, "HH:mm:ss").format("HH:mm"),
                            ),
                          },
                          {
                            label: "Sun Set",
                            value: getDisplayValue(
                              currentWeatherItem?.vcData?.sunset,
                              (value) =>
                                moment(value, "HH:mm:ss").format("HH:mm"),
                            ),
                          },
                        ],
                        [
                          {
                            label: "Time Zone",
                            value: `${getDisplayValue(
                              galleryLocation?.timeZone,
                            )}`,
                          },
                          {
                            label: "Offset",
                            value: `${
                              currentWeatherItem
                                ? momentTz().format("Z z")
                                : "-"
                            }`,
                          },
                        ],
                      ].map((row, rowIndex) => {
                        return (
                          <Fragment key={rowIndex}>
                            <Divider sx={{ my: 1 }} />

                            <Box
                              sx={({ breakpoints }) => ({
                                display: "flex",
                                justifyContent: "space-between",
                                gap: 4,

                                [breakpoints.down("sm")]: {
                                  flexDirection: "column",
                                  gap: 1,
                                },
                              })}
                            >
                              {row.map((data, dataIndex) => {
                                return (
                                  <Box
                                    key={dataIndex}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flex: 1,
                                      gap: 0.5,
                                    }}
                                  >
                                    <Typography sx={{ fontSize: 14 }}>
                                      {data.label}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        textAlign: "end",
                                      }}
                                    >
                                      {data.value}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Fragment>
                        );
                      })}

                      <Divider sx={{ my: 1 }} />

                      <Box
                        sx={({ breakpoints }) => ({
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 4,

                          [breakpoints.down("sm")]: {
                            flexDirection: "column",
                            gap: 1,
                          },
                        })}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 14, whiteSpace: "nowrap" }}
                          >
                            Station Used
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: "bold",
                              textAlign: "start",
                              wordBreak: "break-word",
                            }}
                          >
                            {usedStations.map((s) => s).join(", ")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "50%",
                        lg: "40%",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: ({ palette }) =>
                        alpha(palette.tertiary.main, 0.2),
                      border: ({ palette }) =>
                        `1px solid ${alpha(palette.onTertiary.main, 0.1)}`,
                      borderRadius: 2,
                      p: 1,
                      m: "auto",
                    }}
                  >
                    <ThumbnailCard
                      thumbnail={selectedHourThumbnail}
                      imageHandler={imageHandler}
                      thumbnailSrc={thumbnailSrc}
                      setThumbnailSrc={setThumbnailSrc}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: ({ palette }) => palette.surface.main,
                  }}
                >
                  <Box
                    sx={{
                      background: ({ palette }) =>
                        alpha(palette.tertiary.main, 0.2),

                      color: ({ palette }) => palette.onSurface.main,
                    }}
                  >
                    {isLoadingHoursData ? (
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{
                          height: 300,
                          width: "100%",
                          background: ({ palette }) =>
                            alpha(palette.onSurface.main, 0.1),
                        }}
                      />
                    ) : (
                      <>
                        <Box>
                          <ChartTabs
                            TabsProps={{
                              sx: {
                                background: ({ palette }) =>
                                  palette.secondary.main,
                                minHeight: 44,

                                ".MuiTab-root": {
                                  color: ({ palette }) =>
                                    `${palette.onSecondary.main} !important`,
                                  fontSize: 12,
                                  p: 1,
                                  minHeight: 44,

                                  "&.Mui-selected": {
                                    background: ({ palette }) =>
                                      getContrastShade(
                                        palette.secondary,
                                        "dark",
                                      ),
                                  },
                                },

                                ".MuiTabs-indicator": {
                                  background: ({ palette }) =>
                                    palette.onSecondary.main,
                                },
                              },
                            }}
                            chartsData={hourChartsData}
                            onItemClick={handleHourClick}
                          />
                        </Box>

                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: -1,
                            height: "100%",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            ref={ref}
                            sx={{
                              width: 1000,
                            }}
                          >
                            {hourChartsData.map((data, dataIndex) => {
                              return (
                                <Box sx={{ mb: 1 }} key={dataIndex}>
                                  <LineChart
                                    id={data.title}
                                    data={data.data}
                                    options={{
                                      ...data.options,
                                      isAnimationActive: false,
                                    }}
                                    title={data.title}
                                  />
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            p: 2,
                          }}
                        >
                          {weatherHoursData2.map((data, dataIndex) => {
                            return (
                              <HourDataAccordion
                                key={dataIndex}
                                data={data}
                                dataIndex={dataIndex}
                                currentWeatherHourItemIndex={
                                  currentWeatherHourItemIndex
                                }
                                setCurrentWeatherHourItemIndex={
                                  setCurrentWeatherHourItemIndex
                                }
                                imageHandler={imageHandler}
                                setSelectedHourThumbnail={
                                  setSelectedHourThumbnail
                                }
                              />
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                color: ({ palette }) => palette.onBackground.main,

                mt: 4,
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                {`Report Generated: ${moment()
                  .tz(galleryLocation?.timeZone || "UTC")
                  .format("HH:mm A z, Do MMMM YYYY ")}`}
              </Typography>

              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                Information supplied by Weatherzone based on data provided by
                the Bureau of Meteorology (BOM)
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ImageViewerWeatherWindow;
