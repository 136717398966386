import {
  Comment,
  DeviceV2,
  DropBoxImage,
  FirebaseImage,
  GalleryV2,
  ImageExtras,
  ImageHost,
  PhotoSentielImage,
  UnixEpoch,
  User,
} from "./DataTypes";
import { getDefaultComment } from "./DataDefaultValues";

import { getFirebaseController } from "./FirebaseController";
import PhotoSentielController from "./PhotoSentinelController";

import beerLoading from "assets/BEERAssets/BeerLoading.gif";
import { FilterInputs } from "Windows/ImageViewer/ImageViewerGridWindow";

import moment from "moment";
import prettyBytes from "pretty-bytes";

import _ from "lodash";
import { getDropboxController } from "./DropboxController";

export interface AllImageExtrasMapper {
  [key: number | string]: ImageExtras[] | undefined;
}

export interface UserImageExtrasMapper {
  [key: number | string]: ImageExtras | undefined;
}

export interface ImageTagMapper {
  [key: number]: string[];
}

export interface Thumbnail<T extends Image> {
  thumbnail: string;
  image: T;
  fulRes: string;
  searching: boolean;
  cacheThumbnail: HTMLImageElement | null;
  selected: boolean;
  findingFullRes: boolean;
  file?: HTMLElement;
  fullResBlob?: Blob;
  event?: Promise<string>;
}

export type Image = FirebaseImage | DropBoxImage | PhotoSentielImage;

interface ThumbnailCaches {
  [key: string]: string;
}

interface FullResCaches {
  [key: string]: string;
}

// global caches, in case of instance got discarded while fethcing thumbnail.
const _thumbnailCaches = {};

export type WeatherLocation = {
  param: string;
  timeZone: string;
  type: "city" | "geo";
};

export type ImageFilterOptions = {
  filterInputs?: FilterInputs[];
  filtersUpdated?: boolean;
  filterImageIds?: (string | number)[] | null;
  isOverlay?: boolean;
  hiddenImageIds?: (string | number)[];
};

export default class ImageHandlerV2 {
  private static _instance: ImageHandlerV2 | null;

  currentObject!: DeviceV2 | GalleryV2;
  isDevice!: boolean;
  hostUsed!: ImageHost;
  dateRange!: [UnixEpoch, UnixEpoch];
  fullDateRange!: [UnixEpoch, UnixEpoch];
  filterStartDate!: UnixEpoch;
  filterEndDate!: UnixEpoch;
  filterImageIds!: (string | number)[] | null;
  overlayFilterStartDate!: UnixEpoch;
  overlayFilterEndDate!: UnixEpoch;
  allImageExtrasMapper!: AllImageExtrasMapper;
  userImageExtrasMapper!: UserImageExtrasMapper;
  images!: Image[];
  displayImages!: Image[];
  overlayDisplayImages!: Image[];
  thumbnails!: Thumbnail<Image>[];
  overlayThumbnails!: Thumbnail<Image>[];
  imageTags!: ImageTagMapper;
  allImageTags!: string[];
  thumbnailCaches!: ThumbnailCaches;
  fullResCaches!: FullResCaches;
  weatherLocation!: WeatherLocation | undefined;
  hiddenImageIds!: (string | number)[];
  hiddenImageIdsUpdatedAt!: UnixEpoch | undefined;
  firstAndLastImage!: Image[];

  constructor(object: DeviceV2 | GalleryV2, isDevice = true) {
    if (!object) {
      throw new Error();
    }

    if (object.id === ImageHandlerV2._instance?.currentObject?.id) {
      return ImageHandlerV2._instance;
    }

    ImageHandlerV2._instance = this;

    this.currentObject = object;

    this.isDevice = isDevice;
    this.hostUsed = this.isDevice
      ? "0"
      : (this.currentObject as GalleryV2).galleryImageHost;

    const currentDate: UnixEpoch = moment().unix();

    this.dateRange = [currentDate, currentDate];
    this.fullDateRange = [currentDate, currentDate];

    this.filterStartDate = currentDate;
    this.filterEndDate = currentDate;
    this.filterImageIds = null;
    this.overlayFilterStartDate = currentDate;
    this.overlayFilterEndDate = currentDate;
    this.allImageExtrasMapper = {};
    this.userImageExtrasMapper = {};
    this.images = [];
    this.displayImages = [];
    this.overlayDisplayImages = [];
    this.thumbnails = [];
    this.overlayThumbnails = [];
    this.imageTags = {};
    this.allImageTags = [];
    this.thumbnailCaches = {};
    this.fullResCaches = {};
    this.weatherLocation = undefined;
    this.hiddenImageIds = [];
    this.hiddenImageIdsUpdatedAt = undefined;
    this.firstAndLastImage = [];
  }

  initOverlay() {
    this.overlayDisplayImages = _.cloneDeep(this.displayImages);
    this.overlayThumbnails = _.cloneDeep(this.thumbnails);
    this.overlayFilterStartDate = this.filterStartDate;
    this.overlayFilterEndDate = this.filterEndDate;
  }

  getCurrentObjectName(): string {
    return ImageHandlerV2.getObjectName(this.currentObject);
  }

  async getLatestImages(includeHidden: boolean = false): Promise<void> {
    switch (this.hostUsed) {
      case "0":
        return await this.getImagesFirebase(includeHidden).catch((err) =>
          console.error(err),
        );

      case "1":
        return await this.getImagesDropbox(includeHidden).catch((err) =>
          console.error(err),
        );

      case "2":
        return await this.getImagesPhotoSentinel().catch((err) =>
          console.error(err),
        );

      default:
    }
  }

  async getImagesFirebase(includeHidden: boolean = false): Promise<void> {
    const firebaseController = getFirebaseController();

    const { image: firstImage, displayImage: firstDisplayImage } =
      await firebaseController.Image.getFirstImage(
        this.currentObject.id as number,
        this.isDevice,
      );

    const { image: lastImage, displayImage: lastDisplayImage } =
      await firebaseController.Image.getLastImage(
        this.currentObject.id as number,
        this.isDevice,
      );

    if (!firstImage || !lastImage || !firstDisplayImage || !lastDisplayImage) {
      throw new Error("Image not found.");
    }

    this.firstAndLastImage = [firstDisplayImage, lastDisplayImage];

    this.dateRange = [firstDisplayImage.epochTime, lastDisplayImage.epochTime];
    this.fullDateRange = [firstImage.epochTime, lastImage.epochTime];

    const lastDate = includeHidden ? this.fullDateRange[1] : this.dateRange[1];

    await this.filter(lastDate, lastDate);
  }

  async getImagesPhotoSentinel(): Promise<void> {
    const [firstImage, lastImage] =
      await PhotoSentielController.getFirstAndLastImage(
        (this.currentObject as GalleryV2).assignedDevice as number,
      );

    this.dateRange = [
      moment(firstImage.datetime_taken_local).unix(),
      moment(lastImage.datetime_taken_local).unix(),
    ];

    this.firstAndLastImage = [firstImage, lastImage];

    await this.filter(this.dateRange[1], this.dateRange[1]);
  }

  async getImagesDropbox(includeHidden: boolean = false): Promise<void> {
    const dropboxController = getDropboxController();

    const [firstImage, lastImage] =
      await dropboxController.getFirstAndLastImage(
        (this.currentObject as GalleryV2).externalHostDirectory,
        { objectId: this.currentObject.id as number, includeHidden: true },
      );

    const [a, b] = await dropboxController.getFirstAndLastImage(
      (this.currentObject as GalleryV2).externalHostDirectory,
      { objectId: this.currentObject.id as number, includeHidden: false },
    );

    this.firstAndLastImage = [firstImage, lastImage];

    this.dateRange = [
      moment(this.getImageTime(a)).unix(),
      moment(this.getImageTime(b)).unix(),
    ];

    this.fullDateRange = [
      moment(this.getImageTime(firstImage)).unix(),
      moment(this.getImageTime(lastImage)).unix(),
    ];

    const lastDate = includeHidden ? this.fullDateRange[1] : this.dateRange[1];

    await this.filter(lastDate, lastDate);
  }

  async getImageExtras(thumbnail: Thumbnail<Image>): Promise<void> {
    if (thumbnail?.image) {
      const firebaseController = getFirebaseController();

      return await firebaseController.Image.getImageExtras(
        this.getThumbnailImageId(thumbnail),
        {
          isDevice: this.isDevice,
          objectId: this.currentObject.id as number | string,
        },
      )
        .then((allImageExtras) => {
          this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)] =
            allImageExtras;

          this.userImageExtrasMapper[this.getThumbnailImageId(thumbnail)] =
            allImageExtras.find(
              (ie) => ie.associatedUser === firebaseController.currentUser!.id,
            );
        })
        .catch(() => {});
    }
  }

  updateImageExtrasMapper(newImageExtras: ImageExtras, merge: boolean = false) {
    const imageExtra = merge
      ? {
          ...(this.userImageExtrasMapper[newImageExtras.imageApplied] || {}),
          ...newImageExtras,
        }
      : newImageExtras;

    this.userImageExtrasMapper[newImageExtras.imageApplied] = imageExtra;

    let allImageExtras =
      this.allImageExtrasMapper[newImageExtras.imageApplied] || [];

    const index = allImageExtras.findIndex(
      (imageExtras) => imageExtras.id === newImageExtras.id,
    );

    if (index === -1) {
      allImageExtras = [...allImageExtras, imageExtra];
    } else {
      allImageExtras[index] = imageExtra;
    }

    this.allImageExtrasMapper[newImageExtras.imageApplied] = allImageExtras;
  }

  async filter(
    startDate: UnixEpoch,
    endDate: UnixEpoch,
    {
      filterInputs,
      filtersUpdated = false,
      filterImageIds = null,
      isOverlay = false,
      hiddenImageIds,
    }: ImageFilterOptions = {},
  ): Promise<Image[]> {
    startDate = startDate || this.dateRange[1];
    endDate = endDate || this.dateRange[1];

    this.filterImageIds = filterImageIds;

    if (isOverlay) {
      this.overlayFilterStartDate = startDate;
      this.overlayFilterEndDate = endDate;
    } else {
      this.filterStartDate = startDate;
      this.filterEndDate = endDate;
    }

    const usedHiddenImageIds =
      hiddenImageIds || (await this.getHiddenImageIds());

    switch (this.hostUsed) {
      case "0":
        await this.filterFirebase(startDate, endDate, {
          filterInputs,
          filtersUpdated,
          filterImageIds,
          hiddenImageIds: usedHiddenImageIds,
          isOverlay,
        });
        break;
      case "1":
        await this.filterDropbox(startDate, endDate, {
          filterInputs,
          filtersUpdated,
          filterImageIds,
          hiddenImageIds: usedHiddenImageIds,
          isOverlay,
        });
        break;

      case "2":
        await this.filterPhotoSentinel(startDate, endDate, {
          filterInputs,
          filtersUpdated,
          filterImageIds,
          hiddenImageIds: usedHiddenImageIds,
          isOverlay,
        });
        break;
      default:
    }

    this.initThumbnails(isOverlay);

    return this.displayImages;
  }

  async getFavoritedImageIds() {
    const firebaseController = getFirebaseController();

    const imageExtras = await firebaseController.Image.getAllImageExtras({
      objectId: this.currentObject.id as number,
      isDevice: this.isDevice,
      userId: firebaseController.currentUser!.id as number,
      isFavorited: true,
    });

    const ids: (number | string)[] = [];

    imageExtras.forEach((imageExtra) => {
      ids.push(imageExtra.imageApplied);

      this.userImageExtrasMapper[imageExtra.imageApplied] = imageExtra;
    });

    return ids;
  }

  async getHiddenImageIds(reload = false) {
    const firebaseController = getFirebaseController();

    const ids: (number | string)[] = [];

    await firebaseController.Image.getHiddenImageExtras(
      this.currentObject.id as number,
      this.isDevice,
      {
        reload,
        resultCallback: (hiddenImageExtras, updated) => {
          if (updated || this.hiddenImageIds.length === 0) {
            hiddenImageExtras.forEach((imageExtra) => {
              ids.push(imageExtra.imageApplied);

              this.allImageExtrasMapper[imageExtra.imageApplied] = [
                ...(this.allImageExtrasMapper[imageExtra.imageApplied] || []),
                imageExtra,
              ];
            });

            this.hiddenImageIds = ids;
          } else {
            ids.push(...this.hiddenImageIds);
          }
        },
      },
    );

    return ids;
  }

  async getCommentedImageIds() {
    const imageExtras: ImageExtras[] =
      await getFirebaseController().Image.getAllImageExtras({
        objectId: this.currentObject.id as number,
        isDevice: this.isDevice,
        isCommented: true,
      });

    const ids: (number | string)[] = [];

    imageExtras.forEach((imageExtra) => {
      ids.push(imageExtra.imageApplied);

      this.allImageExtrasMapper[imageExtra.imageApplied] = [
        ...(this.allImageExtrasMapper[imageExtra.imageApplied] || []),
        imageExtra,
      ];

      if (
        imageExtra.associatedClient === getFirebaseController().currentUser!.id
      ) {
        this.userImageExtrasMapper[imageExtra.imageApplied] = imageExtra;
      }
    });

    return ids;
  }

  async getTaggedImageIds() {
    const imageExtras: ImageExtras[] =
      await getFirebaseController().Image.getAllImageExtras({
        objectId: this.currentObject.id as number,
        isDevice: this.isDevice,
        isTagged: true,
      });

    const ids: (number | string)[] = [];

    imageExtras.forEach((imageExtra) => {
      ids.push(imageExtra.imageApplied);

      this.allImageExtrasMapper[imageExtra.imageApplied] = [
        ...(this.allImageExtrasMapper[imageExtra.imageApplied] || []),
        imageExtra,
      ];

      if (
        imageExtra.associatedClient === getFirebaseController().currentUser!.id
      ) {
        this.userImageExtrasMapper[imageExtra.imageApplied] = imageExtra;
      }
    });

    return ids;
  }

  isDateMatched(startDate, endDate, image): boolean {
    const imageTime = moment(this.getImageTime(image)).unix();

    const isWithinTimeRange = imageTime >= startDate && imageTime <= endDate;

    return isWithinTimeRange;
  }

  isTagsMatched(thubmnail, filterTags: string[], operator: string) {
    const allImageTags = this.getImageTags(thubmnail);

    return filterTags.every((t) =>
      operator === "Is" ? allImageTags.includes(t) : !allImageTags.includes(t),
    );
  }

  isFavoritedMatched(thubmnail, filterFavorited: boolean, operator: string) {
    const result =
      filterFavorited ===
      this.getIsImageFavourited(thubmnail as Thumbnail<Image>);

    return operator === "Is" ? result : !result;
  }

  isCommentedMatch(thumbnail, filterCommented: boolean, operator: string) {
    const commented = this.getIsImageCommented(thumbnail);

    const result = commented === filterCommented;

    return operator === "Is" ? result : !result;
  }

  isHiddenMatch(thumbnail, filterHidden: boolean, operator: string) {
    const hidden = this.getIsImageHidden(thumbnail);

    const result = hidden === filterHidden;

    return operator === "Is" ? result : !result;
  }

  async handleHiddenImageCachesInit() {
    const hiddenImageIds = await this.getHiddenImageIds();

    this.images = this.images.filter((image) => {
      return !hiddenImageIds.includes(this.getImageId(image));
    });

    this.displayImages = this.displayImages.filter((image) => {
      return !hiddenImageIds.includes(this.getImageId(image));
    });

    this.thumbnails = this.thumbnails.filter((thumbnail) => {
      return !hiddenImageIds.includes(this.getThumbnailImageId(thumbnail));
    });
  }

  async filterFirebase(
    startDate: UnixEpoch,
    endDate: UnixEpoch,
    {
      filterInputs,
      filtersUpdated = false,
      filterImageIds = null,
      hiddenImageIds,
      isOverlay = false,
    }: ImageFilterOptions = {},
  ): Promise<FirebaseImage[]> {
    let displayImages: Image[] = [];

    startDate = moment.unix(startDate).startOf("days").utc().unix();
    endDate = moment.unix(endDate).endOf("days").utc().unix();

    // make a request for the images.
    // If it is filtering because of the filters, don't request for more images.
    if (!filtersUpdated || this.images.length === 0) {
      await getFirebaseController()
        .Image.getImages(this.currentObject.id as number, {
          isDevice: this.isDevice,
          startDate,
          endDate,
          imageIds: filterImageIds || undefined,
          hiddenImageIds: hiddenImageIds || undefined,
        })
        .then((images) => {
          this.images = images.filter((image) => {
            return !!image.timestamp;
          });

          return this.images;
        })
        .catch((error) => {
          console.error("Errored on getting firebase ", error);
        });
    }

    if (filterInputs && filterInputs.length > 0) {
      // filter by tag
      displayImages = (this.images as FirebaseImage[]).filter(
        (image): boolean => {
          const matches = [true];

          filterInputs?.forEach((input) => {
            switch (input.where) {
              case "Tags":
                matches.push(
                  this.isTagsMatched(
                    { image },
                    input.values as string[],
                    input.condition,
                  ),
                );
                break;
              case "Favorited":
                matches.push(
                  this.isFavoritedMatched(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              case "Commented":
                matches.push(
                  this.isCommentedMatch(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              case "Hidden":
                matches.push(
                  this.isHiddenMatch(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              default:
                break;
            }
          });
          return matches.every((m) => !!m);
        },
      );
    } else {
      displayImages = this.images;
    }

    if (isOverlay) {
      this.overlayDisplayImages = displayImages;
    } else {
      this.displayImages = displayImages;
    }

    return displayImages as FirebaseImage[];
  }

  async filterDropbox(
    startDate: number,
    endDate: number,
    {
      filterInputs,
      filtersUpdated = false,
      filterImageIds = null,
      hiddenImageIds,
      isOverlay = false,
    }: ImageFilterOptions = {},
  ): Promise<DropBoxImage[]> {
    let displayImages: Image[] = [];

    startDate = moment.unix(startDate).startOf("days").utc().unix();
    endDate = moment.unix(endDate).endOf("days").utc().unix();

    if (!filtersUpdated || this.images.length === 0) {
      await getDropboxController()
        .getImages(
          (this.currentObject as GalleryV2).externalHostDirectory,
          startDate,
          endDate,
          filterImageIds,
          hiddenImageIds,
        )
        .then((images) => {
          this.images = images;

          return this.images;
        })
        .catch((error) => {
          console.error("Errored on getting firebase ", error);
        });
    }

    if (filterInputs && filterInputs.length > 0) {
      displayImages = (this.images as DropBoxImage[]).filter(
        (image): boolean => {
          const matches: boolean[] = [];

          if (filterImageIds) {
            matches.push(filterImageIds.includes(image.id));
          }

          filterInputs?.forEach((input) => {
            switch (input.where) {
              case "Tags":
                matches.push(
                  this.isTagsMatched(
                    { image },
                    input.values as string[],
                    input.condition,
                  ),
                );
                break;
              case "Favorited":
                matches.push(
                  this.isFavoritedMatched(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              case "Commented":
                matches.push(
                  this.isCommentedMatch(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              case "Hidden":
                matches.push(
                  this.isHiddenMatch(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              default:
                break;
            }
          });

          return matches.every((m) => !!m);
        },
      );
    } else {
      displayImages = this.images;
    }

    if (isOverlay) {
      this.overlayDisplayImages = displayImages;
    } else {
      this.displayImages = displayImages;
    }

    return displayImages as DropBoxImage[];
  }

  async filterPhotoSentinel(
    startDate: UnixEpoch,
    endDate: UnixEpoch,
    {
      filterInputs,
      filtersUpdated = false,
      filterImageIds = null,
      hiddenImageIds,
      isOverlay = false,
    }: ImageFilterOptions = {},
  ): Promise<PhotoSentielImage[]> {
    let displayImages: Image[] = [];

    startDate = moment.unix(startDate).startOf("days").utc().unix();
    endDate = moment.unix(endDate).endOf("days").utc().unix();

    // make a request for the images.
    // If it is filtering because of the filters, don't request for more images.
    if (!filtersUpdated || this.images.length === 0) {
      await PhotoSentielController.getImages(
        (this.currentObject as GalleryV2).assignedDevice as number,
        startDate,
        endDate,
        filterImageIds as number[],
      )
        .then((data) => {
          this.images = (data as PhotoSentielImage[]).filter((image) => {
            return !hiddenImageIds?.includes(image.photo_id);
          });

          return this.images;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (filterInputs && filterInputs.length > 0) {
      // filter by tag
      displayImages = (this.images as FirebaseImage[]).filter(
        (image): boolean => {
          const matches = [true];

          filterInputs?.forEach((input) => {
            switch (input.where) {
              case "Tags":
                matches.push(
                  this.isTagsMatched(
                    { image },
                    input.values as string[],
                    input.condition,
                  ),
                );
                break;
              case "Favorited":
                matches.push(
                  this.isFavoritedMatched(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              case "Commented":
                matches.push(
                  this.isCommentedMatch(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              case "Hidden":
                matches.push(
                  this.isHiddenMatch(
                    { image },
                    !!input.values[0],
                    input.condition,
                  ),
                );
                break;
              default:
                break;
            }
          });
          return matches.every((m) => !!m);
        },
      );
    } else {
      displayImages = this.images;
    }

    if (isOverlay) {
      this.overlayDisplayImages = displayImages;
    } else {
      this.displayImages = displayImages;
    }

    return displayImages as PhotoSentielImage[];
  }

  initThumbnails(isOverlay: boolean = false): void {
    const thumbnails = [];
    const displayImages = isOverlay
      ? this.overlayDisplayImages
      : this.displayImages;

    switch (this.hostUsed) {
      case "0":
        (displayImages as FirebaseImage[]).forEach((displayImage) => {
          (thumbnails as Thumbnail<FirebaseImage>[]).push({
            thumbnail: this.thumbnailCaches[displayImage.id] || "",
            image: displayImage,
            fulRes: displayImage.url,
            searching: false,
            cacheThumbnail: null,
            selected: false,
            findingFullRes: false,
          });
        });

        break;
      case "1":
        (displayImages as DropBoxImage[]).forEach((displayImage) => {
          (thumbnails as Thumbnail<DropBoxImage>[]).push({
            thumbnail: this.thumbnailCaches[displayImage.id] || "",
            image: displayImage,
            fulRes: this.fullResCaches[displayImage.id] || "",
            searching: false,
            cacheThumbnail: null,
            selected: false,
            findingFullRes: false,
          });
        });
        break;
      case "2":
        (displayImages as PhotoSentielImage[]).forEach(async (displayImage) => {
          (thumbnails as Thumbnail<PhotoSentielImage>[]).push({
            thumbnail: displayImage.thumb_url,
            image: displayImage,
            fulRes: displayImage.original_url,
            searching: false,
            cacheThumbnail: null,
            selected: false,
            findingFullRes: false,
          });
        });
        break;
      default:
    }

    if (isOverlay) {
      this.overlayThumbnails = thumbnails;
    } else {
      this.thumbnails = thumbnails;
    }
  }

  getImageId(image: Image) {
    if (this.hostUsed === "2") {
      return (image as PhotoSentielImage).photo_id;
    } else {
      return (image as FirebaseImage | DropBoxImage).id;
    }
  }

  getThumbnailImageId(thumbnail: Thumbnail<Image>) {
    return this.getImageId(thumbnail.image);
  }

  findThumbnail(thumbnail: Thumbnail<Image>) {
    if (thumbnail.thumbnail) {
      return { found: true, thumbnail: thumbnail.thumbnail, event: null };
    } else if (_thumbnailCaches[this.getThumbnailImageId(thumbnail)]) {
      const cache = _thumbnailCaches[this.getThumbnailImageId(thumbnail)];

      thumbnail.thumbnail = cache;
      thumbnail.searching = false;

      this.thumbnailCaches[this.getThumbnailImageId(thumbnail)] = cache;

      return {
        found: true,
        thumbnail: cache,
        event: null,
      };
    } else if (thumbnail.image) {
      if (!thumbnail.searching) {
        thumbnail.searching = true;

        switch (this.hostUsed) {
          case "0": {
            const event = this.findThumbnailFirebase(
              thumbnail as Thumbnail<FirebaseImage>,
            );

            thumbnail.event = event;

            return {
              found: false,
              thumbnail: "",
              event,
            };
          }
          case "1":
          default: {
            const event = this.findThumbnailDropbox(
              thumbnail as Thumbnail<DropBoxImage>,
            );

            thumbnail.event = event;

            return {
              found: false,
              thumbnail: "",
              event,
            };
          }
        }
      }

      return {
        found: true,
        thumbnail: thumbnail.thumbnail,
        event: thumbnail.event,
      };
    } else {
      return { found: false, thumbnail: beerLoading, event: null };
    }
  }

  async findThumbnailFirebase(thumbnail: Thumbnail<FirebaseImage>) {
    return await getFirebaseController()
      .Image.getThumbnail(
        thumbnail.image.storageLocation,
        thumbnail.image.fileName,
      )
      .then((url) => {
        thumbnail.thumbnail = url;
        thumbnail.searching = false;

        this.thumbnailCaches[this.getThumbnailImageId(thumbnail)] = url;
        _thumbnailCaches[this.getThumbnailImageId(thumbnail)] = url;

        return url;
      })
      .catch((err) => {
        console.error(err);

        // fall back to use full res image
        thumbnail.thumbnail = thumbnail.fulRes;
        thumbnail.searching = false;

        this.thumbnailCaches[this.getThumbnailImageId(thumbnail)] =
          thumbnail.fulRes;
        _thumbnailCaches[this.getThumbnailImageId(thumbnail)] =
          thumbnail.fulRes;

        return "";
      });
  }

  async findThumbnailDropbox(thumbnail: Thumbnail<DropBoxImage>) {
    const dropboxController = getDropboxController();

    return await dropboxController
      .getImageThumbnail(thumbnail.image.path_display)
      .then((url) => {
        thumbnail.thumbnail = url;
        thumbnail.searching = false;

        this.thumbnailCaches[this.getThumbnailImageId(thumbnail)] = url;
        _thumbnailCaches[this.getThumbnailImageId(thumbnail)] = url;

        return url;
      })
      .catch(async (err) => {
        console.error(err);

        return await dropboxController
          .getImageHighRes(thumbnail.image.path_display, thumbnail.image.name)
          .then((data) => {
            // fall back to use full res image

            thumbnail.fulRes = data.url;
            thumbnail.file = data.file;
            thumbnail.fullResBlob = data.blob;

            this.fullResCaches[this.getThumbnailImageId(thumbnail)] =
              thumbnail.fulRes;

            return data.url;
          })
          .catch((err) => {
            console.log(err);

            thumbnail.searching = false;

            return "";
          });
      });
  }

  getImageTime(image: Image): string {
    return ImageHandlerV2.getImageTime(image, this.hostUsed);
  }

  static getObjectName(object: DeviceV2 | GalleryV2): string {
    if ("friendlyName" in object) {
      return (object as DeviceV2).friendlyName || object.deviceId;
    } else if ("galleryName" in object) {
      return (object as GalleryV2).galleryName;
    } else {
      return "";
    }
  }

  static getImageTime(image: Image, host: ImageHost): string {
    let returnDate;

    switch (host) {
      case "0":
        returnDate = (image as FirebaseImage).timestamp;

        break;
      case "1": {
        image = image as DropBoxImage;

        if (image.timeStamp) {
          returnDate = image.timeStamp;
        } else {
          const directory = image.path_lower?.split("/");

          // TODO: fix getImageTime error when toggling gallery
          if (!directory) {
            return "";
          }

          let matchedTimeString;

          switch (directory[2]) {
            case "photosentinel":
              // eg: "20231020113115.JPG"
              matchedTimeString = image.name.match(
                /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
              );

              break;

            case "infinity image sync":
            default: {
              // eg: "CAM_UXWNFPHM3_20231020_13_50_31.jpg";
              matchedTimeString = image.name.match(
                /_(\d{4})(\d{2})(\d{2})_(\d{2})_(\d{2})_(\d{2})./,
              );

              break;
            }
          }

          if (matchedTimeString) {
            const [, year, month, day, hours, minutes, seconds] =
              matchedTimeString;

            // month is 0 indexed
            returnDate = moment(
              new Date(
                Number(year),
                Number(month) - 1,
                Number(day),
                Number(hours),
                Number(minutes),
                Number(seconds),
              ),
            ).toISOString(true);
          } else {
            // fallback
            returnDate = new Date(image.client_modified).toISOString();
          }

          image.timeStamp = returnDate;
        }
        break;
      }
      case "2": {
        returnDate = (image as PhotoSentielImage).datetime_taken_local;

        break;
      }

      default:
    }

    return returnDate;
  }

  async findFullRes(thumbnail: Thumbnail<Image>) {
    thumbnail.findingFullRes = true;

    // If the image has already been found, return it
    if (thumbnail.fulRes) {
      thumbnail.searching = false;

      return thumbnail.fulRes;
    } else {
      switch (this.hostUsed) {
        case "0":
          this.fullResCaches[this.getThumbnailImageId(thumbnail)] =
            thumbnail.fulRes;

          return thumbnail.fulRes;
        case "1": {
          const typedThumbnail = thumbnail as Thumbnail<DropBoxImage>;

          return await getDropboxController()
            .getImageHighRes(
              typedThumbnail.image.path_display,
              typedThumbnail.image.name,
            )
            .then((data) => {
              thumbnail.fulRes = data.url;
              thumbnail.file = data.file;
              thumbnail.fullResBlob = data.blob;

              this.fullResCaches[this.getThumbnailImageId(thumbnail)] =
                thumbnail.fulRes;

              return data.url;
            });
        }
        case "2": {
          const typedThumbnail = thumbnail as Thumbnail<PhotoSentielImage>;

          return typedThumbnail.fulRes;
        }
        default:
          return "";
      }
    }
  }

  // Checks if the previous image is in the current filter. If it is, we will most likely want to select that as the current image.
  findFromPrevious(prevThumbnail: Thumbnail<Image>): number {
    let selectVal = -1;
    let searchDate = "";

    if (this.thumbnails.length >= 1) {
      switch (this.hostUsed) {
        case "0": {
          const typedPrevThumbnail = prevThumbnail as Thumbnail<FirebaseImage>;
          const typedThumbnails = this.thumbnails as Thumbnail<FirebaseImage>[];

          searchDate = typedPrevThumbnail.image.timestamp;
          if (
            // If the given image is outside of the filter, select the latest image.

            searchDate <= typedThumbnails[0].image.timestamp ||
            searchDate >=
              typedThumbnails[typedThumbnails.length - 1].image.timestamp
          ) {
            selectVal = typedThumbnails.length - 1;
          } else {
            // check where selected image is, as it should be in the array.

            selectVal = typedThumbnails.findIndex((val) => {
              return searchDate === val.image.timestamp;
            });
          }
          break;
        }
        case "1":
        default: {
          const typedPrevThumbnail = prevThumbnail as Thumbnail<DropBoxImage>;
          const typedThumbnails = this.thumbnails as Thumbnail<DropBoxImage>[];

          searchDate = this.getImageTime(typedPrevThumbnail.image);
          // If the given image is outside of the filter, select the latest image.
          if (
            searchDate <= this.getImageTime(typedThumbnails[0].image) ||
            searchDate >=
              this.getImageTime(
                typedThumbnails[typedThumbnails.length - 1].image,
              )
          ) {
            selectVal = typedThumbnails.length - 1;
          }
          // check where selected image is, as it should be in the array.
          else {
            selectVal = typedThumbnails.findIndex((val) => {
              return searchDate === this.getImageTime(val.image);
            });
          }
          break;
        }
      }
    }

    return selectVal;
  }

  isPhotoSentinel() {
    return (
      !this.isDevice &&
      (this.currentObject as GalleryV2).galleryImageHost === "2"
    );
  }

  async updateImageHiddenStatus(
    thumbnail: Thumbnail<Image>,
    isHidding: boolean,
  ): Promise<boolean> {
    const firebaseController = getFirebaseController();

    const currentUser = firebaseController.currentUser;

    if (currentUser && this.getThumbnailImageId(thumbnail)) {
      const userImageExtras =
        this.userImageExtrasMapper[this.getThumbnailImageId(thumbnail)];

      if (isHidding) {
        // return true as image alaready hidden
        if (userImageExtras?.hidden) {
          return true;
        }

        const id = userImageExtras?.id || firebaseController.getNewDocumentId();

        // create user image extra if it doenst already exist
        const newImageExtras: Pick<
          ImageExtras,
          "id" | "imageApplied" | "hidden"
        > = {
          id,
          imageApplied: this.getThumbnailImageId(thumbnail),
          hidden: true,
        };

        if (!userImageExtras && this.isPhotoSentinel()) {
          // saved a copy of image date, so that we can get it with id

          await firebaseController.Image.addPhotoSentinelImage(
            thumbnail.image as PhotoSentielImage,
          );
        }

        // optimistic update
        this.updateImageExtrasMapper(newImageExtras as ImageExtras, true);
        this.hiddenImageIds = [
          ...this.hiddenImageIds,
          this.getThumbnailImageId(thumbnail),
        ];

        return await firebaseController.Image.createOrUpdateImageExtras(
          this.currentObject.id as number,
          this.isDevice,
          newImageExtras,
          { isInitDefault: !userImageExtras },
        ).then(async (imageExtras) => {
          if (imageExtras) {
            this.updateImageExtrasMapper(imageExtras);
            this.hiddenImageIds = [
              ...this.hiddenImageIds,
              this.getThumbnailImageId(thumbnail),
            ];

            return imageExtras.hidden;
          } else {
            return false;
          }
        });
      } else {
        // if unhiding, just update all the imageExtras that is hidden before

        const allHiddenImageExtras = (
          this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)] || []
        ).filter((imageExtras) => imageExtras.hidden);

        if (allHiddenImageExtras.length === 0) {
          // return false as image not hidden already

          return false;
        }

        const promises: Promise<ImageExtras | undefined>[] = [];

        allHiddenImageExtras.forEach((imageExtras) => {
          // optimistic update
          this.updateImageExtrasMapper(imageExtras);
          this.hiddenImageIds = this.hiddenImageIds.filter(
            (id) => id !== this.getThumbnailImageId(thumbnail),
          );

          promises.push(
            firebaseController.Image.updateImageExtras(
              imageExtras.id as number,
              {
                hidden: false,
              },
            ),
          );
        });

        return await Promise.all(promises).then((imageExtrasList) => {
          imageExtrasList.forEach((imageExtras) => {
            if (imageExtras) {
              this.updateImageExtrasMapper(imageExtras);
            }
          });

          this.hiddenImageIds = this.hiddenImageIds.filter(
            (id) => id !== this.getThumbnailImageId(thumbnail),
          );

          return false;
        });
      }
    } else {
      // failed

      return !isHidding;
    }
  }

  async updateImageFavorited(
    thumbnail: Thumbnail<Image>,
    isFavourited: boolean,
  ): Promise<ImageExtras | void> {
    const firebaseController = getFirebaseController();

    const currentUser = firebaseController.currentUser;

    if (currentUser && this.getThumbnailImageId(thumbnail)) {
      const currentImageExtras =
        this.userImageExtrasMapper[this.getThumbnailImageId(thumbnail)];

      const newImageExtras: Pick<
        ImageExtras,
        "id" | "imageApplied" | "favorited"
      > = {
        id: currentImageExtras?.id || firebaseController.getNewDocumentId(),
        imageApplied: this.getThumbnailImageId(thumbnail),
        favorited: isFavourited,
      };

      if (!currentImageExtras && this.isPhotoSentinel()) {
        // saved a copy of image date, so that we can get it with id

        await firebaseController.Image.addPhotoSentinelImage(
          thumbnail.image as PhotoSentielImage,
        );
      }

      const updatedImageExtras =
        await firebaseController.Image.createOrUpdateImageExtras(
          this.currentObject.id as number,
          this.isDevice,
          newImageExtras,
          { isInitDefault: !currentImageExtras },
        );

      if (updatedImageExtras) {
        this.updateImageExtrasMapper(updatedImageExtras);
      }

      return updatedImageExtras;
    }
  }

  async updateImageTag(
    thumbnail: Thumbnail<Image>,
    tagValues: string[],
    isAdd: boolean = true,
  ) {
    const firebaseController = getFirebaseController();

    const currentUser = firebaseController.currentUser;
    const promises: Promise<any>[] = [];

    if (currentUser && this.getThumbnailImageId(thumbnail)) {
      if (isAdd) {
        const currentImageExtras =
          this.userImageExtrasMapper[this.getThumbnailImageId(thumbnail)];

        const newImageExtras: Pick<
          ImageExtras,
          "id" | "imageApplied" | "tags"
        > = {
          id: currentImageExtras?.id || firebaseController.getNewDocumentId(),
          imageApplied: this.getThumbnailImageId(thumbnail),
          tags: _.sortBy([...(currentImageExtras?.tags || []), ...tagValues]),
        };

        if (!currentImageExtras && this.isPhotoSentinel()) {
          // saved a copy of image date, so that we can get it with id

          await firebaseController.Image.addPhotoSentinelImage(
            thumbnail.image as PhotoSentielImage,
          );
        }

        promises.push(
          firebaseController.Image.createOrUpdateImageExtras(
            this.currentObject.id as number,
            this.isDevice,
            newImageExtras,
            { isInitDefault: !currentImageExtras },
          ),
        );
      } else {
        this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)]?.forEach(
          async (imageExtra) => {
            const prevTags = imageExtra.tags || [];

            imageExtra.tags = prevTags.filter((tag) => {
              return !tagValues.includes(tag);
            });

            const shouldUpdate = prevTags.length !== imageExtra.tags.length;

            if (shouldUpdate) {
              promises.push(
                firebaseController.Image.createOrUpdateImageExtras(
                  this.currentObject.id as number,
                  this.isDevice,
                  { id: imageExtra.id, tags: imageExtra.tags },
                  { isInitDefault: false },
                ),
              );
            }
          },
        );
      }

      return Promise.all(promises).then(async (results) => {
        await this.getImageExtras(thumbnail);

        return _.last(results);
      });
    }
  }

  async updateImageComment(
    thumbnail: Thumbnail<Image>,
    comment: string,
    isAdd: boolean = true,
  ) {
    const firebaseController = getFirebaseController();

    const currentUser = firebaseController.currentUser;
    const promises: Promise<any>[] = [];

    if (currentUser && this.getThumbnailImageId(thumbnail)) {
      if (isAdd) {
        const currentImageExtras =
          this.userImageExtrasMapper[this.getThumbnailImageId(thumbnail)];

        const newComment = {
          ...getDefaultComment,
          id: firebaseController.getNewDocumentId(),
          commentedUserId: currentUser.id,
          epochTime: moment.utc().valueOf(),
          comment: comment.trim(),
        };

        if (!currentImageExtras && this.isPhotoSentinel()) {
          // saved a copy of image date, so that we can get it with id

          await firebaseController.Image.addPhotoSentinelImage(
            thumbnail.image as PhotoSentielImage,
          );
        }

        promises.push(
          firebaseController.Image.addComment(newComment).then(async () => {
            const newImageExtras: Pick<
              ImageExtras,
              "id" | "imageApplied" | "comments"
            > = {
              id:
                currentImageExtras?.id || firebaseController.getNewDocumentId(),
              imageApplied: this.getThumbnailImageId(thumbnail),
              comments: _.sortBy([
                ...(currentImageExtras?.comments || []),
                newComment.id,
              ]),
            };

            return await firebaseController.Image.createOrUpdateImageExtras(
              this.currentObject.id as number,
              this.isDevice,
              newImageExtras,
              { isInitDefault: !currentImageExtras },
            );
          }),
        );
      }

      return Promise.all(promises).then(async (results) => {
        await this.getImageExtras(thumbnail);

        return _.last(results);
      });
    }
  }

  async getImageComments(thumbnail: Thumbnail<Image>) {
    const allImageExtras =
      this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)];

    const returnComments: (Comment & { user: User })[] = [];

    if (allImageExtras) {
      const allCommentsIds = _.compact(
        _.uniq(_.flatMap(allImageExtras, "comments")),
      );

      const promises: Promise<any>[] = [];

      const firebaseController = getFirebaseController();

      const comments = await firebaseController.Image.getComments(
        allCommentsIds,
      );

      // TODO: refactor
      comments.forEach((comment) => {
        promises.push(
          firebaseController.User.getUser(comment.commentedUserId).then(
            (user) => {
              if (user) {
                returnComments.push({ ...comment, user });
              }
            },
          ),
        );
      });

      return Promise.all(promises).then(() => {
        return _.sortBy(returnComments, "epochTime");
      });
    } else {
      return returnComments;
    }
  }

  getIsImageCommented(thumbnail: Thumbnail<Image>) {
    return (
      this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)] || []
    ).some((imageExtras) => (imageExtras.comments || []).length > 0);
  }

  getIsImageFavourited(thumbnail: Thumbnail<Image>) {
    return !!this.userImageExtrasMapper?.[this.getThumbnailImageId(thumbnail)]
      ?.favorited;
  }

  getIsImageHidden(thumbnail: Thumbnail<Image>) {
    return (
      this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)] || []
    ).some((imageExtras) => imageExtras.hidden);
  }

  getImageTags(thumbnail: Thumbnail<Image>): string[] {
    // tags of 1 image
    const imageExtras =
      this.allImageExtrasMapper[this.getThumbnailImageId(thumbnail)] || [];

    // joining the tags value from all the image extras
    return _.sortBy(_.compact(_.uniq(_.flatMap(imageExtras, "tags"))));
  }

  getAllImageTags(): string[] {
    // tags of all images
    const allImageExtras = _.flatMap(this.allImageExtrasMapper);

    // joiing the tags value from all the image extras
    return _.sortBy(_.compact(_.uniq(_.flatMap(allImageExtras, "tags"))));
  }

  getImageInfo(image: Image) {
    const info = {
      Timestamp: "N/A",
      "File Size": "N/A",
      "File Location": "N/A",
      "File Name": "N/A",
      "Exposure Time": "N/A",
      "Aperture Size": "N/A",
      "Focus Distance": "N/A",
    };

    info["Timestamp"] = this.getImageTime(image);

    switch (this.hostUsed) {
      case "0": {
        const typedImage = image as FirebaseImage;

        info["File Size"] = prettyBytes(typedImage.fileSize);
        info["File Location"] = typedImage.storageLocation;
        info["File Name"] = typedImage.fileName;
        info["Exposure Time"] = typedImage.exposureTime;
        info["Aperture Size"] = typedImage.apertureSize;
        info["Focus Distance"] = typedImage.manualFocusDistance.toString();

        break;
      }

      case "1": {
        const typedImage = image as DropBoxImage;

        info["File Size"] = prettyBytes(typedImage.size);
        info["File Location"] = typedImage.path_display;
        info["File Name"] = typedImage.name;

        break;
      }
      case "2": {
        const typedImage = image as PhotoSentielImage;

        break;
      }
    }

    return info;
  }

  async getWeatherLocation(): Promise<WeatherLocation> {
    if (this.weatherLocation) {
      return this.weatherLocation;
    }

    let param = "";
    let type: WeatherLocation["type"] = "geo";
    let timeZone = "";

    if (this.isDevice) {
      const details = await getFirebaseController().Device.getDeviceDetails(
        this.currentObject.id as number,
      );

      if (details) {
        if (details.latitude && details.longitude) {
          param = `${details.latitude},${details.longitude}`;
          type = "geo";
          timeZone = await getFirebaseController()
            .Callable.getTimeZoneId({
              long: Number(details.longitude),
              lat: Number(details.latitude),
            })
            .then((data) => data.timeZoneId);
        } else if (details.timeZone) {
          const city = details.timeZone.split("/")[1];

          param = city;
          type = "city";
          timeZone = details.timeZone;
        }
      }
    } else {
      let lat = (this.currentObject as GalleryV2).pinnedLatitude;
      let lon = (this.currentObject as GalleryV2).pinnedLongitude;

      if (!lat || !lon) {
        const jobsite = await getFirebaseController().JobSite.getJobSite(
          (this.currentObject as GalleryV2).jobSite,
        );

        const [jobSiteLat = "", jobSiteLon = ""] = (
          jobsite?.gpsLocation || ""
        ).split(",");

        lat = jobSiteLat;
        lon = jobSiteLon;
      }

      param = `${[Number(lat).toFixed(5), Number(lon).toFixed(5)]
        .filter((v) => !!v)
        .join(",")}`;
      type = "geo";
      timeZone = await getFirebaseController()
        .Callable.getTimeZoneId({
          long: Number(lon),
          lat: Number(lat),
        })
        .then((data) => data.timeZoneId);
    }

    this.weatherLocation = { param, type, timeZone };

    return { param, type, timeZone };
  }

  isImageHidable(imageId: string | number) {
    return true;
  }

  getHourlyThumnails() {
    return _.groupBy(this.thumbnails, (thumbnail) => {
      return moment.parseZone(this.getImageTime(thumbnail.image)).format("HH");
    });
  }
}
