import {
  MouseEventHandler,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  alpha,
  Tooltip as MuiTooltip,
  Radio,
  Tabs,
  Tab,
  ListItemText,
  MenuList,
  useTheme,
} from "@mui/material";

import {
  DateCalendar as MuiDateCalendar,
  LocalizationProvider,
  DateCalendarProps,
  DateField,
  DateFieldProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import imageViewerHeatmapBg from "./image_viewer_heatmap_bg.png";
import timelapsePlayerHeatmapBg from "./timelapse_player_heatmap_bg.png";

import {
  BarChart,
  Bar,
  ResponsiveContainer,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ReferenceLine,
  Cell,
} from "recharts";

import { getFirebaseController } from "database/FirebaseController";

import {
  AnalyticActionCountItem,
  AnalyticActiveUsersItem,
  AnalyticReportFilterOptions,
  AnalyticReportSettingOptions,
  AnalyticTopActiveClientItem,
  AnalyticTopActiveUserItem,
  AnalyticTopViewedGalleryItem,
  Client,
  GalleryV2,
  JobSite,
  User,
  UserRole,
} from "database/DataTypes";

import { useAtom, useAtomValue } from "jotai";
import {
  clientListCacheState,
  galleryListCacheState,
  jobSitesListCacheState,
  userListCacheState,
  userRolesListCacheState,
} from "states/caches";

import moment, { Moment } from "moment";
import _ from "lodash";

import DashboardContainer from "components/Dashboard/DashboardContainer";

import { _jobSiteCaches } from "Windows/Gallery/GalleryGridWindow";

import { IMAGE_VIEWER_EVENT_ACTION_ITEM_MAPPER } from "hooks/eventTracker/useImageViewerTracker";
import {
  IMAGE_VIEWER_HEAT_MAP_DATA,
  TIMELAPSE_PLAYER_ACTIONS_NAMES,
  TIMELAPSE_PLAYER_HEAT_MAP_DATA,
} from "database/constants/analytics";
import { TIMELAPSE_PLAYER_EVENT_ACTION_ITEM_MAPPER } from "hooks/eventTracker/useTimelapsePlayerTracker";
import useIsScrollComplete from "hooks/useIsScrollComplete";
import {
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
  InfoOutlined as InfoIcon,
  OpenInNew as OpenInNewIcon,
  Palette,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { orderByIgnoreCase } from "utils/display";
import { getContrastShade } from "theme/reliveItTheme";

const DATE_FILTER_OPTIONS = [
  {
    label: "Past Day",
  },
  {
    label: "Past 3 Days",
  },
  {
    label: "Past Week",
  },
  {
    label: "Past Month",
  },
  {
    label: "Past 3 Months",
  },
  {
    label: "Custom",
  },
];

const DateCalendar = (props: DateCalendarProps<Moment>) => {
  const DATE_SIZE = 30;

  return (
    <MuiDateCalendar
      views={["year", "month", "day"]}
      slotProps={{
        previousIconButton: {
          size: "small",
        },
        nextIconButton: {
          size: "small",
        },
        switchViewButton: {
          size: "small",
        },

        switchViewIcon: {
          fontSize: "small",
        },
      }}
      reduceAnimations
      sx={{
        width: 256,
        minHeight: 230,

        "& .MuiPickersCalendarHeader-root": {
          px: 1,
          my: 1,
        },

        "& [role='row']": {
          px: 1,
          justifyContent: "space-between",

          "> span": {
            height: 30,
          },
        },

        "& .MuiTypography-caption": {
          width: DATE_SIZE,
          margin: 0,
        },
        "& .PrivatePickersSlideTransition-root": {
          minHeight: DATE_SIZE * 6,
        },

        "& .MuiDayCalendar-slideTransition": {
          minHeight: "auto",
        },

        '& .PrivatePickersSlideTransition-root [role="row"]': {
          margin: 0,
        },
        "& .MuiPickersDay-dayWithMargin": {
          margin: 0,
        },
        "& .MuiPickersDay-root": {
          width: DATE_SIZE,
          height: DATE_SIZE,
          lineHeight: 1,
        },

        "& .MuiYearCalendar-root, .MuiMonthCalendar-root": {
          width: 256,
        },

        "& .MuiPickersYear-root, .MuiPickersMonth-root": {
          flexBasis: "25%",
        },

        ".MuiPickersYear-yearButton, .MuiPickersMonth-monthButton": {
          fontSize: 12,
          height: "auto",
          width: "100%",
        },
      }}
      {...props}
    />
  );
};

const Field = memo(
  (props: DateFieldProps<Moment>) => {
    return <DateField label="From" size="small" fullWidth {...props} />;
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

const DateMenu = ({
  open,
  anchorEl,
  onClose,
  selectedDateOption,
  selectedDateRange,
  handleDateOptionChanged,
  handleApply,
}: {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  selectedDateOption: string;
  selectedDateRange: string[];
  handleDateOptionChanged: (dateOption: string) => void;
  handleApply: (dateRange: string[]) => void;
}) => {
  const [errors, setErrors] = useState(0);
  const [dateRange, setDateRange] = useState(selectedDateRange);

  useEffect(() => {
    setDateRange(selectedDateRange);
  }, [selectedDateRange]);

  const handleDateRangeChanged = (date, name: "start" | "end") => {
    let start = dateRange[0];
    let end = dateRange[1];

    if (name === "start") {
      start = date.format("YYYY-MM-DD");

      if (start > end) {
        end = date.format("YYYY-MM-DD");
      }
    } else {
      end = date.format("YYYY-MM-DD");

      if (end < start) {
        start = date.format("YYYY-MM-DD");
      }
    }

    setDateRange([start, end]);
  };

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
    >
      <Box
        sx={{
          outline: "none !important",

          display: "flex",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box sx={{ flex: 1, minWidth: 250 }}>
          {DATE_FILTER_OPTIONS.map((option, optionIndex) => {
            return (
              <MenuItem
                onClick={() => {
                  handleDateOptionChanged(option.label);

                  if (option.label !== "Custom") {
                    onClose();
                  }
                }}
                key={optionIndex}
                selected={selectedDateOption === option.label}
                sx={{ fontSize: 14, py: 1 }}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Box>

        {selectedDateOption === "Custom" && (
          <Box
            sx={{
              pr: 1,
              minWidth: 250,
              maxHeight: 500,
              overflow: "auto",
            }}
            onKeyDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ mt: 1 }}>
                <Field
                  value={moment(dateRange[0])}
                  minDate={moment("01-01-2023")}
                  maxDate={moment(dateRange[1])}
                  onError={(error) => {
                    error ? setErrors(errors + 1) : setErrors(errors - 1);
                  }}
                  onChange={(date) => {
                    handleDateRangeChanged(date, "start");
                  }}
                />

                <DateCalendar
                  value={moment(dateRange[0])}
                  minDate={moment("01-01-2023")}
                  maxDate={moment(dateRange[1])}
                  onChange={(date) => {
                    handleDateRangeChanged(date, "start");
                  }}
                />
              </Box>
            </LocalizationProvider>

            <Divider sx={{ my: 1 }} />

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box sx={{ mt: 2 }}>
                <Field
                  value={moment(dateRange[1])}
                  minDate={moment(dateRange[0])}
                  maxDate={moment()}
                  onError={(error) => {
                    error ? setErrors(errors + 1) : setErrors(errors - 1);
                  }}
                  onChange={(date) => {
                    handleDateRangeChanged(date, "end");
                  }}
                />

                <DateCalendar
                  value={moment(dateRange[1])}
                  minDate={moment(dateRange[0])}
                  maxDate={moment()}
                  onChange={(date) => {
                    handleDateRangeChanged(date, "end");
                  }}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        )}
      </Box>

      {selectedDateOption === "Custom" && (
        <Box>
          <Divider sx={{ mb: 1 }} />

          <Box sx={{ display: "flex", justifyContent: "end", px: 1, gap: 1 }}>
            <Button
              onClick={onClose}
              size="small"
              variant="text"
              disableElevation
              sx={{
                textTransform: "none",
                color: ({ palette }) => palette.secondary.light,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleApply(dateRange)}
              size="small"
              variant="outlined"
              disableElevation
              disabled={!!errors}
              sx={{
                textTransform: "none",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      )}
    </Menu>
  );
};

const DropDownSelectMenu = <T,>({
  open,
  anchorEl,
  onClose,
  dataList,
  labelKey = "name",
  itemKey = "id",
  onItemClick,
  selectedItem,
  searchLabel,
  labelFormat,
  customSearchFields,
}: {
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  dataList: T[];
  labelKey?: string;
  itemKey?: string;
  onItemClick: (item?: T) => void;
  selectedItem: T | null;
  searchLabel?: string;
  labelFormat?: (item: T) => string;
  customSearchFields?: (item: T) => string[];
}) => {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (!open) {
      setSearchInput("");
    }
  }, [open]);

  const getDisplayList = useCallback(() => {
    if (searchInput) {
      const searchTexts = _.toLower(searchInput).split(" ");

      return dataList.filter((item) => {
        const searchFields: string[] = customSearchFields
          ? customSearchFields(item)
          : [_.toLower(item[labelKey])];

        return searchTexts.every((s) => {
          return searchFields.some((f) => {
            return f.includes(s);
          });
        });
      });
    } else {
      return dataList;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, dataList]);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box>
          <Box sx={{ px: 1 }}>
            <TextField
              size="small"
              autoFocus
              fullWidth
              placeholder="Search"
              label={searchLabel}
              value={searchInput}
              onChange={(e) => {
                e.stopPropagation();
                setSearchInput(e.target.value);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              InputProps={{
                sx: {
                  fontSize: 14,
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 14,
                },
              }}
            />

            <Divider sx={{ mt: 1 }} />
          </Box>

          <Box
            sx={{
              maxHeight: 400,
              overflow: "auto",
            }}
          >
            <MenuItem
              sx={{ fontStyle: "italic", fontSize: 14, py: 1 }}
              selected={!selectedItem}
              onClick={() => {
                onItemClick();
                onClose();
              }}
            >
              Show All
            </MenuItem>

            {[...getDisplayList()].map((item, itemIndex) => {
              return (
                <MenuItem
                  sx={{ fontSize: 14, py: 1 }}
                  key={`${item[itemKey]}:${itemIndex}`}
                  selected={item[itemKey] === selectedItem?.[itemKey]}
                  onClick={() => {
                    onItemClick(item);
                    onClose();
                  }}
                >
                  {labelFormat ? labelFormat(item) : item[labelKey]}
                </MenuItem>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Menu>
  );
};

const CardContainer = ({
  title,
  info,
  isLoading,
  children,
  height,
}: {
  title: string;
  info?: string;
  isLoading: boolean;
  children: ReactNode | ReactNode[];
  height?: number | string;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isScrollComplete } = useIsScrollComplete({ ref, trigger: isLoading });

  return (
    <Box
      className="card-container"
      sx={{
        backgroundColor: ({ palette }) =>
          isLoading
            ? alpha(palette.surface.main, 0.9)
            : alpha(palette.surface.main, 1),
        transition: ({ transitions }) => transitions.create("background"),
        borderRadius: 1,
        boxShadow: 1,
        color: ({ palette }) => palette.onSurface.main,
        py: 2,
      }}
    >
      <Box sx={{ px: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            {title}
          </Typography>

          {info && (
            <MuiTooltip
              title={<Box sx={{ whiteSpace: "pre-line" }}>{info}</Box>}
            >
              <InfoIcon sx={{ fontSize: 16 }} />
            </MuiTooltip>
          )}
        </Box>

        <Divider sx={{ mt: 2 }} />
      </Box>

      <Box
        ref={ref}
        className="content"
        sx={{
          height: height || 300,
          minHeight: 300,
          overflow: "auto",

          ".recharts-responsive-container": {
            // not sure why it's scrollable even its content height is lower than its parent
            overflow: "hidden",
          },

          px: 2,
          scrollbarColor: ({ palette }) =>
            `${getContrastShade(palette.primary, "dark")} transparent`,
          scrollbarWidth: "thin",

          maskImage: !isScrollComplete
            ? "linear-gradient(to bottom, black calc(100% - 48px), transparent 100%)"
            : "",
        }}
      >
        <Box sx={{ minWidth: 250, px: 2 }}>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
              sx={{
                background: ({ palette }) =>
                  alpha(palette.onSurface.main, 0.01),
                minHeight: 300,
              }}
            />
          ) : (
            children
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ListBarChart = ({
  data,
  xKey,
  yKey,
  isShowIndex = false,
  itemOnClick,
}: {
  data: any[];
  xKey: string;
  yKey: string;
  isShowIndex?: boolean;
  itemOnClick?: (item) => void;
}) => {
  const [currentDataIndex, setCurrentDataIndex] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);

  const props = useMemo(() => {
    return itemOnClick
      ? {
          onClick: (state) => {
            itemOnClick(data[currentDataIndex]);

            if (state) {
              if (state.activeTooltipIndex === currentDataIndex) {
                setIsHovered(false);
                setCurrentDataIndex(-1);
              } else {
                setIsHovered(false);
                setCurrentDataIndex(state.activeTooltipIndex as number);
              }
            }
          },
          onMouseLeave: (state) => {
            setCurrentDataIndex(-1);
            setIsHovered(false);
          },
          onMouseMove: (state) => {
            if (state) {
              setCurrentDataIndex(state.activeTooltipIndex as number);
              setIsHovered(true);
            } else {
              setCurrentDataIndex(-1);
              setIsHovered(false);
            }
          },
        }
      : {};
  }, [currentDataIndex, itemOnClick]);

  const { palette } = useTheme();

  return (
    <>
      {data.length > 0 ? (
        <Box height={45 * data.length}>
          <ResponsiveContainer debounce={300}>
            <BarChart
              data={data}
              layout="vertical"
              barSize={5}
              style={{
                cursor: isHovered ? "pointer" : "auto",
              }}
              {...props}
            >
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                domain={[0, "dataMax"]}
                hide
              />

              <YAxis
                type="category"
                dataKey={yKey}
                axisLine={false}
                tickLine={false}
                hide
              />

              <Bar
                dataKey={xKey}
                fill={getContrastShade(palette.info, "dark")}
                background
                style={{
                  cursor: "pointer",
                }}
              >
                {data.map((data, dataIndex) => {
                  return (
                    <Cell
                      key={dataIndex}
                      style={{
                        transition: "fill 0.3s ease",
                      }}
                      fill={
                        isHovered
                          ? currentDataIndex === dataIndex
                            ? getContrastShade(palette.info, "dark")
                            : alpha(getContrastShade(palette.info, "dark"), 0.2)
                          : getContrastShade(palette.info, "dark")
                      }
                    />
                  );
                })}

                <LabelList
                  dataKey={yKey}
                  fill="#ffffff"
                  content={(props) => {
                    const x = 0;
                    const y = props.y;

                    const label = isShowIndex
                      ? `${moment.localeData().ordinal(props["index"] + 1)} - ${
                          props.value
                        }`
                      : props.value;

                    return (
                      <text
                        x={x}
                        y={y}
                        dx={5}
                        dy={-5}
                        fill={
                          isHovered
                            ? currentDataIndex === props["index"]
                              ? getContrastShade(palette.info, "dark")
                              : alpha(
                                  getContrastShade(palette.info, "dark"),
                                  0.2,
                                )
                            : getContrastShade(palette.info, "dark")
                        }
                        fontWeight={"bold"}
                        fontSize={12}
                        textAnchor={"start"}
                        style={{
                          cursor: itemOnClick ? "pointer" : "auto",
                        }}
                      >
                        {_.truncate(label as string, { length: 40 })}
                      </text>
                    );
                  }}
                />

                <LabelList
                  dataKey={xKey}
                  fill="#ffffff"
                  content={(props) => {
                    const x = "100%";
                    const y = props.y;

                    return (
                      <text
                        x={x}
                        y={y}
                        dx={-5}
                        dy={-5}
                        fontWeight={"bold"}
                        fontSize={12}
                        textAnchor={"end"}
                        style={{
                          cursor: itemOnClick ? "pointer" : "auto",
                        }}
                        fill={
                          isHovered
                            ? currentDataIndex === props["index"]
                              ? getContrastShade(palette.info, "dark")
                              : alpha(
                                  getContrastShade(palette.info, "dark"),
                                  0.2,
                                )
                            : getContrastShade(palette.info, "dark")
                        }
                      >
                        {props.value}
                      </text>
                    );
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

const NoDataCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography sx={{ fontSize: 12 }}>No Data</Typography>
    </Box>
  );
};

const TopListBarChart = ({
  data,
  xKey,
  yKey,
  topItemLabel,
  itemOnClick,
}: {
  data: any[];
  xKey: string;
  yKey: string;
  topItemLabel?: (value: string) => void;
  itemOnClick?: (item) => void;
}) => {
  return (
    <Box sx={{ height: 300 }}>
      {data.length > 0 ? (
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "50%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: ({ palette }) => getContrastShade(palette.info, "dark"),
            }}
          >
            <Box>
              {topItemLabel ? (
                topItemLabel(data[0][yKey])
              ) : (
                <Typography
                  sx={{
                    fontSize: 26,
                    fontWeight: "bold",
                  }}
                >
                  {data[0][yKey]}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography sx={{ fontSize: 12 }}>{data[0][xKey]}</Typography>
            </Box>
          </Box>

          <ListBarChart
            data={data}
            xKey={xKey}
            yKey={yKey}
            isShowIndex={true}
            itemOnClick={itemOnClick}
          />
        </Box>
      ) : (
        <NoDataCard />
      )}
    </Box>
  );
};

const FilterButton = ({
  label,
  value,
  onClick,
  isActive,
}: {
  label: string;
  value: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
}) => {
  return (
    <Button
      fullWidth
      onClick={onClick}
      size="small"
      variant="contained"
      color={"info"}
      sx={{
        textTransform: "none",
        fontSize: 12,
        background: ({ palette }) =>
          isActive ? palette.primary.main : palette.info.main,

        color: ({ palette }) => `${palette.onPrimary.main} !important`,
        minWidth: "auto",

        border: ({ palette }) =>
          `1px solid ${alpha(palette.onPrimary.main, 0.1)}`,
      }}
    >
      <Typography sx={{ fontSize: 12, whiteSpace: "nowrap" }}>
        {label}
      </Typography>
      <Typography
        sx={{ pl: 1, fontSize: 13, fontWeight: "bold", whiteSpace: "nowrap" }}
      >
        {value}
      </Typography>
    </Button>
  );
};

const HeatMapChart = ({
  imageViewerActionData,
  timelapsePlayerActionData,
  tab,
}) => {
  const [heatMapData, setHeatMapData] = useState<any[]>([
    ...TIMELAPSE_PLAYER_HEAT_MAP_DATA,
  ]);

  const [chartData, setChartData] = useState<any[]>([]);

  const [actionHighestCount, setActionHighestCount] = useState([0, 0]);

  const [currentDataIndex, setCurrentDataIndex] = useState<number>(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [eventCountMapper, setEventCountMapper] = useState<{
    [key: string]: number[];
  } | null>(null);

  const [viewerHeatMapData, setViewerHeatMapData] = useState<any[]>([]);
  const [playerHeatMapData, setPlayerHeatMapData] = useState<any[]>([]);
  const [viewerActionCountMapper, setViewerActionCountMapper] = useState<{
    [key: string]: number[];
  } | null>(null);
  const [playerActionCountMapper, setPlayerActionCountMapper] = useState<{
    [key: string]: number[];
  } | null>(null);

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { palette } = useTheme();

  useEffect(() => {
    setIsDataLoaded(false);

    const viewerActionCountIndexMapper = {};
    const playerActionCountIndexMapper = {};
    const getHeatMapDataAndHighestCount = (
      isImageViewer = true,
    ): [any[], number] => {
      let highest = 0;

      const countMapper = isImageViewer
        ? viewerActionCountIndexMapper
        : playerActionCountIndexMapper;
      const labelMapper = isImageViewer
        ? IMAGE_VIEWER_EVENT_ACTION_ITEM_MAPPER
        : TIMELAPSE_PLAYER_EVENT_ACTION_ITEM_MAPPER;
      const initialHeatmapData = isImageViewer
        ? IMAGE_VIEWER_HEAT_MAP_DATA
        : TIMELAPSE_PLAYER_HEAT_MAP_DATA;

      return [
        initialHeatmapData.map((item) => {
          const data = countMapper[item.name];

          const labelItem = labelMapper[item.name] || {};

          item.label = labelItem.title || item.name;

          if (item.name === "item toggle") {
            item.description = [
              `Searched - ${countMapper["item search"]?.[0] || 0}`,
            ];
          }

          if (isImageViewer && item.name === "download") {
            const downloadData = imageViewerActionData.find(
              (data) => data.actionName === "download",
            );

            item.description = [
              `Original - ${downloadData.info.original}`,
              `With timestamp - ${downloadData.info.timestamp}`,
            ];
          }

          if (!isImageViewer && item.name === "compile") {
            const compileData = timelapsePlayerActionData.find(
              (data) => data.actionName === "compile",
            );

            item.description = (
              <Box key="compile">
                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                  Date Range Option:
                </Typography>

                {Object.entries(compileData.dateRangeInfo).map(
                  ([name, count]) => {
                    return (
                      <Typography
                        key={name}
                        sx={{ fontSize: 12 }}
                      >{`${name} - ${count}`}</Typography>
                    );
                  },
                )}

                <Typography sx={{ fontSize: 12, fontWeight: "bold", mt: 1 }}>
                  Frame Blending Option:
                </Typography>

                {Object.entries(compileData.frameBlendingInfo).map(
                  ([name, count]) => {
                    return (
                      <Typography
                        key={name}
                        sx={{ fontSize: 12 }}
                      >{`${name} - ${count}`}</Typography>
                    );
                  },
                )}
              </Box>
            );
          }

          if (data) {
            const [count, index] = data;

            item.count = count || 0;

            if (item.count > highest) {
              highest = item.count;
            }

            item.index = index;
          }

          return item;
        }),
        highest,
      ];
    };

    const newActionHighestCount = [0, 0];

    if (imageViewerActionData.length > 0) {
      imageViewerActionData.forEach((item, itemIndex) => {
        viewerActionCountIndexMapper[item.actionName] = [item.count, itemIndex];
      });

      const [viewerHeatMapData, viewerActionHighestCount] =
        getHeatMapDataAndHighestCount(true);

      setViewerActionCountMapper(viewerActionCountIndexMapper);

      setViewerHeatMapData(viewerHeatMapData);

      newActionHighestCount[0] = viewerActionHighestCount;
    }

    if (timelapsePlayerActionData.length > 0) {
      timelapsePlayerActionData.forEach((item, itemIndex) => {
        playerActionCountIndexMapper[item.actionName] = [item.count, itemIndex];
      });

      const [playerHeatMapData, playerActionHighestCount] =
        getHeatMapDataAndHighestCount(false);

      setPlayerActionCountMapper(playerActionCountIndexMapper);
      setPlayerHeatMapData(playerHeatMapData);

      newActionHighestCount[1] = playerActionHighestCount;
    }

    setActionHighestCount(newActionHighestCount);
    setIsDataLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageViewerActionData, timelapsePlayerActionData]);

  const normalize = (rawValue, min = 0, max = 50) => {
    let value = rawValue;
    value = Math.max(value, min);
    value = Math.min(value, max);

    const normalized = (value - min) / (max - min);

    return normalized;
  };

  const heatMapColor = (value) => {
    // 0 - dark blue
    // 0.75 - light blue
    // 0.5 - green
    // 0.25 - yellow
    // 1 - red

    const h = (1 - value) * 240;
    const s = 100;
    const l = 50;

    return `hsl(${h},${s}%,${l}%)`;
  };

  useEffect(() => {
    if (isDataLoaded) {
      setHeatMapData(tab === 0 ? viewerHeatMapData : playerHeatMapData);
      setChartData(
        tab === 0 ? imageViewerActionData : timelapsePlayerActionData,
      );
      setEventCountMapper(
        tab === 0 ? viewerActionCountMapper : playerActionCountMapper,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, isDataLoaded]);

  const labelMapper = useMemo(() => {
    return tab === 0
      ? IMAGE_VIEWER_EVENT_ACTION_ITEM_MAPPER
      : TIMELAPSE_PLAYER_EVENT_ACTION_ITEM_MAPPER;
  }, [tab]);

  return (
    <Box>
      {chartData.length > 0 && (
        <Box
          sx={{
            px: {
              sm: 0,
              xl: 20,
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
            onClick={() => {
              setIsChecked(false);
              setIsHovered(false);
              setCurrentDataIndex(-1);
            }}
          >
            <Box>
              {heatMapData.map((data, index) => {
                return (
                  <MuiTooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          minWidth: 200,
                          maxWidth: "none",
                        },
                        onMouseMove: () => {
                          if (!isChecked) {
                            setIsHovered(true);
                            setCurrentDataIndex(data.index);
                          }
                        },
                        onMouseLeave: () => {
                          if (!isChecked) {
                            setIsHovered(false);
                            setCurrentDataIndex(-1);
                          }
                        },
                        onClick: (e) => {
                          e.stopPropagation();
                        },
                      },
                    }}
                    title={
                      <Box
                        sx={{
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              {data.label}
                            </Typography>

                            {data.description && (
                              <Box sx={{ mt: 0.5 }}>
                                {_.isArray(data.description) ? (
                                  <>
                                    {data.description.map((desc, descIndex) => {
                                      if (_.isString(desc)) {
                                        return (
                                          <Typography
                                            key={descIndex}
                                            sx={{ fontSize: 12 }}
                                          >
                                            {desc}
                                          </Typography>
                                        );
                                      } else {
                                        return desc;
                                      }
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {_.isString(data.description) ? (
                                      <Typography sx={{ fontSize: 12 }}>
                                        {data.description}
                                      </Typography>
                                    ) : (
                                      data.description
                                    )}
                                  </>
                                )}
                              </Box>
                            )}
                          </Box>

                          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                            {data.count}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    arrow
                    key={index}
                    open={
                      (isHovered || isChecked) &&
                      currentDataIndex === data.index
                    }
                    PopperProps={{
                      sx: {
                        zIndex: 1,
                      },
                    }}
                  >
                    <Radio
                      sx={{
                        position: "absolute",
                        ...data.pos,
                        background: heatMapColor(
                          normalize(data.count, 0, actionHighestCount[tab]),
                        ),
                        height: 20,
                        width: 20,
                        borderRadius: 1000,
                        opacity:
                          isHovered || isChecked
                            ? currentDataIndex === data.index
                              ? 0.8
                              : 0.2
                            : 0.8,
                        transform: "translate(-50%, -50%)",
                        transition: ({ transitions }) =>
                          transitions.create("opacity"),

                        color: "white !important",

                        ":hover": {
                          background: heatMapColor(
                            normalize(data.count, 0, actionHighestCount[tab]),
                          ),
                        },
                      }}
                      checked={isChecked && currentDataIndex === data.index}
                      onMouseEnter={() => {
                        if (!isChecked) {
                          setCurrentDataIndex(data.index as number);
                          setIsHovered(true);
                        }
                      }}
                      onMouseLeave={() => {
                        if (!isChecked) {
                          setCurrentDataIndex(-1);
                          setIsHovered(false);
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!isChecked || data.index !== currentDataIndex) {
                          setCurrentDataIndex(data.index as number);
                          setIsHovered(false);
                          setIsChecked(true);
                        } else {
                          setCurrentDataIndex(-1);
                          setIsHovered(false);
                          setIsChecked(false);
                        }
                      }}
                    />
                  </MuiTooltip>
                );
              })}
            </Box>

            <Box
              component={"img"}
              src={tab === 0 ? imageViewerHeatmapBg : timelapsePlayerHeatmapBg}
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",

                objectFit: "cover",
                display: "block",
                userSelect: "none",
                pointerEvents: "none",
              }}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        {chartData.length > 0 ? (
          <Box height={400}>
            <ResponsiveContainer debounce={300}>
              <BarChart
                data={chartData}
                layout="vertical"
                onClick={(state) => {
                  if (state) {
                    if (
                      isChecked &&
                      state.activeTooltipIndex === currentDataIndex
                    ) {
                      setIsChecked(false);
                      setIsHovered(false);
                      setCurrentDataIndex(-1);
                    } else {
                      setIsChecked(true);
                      setIsHovered(false);
                      setCurrentDataIndex(state.activeTooltipIndex as number);
                    }
                  }
                }}
                onMouseLeave={(state) => {
                  if (!isChecked) {
                    setCurrentDataIndex(-1);
                    setIsHovered(false);
                  }
                }}
                onMouseMove={(state) => {
                  if (!isChecked) {
                    if (state.isTooltipActive) {
                      setCurrentDataIndex(state.activeTooltipIndex as number);
                      setIsHovered(true);
                    } else {
                      setCurrentDataIndex(-1);
                      setIsHovered(false);
                    }
                  }
                }}
              >
                <XAxis
                  type="number"
                  dataKey="count"
                  fontSize={12}
                  domain={["auto", "dataMax"]}
                  interval={0}
                />

                <YAxis
                  type="category"
                  dataKey="actionName"
                  fontSize={12}
                  textAnchor="end"
                  width={100}
                  tickLine={false}
                  interval={0}
                  tickFormatter={(label) => {
                    const { title } = labelMapper[label] || {};

                    return _.capitalize(
                      _.truncate(title || label, {
                        length: 13,
                        separator: /,? +/,
                      }),
                    );
                  }}
                />

                <Tooltip
                  content={(props) => {
                    const { active, payload, label } = props;

                    const { title, description: labelDescription } =
                      labelMapper[label] || {};

                    const description = (
                      <>
                        {labelDescription && (
                          <Typography sx={{ fontSize: 12 }}>
                            {labelDescription}
                          </Typography>
                        )}
                      </>
                    );

                    return (
                      <>
                        {active && payload?.length && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: 4,
                              background: ({ palette }) => palette.surface.main,
                              boxShadow: 1,
                              borderRadius: 1,
                              p: 1,
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{ fontSize: 16, fontWeight: "bold" }}
                              >
                                {title || label}
                              </Typography>

                              {description}
                            </Box>

                            <Typography
                              sx={{ fontSize: 16, fontWeight: "bold" }}
                            >
                              {payload?.[0].value}
                            </Typography>
                          </Box>
                        )}
                      </>
                    );
                  }}
                />

                <Bar
                  dataKey="count"
                  fill={getContrastShade(palette.info, "dark")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {chartData.map((data, dataIndex) => {
                    return (
                      <Cell
                        key={dataIndex}
                        style={{
                          transition: "fill 0.3s ease",
                        }}
                        fill={
                          isHovered || isChecked
                            ? currentDataIndex === dataIndex
                              ? getContrastShade(palette.info, "dark")
                              : alpha(
                                  getContrastShade(palette.info, "dark"),
                                  0.2,
                                )
                            : getContrastShade(palette.info, "dark")
                        }
                      />
                    );
                  })}

                  <LabelList
                    dataKey="count"
                    fill="#ffffff"
                    fontSize={12}
                    position={"insideRight"}
                    formatter={(label) => {
                      return label > 0 ? label : null;
                    }}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box height={300}>
            <NoDataCard />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ActionChart = ({
  isLoading,
  imageViewerActionData,
  timelapsePlayerActionData,
}) => {
  const [tab, setTab] = useState(0);

  return (
    <CardContainer title="Function Used" isLoading={isLoading} height={"100%"}>
      <Box>
        <Tabs
          variant="fullWidth"
          sx={{
            px: {
              xl: 20,
              lg: 0,
            },
            minHeight: 32,

            ".Mui-selected": {
              backgroundColor: ({ palette }) =>
                getContrastShade(palette.primary, "dark"),
              color: ({ palette }) => `${palette.onPrimary.main} !important`,
            },

            transition: ({ transitions }) => transitions.create("background"),

            ".MuiTab-root": {
              fontSize: 12,
              textTransform: "none",
              padding: 0.5,
              minHeight: 32,
            },
          }}
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
        >
          <Tab label="Image Viewer" />
          <Tab label="Timelapse Player" />
        </Tabs>
      </Box>

      <HeatMapChart
        imageViewerActionData={imageViewerActionData}
        timelapsePlayerActionData={timelapsePlayerActionData}
        tab={tab}
      />
    </CardContainer>
  );
};

const TopActiveUsersCard = ({
  data,
  isLoading,
  selectedDateRange,
}: {
  data: AnalyticTopActiveUserItem[];
  isLoading: boolean;
  selectedDateRange: string[];
}) => {
  const [detailData, setDetailData] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<AnalyticTopActiveUserItem | null>(null);

  const caches = useRef({});

  const { palette } = useTheme();

  useEffect(() => {
    return () => {
      setDetailData(null);
      setCurrentItem(null);
      caches.current = {};
    };
  }, [isLoading]);

  const getUserDetail = async (userId, gaClientId) => {
    setIsLoadingData(true);

    const key = `${userId}:${gaClientId}`;

    try {
      const cache = caches.current[key];

      let data;

      if (cache) {
        data = cache;
      } else {
        data = await getFirebaseController()
          .Analytic.getDetailReport({
            type: "user",
            filters: {
              dateRange: selectedDateRange,
              userId,
              gaClientId,
            },
          })
          .then((data) => data["data"]);

        caches.current[key] = data;
      }

      setDetailData(data);
      setIsLoadingData(false);
    } catch (error) {
      console.error(error);

      setIsLoadingData(false);
    }
  };

  const handleItemClick = (item: AnalyticTopActiveUserItem) => {
    if (item) {
      setCurrentItem(item);
      getUserDetail(item.userId, item.gaClientId);
    }
  };

  return (
    <CardContainer
      title={"Top Active Users"}
      info={
        "Total number of view events from the gallery, image viewer, and timelapse player for each user. \n \n When the 'show unique users' setting is enabled, multiple usernames may be displayed if different individuals are using the same account."
      }
      isLoading={isLoading || isLoadingData}
    >
      {detailData ? (
        <Box sx={{ pt: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: getContrastShade(palette.info, "dark"),

              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,

                cursor: "pointer",
                width: "max-content",

                ":hover": {
                  color: ({ palette }) => palette.info.main,
                },
              }}
              onClick={() => {
                setCurrentItem(null);
                setDetailData(null);
              }}
            >
              <ArrowBackIcon fontSize="small" />

              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {`${currentItem?.userName} (${currentItem?.gaClientId})`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "max-content",

                ":hover": {
                  color: ({ palette }) => palette.info.main,
                },
              }}
              onClick={() => {
                if (currentItem) {
                  window.open(
                    `${window.location.origin}/users/${currentItem.userId}`,
                  );
                }
              }}
            >
              <MuiTooltip title="Open user in new tab">
                <OpenInNewIcon sx={{ fontSize: 14 }} />
              </MuiTooltip>
            </Box>
          </Box>

          <ListBarChart data={detailData} xKey={"count"} yKey={"title"} />
        </Box>
      ) : (
        <TopListBarChart
          data={data}
          xKey="count"
          yKey={"userName"}
          itemOnClick={handleItemClick}
        />
      )}
    </CardContainer>
  );
};

// TODO: can make this a shareable component with TopActiveUsersCard
const TopActiveClientsCard = ({
  data,
  isLoading,
  selectedDateRange,
}: {
  data: AnalyticTopActiveClientItem[];
  isLoading: boolean;
  selectedDateRange: string[];
}) => {
  const [detailData, setDetailData] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<AnalyticTopActiveClientItem | null>(null);

  const caches = useRef({});

  const getClientDetail = async (clientId) => {
    setIsLoadingData(true);

    const key = `${clientId}`;

    try {
      const cache = caches.current[key];

      let data;

      if (cache) {
        data = cache;
      } else {
        data = await getFirebaseController()
          .Analytic.getDetailReport({
            type: "client",
            filters: {
              dateRange: selectedDateRange,
              clientId,
            },
          })
          .then((data) => data["data"]);

        caches.current[key] = data;
      }

      setDetailData(data);
      setIsLoadingData(false);
    } catch (error) {
      console.error(error);

      setIsLoadingData(false);
    }
  };

  const handleItemClick = (item: AnalyticTopActiveClientItem) => {
    setCurrentItem(item);
    getClientDetail(item.clientId);
  };

  const { palette } = useTheme();

  return (
    <CardContainer
      title={"Top Active Clients"}
      info={
        "Total number of view events from the gallery, image viewer, and timelapse player for each user under the same client."
      }
      isLoading={isLoading || isLoadingData}
    >
      {detailData ? (
        <Box sx={{ pt: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: getContrastShade(palette.info, "dark"),

              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,

                cursor: "pointer",
                width: "max-content",

                ":hover": {
                  color: ({ palette }) => palette.info.main,
                },
              }}
              onClick={() => {
                setCurrentItem(null);
                setDetailData(null);
              }}
            >
              <ArrowBackIcon fontSize="small" />

              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {`${currentItem?.clientName} (${currentItem?.clientId})`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                width: "max-content",

                ":hover": {
                  color: ({ palette }) => palette.info.main,
                },
              }}
              onClick={() => {
                if (currentItem) {
                  window.open(
                    `${window.location.origin}/clients/${currentItem.clientId}`,
                  );
                }
              }}
            >
              <MuiTooltip title="Open client in new tab">
                <OpenInNewIcon sx={{ fontSize: 14 }} />
              </MuiTooltip>
            </Box>
          </Box>

          <ListBarChart data={detailData} xKey={"count"} yKey={"title"} />
        </Box>
      ) : (
        <TopListBarChart
          data={data}
          xKey="count"
          yKey={"clientName"}
          itemOnClick={handleItemClick}
        />
      )}
    </CardContainer>
  );
};

const AnalyticIndexWindow = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [usersOverTimeData, setUsersOverTimeData] = useState<
    AnalyticActiveUsersItem[]
  >([]);
  const [imageViewerActionData, setImageViewerActionData] = useState<
    AnalyticActionCountItem[]
  >([]);
  const [timelapsePlayerActionData, setTimelapsePlayerActionData] = useState<
    AnalyticActionCountItem[]
  >([]);
  const [topActiveUsersData, setTopActiveUsersData] = useState<
    AnalyticTopActiveUserItem[]
  >([]);
  const [topActiveClients, setTopActiveClients] = useState<
    AnalyticTopActiveClientItem[]
  >([]);
  const [topViewedGalleryData, setTopViewedGalleryData] = useState<
    AnalyticTopViewedGalleryItem[]
  >([]);

  const [seletedDateOption, setSelectedDateOption] =
    useState<string>("Past Month");
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([
    moment().subtract("1", "months").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedJobSite, setSelectedJobSite] = useState<JobSite | null>(null);
  const [selectedGallery, setSelectedGallery] = useState<GalleryV2 | null>(
    null,
  );
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isNoAdmin, setIsNoAdmin] = useState(true);
  const [isUniqueUser, setIsUniqueUser] = useState(true);

  const [dateMenuAnchorEl, setDateMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [clientMenuAnchorEl, setClientMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [jobSiteMenuAnchorEl, setJobSiteMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [galleryMenuAnchorEl, setGalleryMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const [getReportTrigger, setGetReportTrigger] = useState<boolean>(false);

  const clientList = useAtomValue(clientListCacheState);
  const allJobSiteList = useAtomValue(jobSitesListCacheState);
  const [jobSiteList, setJobSiteList] = useState(allJobSiteList);
  const [galleryList, setGalleryList] = useAtom(galleryListCacheState);
  const [userList, setUserList] = useAtom(userListCacheState);
  const [userRoleList, setUserRoleList] = useAtom(userRolesListCacheState);
  const [userRoleMapper, setUserRoleMapper] = useState<{
    [key: number]: UserRole;
  }>({});

  const [displayUserList, setDisplayUserList] = useState<User[]>([]);

  const getReport = async (
    filters: AnalyticReportFilterOptions = {},
    settings: AnalyticReportSettingOptions = {},
  ) => {
    setIsLoading(true);

    // TODO: refactor this, might be a good idea to combine all filter states in one

    filters.dateRange = filters.dateRange || selectedDateRange;

    const clientId = filters.clientId || selectedClient?.id;

    if (clientId) {
      filters.clientId = clientId;
    }

    const siteId = filters.siteId || selectedJobSite?.id;

    if (siteId) {
      filters.siteId = siteId;
    }

    const galleryId = filters.galleryId || selectedGallery?.id;

    if (galleryId) {
      filters.galleryId = galleryId;
    }

    const userId = filters.userId || selectedUser?.id;

    if (userId) {
      filters.userId = userId;
    }

    settings.noAdmin = settings.noAdmin || isNoAdmin;
    settings.uniqueUser = settings.uniqueUser || isUniqueUser;

    await getFirebaseController()
      .Analytic.getAnalyticsReport({
        filters,
        settings,
      })
      .then((data) => {
        setUsersOverTimeData(data.activeUsers || {});
        setTopActiveUsersData(data.topActiveUsers || []);
        setTopViewedGalleryData(data.topViewedGallery || []);
        setTopActiveClients(data.topActiveClients || []);
        setImageViewerActionData(data.imageViewerActionCount || []);
        setTimelapsePlayerActionData(data.timelapsePlayerActionCount || []);
      });

    setIsLoading(false);
  };

  const handleDateOptionChanged = (dateOption) => {
    const currentDate = moment();

    switch (dateOption) {
      case "Past Day":
        handleDateApply([
          moment().subtract("1", "days").format("YYYY-MM-DD"),
          currentDate.format("YYYY-MM-DD"),
        ]);

        break;
      case "Past 3 Days":
        handleDateApply([
          moment().subtract("3", "days").format("YYYY-MM-DD"),
          currentDate.format("YYYY-MM-DD"),
        ]);

        break;
      case "Past Week":
        handleDateApply([
          moment().subtract("1", "weeks").format("YYYY-MM-DD"),
          currentDate.format("YYYY-MM-DD"),
        ]);
        break;
      case "Past Month":
        handleDateApply([
          moment().subtract("1", "months").format("YYYY-MM-DD"),
          currentDate.format("YYYY-MM-DD"),
        ]);

        break;
      case "Past 3 Months":
        handleDateApply([
          moment().subtract("3", "months").format("YYYY-MM-DD"),
          currentDate.format("YYYY-MM-DD"),
        ]);

        break;
      case "Custom":
        break;
      default:
        return;
    }

    setSelectedDateOption(dateOption);
  };

  const handleDateApply = async (dateRange) => {
    setSelectedDateRange(dateRange);
    setDateMenuAnchorEl(null);

    setGetReportTrigger(!getReportTrigger);
  };

  const handleClientFilter = (client?: Client) => {
    if (client) {
      setSelectedClient(client);
    } else {
      setSelectedClient(null);
    }

    setSelectedJobSite(null);
    setSelectedGallery(null);

    setGetReportTrigger(!getReportTrigger);
  };

  const handleSiteFilter = (site?: JobSite) => {
    if (site) {
      setSelectedJobSite(site);

      const client = clientList.find((client) => {
        return site.associatedClients[0] === client.id;
      });

      setSelectedClient(client || null);
    } else {
      setSelectedJobSite(null);
    }

    setSelectedGallery(null);

    setGetReportTrigger(!getReportTrigger);
  };

  const handleGalleryFilter = (gallery?: GalleryV2) => {
    if (gallery) {
      const site = jobSiteList.find((site) => {
        return site.id === gallery.jobSite;
      });

      if (site) {
        const client = clientList.find((client) => {
          return site.associatedClients[0] === client.id;
        });

        setSelectedJobSite(site || null);
        setSelectedClient(client || null);
      }

      setSelectedGallery(gallery);
    } else {
      setSelectedGallery(null);
    }

    setGetReportTrigger(!getReportTrigger);
  };

  const handleUserFilter = (user?: User) => {
    if (user) {
      setSelectedUser(user);
    } else {
      setSelectedUser(null);
    }

    setGetReportTrigger(!getReportTrigger);
  };

  useEffect(() => {
    getReport();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReportTrigger]);

  useEffect(() => {
    if (galleryList.length === 0) {
      getFirebaseController()
        .Gallery.getGalleries()
        .then((galleries) => {
          galleries.forEach((gallery) => {
            if (!_jobSiteCaches[gallery.id as number]) {
              const site = jobSiteList.find((site) => {
                return site.id === gallery.jobSite;
              });

              if (site) {
                _jobSiteCaches[gallery.id as number] = site;
              }
            }
          });

          const sortedGalleryList = _.orderBy(
            galleries,
            [
              (gallery) =>
                (
                  _jobSiteCaches[gallery.id as number]?.name || "-"
                ).toLowerCase(),
              (gallery) => gallery.galleryName.toLowerCase(),
            ],
            ["asc", "asc"],
          );

          setGalleryList(sortedGalleryList);
        });
    }

    if (userRoleList.length === 0) {
      getFirebaseController()
        .User.getUserRoles()
        .then(async (data) => {
          const userRoleMapper: { [key: number]: UserRole } = {};
          const userIds: number[] = [];

          data.forEach((userRole) => {
            if (userRole.associatedUser) {
              userRoleMapper[userRole.associatedUser] = userRole;
              userIds.push(userRole.associatedUser);
            }
          });

          const usersList = await getFirebaseController().User.getUsers(
            userIds,
          );

          const sortedUserList = orderByIgnoreCase(usersList, "username");

          setDisplayUserList(
            getDisplayUserList(sortedUserList, userRoleMapper, true),
          );

          setUserList(sortedUserList);
          setUserRoleList(data);
          setUserRoleMapper(userRoleMapper);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisplayUserList = (
    initUserList: User[],
    userRoleMapper: { [key: number]: UserRole },
    isNoAdmin: boolean,
  ) => {
    if (isNoAdmin) {
      return initUserList.filter((user) => {
        const userRole = userRoleMapper[user.id as number];

        return userRole && userRole.accessLevel !== "3";
      });
    } else {
      return initUserList;
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSetting = (setting: "noAdmin" | "uniqueUser") => {
    if (setting === "noAdmin") {
      setIsNoAdmin(!isNoAdmin);
      setDisplayUserList(
        getDisplayUserList(userList, userRoleMapper, !isNoAdmin),
      );
    } else if (setting === "uniqueUser") {
      setIsUniqueUser(!isUniqueUser);
    }

    setGetReportTrigger(!getReportTrigger);
    setAnchorEl(null);
  };

  const { palette } = useTheme();

  return (
    <DashboardContainer NavbarProps={{ title: "Analytics" }}>
      <Box
        sx={{
          borderRadius: 1,
        }}
      >
        <DateMenu
          anchorEl={dateMenuAnchorEl}
          open={!!dateMenuAnchorEl}
          onClose={() => setDateMenuAnchorEl(null)}
          selectedDateOption={seletedDateOption}
          selectedDateRange={selectedDateRange}
          handleDateOptionChanged={handleDateOptionChanged}
          handleApply={handleDateApply}
        />

        <DropDownSelectMenu<Client>
          searchLabel="Client"
          open={!!clientMenuAnchorEl}
          anchorEl={clientMenuAnchorEl}
          onClose={() => setClientMenuAnchorEl(null)}
          selectedItem={selectedClient}
          onItemClick={handleClientFilter}
          dataList={clientList}
        />

        <DropDownSelectMenu<JobSite>
          searchLabel="Site"
          open={!!jobSiteMenuAnchorEl}
          anchorEl={jobSiteMenuAnchorEl}
          onClose={() => setJobSiteMenuAnchorEl(null)}
          selectedItem={selectedJobSite}
          onItemClick={handleSiteFilter}
          dataList={jobSiteList}
        />

        <DropDownSelectMenu<GalleryV2>
          searchLabel="Gallery"
          open={!!galleryMenuAnchorEl}
          anchorEl={galleryMenuAnchorEl}
          onClose={() => setGalleryMenuAnchorEl(null)}
          selectedItem={selectedGallery}
          onItemClick={handleGalleryFilter}
          dataList={galleryList}
          labelFormat={(gallery) => {
            const site = _jobSiteCaches[gallery.id as number];

            if (site) {
              return `${site.name} - ${gallery.galleryName}`;
            } else {
              return `${gallery.galleryName}`;
            }
          }}
          customSearchFields={(gallery) => {
            const site = _jobSiteCaches[gallery.id as number];

            const fields = [_.toLower(gallery.galleryName)];

            if (site) {
              fields.push(_.toLower(site.name), "-");
            }

            return fields;
          }}
        />

        <DropDownSelectMenu<User>
          searchLabel="User"
          open={!!userMenuAnchorEl}
          anchorEl={userMenuAnchorEl}
          onClose={() => setUserMenuAnchorEl(null)}
          selectedItem={selectedUser}
          onItemClick={handleUserFilter}
          dataList={displayUserList}
          labelKey={"username"}
        />

        <Box
          sx={{
            width: "100%",
            minHeight: 60,
            background: ({ palette }) => palette.secondary.main,
            position: "sticky",
            zIndex: 10,
            top: ({ customConfig }) =>
              `calc(${customConfig.navbarHeight} + ${customConfig.subNavbarHeight})`,

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
            mb: 2,
            py: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexBasis: { xs: "100%", sm: "auto" },
              overflow: "auto",
              alignItems: "center",
            }}
          >
            <FilterButton
              label={"Client"}
              value={selectedClient ? selectedClient.name : "All"}
              onClick={(e) => {
                setClientMenuAnchorEl(e.currentTarget);
              }}
              isActive={!!selectedClient}
            />

            <FilterButton
              label={"Site"}
              value={selectedJobSite ? selectedJobSite.name : "All"}
              onClick={(e) => {
                setJobSiteMenuAnchorEl(e.currentTarget);
              }}
              isActive={!!selectedJobSite}
            />

            <FilterButton
              label={"Gallery"}
              value={selectedGallery ? selectedGallery.galleryName : "All"}
              onClick={(e) => {
                setGalleryMenuAnchorEl(e.currentTarget);
              }}
              isActive={!!selectedGallery}
            />

            <FilterButton
              label={"User"}
              value={selectedUser ? selectedUser.username : "All"}
              onClick={(e) => {
                setUserMenuAnchorEl(e.currentTarget);
              }}
              isActive={!!selectedUser}
            />

            <Box>
              <Button
                fullWidth
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                size="small"
                variant="contained"
                color={"info"}
                sx={{
                  textTransform: "none",
                  fontSize: 12,
                  background: ({ palette }) => palette.info.main,

                  color: ({ palette }) =>
                    `${palette.onPrimary.main} !important`,

                  border: ({ palette }) =>
                    `1px solid ${alpha(palette.onPrimary.main, 0.1)}`,
                  minWidth: "auto",
                  minHeight: 27.5,
                }}
              >
                <SettingsIcon sx={{ fontSize: 16 }} />
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuList
                  dense
                  sx={{
                    width: 200,
                    padding: 0,
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleFilterSetting("noAdmin");
                    }}
                  >
                    <ListItemText>Show Admin User</ListItemText>

                    {!isNoAdmin && <CheckIcon fontSize="small" />}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleFilterSetting("uniqueUser");
                    }}
                  >
                    <ListItemText>Show Unique User</ListItemText>
                    {isUniqueUser && <CheckIcon fontSize="small" />}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Box>

          <Box sx={{ flexBasis: { xs: "100%", sm: "auto" } }}>
            <FilterButton
              label={seletedDateOption}
              value={`${moment(selectedDateRange[0]).format(
                "DD MMM",
              )} - ${moment(selectedDateRange[1]).format("DD MMM, YYYY")}`}
              onClick={(e) => {
                setDateMenuAnchorEl(e.currentTarget);
              }}
            />
          </Box>
        </Box>

        <Box
          sx={
            {
              // ".card-container": {
              //   background: ({ palette }) =>
              //     !isLoading
              //       ? alpha(palette.onSurface.main, 0.1)
              //       : palette.surface.main,
              // },
            }
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardContainer
                title="Daily Active Users"
                info={
                  "Unique daily visitor (tracked by GA Client Id) regardless of whether it is the same user account."
                }
                isLoading={isLoading}
              >
                {usersOverTimeData.length > 1 ? (
                  <ResponsiveContainer height={300} debounce={300}>
                    <LineChart data={usersOverTimeData}>
                      <Tooltip
                        labelFormatter={(label) => {
                          return moment(label).format("DD MMM YYYY");
                        }}
                      />
                      <XAxis
                        dataKey="date"
                        fontSize={12}
                        label={{ fill: getContrastShade(palette.info, "dark") }}
                        color={getContrastShade(palette.info, "dark")}
                        type="category"
                        interval="preserveStartEnd"
                        tickFormatter={(label) => {
                          return moment(label).format("DD/MM");
                        }}
                      />
                      <YAxis
                        fontSize={12}
                        dataKey={"count"}
                        type="number"
                        interval="preserveStartEnd"
                        width={25}
                      />
                      <ReferenceLine
                        stroke="black"
                        ifOverflow="visible"
                        strokeDasharray="3 3"
                        segment={[
                          {
                            x: usersOverTimeData[0].date,
                            y: usersOverTimeData[0].count,
                          },
                          {
                            x: usersOverTimeData[usersOverTimeData.length - 1]
                              .date,
                            y: usersOverTimeData[usersOverTimeData.length - 1]
                              .count,
                          },
                        ]}
                      />
                      <Line
                        type="monotone"
                        data={usersOverTimeData}
                        dataKey="count"
                        stroke={getContrastShade(palette.info, "dark")}
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <Box
                    sx={{
                      height: 300,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <NoDataCard />
                  </Box>
                )}
              </CardContainer>
            </Grid>
          </Grid>

          <Grid container spacing={2} pt={4}>
            <Grid item xs={12} sm={6} md={4}>
              <TopActiveUsersCard
                data={topActiveUsersData}
                isLoading={isLoading}
                selectedDateRange={selectedDateRange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TopActiveClientsCard
                data={topActiveClients}
                isLoading={isLoading}
                selectedDateRange={selectedDateRange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardContainer title="Top Viewed Gallery" isLoading={isLoading}>
                <TopListBarChart
                  data={topViewedGalleryData}
                  xKey="count"
                  yKey={"galleryFriendlyName"}
                  topItemLabel={(value) => {
                    return (
                      <>
                        {value.split(" - ").map((value, index) => {
                          return (
                            <Typography
                              key={index}
                              sx={{
                                fontSize: 26 - (index + 1) * 4,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {value}
                            </Typography>
                          );
                        })}
                      </>
                    );
                  }}
                />
              </CardContainer>
            </Grid>
          </Grid>

          <Grid container spacing={2} pt={4}>
            <Grid item xs={12}>
              <ActionChart
                isLoading={isLoading}
                imageViewerActionData={imageViewerActionData}
                timelapsePlayerActionData={timelapsePlayerActionData}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardContainer>
  );
};

export default AnalyticIndexWindow;
